import { useMemoizedFn } from 'ahooks';
import { useAtomValue } from 'jotai';
import { useAtomsDebugValue } from 'jotai-devtools';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useSyncExternalStore,
} from 'react';
import { match } from 'ts-pattern';
import type { JsonObject } from 'type-fest';

import { useCurrentOrgId } from '@/hook';

import type { LayoutPositionType } from '../models/Store';
import { widgetInstanceEventKeyCreator } from '../models/Store';
import { getInitalPageConfig } from '../utils/config';
import { editorAtom, storeAtom, widgetManagerAtom } from './atom';

/**
 * 内部使用，不对外暴露
 */
const useEditorInnerValue = <T>(
  subscribe: (fn: () => void) => () => void,
  getter: () => T,
  initialValue?: T,
) => {
  const [value, setValue] = useState(initialValue || getter);

  const fn = useRef(subscribe).current;
  useEffect(() => {
    return fn(() => {
      const v = getter();
      return setValue(v);
    });
  }, [fn, getter]);

  return value;
};

export function useEditor() {
  useAtomsDebugValue();

  const editor = useAtomValue(editorAtom);

  return editor;
}

export function useStore() {
  const store = useAtomValue(storeAtom);

  return store;
}

export function useEditorId() {
  const editor = useEditor();

  const editorId = useSyncExternalStore(
    editor.subscribeCreator('editorIdChanged'),
    () => editor.id,
  );

  return editorId;
}

export function useActiveWidgetInstance<T extends JsonObject = JsonObject>() {
  const editor = useEditor();
  const activeWidgetInstance = useSyncExternalStore(
    editor.subscribeCreator('activeWidgetChanged'),
    editor.getActiveWidgetInstance<T>,
  );

  return activeWidgetInstance;
}

export function useActiveWidgetConfig() {
  const editor = useEditor();

  const activeWidgetConfig = useEditorInnerValue(
    editor.subscribeCreator('activeWidgetChanged'),
    editor.getActiveWidgetConfig,
  );

  return activeWidgetConfig;
}

export function useSetActiveWidgetInstanceConfig<
  T extends JsonObject = JsonObject,
>() {
  const editor = useEditor();

  return editor.updateActiveWidgetInstanceConfig<T>;
}

/**
 * @todo widgets 支持动态添加/卸载
 */
export function useWidgetManager() {
  const widgetManager = useAtomValue(widgetManagerAtom);

  return widgetManager;
}

export function useWidgets() {
  const widgetManager = useWidgetManager();

  const getter = useCallback(() => {
    return widgetManager.widgets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const widgets = useEditorInnerValue(
    widgetManager.subscribeCreator('groupsChanged'),
    getter,
    getter(),
  );

  return widgets;
}

export function useCustomWidgets() {
  const widgetManager = useWidgetManager();

  const getter = useCallback(() => {
    return widgetManager.customWidgets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const widgets = useEditorInnerValue(
    widgetManager.subscribeCreator('groupsChanged'),
    getter,
    getter(),
  );

  return widgets;
}

export function useWidgetInstance<T extends JsonObject = JsonObject>(
  id: string,
) {
  const store = useStore();
  const getter = useCallback(() => {
    return store.getWidgetInstance<T>(id);
  }, [store, id]);

  const widgetInstance = useSyncExternalStore(
    store.subscribeCreator(widgetInstanceEventKeyCreator(id)),
    getter,
  );

  return widgetInstance;
}

export function useWidgetInstancesByWidgetId<T extends JsonObject = JsonObject>(
  id: string,
) {
  const store = useStore();
  const getter = useCallback(() => {
    return store.getWidgetInstancesByWidgetId<T>(id);
  }, [store, id]);

  const widgetInstance = useEditorInnerValue(
    store.subscribeCreator('widgetInstanceChanged'),
    getter,
    getter(),
  );

  return widgetInstance;
}

export function useWidgetInstanceList() {
  const store = useStore();

  const getter = useCallback(() => {
    return store.getWidgetInstanceList();
  }, [store]);

  const widgetInstanceList = useEditorInnerValue(
    store.subscribeCreator('widgetInstanceChanged'),
    getter,
    getter(),
  );

  return widgetInstanceList || [];
}

export function useEditorData() {
  const store = useStore();

  const getter = useCallback(() => {
    return store.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorData = useSyncExternalStore(
    store.subscribeCreator('widgetInstanceChanged'),
    getter,
  );

  return editorData;
}

export function useEditorWidgetTree(type?: LayoutPositionType) {
  const store = useStore();

  const getList = useMemoizedFn(() => {
    const list = match(type || 'default')
      .with('default', () => store.widgetTree)
      .with('stickyTop', () => store.topWidgetTree)
      .with('stickyBottom', () => store.bottomWidgetTree)
      .exhaustive();
    return list || [];
  });

  const list = useEditorInnerValue(
    store.subscribeCreator('widgetInstanceChanged'),
    getList,
    getList(),
  );

  return list!;
}

export function useEditorRootWidgets() {
  const store = useStore();

  const getter = useCallback(() => {
    return store.rootWidgets;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorData = useSyncExternalStore(
    store.subscribeCreator('widgetInstanceChanged'),
    getter,
  );

  return editorData;
}

/**
 * 初始化，重置都会生成新的 sessionId
 */
export function useEditorSessionId() {
  const store = useStore();

  const getter = useCallback(() => {
    return store.sessionId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editorSessionId = useSyncExternalStore(
    store.subscribeCreator('sessionIdChanged'),
    getter,
  );

  return editorSessionId;
}

export function usePageConfig() {
  const store = useStore();

  const orgId = useCurrentOrgId();

  const activeWidgetConfig = useSyncExternalStore(
    store.subscribeCreator('pageConfigChanged'),
    () => store.data.pageConfig || getInitalPageConfig(orgId),
  );

  return activeWidgetConfig;
}

export function useWidgetInstanceListByGroupId<
  T extends JsonObject = JsonObject,
>(groupId: string) {
  const editor = useEditor();

  const getter = useCallback(() => {
    return editor.getWidgetInstanceListByGroup<T>(groupId);
  }, [editor, groupId]);

  const widgetInstanceList = useEditorInnerValue(
    editor.store.subscribeCreator('widgetInstanceChanged'),
    getter,
  );

  return widgetInstanceList;
}
