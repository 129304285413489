import { toArray } from '@linkpi/utils';
import type { SelectProps } from 'antd';
import type { FC } from 'react';

import { TemplateEnumPropSelect } from '@/components/LinkPiForm';
import type { SizeType } from '@/components/PageModelEditor/src/widgets/GlobalFilterWidget/types';
import { type TemplateMapUsage, useCurrentOrgId } from '@/hook';
import { cn } from '@/utils/utils';

import { useConditionItemInnerConfig } from '../../../hooks/useConditionItemInnerConfig';
import type { Condition } from '../../../types';

// 将字符串null处理成null
const handleValueNullStringToNull = (data: any) => {
  if (!Array.isArray(data)) return data;
  return data.map((d) => {
    if (d === 'null') return null;
    return d;
  });
};

type EnumSelectProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  className?: string;
  config: Pick<Condition, 'key' | 'index'>;
  templateId: string;
  templateUsage?: TemplateMapUsage;
  disableMultiple?: boolean;
  maxTagCount?: SelectProps['maxTagCount'];
  size?: SizeType;
};

export const EnumSelect: FC<EnumSelectProps> = ({
  value,
  onChange,
  disabled,
  className,
  config,
  templateId,
  templateUsage,
  disableMultiple,
  maxTagCount,
  size,
}) => {
  const orgId = useCurrentOrgId();
  const conditionConfig = useConditionItemInnerConfig({
    ...config,
    templateId,
    templateUsage,
  });

  const propInfo = conditionConfig?.data;

  const tagRender = propInfo?.extendColorMode
    ? (tagProps: any) => (
        <span
          className="mr-1 px-2 text-white rounded-full"
          style={{
            backgroundColor:
              propInfo?.extendColor?.[
                propInfo?.extend.findIndex((e: string) => e === tagProps.value)
              ],
          }}
        >
          {tagProps.value}
        </span>
      )
    : undefined;

  return (
    <TemplateEnumPropSelect
      fieldProps={{
        tagRender,
        className: cn('w-full', className),
        mode: disableMultiple ? undefined : 'multiple',
        maxTagCount,
        maxTagTextLength: 7,
        placeholder: '请选择',
        value: value || [],
        onChange: (e) => {
          const newInput = handleValueNullStringToNull(e);
          onChange?.(newInput ? toArray(newInput) : []);
        },
        size,
      }}
      showAux={false}
      noStyle
      propIndex={config.index!}
      templateId={templateId}
      orgId={orgId}
      disabled={disabled}
    />
  );
};
