import { useMemo } from 'react';

import { RegularIcon } from '@/components/IconFont';
import { useOrgTemplatesSettingInfoMap } from '@/hook';
import { cn } from '@/utils/utils';

import type { RGBAColor } from '../../ColorInput';
//
import type { IDataIndicatorIndexType, IDataIndicatorWidget } from '..';

import styles from './styles.less';

export const PreviewCards = (props: {
  data: IDataIndicatorWidget;
  selectable?: boolean;
  selectId?: string;
  onSelect?: (id: string) => void;
}) => {
  const { data, selectable, selectId, onSelect } = props;

  const tempMap = useOrgTemplatesSettingInfoMap();

  const indexWithSort = useMemo(() => {
    if (!data || !data.indexSort || !data.indexes) return [];
    return data.indexSort
      .map((indexId) => ({ id: indexId, data: data.indexes[indexId] }))
      .filter((x) => !!x.data);
  }, [data]);

  function getTempPropName(
    dataSource: IDataIndicatorIndexType['dataSource'],
    propIndex: number | undefined,
  ) {
    const tempIdCondition = dataSource.conditions.find(
      (x: any) => x.key === 'templateId',
    );
    const tempId = tempIdCondition?.input?.[0] || null;

    if (!tempId || propIndex === undefined) return '未命名';
    return tempMap[tempId]?.prop?.[propIndex]?.name || '未命名';
  }

  const onCardClick = (id: string) => {
    if (!selectable || !onSelect) return;
    if (selectId !== id) {
      onSelect(id);
    }
  };

  const renderIndex = (
    indexData: {
      id: string;
      data: IDataIndicatorIndexType;
    },
    index: number,
  ) => {
    const icon = indexData.data.showIcon ? (
      <div className={styles.indexIcon}>
        <img src={indexData.data.iconSrc} />
      </div>
    ) : null;

    const subIndex = (
      <div className={styles.subitemWrapper}>
        {indexData.data.subitems.map((subitem, index) => {
          const propName = getTempPropName(
            indexData.data.dataSource,
            subitem.propIndex,
          );
          const propNameStyle = getSubitemTextStyle(
            indexData.data.styles.propName,
          );

          const propValue = '—';
          const propValueStyle = getSubitemTextStyle(
            indexData.data.styles.propValue,
          );

          const statisticalName = subitem.statisticalName;
          const statisticalValue = '0';
          const statisticalStyle = getSubitemTextStyle(
            indexData.data.styles.statisticalProp,
          );

          const statisticalIcon = subitem.showSuffixIcon ? (
            <RegularIcon
              size={12}
              type="iconshangjiantou"
              color="rgba(255,255,255,0.5)"
            />
          ) : null;

          return (
            <div
              className={styles.subitem}
              key={index}
              style={{
                padding:
                  indexData.data.styles.spacingType === 'normal'
                    ? '0 16px'
                    : '0 10px',
              }}
            >
              <div className={styles.subitemTitle} style={propNameStyle}>
                {propName}
              </div>
              <div className={styles.subitemContent}>
                <div style={propValueStyle}>{propValue}</div>
                {subitem.showStatistical ? (
                  <>
                    <div style={statisticalStyle}>{statisticalName}</div>
                    <div style={statisticalStyle}>{statisticalValue}</div>
                    <div>{statisticalIcon}</div>
                  </>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    );

    let cardPaddingLeft = 0;
    if (indexData.data.showIcon && indexData.data.iconPlacement === 'left') {
      cardPaddingLeft =
        indexData.data.styles.spacingType === 'normal' ? 16 : 10;
    }

    return (
      <div
        key={indexData.id}
        id={'indexCard_' + indexData.id}
        onClick={() => onCardClick(indexData.id)}
        className={cn(styles.indexCard, {
          [styles.iconPositionLeft]:
            indexData.data.showIcon && indexData.data.iconPlacement === 'left',
          [styles.iconPositionRight]:
            indexData.data.showIcon && indexData.data.iconPlacement === 'right',
          [styles.selected]: selectable && indexData.id === selectId,
        })}
        style={{
          background: indexData.data.styles.background,
          marginRight: indexData.data.styles.spacingType === 'normal' ? 16 : 10,
          paddingLeft: cardPaddingLeft,
        }}
      >
        {icon}
        {subIndex}
      </div>
    );
  };

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.selectable]: selectable,
      })}
    >
      {indexWithSort.map((indexData, index) => renderIndex(indexData, index))}
    </div>
  );
};

function componentToHex(c: number): string {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}
function rgbatohex(color: RGBAColor): string {
  const { r, g, b, a } = color;

  // 透明度 a 是 0-1 的浮点数，乘以 255 后转换为整数
  const alpha = Math.round(a * 255);

  return (
    '#' +
    componentToHex(r) +
    componentToHex(g) +
    componentToHex(b) +
    componentToHex(alpha)
  );
}

function getSubitemTextStyle(
  styleConfig: IDataIndicatorWidget['indexes'][string]['styles']['propName'],
) {
  const res: React.CSSProperties = {
    fontSize: styleConfig.fontSize,
    color: rgbatohex(styleConfig.color),
  };
  if (styleConfig.bold) {
    res['fontWeight'] = 'bold';
  }
  if (styleConfig.italic) {
    res['fontStyle'] = 'italic';
  }
  if (styleConfig.underline) {
    res['textDecoration'] = 'underline';
  }
  return res;
}
