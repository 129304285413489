import { SettingOutlined } from '@ant-design/icons';
import ProForm from '@ant-design/pro-form';
import { delay } from '@linkpi/utils';
import { Button, Switch } from 'antd';
import { useSetAtom } from 'jotai';
import { nanoid } from 'nanoid';

import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import { SelectedFilterIdAtom } from '../../store';
import type { IGlobalConditionFilterConfig } from '../../types';
import ArrangementPopover from '../ArrangementPopover';
import { SortPopover } from '../SortPopover';

import Styles from './index.less';

const FilterToolbar = () => {
  const setSelectedFilterId = useSetAtom(SelectedFilterIdAtom);
  const form = ProForm.useFormInstance<IGlobalConditionFilterConfig>();

  const handleAdd = async () => {
    console.log(form.getFieldValue('conditionList'));
    const id = nanoid();
    form.setFieldsValue({
      conditionList: [
        ...form.getFieldValue('conditionList'),
        {
          name: '未命名',
          id,
          filedType: 'text' as const,
          showName: true,
        },
      ],
    });

    await delay(10);
    setSelectedFilterId(id);
  };

  return (
    <div
      className={cn(
        'h-12 flex items-center justify-between px-6 z-10',
        Styles['toolbar'],
      )}
    >
      <div className="flex items-center">
        <div className="flex items-center">
          <div className="mr-2">查询重置</div>
          <ProForm.Item
            noStyle
            name={['enableSubmitButton']}
            valuePropName="checked"
          >
            <Switch size="small" />
          </ProForm.Item>
        </div>
        <div className="flex items-center ml-6">
          <div className="mr-2">展开收起</div>
          <ProForm.Item noStyle name={['enableFold']} valuePropName="checked">
            <Switch size="small" />
          </ProForm.Item>
        </div>
      </div>
      <div className="flex items-center gap-3">
        <ProForm.Item noStyle name={['arrangement']}>
          <ArrangementPopover>
            <Button
              onClick={console.log}
              icon={<SettingOutlined color="#fff" />}
            >
              高级设置
            </Button>
          </ArrangementPopover>
        </ProForm.Item>
        <ProForm.Item noStyle name={['conditionList']}>
          <SortPopover>
            <Button icon={<RegularIcon size={16} type="iconpaixu" />}>
              排序
            </Button>
          </SortPopover>
        </ProForm.Item>
        <Button
          type="primary"
          onClick={handleAdd}
          icon={
            <RegularIcon
              size={16}
              type="iconchangyong-tianjia"
              color="#fff"
              className=" align-middle"
            />
          }
        >
          筛选项
        </Button>
      </div>
    </div>
  );
};

export default FilterToolbar;
