import { useAtom, useSetAtom } from 'jotai';

import {
  addConditionGroupAtom,
  rootConditionAtom,
  updateRootLogicAtom,
} from './atoms';

export function useConditionRoot() {
  const [rootCondition, updateRootCondition] = useAtom(rootConditionAtom);
  const addGroup = useSetAtom(addConditionGroupAtom);
  const updateRootLogic = useSetAtom(updateRootLogicAtom);

  return {
    root: rootCondition,
    addGroup,
    updateLogic: updateRootLogic,
    updateRootCondition,
  };
}
