/* eslint-disable react/no-array-index-key */
import { Select } from 'antd';
import React from 'react';

import { PiButton, RegularIcon } from '@/components';
import { cn } from '@/utils/utils';

import { MATCHING_OP_OPTIONS } from '../const';
import { useConditionGroup } from '../hooks';
import ConditionItem from './ConditionItem';

interface ConditionListProps {
  groupIndex: number;
}

export const ConditionGroup: React.FC<ConditionListProps> = ({
  groupIndex,
}) => {
  const { group, addNewCondition, updateLogic } = useConditionGroup(groupIndex);

  return (
    <div
      className={cn(
        'p-2 flex flex-col gap-2 bg-[#F8F9FB] rounded-[8px]',
        'condition-list',
      )}
    >
      {group.input.map((_, index) => (
        <div key={index} className="flex items-start gap-2">
          {index === 0 ? (
            <Select
              value="当"
              disabled
              showArrow={false}
              className="w-[56px] flex-none"
            />
          ) : (
            <Select
              defaultValue="and"
              value={group.op}
              options={MATCHING_OP_OPTIONS}
              onChange={updateLogic as any}
              className="w-[56px] flex-none"
            />
          )}
          <ConditionItem
            groupIndex={groupIndex}
            conditionIndex={index}
            key={index}
          />
        </div>
      ))}
      <div className="condition-list-add">
        <PiButton
          className="w-full bg-transparent shadow-none"
          type="dashed"
          onClick={() =>
            addNewCondition({
              key: '',
              op: 'intersect',
              input: null,
            })
          }
          icon={<RegularIcon type="iconadd" />}
        >
          添加条件
        </PiButton>
      </div>
    </div>
  );
};
