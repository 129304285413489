import { useControllableValue, useMemoizedFn } from 'ahooks';
import type { CascaderProps } from 'antd';
import { Cascader, Spin } from 'antd';
import { defaultTo, head, last } from 'ramda';
import { type FC, Suspense, useEffect, useMemo, useState } from 'react';

import { useCurrentOrgId } from '@/hook';
import { fetchDataSource } from '@/utils/dataManager';
import { cn } from '@/utils/utils';

import type { ICascaderConfig, SizeType } from '../../GlobalFilterWidget/types';
import { useOptionsConfig } from './hook';

// import type { ICascaderConfig } from '../../../components';
import styles from './CascaderSelect.module.less';

export type ICascaderOption = {
  value: string;
  label: string | React.ReactNode;
  templateId: string;
  level: number;
  children?: ICascaderOption[];
};

export type ICascaderValue = {
  value: string;
  templateId: string;
  level: number;
};

type CascaderSelectProps = {
  config: ICascaderConfig;
  currentNodeId?: string;
  value?: ICascaderValue[] | ICascaderValue[][];
  onChange?: (value?: ICascaderValue[] | ICascaderValue[][]) => void;
  size?: SizeType;
};

const getCheckable = (config: ICascaderConfig, level: number) => {
  if (level === config.optionSourceConfig!.length) return true;

  const levelConfig = config.optionSourceConfig![level - 1];
  return levelConfig.checkable;
};

export const CascaderSelect: FC<CascaderSelectProps> = ({
  config,
  size,
  ...props
}) => {
  const [_value, _onChange] = useControllableValue<
    ICascaderValue[] | ICascaderValue[][]
  >(props);

  const optionSourceConfig = defaultTo([])(config?.optionSourceConfig);
  const multiple = config?.multiple ?? false;

  const value = useMemo(() => {
    if (!multiple)
      return (_value as ICascaderValue[])?.map((item) => item.value);
    return (_value as ICascaderValue[][])?.map((i) =>
      Array.isArray(i) ? i.map((j) => j.value) : i.value,
    );
  }, [_value, multiple]);

  const onChange = useMemoizedFn((_: string[], selectedOptions: unknown) => {
    if (!multiple) {
      const list = selectedOptions as ICascaderValue[];
      _onChange?.(
        list
          ?.map((item) => ({
            value: item.value,
            templateId: item.templateId,
            level: item.level,
          }))
          .filter((item) => getCheckable(config, item.level)),
      );
      return;
    } else {
      const list = selectedOptions as ICascaderValue[][];
      _onChange?.(
        list
          .map((item) =>
            item.map((i) => ({
              value: i.value,
              templateId: i.templateId,
              level: i.level,
            })),
          )
          .filter((item) => {
            const i = last(item);

            if (!i) return false;

            return getCheckable(config, i.level);
          }) as any,
      );
    }
  });

  const loadData = useMemoizedFn(async (selectedOptions: ICascaderOption[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];

    const nextLevel = targetOption.level + 1;
    const nextLevelConfig = optionSourceConfig[nextLevel - 1];

    if (!nextLevelConfig) return;

    const { dataSource } = nextLevelConfig;

    if (
      !dataSource ||
      !dataSource.conditions.length ||
      !nextLevelConfig.parentIdProp
    )
      return;

    const conditions: typeof dataSource.conditions = [
      ...dataSource.conditions,
      {
        key: 'prop',
        index: nextLevelConfig.parentIdProp,
        op: 'intersect',
        input: [targetOption.value],
      },
    ];

    const { list, status } = await fetchDataSource({
      type: dataSource.type,
      parentType: dataSource.parentType,
      conditions,
      matchings: dataSource.matchings,
      orgId,
      page: 1,
      pageSize: 100,
    });

    if (status !== 'ok') return;

    const getLabel = (node: PiNode) => {
      const propIndex = nextLevelConfig.labelProp;
      return node.tempInfo.prop[propIndex];
    };

    const getValue = (node: PiNode) => {
      const propIndex = nextLevelConfig.valueProp;
      return node.tempInfo.prop[propIndex];
    };

    targetOption.children = list.map((item) => ({
      value: getValue(item),
      label: (
        <span
          className={cn(
            getCheckable(config, nextLevel) || 'pi-cascader-label-disabled',
          )}
        >
          {getLabel(item)}
        </span>
      ),
      templateId: dataSource.conditions.find((i) => i.key === 'templateId')
        ?.input[0],
      level: nextLevel,
      isLeaf: optionSourceConfig.length === nextLevel,
    }));

    setOptions((prev) => [...prev]);
  });

  const [options, setOptions] = useState<ICascaderOption[]>([]);

  const orgId = useCurrentOrgId();

  useEffect(() => {
    (async () => {
      if (!optionSourceConfig.length) return;
      const firstLevelOptionDataSourceConfig = head(optionSourceConfig)!;
      if (
        !firstLevelOptionDataSourceConfig.dataSource ||
        !firstLevelOptionDataSourceConfig.dataSource.conditions.length
      )
        return;

      const { list, status } = await fetchDataSource({
        type: firstLevelOptionDataSourceConfig.dataSource.type,
        parentType: firstLevelOptionDataSourceConfig.dataSource.parentType,
        conditions: firstLevelOptionDataSourceConfig.dataSource.conditions,
        matchings: firstLevelOptionDataSourceConfig.dataSource.matchings,
        orgId,
        page: 1,
        pageSize: 100,
      });

      if (status !== 'ok') return;

      const getLabel = (node: PiNode) => {
        const propIndex = firstLevelOptionDataSourceConfig.labelProp;
        return node.tempInfo.prop[propIndex];
      };

      const getValue = (node: PiNode) => {
        const propIndex = firstLevelOptionDataSourceConfig.valueProp;
        return node.tempInfo.prop[propIndex];
      };

      const templateId =
        firstLevelOptionDataSourceConfig.dataSource.conditions.find(
          (item) => item.key === 'templateId',
        )?.input[0];

      setOptions(
        list.map((item) => ({
          value: getValue(item),
          label: (
            <span
              className={cn(
                getCheckable(config, 1) || 'pi-cascader-label-disabled',
              )}
            >
              {getLabel(item)}
            </span>
          ),
          templateId,
          level: 1,
          isLeaf: optionSourceConfig.length === 1,
        })),
      );
    })();
  }, [config, optionSourceConfig, orgId]);

  const showCheckedStrategy: CascaderProps['showCheckedStrategy'] =
    optionSourceConfig.find((_, index) => !getCheckable(config, index + 1))
      ? 'SHOW_CHILD'
      : 'SHOW_PARENT';

  return (
    <Cascader
      className={cn('w-full')}
      dropdownClassName={cn(styles.CascaderSelect)}
      showCheckedStrategy={showCheckedStrategy}
      value={value}
      onChange={onChange as any}
      multiple={multiple}
      options={options}
      loadData={loadData as any}
      expandTrigger="hover"
      maxTagCount="responsive"
      size={size}
      placeholder="请选择"
    />
  );
};

const defaultConfig: ICascaderConfig = {
  multiple: false,
};

export const CascaderSelectWrapper = (props: CascaderSelectProps) => {
  const { config = defaultConfig, ...otherProps } = props;

  const { data } = useOptionsConfig('jilian', config.optionId);

  return (
    <Suspense fallback={<Spin />}>
      <CascaderSelect
        {...otherProps}
        config={{
          ...config,
          optionSourceConfig:
            data?.optionSourceConfig ?? config?.optionSourceConfig ?? [],
        }}
      />
    </Suspense>
  );
};
