import { AUTO_UPDATE_RANGE_OPTIONS, Date_Format_Options } from '@linkpi/core';
import type { TimeRangePickerProps } from 'antd';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

import type { SizeType } from '@/components/PageModelEditor/src/widgets/GlobalFilterWidget/types';
import type { TemplateMapUsage } from '@/hook';
import { transRangeDate } from '@/pages/space/components/TemplateProp/components/TempPropModal/utils';
import { cn } from '@/utils/utils';

import { useConditionItemInnerConfig } from '../../../hooks/useConditionItemInnerConfig';
import type { Condition } from '../../../types';

type DateRangerPickerValue =
  | [number | undefined, number | undefined, number | undefined]
  | [];

type RangeValue = TimeRangePickerProps['value'];

interface DateRangerPickerProps {
  value?: DateRangerPickerValue;
  onChange?: (value: DateRangerPickerValue) => void;
  disabled?: boolean;
  templateId: string | string[];
  templateUsage?: TemplateMapUsage;
  config: Pick<Condition, 'key' | 'index'>;
  disableRelative?: boolean;
  className?: string;
  size?: SizeType;
}

export const DateRangerPicker: React.FC<DateRangerPickerProps> = ({
  value,
  onChange,
  disabled,
  templateId,
  templateUsage,
  config,
  disableRelative,
  className,
  size,
}) => {
  const [startDate, endDate, extraKey] = (value as string[]) || [];

  const [visibleRangePicker, setVisibleRangePicker] = useState(false);

  const conditionConfig = useConditionItemInnerConfig({
    templateId,
    templateUsage,
    key: config.key,
    index: config.index,
  });

  const conditionConfigType = conditionConfig?.type;

  let display = '';
  let datePickerValue: RangeValue = undefined;
  const dateFormat =
    conditionConfig?.data?.dateFormat ||
    (conditionConfigType === 'date' ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm');
  const dateConfig =
    Date_Format_Options.find((x) => x.value === dateFormat) ||
    Date_Format_Options[0];
  const dateModule = dateConfig.range || 'date';
  const conditionFilterFormat = dateConfig.conditionFilterFormat;
  const showTime = (dateConfig as any).pickerShowTime || undefined;

  if (value && value.length) {
    datePickerValue = [moment(startDate), moment(endDate)];

    // 自动更新 近多少天 需要自动更新日期 而且是一个时间范围
    if (extraKey !== undefined) {
      if (!isNaN(Number(extraKey))) {
        datePickerValue = [
          moment()
            .subtract(extraKey, 'd')
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0),
          moment().hour(23).minute(59).second(59).millisecond(999),
        ];
      } else {
        if (extraKey === 'month') {
          datePickerValue = [
            moment().startOf('month'),
            moment().endOf('month'),
          ];
        } else if (extraKey === 'year') {
          datePickerValue = [moment().startOf('year'), moment().endOf('year')];
        }
      }
    }
    if (extraKey !== undefined) {
      // 自动更新
      display =
        AUTO_UPDATE_RANGE_OPTIONS.find((x) => x.value === Number(extraKey))
          ?.label || '';
    } else {
      display =
        startDate && endDate
          ? [startDate, endDate]
              .map((v) => moment(v).format(conditionFilterFormat))
              .join(' ~ ')
          : '';
    }
  }

  return (
    <DatePicker.RangePicker
      className={cn('condition-filter-v2-date-ranger-picker', className)}
      size={size}
      // @ts-ignore
      picker={dateModule}
      showTime={showTime}
      open={visibleRangePicker}
      onOpenChange={setVisibleRangePicker}
      disabled={disabled}
      style={{ width: '100%', borderRadius: '8px' }}
      // className={isTimeModule ? '' : styles.hiddenPicker}
      ranges={
        dateModule === 'date'
          ? {
              今日: [moment(), moment()],
              七天内: [moment().subtract(6, 'd'), moment()],
              本月: [moment().startOf('month'), moment().endOf('month')],
            }
          : {}
      }
      allowClear
      value={datePickerValue}
      onChange={(e) => {
        if (e) {
          const [start, end] = e;

          // 毫秒后三位会出现误差，非 000 和 999
          onChange?.([
            start?.startOf('day').valueOf(),
            end?.endOf('day').valueOf(),
            undefined,
          ]);
        } else onChange?.([]);
      }}
      showSecond={false}
      popupClassName="view-range-picker"
      format={conditionFilterFormat}
      renderExtraFooter={(mode) =>
        // TODO 只有按日时分 才有自动更新
        mode === 'date' && !disableRelative ? (
          <div className="view-range-picker-extra">
            <Space wrap>
              <div className={'extra-title'}>自动更新：</div>
              {AUTO_UPDATE_RANGE_OPTIONS.map((r) => (
                <div
                  key={r.value}
                  className={`extra-title ${extraKey == r.value ? 'active' : ''}`}
                  onClick={() => {
                    setVisibleRangePicker(false);
                    onChange?.(transRangeDate(r.value) as any);
                  }}
                >
                  {r.label}
                </div>
              ))}
            </Space>
          </div>
        ) : null
      }
    />
  );
};
