import { QueryClientProvider } from '@tanstack/react-query';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import type { FC, PropsWithChildren } from 'react';

import { CustomWidgeUtilsProvider } from '@/components/CustomWidgetUtilsProvider';
import { TemplateContext } from '@/hook/useCurrentTemplate';
import { getTanstackQueryClient } from '@/utils/tanstackQuery';

import { getDvaProvider } from '../../utils';

export const GridComponentProvider: FC<
  PropsWithChildren<{ currentTemplate: TemplateInfo }>
> = ({ children, currentTemplate }) => {
  const { Provider: DvaProvider, store } = getDvaProvider();
  const queryClient = getTanstackQueryClient();

  return (
    <ConfigProvider locale={zhCN}>
      <CustomWidgeUtilsProvider>
        <DvaProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <TemplateContext.Provider value={currentTemplate}>
              {children}
            </TemplateContext.Provider>
          </QueryClientProvider>
        </DvaProvider>
      </CustomWidgeUtilsProvider>
    </ConfigProvider>
  );
};
