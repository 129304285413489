interface Auth {
  token: string;
  ad: string;
}

export const AD_KEY = 'ad';
export const TOKEN_KEY = 'token';

export const setupAuth = (auth: Auth) => {
  window.localStorage.setItem(TOKEN_KEY, auth.token);
  window.localStorage.setItem(AD_KEY, auth.ad);
};

export const getAuth = (): Auth => {
  return {
    token: window.localStorage.getItem(TOKEN_KEY) || '',
    ad: window.localStorage.getItem(AD_KEY) || '',
  };
};

export const clearAuth = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(AD_KEY);
};
