import type {
  DataTableHeaderConfig,
  TemplateDataTableHeaderMapConfig,
} from '@linkpi/core';

import request from '@/utils/request';

import type { TableHeaderMapItem } from '../table';

export const getTemplateDataTableHeader = async (
  orgId: string,
  tempId: string,
) => {
  if (tempId === 'empty') {
    return {
      isOpen: false,
      header: undefined,
      headerMap: undefined,
      maps: undefined,
    } as const;
  }

  const res = await request<{
    header: DataTableHeaderConfig & { source_temp: string };
    headerMap: TemplateDataTableHeaderMapConfig;
    maps: TableHeaderMapItem[];
  }>('/api/tableHeader/queryTemp', {
    method: 'POST',
    data: {
      org_id: orgId,
      temp_id: tempId,
    },
  });

  if (res.status === 'ok') {
    return { ...res.data, isOpen: true } as const;
  }

  return {
    isOpen: false,
    header: undefined,
    headerMap: undefined,
    maps: undefined,
  } as const;
};
