import { buildRecord } from '@linkpi/utils';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { useWidgetInstancesByWidgetId } from '../../hooks';
import { useSuspenseOptionsConfigList } from '../../widgets/GlobalConditionFilterWidget/GlobalConditionFilter/hook';
import type { IGlobalConditionFilterConfig } from '../../widgets/GlobalFilterWidget/types';
import type { IGlobalConditionFilterConfigV2 } from '../GlobalConditionFilter';

/**
 * 根据目标id获取全局条件筛选组件列表
 *
 * @param id 目标id
 * @returns 全局条件筛选组件列表
 */
export const useGlobalConditionFilterListByTargetId = (id?: string) => {
  const widgetInstances =
    useWidgetInstancesByWidgetId<IGlobalConditionFilterConfigV2>(
      'GlobalConditionFilter',
    );

  const globalConditionFilterList = useMemo(() => {
    if (!widgetInstances) return [];
    if (!id) return [];

    return widgetInstances.filter((item) => {
      return item.config.conditionList.find((c) =>
        c.targetList?.find((t) => t.id === id),
      );
    });
  }, [id, widgetInstances]);

  return globalConditionFilterList;
};

export const useGlobalConditionFilterItemTree = () => {
  const widgetInstances =
    useWidgetInstancesByWidgetId<IGlobalConditionFilterConfig>(
      'GlobalConditionFilter',
    );

  const optionIdList = useMemo(() => {
    if (!widgetInstances) return [];

    const result: string[] = [];
    widgetInstances.forEach((item) => {
      item.config.conditionList.forEach((c) => {
        if (c.filedType === 'cascader') {
          if (c.cascaderConfig?.optionId) {
            result.push(c.cascaderConfig?.optionId);
          }
        }
      });
    });

    return result;
  }, [widgetInstances]);

  const queryList = useSuspenseOptionsConfigList({
    configType: 'jilian',
    idList: optionIdList,
  });

  const globalConditionFilterList = useMemo(() => {
    if (!widgetInstances) return [];

    const optionsConfigList = queryList.map((q) => q.data);
    const optionsConfigMap = buildRecord(optionsConfigList, 'id');

    return widgetInstances.map((item) => {
      const children = item.config.conditionList.map((c) => {
        if (c.filedType === 'cascader') {
          const optionSourceConfig = match(c)
            .with(
              { cascaderConfig: { optionId: P.string } },
              ({ cascaderConfig: { optionId } }) => {
                return optionsConfigMap[optionId].optionSourceConfig;
              },
            )
            .otherwise((_c) => {
              return _c.cascaderConfig?.optionSourceConfig;
            });
          return {
            id: c.id,
            name: c.name,
            type: c.filedType,
            children: optionSourceConfig?.map((t, index) => ({
              id: index,
              name: `${c.name}-${index + 1}`,
            })),
          };
        }
        return {
          id: c.id,
          name: c.name,
          type: c.filedType,
        };
      });

      return {
        id: item.id,
        name: item.config.name || '未命名组件',
        children,
      };
    });
  }, [widgetInstances]);

  return globalConditionFilterList;
};
