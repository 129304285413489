/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { assertExists } from '@linkpi/utils';
import { useImmerState } from '@shrugsy/use-immer-state';
import { useMemoizedFn } from 'ahooks';
import { Divider, Form, Modal } from 'antd';
import { type FC, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { ConditionFilterV2 } from '@/components/ConditionFilterV2/src';
import { TemplatePureSelect } from '@/components/LinkPiForm';
import { useOrgInfo } from '@/hook';

import type { IDataSourceSetting } from './types';
import { createInitialValue, migrateDataSourceSetting } from './util';

export const DataSourceSettingModal: FC<{
  defaultValue?: IDataSourceSetting;
}> = ({ defaultValue }) => {
  const [orgInfo] = useOrgInfo();

  assertExists(orgInfo);

  const modal = useModal();
  const modalProps = antdModalV5(modal);

  const initialValue = match(defaultValue)
    .with({ conditions: P.array() }, (c) => c)
    .otherwise(() => createInitialValue());

  const [value, setValue] = useImmerState<IDataSourceSetting>(
    migrateDataSourceSetting(initialValue),
  );

  const onOk = useMemoizedFn(() => {
    modal.resolve(value);
    modalProps.onOk();
  });

  const conditionTemplateId = value.conditions.find(
    (item) => item.key === 'templateId',
  )?.input[0];

  const setConditionTemplateId = useMemoizedFn((templateId: string) => {
    setValue((draft) => {
      draft.type = 'conditionFilterV2';
      draft.conditions = [
        { key: 'templateId', op: 'intersect', input: [templateId] },
        createInitialValue().conditions[0],
      ];
    });
  });

  const condition = useMemo(() => {
    return value.conditions.find((item) => item.key !== 'templateId');
  }, [value.conditions]);

  const setCondition = useMemoizedFn(
    (_condition: IDataSourceSetting['conditions'][number]) => {
      setValue((draft) => {
        draft.type = 'conditionFilterV2';
        draft.conditions = [
          { key: 'templateId', op: 'intersect', input: [conditionTemplateId] },
          _condition,
        ];
      });
    },
  );
  console.log('😇 conditionTemplateId', conditionTemplateId);

  return (
    <Modal
      title={
        <span className="text-[#242D3F] font-bold">
          {(defaultValue ? '编辑' : '添加') + '组件数据源'}
        </span>
      }
      maskClosable={false}
      {...modalProps}
      width={800}
      bodyStyle={{ padding: '0px 24px' }}
      onOk={onOk}
      destroyOnClose
    >
      <div className="flex flex-col gap-3 pt-4 pb-6">
        <Form.Item label="主题类型" style={{ marginBottom: 0 }}>
          <TemplatePureSelect
            className="w-full"
            value={conditionTemplateId}
            onChange={setConditionTemplateId}
            placeholder="请选择主题类型"
          />
        </Form.Item>
        <Divider dashed style={{ margin: '8px 0' }} />
        <div className="flex flex-col gap-2">
          <div className="text-[14px] text-[#242D3F] font-bold text-base">
            设置查询条件
          </div>
          <ConditionFilterV2
            key={conditionTemplateId}
            templateId={conditionTemplateId}
            value={condition as any}
            onChange={setCondition as any}
            className="flex-1"
            templateUsage="fullAccess"
            disabled={!conditionTemplateId}
          />
        </div>
      </div>
    </Modal>
  );
};

const DataSourceSettingNiceModalId = '$$DataSourceSettingNiceModal$$';

export const _DataSourceSettingNiceModal = create(DataSourceSettingModal);

export const DataSourceSettingNiceModal = () => {
  return <_DataSourceSettingNiceModal id={DataSourceSettingNiceModalId} />;
};

export const getDataSourceSettingValue = (defaultValue?: IDataSourceSetting) =>
  show(DataSourceSettingNiceModalId, {
    defaultValue,
  }) as Promise<IDataSourceSetting>;
