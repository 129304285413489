import type { CurrentUser } from '@linkpi/core';
import type { FC } from 'react';

import { RegularIcon } from '@/components/IconFont';
import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/components/ui/dropdown-menu';

import { CurrentNodePropSelect } from './CurrentNodePropSelect';

interface CurrentNodePropMenuProps {
  propType: CurrentUser.propType;
  onChange: (value: any) => void;
}

export const CurrentNodePropMenu: FC<CurrentNodePropMenuProps> = ({
  propType,
  onChange,
}) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <RegularIcon type="iconzhutileixingshuxing" color="#242D3F" />
        当前主题类型属性
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent className="z-[1000] min-w-[180px] max-w-[280px] pi-light-theme">
          <CurrentNodePropSelect
            propType={propType}
            onChange={(v) => {
              onChange({
                target: v,
              });
            }}
          />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
