import {
  CloseOutlined,
  ExclamationCircleOutlined,
  FullscreenOutlined,
} from '@ant-design/icons';
import type { OpenModalConfig } from '@linkpi/core';
import { generateAddOpId, NODE_PERMISSION, recycleNode } from '@linkpi/core';
import { usePiNode } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import { useDispatch } from '@umijs/max';
import { useMemoizedFn, useToggle } from 'ahooks';
import type { ModalProps } from 'antd';
import {
  Button,
  Drawer,
  message,
  Modal,
  Popover,
  Spin,
  Typography,
} from 'antd';
import { cloneDeep } from 'lodash';
import type { FC } from 'react';
import { Fragment, lazy, Suspense, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { match } from 'ts-pattern';

import { AfterModalOpen, exportNodeToPDF } from '@/components';
import GroupChatPage2 from '@/components/Chat2';
import DraftsModal from '@/components/DraftNodeModal/DraftsModal';
import ExportGraphicCodesModal from '@/components/ExportGraphicCodesModal';
import LinkPiPop from '@/components/LinkPiPop';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useNodeHandle } from '@/context/NodeHandleProvider';
import {
  NodeContentModalProvider,
  useComponentId,
  useCurrentOrgId,
  useCurrentUser,
  useOrgConnection,
  useOrgInfo,
  useOrgTempMap,
  useOrgUserMap,
} from '@/hook';
import useFilterTemplateList from '@/hook/useFilterTemplateList';
import NodeHistoryModal from '@/pages/home/components/NodeHistoryModal';
import { NodeImport } from '@/pages/home/components/NodeImport';
import { QrExport } from '@/pages/home/components/QrExport';
import PermissionModal from '@/pages/home/components/ThemeTree/PermissionModal';
import request from '@/utils/request';
import {
  cn,
  exportGraphicCodes,
  genPDF,
  getExportGraphicCodesPropValues,
  getPropsSupportGraphicCode,
  isAdmin,
  isNodeAllowCopy,
  isNodeAllowPaste,
  isShowImportMenu,
  notAdminDelNodeAcl,
} from '@/utils/utils';

import css from './css';
import './index.less';
import styles from './ViewContent.module.less';

const TabContent = lazy(() => import('@/pages/home/components/TabContent'));
const TabContentWrapper = lazy(() =>
  import('@/pages/home/Workbench/NodePageModel').then((res) => ({
    default: res.TabContentWrapper,
  })),
);

const { confirm } = Modal;

const EMPTY_FN = () => {};

const INITIAL_MODAL_CONFIG: OpenModalConfig = {
  visibleSetting: [
    'nodeTools',
    'viewInPageButton',
    'switchNodeButton',
    'nodePath',
  ],
  heightType: 'fitContent',
  width: '80vw',
};

export type ViewContentProp = {
  contentVisible: boolean;
  setContentVisible: (visible: boolean) => void;
  nodeIndex: number;
  dataList: string[];
  setNodeIndex: (index: number) => void;
  showNodeSaved?: () => void;
  changeable?: boolean;
  modalConfig?: OpenModalConfig;
  pageModelConfigId?: string;
  openType?: 'modal' | 'drawer';
} & Pick<ModalProps, 'afterClose'>;

export const ViewContent: FC<ViewContentProp> = ({
  contentVisible,
  setContentVisible,
  nodeIndex,
  dataList,
  setNodeIndex,
  showNodeSaved,
  changeable: _changeable = true,
  afterClose,
  modalConfig = INITIAL_MODAL_CONFIG,
  pageModelConfigId,
  openType = 'modal',
}) => {
  const dispatch = useDispatch();
  const toolVisibleMap = useMemo(() => {
    return modalConfig.visibleSetting.reduce(
      (r, i) => ({ ...r, [i]: true }),
      {} as Record<OpenModalConfig['visibleSetting'][number], boolean>,
    );
  }, [modalConfig.visibleSetting]);
  const changeable = toolVisibleMap.switchNodeButton && _changeable;

  const orgId = useCurrentOrgId();
  const currentUser = useCurrentUser();
  const orgConnection = useOrgConnection();
  const [orgInfo] = useOrgInfo();
  const userMap = useOrgUserMap();
  const tempMap = useOrgTempMap();

  const [propVisible, setPropVisible] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [nodeId, setNodeId] = useState<string | null>(null);
  const [contentList, setContentList] = useState<string[]>([]);
  const [laseNodes, setLastNodes] = useState([]);
  const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  const [showNodeHistoryModal, setShowNodeHistoryModal] = useState(false);
  const [contentNode] = usePiNode(orgConnection, nodeId);
  const nodeHandle = useNodeHandle();

  assertExists(orgInfo);

  const configWidth = useMemo(
    () =>
      modalConfig.width ??
      match(openType)
        .with('drawer', () => '50vw')
        .with('modal', () => '80vw')
        .otherwise(() => '80vw'),
    [modalConfig.width, openType],
  );

  // 允许的主题
  const filterTemplateList = useFilterTemplateList(orgInfo);
  const [visibleQrExport, setVisibleQrExport] = useState(false);
  // 图形码
  // 导出图形码modal
  const [showExportGraphicCodesModal, setShowExportGraphicCodesModal] =
    useState(false);
  const [graphicCodeProps, setGraphicCodeProps] = useState([]);

  // 导入主题
  const [showNodeImport, setShowNodeImport] = useState(false);

  // 复制新增 草稿数据
  const [draftsNodeData, setDraftsNodeData] = useState({});
  const [showDraftsModal, setShowDraftsModal] = useState(false);
  const [initParentId, setInitParentId] = useState<string | null>(null);
  const [openThemeMore, setOpenThemeMore] = useState(false);
  const sysReadOnly = useMemo(() => {
    if (!contentNode || !orgInfo) return true;
    return !contentNode.value.nodeManager.isEditor(contentNode.value.prop);
  }, [contentNode, orgInfo]);

  useEffect(() => {
    setContentList(dataList);
  }, [dataList]);

  useEffect(() => {
    if (!~nodeIndex) return;
    const item = contentList[nodeIndex];

    if (!item) {
      setNodeId(null);
      return;
    }
    const nodeId = typeof item === 'string' ? item : item.id;
    setNodeId(nodeId);

    // 记录
    if (contentVisible) {
      dispatch({
        type: 'workspace/insertVisited',
        payload: { org_id: orgId, node_id: nodeId },
      });
    }
  }, [contentList, nodeIndex]);

  const navData = useMemo(() => {
    if (!contentNode) {
      return [];
    }
    const arr = contentNode.value.nodeManager.findChildrenPathDoc(
      contentNode.value.id,
    );
    return arr || [];
  }, [contentNode]);

  const forwardContent = (newIndex, e) => {
    e.stopPropagation();
    if (newIndex < 0 || newIndex >= contentList.length) return;
    setNodeIndex && setNodeIndex(newIndex);
  };

  const hasSubscribe = useMemo(() => {
    return !!contentNode?.value.prop?._sys_subscriber?.[currentUser.userid];
  }, [contentNode?.value.prop?._sys_subscriber, currentUser.userid]);

  // TODO 通过nodeHanlder来调用
  const handleSubscribe = async () => {
    const data = { node_id: nodeId };
    if (hasSubscribe) {
      data.unsub = '';
    }
    const res = await dispatch({
      type: 'workspace/subscribeNode',
      payload: data,
    });
    if (res.status === 'ok') {
      message.success(hasSubscribe ? '取消收藏成功' : '收藏成功');
    } else {
      message.error(hasSubscribe ? '取消收藏失败' : '收藏失败');
    }
  };

  const onViewClick = (node_id: string) => {
    setNodeId(node_id);
    const index = contentList.findIndex(
      (x) => x === node_id || x?.node_id === node_id,
    );
    setNodeIndex(index);
    setLastNodes(~index ? [] : [...laseNodes, nodeId]);
  };

  // TODO 通过nodeHanlder来调用
  const handleDeleteItem = async () => {
    const [err, res] = await recycleNode(request, {
      org_id: orgId,
      node_id: nodeId,
    });

    if (!!err || res?.status !== 'ok') return;
    message.success('节点已移至回收站');
    if (contentList.length > 1) {
      setNodeIndex(
        nodeIndex + 1 === contentList.length ? nodeIndex - 1 : nodeIndex,
      );
      const newList = [...contentList];
      newList.splice(nodeIndex, 1);
      setContentList(newList);
    } else {
      setContentVisible(false);
    }
  };

  const copyAdd = () => {
    assertExists(contentNode);

    const { parent, prop, title } = contentNode.value;

    assertExists(parent);

    const id = generateAddOpId();
    const newProp = {
      _sys_temp: cloneDeep(prop._sys_temp),
      _sys_task_status: cloneDeep(prop._sys_task_status),
    };
    setInitParentId(parent.id);
    setDraftsNodeData({
      org_id: orgId,
      parentId: parent.id,
      siblingId: null,
      draft: true,
      node: {
        node_id: id,
        prop: newProp,
        title,
      },
    });
    setOpenThemeMore(false);
    setShowDraftsModal(true);
  };

  const exportNodeExcel = async (template_id: string) => {
    assertExists(orgInfo);
    assertExists(contentNode);

    const res = await request('/docapi/template/export', {
      method: 'POST',
      data: {
        org_id: orgInfo.orgId,
        template_id: template_id,
        node_id: contentNode.value.id,
      },
    });
    if (res?.status === 'ok' && res.data) {
      window.open(res.data);
    } else {
      message.error(res.message || '导出失败');
    }
  };
  const exportPdf = async () => {
    const modal = document.getElementsByClassName('ant-modal')[0];
    const dom = modal.getElementsByClassName('tabcontent-editor')[0];
    if (!dom) return message.warning('没有正文需要导出pdf');
    await genPDF(
      `<style>${css}</style>` + dom?.innerHTML,
      false,
      contentNode?.value.title || '无标题',
    );
  };
  const exportPropsGraphicCodes = async () => {
    assertExists(contentNode);

    const template = tempMap[contentNode.value.tempInfo.id];

    const props = getPropsSupportGraphicCode(template);
    if (props.length === 0)
      return message.warning('不存在可以导出图形码的属性～');
    if (props.length < 2) {
      // 直接导出 ，不需要打开属性选择modal
      const propValues = getExportGraphicCodesPropValues(
        [contentNode.value],
        props,
      );
      await exportGraphicCodes(propValues);
      return message.success(`${props[0].name}的图形码已导出`);
    }

    setGraphicCodeProps(props);
    setShowExportGraphicCodesModal(true);
  };

  // 节点导出pdf
  const exportPDF = async () => {
    assertExists(contentNode);
    exportNodeToPDF({ node: contentNode.value });
  };

  const getNodeInfo = () =>
    contentNode ? contentNode.value.toAntDTreeData(contentNode.value.id) : {};

  const onClickItem = (type) => {
    if (type === '权限') {
      setPermissionModalVisible(true);
      return;
    }

    if (type === '剪切') {
      nodeHandle.handleShear(orgId, nodeId);
    }

    if (type === '复制') {
      nodeHandle.handleCopy(orgId, nodeId);
    }

    if (type === '粘贴') {
      nodeHandle.handlePaste(orgId, nodeId);
    }

    if (type === '复制新增') {
      copyAdd();
    }
  };

  // 剪切 复制 粘贴
  const more = [
    {
      name: '全屏',
      render: () => (
        <li key="full" onClick={() => toggleFullScreen()}>
          <FullscreenOutlined style={{ color: '#6b7a96' }} />
          <span className="ml-2">全屏</span>
        </li>
      ),
    },
    {
      name: '消息',
      render: () => (
        <li key="msg" onClick={() => setShowMessage(!showMessage)}>
          <i className="iconfont iconmessage" />
          <span>消息</span>
        </li>
      ),
    },
    {
      name: '修改历史',
      render: () => (
        <li key="history" onClick={() => showNodeHistory()}>
          <i className="iconfont icona-ChangeLog" />
          <span>修改历史</span>
        </li>
      ),
    },
    {
      name: '剪切',
      render: (data) =>
        data.acl === NODE_PERMISSION.管理 &&
        data.nodeType !== 5 &&
        !sysReadOnly ? (
          <li key="shear" onClick={() => onClickItem('剪切')}>
            <i className="iconfont iconjianqie" />
            <span>剪切</span>
          </li>
        ) : null,
    },
    {
      name: '复制',
      render: (data) =>
        data.nodeType !== 5 && isNodeAllowCopy(orgInfo, contentNode?.value) ? (
          <li key="copy" onClick={() => onClickItem('复制')}>
            <i className="iconfont iconfuzhi" />
            <span>复制</span>
          </li>
        ) : null,
    },
    {
      name: '粘贴',
      render: (data) =>
        data.nodeType !== 3 &&
        data.nodeType !== 5 &&
        isNodeAllowPaste(orgInfo, contentNode?.value) ? (
          <li key="paste" onClick={() => onClickItem('粘贴')}>
            <i className="iconfont iconzhantie" />
            <span>粘贴</span>
          </li>
        ) : null,
    },
    {
      name: '分享',
      render: (data) =>
        data.nodeType < 3 && (
          <li
            key="share"
            onClick={() => nodeHandle.handleShare(orgInfo.orgId, data.id)}
          >
            <i className={'iconfont iconfenxiang'} />
            <span>分享</span>
          </li>
        ),
    },
    {
      name: '导入主题',
      render: (data) => {
        if (!contentNode) return null;
        return (
          isShowImportMenu(
            contentNode.value.nodeManager.getRoot(),
            orgInfo,
          ) && (
            <li key="import" onClick={() => setShowNodeImport(true)}>
              <i className={'iconfont iconContent_Download'} />
              <span>导入主题</span>
            </li>
          )
        );
      },
    },
    {
      name: '导出',
      render: (data) =>
        data.acl === NODE_PERMISSION.管理 && (
          <Popover
            content={
              <ul className="menu-list" onClick={(e) => e.stopPropagation()}>
                {filterTemplateList.length ? (
                  <Popover
                    content={
                      <PerfectScrollbar>
                        <ul className="menu-list">
                          {filterTemplateList.map((x, i) => (
                            <li
                              key={x.template_id}
                              className="template-item"
                              onClick={() => exportNodeExcel(x.template_id)}
                            >
                              <span
                                className="template-title text-omit"
                                title={x.name}
                              >
                                {x.name}
                              </span>
                            </li>
                          ))}
                        </ul>
                      </PerfectScrollbar>
                    }
                    placement="left"
                    overlayClassName="optionMorePop"
                  >
                    <li key="e">
                      导出子主题
                      {/* <i className={'iconfont iconzhankaicopy4 more'} /> */}
                    </li>
                  </Popover>
                ) : (
                  <li key="exportChildNodes">导出子主题</li>
                )}
                <li key="exportPDF" onClick={() => exportPDF()}>
                  导出打印单
                </li>
                <li key="exportQRcode" onClick={() => setVisibleQrExport(true)}>
                  导出二维码
                </li>
                <li
                  key="exportPropsGraphicCodes"
                  onClick={exportPropsGraphicCodes}
                >
                  导出图形码
                </li>
                <li key={'exportPdf'} onClick={exportPdf}>
                  导出PDF
                </li>
              </ul>
            }
            placement="left"
            overlayClassName="optionMorePop"
          >
            <li key="export">
              <i className={'iconfont icondoachu'} /> 导出
              {/* <i className={'iconfont iconzhankaicopy4 more'} /> */}
            </li>
          </Popover>
        ),
    },
    {
      name: '复制新增',
      render: (data) =>
        data.nodeType !== 3 && data.nodeType !== 5 ? (
          <li key="copyAdd" onClick={() => onClickItem('复制新增')}>
            <i className="iconfont iconzhantie" />
            <span>复制新增</span>
          </li>
        ) : null,
    },
    {
      name: '权限',
      render: (data) =>
        data.spaceType !== -1 && isAdmin(orgInfo) ? (
          <li key="permission" onClick={() => onClickItem('权限')}>
            <i className="iconfont iconquanxian" />
            <span>权限</span>
          </li>
        ) : null,
    },
  ];

  const getSysHideChildren = (node) => node.prop?._sys_hideChildren;

  const recurrenceNodeTree = (node) => {
    if (!node) return false;

    if (getSysHideChildren(node)) return true;

    return recurrenceNodeTree(node.parent);
  };

  const drawerChangeBtn = () => {
    if (openType !== 'drawer') return null;
    if (!changeable) return null;
    if (!~nodeIndex) return null;

    return (
      <div className="flex flex-col items-center justify-center z-10 absolute -left-14">
        <div
          onClick={closeModal}
          className={`step-content-btn left ${nodeIndex === 0 && 'disabled'} mb-4`}
          style={{ padding: 0 }}
        >
          <i
            className={`iconfont iconspaceshezhi`}
            onClick={(e) => forwardContent(nodeIndex - 1, e)}
          />
        </div>
        <div
          className={`step-content-btn right ${
            nodeIndex >= contentList.length - 1 && 'disabled'
          }`}
          style={{ padding: 0 }}
          onClick={setContentVisible}
        >
          <i
            className={`iconfont iconspaceshezhi`}
            onClick={(e) => forwardContent(nodeIndex + 1, e)}
          />
        </div>
      </div>
    );
  };

  const renderOpenContentBtn = () => {
    if (isFullScreen) return null;
    if (!toolVisibleMap.viewInPageButton) return null;
    // if (!contentNode || recurrenceNodeTree(contentNode.value)) return null

    return (
      <Button
        className={cn('node-open-in-page', {
          'absolute -left-[134px] top-[14px]': openType === 'drawer',
        })}
        onClick={() =>
          dispatch({
            type: 'workspace/setCurrentSelection',
            payload: {
              selectNode: nodeId,
            },
          })
        }
        icon={
          <i
            className="iconfont iconzaineirongyedakai"
            style={{ fontSize: 12 }}
          />
        }
      >
        在内容页打开
      </Button>
    );
  };

  const confirmDelete = () => {
    const parentNode = contentNode?.value.parent;
    if (parentNode) {
      const prop: any = parentNode.prop || {};
      const _sys_periodic_history = prop._sys_periodic_history || [];
      const repeatTaskNodes = _sys_periodic_history.reduce(
        (res: any, cur: any) => {
          return res.concat(cur.node_id);
        },
        [] as any,
      );

      if (repeatTaskNodes.includes(contentNode.value.id)) {
        confirm({
          content: '确定删除该主题吗？此为重复任务生成。',
          okType: 'danger',
          okText: '仅删除此主题',
          onOk() {
            handleDeleteItem();
            setContentVisible(false);
          },
          cancelText: '删除所有将来主题',
          async onCancel() {
            const res = await request('/docapi/removePeriodicNodes', {
              method: 'POST',
              data: {
                org_id: orgInfo.orgId,
                node_id: contentNode.value.id,
              },
            });

            if (res.status === 'ok') message.success('操作成功');
            setContentVisible(false);
          },
        });

        return;
      }
    }
    confirm({
      title: '是否确认删除？',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDeleteItem();
        setContentVisible(false);
      },
      onCancel() {},
    });
  };

  // TODO 变量名定义
  const themeMore = (
    <ul
      style={{ overflow: 'auto' }}
      className="menu-list"
      onClick={(e) => e.stopPropagation()}
    >
      {more.map((m) => m.render((contentNode || { value: {} }).value))}
      {contentNode &&
        notAdminDelNodeAcl(orgInfo, contentNode.value) &&
        contentNode.value.acl === NODE_PERMISSION.管理 &&
        contentNode.value.id !== orgInfo.rootId &&
        !contentNode.value.prop._sys_protect && (
          <li key={5} onClick={confirmDelete}>
            <i className={'iconfont iconshanchu'} /> 删除
          </li>
        )}
    </ul>
  );

  const viewNav = useMemo(() => {
    if (!toolVisibleMap.nodePath) return null;
    if (!navData.length) return null;
    const showData = navData.slice(navData.length - 3);
    return (
      <div className="flex">
        {laseNodes.length ? (
          <i
            style={{ color: '#B6B8B9' }}
            className={'iconfont iconfanhui'}
            onClick={() => {
              const lastId = laseNodes[laseNodes.length - 1];
              const ln = laseNodes.slice(0, laseNodes.length - 1);
              setLastNodes(ln);
              let index = -1;
              if (!ln.length)
                index = contentList.findIndex(
                  (x) => x === lastId || x?.node_id === lastId,
                );
              setNodeIndex(index);
              setNodeId(lastId);
            }}
          />
        ) : null}
        <div className={'view-content-nav-org'}>{orgInfo.orgName}</div>
        {navData.length > 3 ? (
          <LinkPiPop
            insideDom={
              <div className={'view-content-nav-title'}>
                <i className={'iconfont iconmore'} />
                <i className={'iconfont iconroute'} />
              </div>
            }
            menuWidth={148}
            menus={navData.slice(0, navData.length - 3).map((nav) => {
              return {
                label: nav.title,
                handler: () => onViewClick(nav.id),
              };
            })}
          />
        ) : null}
        {showData.map((nav, i) => (
          <Fragment key={i}>
            <div
              className={`view-content-nav-title text-omit${
                i === showData.length - 1 ? ' current' : ''
              }`}
              onClick={() => {
                if (i < showData.length - 1) onViewClick(nav.id);
              }}
            >
              {i === showData.length - 1 ? '当前主题' : nav.title || '无标题'}
            </div>
            {i === showData.length - 1 ? null : (
              <i className={'iconfont iconroute'} />
            )}
          </Fragment>
        ))}
      </div>
    );
  }, [
    contentList,
    laseNodes,
    navData,
    onViewClick,
    orgInfo.orgName,
    setNodeIndex,
    toolVisibleMap.nodePath,
  ]);

  const [isFullScreen, { toggle: toggleFullScreen }] = useToggle();

  const showNodeHistory = () => {
    setShowNodeHistoryModal(true);
  };

  const closeModal = useMemoizedFn(() => {
    setContentVisible(false);
  });

  const nodeContentModalProviderValue = useMemo(() => {
    return { close: closeModal };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentId = 'ViewContent' + useComponentId();
  const scrollingContainer = `${componentId} .h-full`;

  if (!contentNode) return null;

  const content = (
    <div
      className={cn(
        'flex items-center w-full pointer-events-none',
        isFullScreen && 'h-screen',
      )}
      style={{
        height: isFullScreen
          ? undefined
          : match(modalConfig)
              .with({ heightType: 'fixed' }, (c) => c.height)
              .otherwise(() => undefined),
      }}
    >
      {drawerChangeBtn()}
      {openType === 'modal' && changeable && !!~nodeIndex && (
        <div
          onClick={closeModal}
          className={`step-content-btn left ${nodeIndex === 0 && 'disabled'}`}
        >
          <i
            className={`iconfont iconspaceshezhi`}
            onClick={(e) => forwardContent(nodeIndex - 1, e)}
          />
        </div>
      )}
      <div
        className={cn(
          'flex flex-col max-h-screen h-full flex-auto pointer-events-auto',
          isFullScreen && 'h-screen',
        )}
      >
        {renderOpenContentBtn()}
        <div className="view-content flex-auto">
          <div className="view-content-btn">
            <div className="view-content-nav flex gap-1">
              {viewNav}
              <Typography.Text strong ellipsis={{ tooltip: true }}>
                {toolVisibleMap.title && <div>{modalConfig.title}</div>}
              </Typography.Text>
            </div>
            <div className="content-btns">
              {toolVisibleMap.nodeTools && (
                <>
                  <div
                    style={{
                      height: 32,
                      padding: '0 8px',
                      display: 'flex',
                      alignItems: 'center',
                      color: '#6B7A96',
                      fontWeight: 700,
                      borderRadius: 8,
                      background: '#F9FAFC',
                    }}
                  >
                    {nodeIndex + 1}/{contentList.length}
                  </div>
                  <Popover
                    overlayClassName="optionMorePop option-main-pop"
                    content={themeMore}
                    placement={'bottomRight'}
                    trigger="click"
                    open={openThemeMore}
                    onOpenChange={setOpenThemeMore}
                  >
                    <div className="content-btn">
                      <i className="iconfont icongengduo1" />
                    </div>
                  </Popover>
                </>
              )}
              {toolVisibleMap.closeButton && (
                <div
                  className="size-8 flex items-center justify-center cursor-pointer text-[#6b7a96] ml-1"
                  onClick={closeModal}
                >
                  <CloseOutlined />
                </div>
              )}
            </div>
          </div>
          <div
            className={cn('view-content-main')}
            style={{
              width: isFullScreen ? '100%' : configWidth,
            }}
          >
            <ScrollArea id={componentId} className="w-full">
              <div className="flex">
                {match(contentNode.value)
                  .when(
                    (n) => {
                      const tempInfo = tempMap[n.tempInfo.id];
                      return (
                        tempInfo.custom_view?.contentType === 'pageModelConfig'
                      );
                    },
                    () => (
                      <AfterModalOpen>
                        <Suspense
                          fallback={
                            <Spin
                              size="large"
                              className="full-w min-h-[120px]"
                            />
                          }
                        >
                          <TabContentWrapper
                            key={contentNode.value.id}
                            isDrafts={false}
                            data={contentNode}
                            showNodeSaved={showNodeSaved}
                            pageModelConfigId={pageModelConfigId}
                            scrollingContainer={scrollingContainer}
                          />
                        </Suspense>
                      </AfterModalOpen>
                    ),
                  )
                  .with({ id: nodeId! }, () => (
                    <AfterModalOpen>
                      <Suspense
                        fallback={
                          <Spin size="large" className="full-w min-h-[120px]" />
                        }
                      >
                        <TabContent
                          data={contentNode}
                          currentUser={currentUser}
                          propVisible={propVisible}
                          setPropVisible={(e) => setPropVisible(e)}
                          handleSubscribe={handleSubscribe}
                          showNodeSaved={showNodeSaved}
                          onViewClick={onViewClick}
                        />
                      </Suspense>
                    </AfterModalOpen>
                  ))
                  .otherwise(() => null)}

                {!!showMessage && (
                  <div className={'chat-page'}>
                    <GroupChatPage2 node={contentNode} />
                  </div>
                )}
              </div>
            </ScrollArea>
          </div>
        </div>
      </div>
      {openType === 'modal' && changeable && !!~nodeIndex && (
        <div
          className={`step-content-btn right ${
            nodeIndex >= contentList.length - 1 && 'disabled'
          }`}
          onClick={setContentVisible}
        >
          <i
            className={`iconfont iconspaceshezhi`}
            onClick={(e) => forwardContent(nodeIndex + 1, e)}
          />
        </div>
      )}
    </div>
  );

  return (
    <NodeContentModalProvider value={nodeContentModalProviderValue}>
      {match(openType)
        .with('modal', () => (
          <Modal
            centered
            open={contentVisible}
            destroyOnClose
            closable={false}
            keyboard={false}
            footer={null}
            onCancel={closeModal}
            bodyStyle={{ padding: 0 }}
            width={''}
            wrapClassName={cn(
              'view-content-modal-wrap',
              isFullScreen && 'full-screen-wrapper',
              styles.contentBaseWrapper,
            )}
            className={cn('view-content-modal', isFullScreen && 'full-screen')}
            afterClose={afterClose}
          >
            {content}
          </Modal>
        ))
        .with('drawer', () => (
          <Drawer
            width={configWidth}
            headerStyle={{ display: 'none' }}
            bodyStyle={{ padding: 0 }}
            open={contentVisible}
            maskClosable
            onClose={() => {
              setContentVisible(false);
            }}
            className={cn(
              'view-content-modal-wrap',
              isFullScreen && 'full-screen-wrapper',
              styles.contentBaseWrapper,
            )}
          >
            <div className={cn('view-content-modal')}>{content}</div>
          </Drawer>
        ))
        .exhaustive()}

      {permissionModalVisible && (
        <PermissionModal
          visible={true}
          data={getNodeInfo()}
          onCancel={() => setPermissionModalVisible(false)}
        />
      )}

      {showNodeImport && (
        <NodeImport
          modalTitle="导入表格"
          templateList={orgInfo?.templateList || []}
          visible
          setVisible={() => setShowNodeImport(false)}
          createView={true}
          getViewList={EMPTY_FN}
          parentId={contentNode.value.id}
          orgInfo={orgInfo}
          node={contentNode}
        />
      )}

      {visibleQrExport && (
        <QrExport
          templateList={orgInfo?.templateList || []}
          visible
          setVisible={() => setVisibleQrExport(false)}
        />
      )}

      {showExportGraphicCodesModal && (
        <ExportGraphicCodesModal
          closeModal={() => setShowExportGraphicCodesModal(false)}
          nodes={[contentNode.value]}
          graphicCodeProps={graphicCodeProps}
        />
      )}

      {showNodeHistoryModal && (
        <NodeHistoryModal
          id={nodeId as any}
          orgId={orgId}
          timeRange={[]}
          closeModal={() => setShowNodeHistoryModal(false)}
        />
      )}

      {/* 弹窗新增 */}
      {showDraftsModal && (
        <DraftsModal
          visible
          mode="add"
          onCancel={() => setShowDraftsModal(false)}
          orgId={orgId}
          draftsNodeData={draftsNodeData}
          initParentId={initParentId}
          onSuccess={() => {}}
        />
      )}
    </NodeContentModalProvider>
  );
};
