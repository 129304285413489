import { useControllableValue } from 'ahooks';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { match, P } from 'ts-pattern';

import { cn } from '@/utils/utils';

import type { IYearMonthDayConfig } from '../../GlobalFilterWidget/types';
import RadioButtonGroup from './RadioButtonGroup';

import styles from './YearMonthDayPicker.module.less';

export type IYearMonthDayPickerValue = {
  selected?: string;
  selectedType?: 'year' | 'month' | 'date';
};

type IYearMonthDayPickerProps = {
  value?: IYearMonthDayPickerValue;
  onChange?: (value: IYearMonthDayPickerValue) => void;
  config: IYearMonthDayConfig | undefined;
  enableYearMonthDay?: boolean;
  noPadding?: boolean;
};

const options: {
  label: string;
  value: NonNullable<IYearMonthDayPickerValue['selectedType']>;
}[] = [
  { label: '日', value: 'date' },
  { label: '月', value: 'month' },
  { label: '年', value: 'year' },
];
export const YearMonthDayPicker: FC<IYearMonthDayPickerProps> = ({
  config = { type: 'yearMonthDayAndDatePicker' } as IYearMonthDayConfig,
  enableYearMonthDay = false,
  noPadding = false,
  ...props
}) => {
  const [value, onChange] =
    useControllableValue<IYearMonthDayPickerValue>(props);

  return (
    <div className={cn('flex gap-[6px] w-full')}>
      {match(config)
        .with(
          { type: P.union('yearMonthDayAndDatePicker', 'yearMonthDay') },
          { type: P.when(() => enableYearMonthDay) },
          () => (
            <div
              className={cn(
                config.type === 'yearMonthDay' ? 'flex-1' : 'flex-none',
                styles.YearMonthDayPicker,
              )}
            >
              <RadioButtonGroup
                options={options}
                value={value?.selectedType}
                selectedStyle={config?.selected}
                unselectedStyle={config?.unselected}
                size={config?.size}
                onChange={(value) =>
                  onChange?.((oldValue) => ({
                    selected: match(value)
                      .with(
                        'year',
                        () =>
                          oldValue?.selected &&
                          dayjs(oldValue.selected).format('YYYY'),
                      )
                      .with(
                        'month',
                        () =>
                          oldValue?.selected &&
                          dayjs(oldValue.selected).format('YYYY-MM'),
                      )
                      .with(
                        'date',
                        () =>
                          oldValue?.selected &&
                          dayjs(oldValue.selected).format('YYYY-MM-DD'),
                      )
                      .otherwise(() => undefined),
                    selectedType: value as NonNullable<
                      IYearMonthDayPickerValue['selectedType']
                    >,
                  }))
                }
              />
            </div>
          ),
        )
        .otherwise(() => null)}
      {match(config)
        .with(
          { type: P.union('yearMonthDayAndDatePicker', 'datePicker') },
          () => (
            <div className="flex-1 min-w-0">
              <DatePicker
                style={{
                  width: '100%',
                  minWidth: 0,
                  borderRadius: '8px',
                  ...(noPadding ? { paddingLeft: 0, paddingRight: 0 } : {}),
                }}
                size={config?.size}
                value={
                  value?.selected ? (dayjs(value?.selected) as any) : undefined
                }
                onChange={(e, v) => onChange?.({ ...value, selected: v })}
                picker={
                  value?.selectedType !== 'date'
                    ? value?.selectedType
                    : undefined
                }
              />
            </div>
          ),
        )
        .otherwise(() => null)}
    </div>
  );
};
