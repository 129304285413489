import type { ApiResponse } from '@linkpi/core';
import {
  DEFAULT_AVATAR,
  propIsNull,
  STATUS_ICON,
  tempValueDisplay,
} from '@linkpi/core';
import { Modal, Table } from 'antd';
import cls from 'classnames';
import moment from 'moment';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { getAttachmentValue } from '@/utils/utils';

import { getUserData } from './getNodeProp';

import styles from './styles.less';

interface IProps {
  closeModal: () => void;
  config: { nodes: PiNode[]; template: ApiResponse.CurrentUser.TemplateInfo };
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  templateList: ApiResponse.CurrentUser.TemplateInfo[];
  toNode: (id: string) => void;
}

type TemplateMap = Record<string, ApiResponse.CurrentUser.TemplateInfo>;

const NodesModal: FC<IProps> = (props) => {
  const {
    closeModal,
    config: { nodes, template },
    userMap,
    templateList,
  } = props;

  const showUser = (info: any) => {
    const res = [];
    const { nick_name, avatar } = info;

    res.push(
      <img
        style={{ height: 30, width: 30, borderRadius: '100%' }}
        key="avatar"
        src={avatar || DEFAULT_AVATAR}
      />,
    );

    res.push(
      <span
        style={{ paddingLeft: 6, paddingRight: 12 }}
        key="nick_name"
        children={nick_name}
      />,
    );

    return res.length === 0 ? null : res;
  };

  const templateMap = useMemo(() => {
    return templateList.reduce((res, cur) => {
      res[cur.template_id] = cur;
      return res;
    }, {} as TemplateMap);
  }, [templateList]);

  const departmentMap = useOrgDepartmentNodesMap();

  const getTemplateProps = () => {
    const res: any[] = [];

    template.prop.forEach((config, index) => {
      if (config.hide || !config.type) return;

      const getData = (_: any, node: PiNode) => node.tempInfo.prop[index];
      let render = getData;

      // 时间类型
      const { type } = config;
      if (type === 'date' || type === 'datetime') {
        const format = type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';

        render = (_: any, node: PiNode) => {
          const time = getData(_, node);
          return time ? moment(time).format(format) : '';
        };
      }

      // enum
      if (type === 'tag' || type === 'enum') {
        render = (_: any, node: PiNode) => {
          const value = getData(_, node);
          return tempValueDisplay({
            propConfig: config,
            propValue: value,
            tempMap: templateMap,
            userMap: userMap,
            propIndex: index,
            sysCascade: node.prop._sys_cascade,
            departmentMap,
            sysDisplay: node.prop._sys_display,
          });
        };
      }

      // str text
      if (type === 'str' || type === 'text') {
        render = (_: any, node: PiNode) => {
          const value = getData(_, node);
          return value || '';
        };
      }

      // currency
      if (type === 'currency') {
        render = (_: any, node: PiNode) => {
          const value = getData(_, node);
          if (value === null) return '';

          const v = Number(value);
          // 转成带精度的字符串
          let _value = '';
          if (!isNaN(v)) {
            //@ts-ignore
            _value = v.toFixed(config.extend.precision);
          }

          return _value;
        };
      }

      // number
      if (type === 'number') {
        render = (_: any, node: PiNode) => {
          const value = getData(_, node);
          if (value === null) return '';

          const v = Number(value);
          // 转成带精度的字符串
          let _value = '';
          if (!isNaN(v)) {
            _value = v.toFixed(config.number.precision);
          }

          return propIsNull(_value) ? '' : _value + (config.number.unit || '');
        };
      }

      // formula
      if (type === 'formula' || type === 'cascade') {
        render = (_: any, node: PiNode) => {
          const value = getData(_, node);
          return tempValueDisplay({
            propConfig: config,
            propValue: value,
            tempMap: templateMap,
            userMap: userMap,
            propIndex: index,
            sysCascade: node.prop._sys_cascade,
            departmentMap,
          });
        };
      }

      // attachment
      if (type === 'attachment') {
        render = (_: any, node: PiNode) => {
          const attachmentValue = getAttachmentValue(node, index);
          return attachmentValue.map((f: any, i: number) => (
            <div
              key={i}
              style={{
                backgroundImage: `url(${f.src})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: 32,
                width: 32,
                marginRight: 6,
              }}
            />
          ));
        };
      }

      // user
      if (type === 'user') {
        render = (_: any, node: PiNode) => {
          const users = getUserData(node, config, getData(_, node), userMap);
          return Array.isArray(users)
            ? users.map((user) => showUser(user))
            : showUser(users);
        };
      }

      // quote
      if (type === 'quote') {
        render = (_: any, node: PiNode) =>
          tempValueDisplay({
            propConfig: config,
            propValue: getData(_, node),
            tempMap: templateMap,
            userMap: userMap,
            propIndex: index,
            sysCascade: node.prop._sys_cascade,
            departmentMap,
          });
      }
      res.push({
        render,
        title: config.name,
        dataIndex: `prop-${index}`,
        key: `prop-${index}`,
      });
    });
    res.sort((p1, p2) => {
      const index1 = p1.key.split('-')[1];
      const index2 = p2.key.split('-')[1];

      return template.prop[index1].sort - template.prop[index2].sort;
    });
    return res;
  };

  const getTemplateStatusProps = () => {
    const res: any[] = [];

    if (template.task_status.length) {
      res.push({
        key: 'statusProp-0',
        dataIndex: 'statusProp-0',
        title: '负责人',
        render: (_: any, node: PiNode) => {
          const statusIndex = node.tempInfo.status;
          const status = template.task_status[statusIndex];
          const config = status ? status.prop[0] : null;
          const users = getUserData(
            node,
            config,
            node.tempInfo.statusProp[0],
            userMap,
          );

          return Array.isArray(users)
            ? users.map((user) => showUser(user))
            : showUser(users);
        },
      });
      res.push({
        key: 'statusProp-1',
        dataIndex: 'statusProp-1',
        title: '参与者',
        render: (_: any, node: PiNode) => {
          const statusIndex = node.tempInfo.status;
          const status = template.task_status[statusIndex];
          const config = status ? status.prop[0] : null;
          const users = getUserData(
            node,
            config,
            node.tempInfo.statusProp[1],
            userMap,
          );

          return Array.isArray(users)
            ? users.map((user) => showUser(user))
            : showUser(users);
        },
      });
      res.push({
        key: 'statusProp-2',
        dataIndex: 'statusProp-2',
        title: '开始时间',
        render: (_: any, node: PiNode) => {
          const time = node.tempInfo.statusProp[2];
          return time ? moment(time).format('YYYY/MM/DD HH:mm') : '';
        },
      });
      res.push({
        key: 'statusProp-3',
        dataIndex: 'statusProp-3',
        title: '结束时间',
        render: (_: any, node: PiNode) => {
          const time = node.tempInfo.statusProp[3];
          return time ? moment(time).format('YYYY/MM/DD HH:mm') : '';
        },
      });
    }
    return res;
  };

  const columns = [
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
      render: (_: any) => _ || '无标题',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, node: PiNode) => {
        const statusIndex = node.tempInfo.status;
        const statusInfo = template.task_status[statusIndex];
        const { name, icon } = statusInfo;
        const nodes: ReactNode[] = [];

        if (icon) {
          const className = cls('iconfont', icon);
          const color = STATUS_ICON[icon as keyof typeof STATUS_ICON].color;
          nodes.push(
            <i
              key="icon"
              className={className}
              style={{
                paddingRight: 4,
                color,
              }}
            />,
          );
        }

        if (name) {
          nodes.push(<span key="name" children={name} />);
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>{nodes}</div>
        );
      },
    },
    ...getTemplateProps(),
    ...getTemplateStatusProps(),
  ];

  return (
    <Modal
      footer={null}
      className={styles.nodesModal}
      visible
      title="主题详情"
      onCancel={closeModal}
      width={800}
    >
      <div className={styles.nodesLength}>共{nodes.length}条记录</div>
      <Table
        pagination={false}
        dataSource={nodes}
        columns={columns}
        rowKey={(v: any) => v.id}
        scroll={{ x: 4200 }}
        expandable={{ childrenColumnName: '_' }}
        onRow={(node) => ({
          onClick: () => {
            props.toNode(node.id);
          },
        })}
      />
    </Modal>
  );
};

export default NodesModal;
