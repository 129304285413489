import type { OrgGroups } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { atom, useAtom } from 'jotai';
import { isEmpty, isNil } from 'ramda';

import { useAtomized, useOrgUserList } from '@/hook';

import type { BasicProp, PropParamsType } from '../types';
import {
  checkDefaultValueIsQuote,
  DEFAULT_ALLOW_MANUAL_UPDATE,
  getDefaultAllowManualUpdate,
} from '../utils';

export const DEFAULT_PROP_PARAMS: BasicProp = {
  name: '',
  remark: '',
  index: -1,
  type: 'text',
  hide: false,
  multiple: false,
  required: false,
  visGroups: ['-1'],
  editGroups: ['-1'],
  sort: -1,
  editable: true,
  recordModification: false,
  requireModificationRemark: false,
  updateInterval: undefined as any,
};

export const baseConfigAtom = atom<BasicProp>(DEFAULT_PROP_PARAMS);

baseConfigAtom.debugLabel = 'propParams 当前正在编辑的属性配置';

const changedAtom = atom(false);
changedAtom.debugLabel = 'configChanged';

const noPermissionUserCanReadAtom = atom(false);
noPermissionUserCanReadAtom.debugLabel = 'noPermissionUserCanRead';

export function useTempPropConfig(options?: {
  isLock: boolean;
  isDebugMode: boolean;
  groups: OrgGroups.OrgGroupsItem[];
}) {
  const spaceUserList = useOrgUserList();

  const isLock = useAtomized('isLock', options?.isLock, false);
  const isDebugMode = useAtomized('isDebugMode', options?.isDebugMode, false);
  const groups = useAtomized('groups', options?.groups, []);

  const [propParams, _setPropParams] = useAtom(baseConfigAtom);
  const [changed, _setChanged] = useAtom(changedAtom);

  const setChanged: typeof _setChanged = useMemoizedFn((...args) => {
    _setChanged(...args);
  });

  const [noPermissionUserCanRead, _setNoPermissionUserCanReadAtom] = useAtom(
    noPermissionUserCanReadAtom,
  );
  const setNoPermissionUserCanReadAtom: typeof _setNoPermissionUserCanReadAtom =
    useMemoizedFn((...args) => {
      setChanged(true);
      _setNoPermissionUserCanReadAtom(...args);
    });

  const setPropParams: typeof _setPropParams = useMemoizedFn((...args) => {
    setChanged(true);
    _setPropParams(...args);
  });

  const changePropParams = (
    setStateAction: Parameters<typeof setPropParams>[0],
  ) => {
    setPropParams(setStateAction);
  };

  const savePropParams = <S extends PropParamsType, U extends BasicProp[S]>(
    value: U,
    key: S,
  ) => {
    changePropParams((_propParams) => {
      const newState = {
        ..._propParams,
        [key]: value,
      };

      /**
       * 默认值切换公式更新以及手动编辑逻辑
       * FIXME: 太脏了
       */
      if (key === 'defaultFormula') {
        if (
          isNil(newState.defaultFormula) ||
          newState.defaultFormula.type === 'const'
        ) {
          newState.formulaUpdateField = null;
          if (!isEmpty(propParams.allowManualUpdate))
            newState.allowManualUpdate = DEFAULT_ALLOW_MANUAL_UPDATE;
        } else {
          newState.formulaUpdateField = [-2];
          newState.allowManualUpdate = getDefaultAllowManualUpdate(
            newState.allowManualUpdate,
          )?.filter((i) => i !== -2);
        }
      }
      // 兼容老的默认值逻辑：日期&人员
      if (key === 'defaultValue') {
        if (checkDefaultValueIsQuote(value)) {
          newState.formulaUpdateField = [];
          if (!isEmpty(propParams.allowManualUpdate))
            newState.allowManualUpdate = DEFAULT_ALLOW_MANUAL_UPDATE;
        } else {
          newState.formulaUpdateField = [-2];
          newState.allowManualUpdate = getDefaultAllowManualUpdate(
            newState.allowManualUpdate?.filter((i) => i !== -2),
          );
        }
      }

      return newState;
    });
  };

  return {
    propParams,
    setPropParams,
    changePropParams,
    savePropParams,
    changed,
    setChanged,
    isLock,
    isDebugMode,
    groups,
    spaceUserList,
    noPermissionUserCanRead,
    setNoPermissionUserCanReadAtom,
  };
}
