import {
  type CurrentUser,
  getQuoteOriginPropInfo,
  PROP_TYPE,
} from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { Input } from 'antd';
import { type FC, useMemo, useState } from 'react';
import { match } from 'ts-pattern';

import { RegularIcon } from '@/components/IconFont';
import {
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import { useOrgTemplatesSettingInfoMap } from '@/hook';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

export const CurrentNodePropSelect: FC<{
  value?: string;
  onChange?: (value: string) => void;
  propType: CurrentUser.propType;
}> = ({ propType, ...props }) => {
  const [value, onChange] = useControllableValue(props);
  const currentTemplate = useCurrentTemplate();
  const tempMap = useOrgTemplatesSettingInfoMap();

  const propsList = useMemo(() => {
    return (
      currentTemplate?.prop
        ?.map((p, index) => ({ ...p, index }))
        .filter((item) => {
          const config = match(item)
            .with({ type: 'quote' }, () => {
              const realProp = getQuoteOriginPropInfo(item, tempMap);
              return realProp as unknown as CurrentUser.TemplateProp;
            })
            .otherwise((c) => c);

          const type = match(config)
            .with({ type: 'formula', formulaFormat: 0 }, () => 'number')
            .with({ type: 'formula', formulaFormat: 1 }, () => 'text')
            .otherwise(({ type: _t }) => _t);

          if (type === propType) return true;

          if (
            (propType === 'enum' || propType === 'tag') &&
            ['text', 'str', 'enum', 'tag'].includes(type)
          ) {
            return true;
          }

          if (
            propType === 'text' &&
            ['text', 'str', 'enum', 'tag', 'number'].includes(type)
          ) {
            return true;
          }

          if (propType === 'date' || propType === 'datetime') {
            if (type === 'date' || type === 'datetime') return true;
            if (item.type === 'formula' && item.formulaFormat === 0)
              return true;
          }

          return false;
        }) || []
    );
  }, [currentTemplate?.prop, propType, tempMap]);

  const [searchValue, setSearchValue] = useState('');

  const filteredProps = useMemo(() => {
    const list = propsList.map((item) => ({
      label: (
        <span className="inline-flex items-baseline gap-1 leading-none">
          <RegularIcon
            // @ts-ignore
            type={PROP_TYPE[item.type].icon}
            size={16}
            className={
              item.type === 'number' ? 'translate-y-[3px]' : 'translate-y-[2px]'
            }
          />
          {item.name}
        </span>
      ),
      filterText: item.name,
      value: item.index,
    }));

    if (!searchValue) return list;

    return list.filter((item) => item.filterText.includes(searchValue));
  }, [propsList, searchValue]);

  return (
    <>
      <DropdownMenuLabel className="bg-[#f7f7f7] w-[calc(100%+8px)] translate-x-[-4px] translate-y-[-4px] py-[10px]">
        {currentTemplate?.name || '暂无主题'}
      </DropdownMenuLabel>
      <DropdownMenuSeparator className="mt-0 translate-y-[-4px]" />
      {filteredProps.length > 0 ? (
        <>
          <div className="px-2 pb-1 pt-2">
            <Input.Search
              placeholder=""
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          {filteredProps.map((item) => (
            <DropdownMenuItem
              key={item.value}
              onClick={() => onChange(item.value)}
            >
              {item.label}
            </DropdownMenuItem>
          ))}
        </>
      ) : (
        <div className="py-2 text-center text-sm text-gray-300">暂无属性</div>
      )}
    </>
  );
};
