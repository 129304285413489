import { history } from '@umijs/max';
import qs from 'query-string';

import { login } from '@/services/login';
import { setupAuth } from '@/utils/auth';
import { getPageQuery } from '@/utils/utils';

const Model = {
  namespace: 'login',
  state: {
    status: undefined,
  },
  effects: {
    *login({ payload }, { call, put }) {
      const response = yield call(login, payload);
      const query = qs.parse(history.location.search);
      yield put({
        type: 'changeLoginStatus',
        payload: response,
      });
      if (response?.status === 'ok') {
        console.log({
          payload,
          redirect: query.redirect,
          response,
        });
        if (
          response.invite?.errCode &&
          response.invite?.data &&
          (payload.invite_token || payload.shareToken)
        ) {
          yield put({
            type: 'user/saveSpaceInvite',
            payload: {
              inviteToken: payload.invite_token || payload.shareToken,
              inviteData: response.invite.data,
              inviteCode: response.invite.errCode,
              inviteType: payload.invite_token ? 'invite' : 'share',
            },
          });
        }

        setupAuth(response.data);

        let replace = '/';
        if (response.redirect) {
          replace = `/home/${response.redirect.spaceId}/${response.redirect.rootId}/${response.redirect.nodeId}`;
        }
        if (response?.type === 'register') {
          if (query.redirect?.includes('tempSquareType')) {
            window.location.href = query.redirect;
          } else {
            history.replace(replace);
          }
        } else {
          if (query.redirect) {
            window.location.href = query.redirect;
          } else {
            history.replace(replace);
          }
        }
      }
      return response;
    },

    logout() {
      const { redirect } = getPageQuery(); // Note: There may be security issues, please note
      // 改为通过ad,token
      window.localStorage.removeItem('ad');
      window.localStorage.removeItem('token');
      if (window.location.pathname !== '/user/login' && !redirect) {
        window.location.href = `/user/login`;
      }
    },
  },
  reducers: {
    changeLoginStatus(state, { payload }) {
      return { ...state, status: payload?.status, type: payload?.type };
    },
  },
};
export default Model;
