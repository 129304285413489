import type { GetterPiNode } from '@linkpi/core/web';
import {
  Calendar,
  DatePicker,
  InputNumber,
  Radio,
  Select,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import styles from '../styles.less';

const weekDays = [
  {
    label: '星期一',
    alias: '一',
    value: 1,
  },
  {
    label: '星期二',
    alias: '二',
    value: 2,
  },
  {
    label: '星期三',
    alias: '三',
    value: 3,
  },
  {
    label: '星期四',
    alias: '四',
    value: 4,
  },
  {
    label: '星期五',
    alias: '五',
    value: 5,
  },
  {
    label: '星期六',
    alias: '六',
    value: 6,
  },
  {
    label: '星期日',
    alias: '日',
    value: 7,
  },
];

const weeks = [
  {
    label: '第一周',
    value: 1,
  },
  {
    label: '第二周',
    value: 2,
  },
  {
    label: '第三周',
    value: 3,
  },
  {
    label: '第四周',
    value: 4,
  },
  {
    label: '第五周',
    value: 5,
  },
  {
    label: '最后一周',
    value: -1,
  },
];

const NewRepeatTaskCommonForm: FC<{
  node: GetterPiNode;
  myRef: any;
  disabled: boolean;
}> = (props) => {
  const { node, myRef, disabled } = props;
  // cycle 周期 exactDate 具体日期
  const [rule, setRule] = useState('cycle');

  // disposable 一次性生成  early 提前
  const [createType, setCreateType] = useState('disposable');
  const [earlyTime, setEarlyTime] = useState(1);

  const [exactDate, setExactDate] = useState<any[]>([]);

  const [repeatType, setRepeatType] = useState('everyDay');
  const [startTime, setStartTime] = useState(dayjs().valueOf());

  const [endType, setEndType] = useState('date');
  const [endTime, setEndTime] = useState(dayjs().endOf('D').valueOf());
  const [endNumber, setEndNumber] = useState(1);

  const [days, setDays] = useState<any[]>([]);
  // 日期1 在2
  const [monthType, setMonthType] = useState<any>(1);
  const [dates, setDates] = useState<any[]>([]);
  const [countWeek, setCountWeek] = useState<any>(undefined);
  const [countWeekValue, setCountWeekValue] = useState<any>(undefined);

  const [months, setMonths] = useState<any[]>([]);

  myRef.current = {
    createType,
    rule,
    startTime,
    earlyTime,
    endType,
    endTime,
    endNumber,
    repeatType,
    months,
    countWeek,
    days,
    countWeekValue,
    dates,
    exactDate,
  };

  useEffect(() => {
    // 回显数据
    const periodic: any = node.value.prop._sys_periodic;
    if (periodic) {
      setEarlyTime(periodic.beforehand / 60); // 提前多久生成任务
      setStartTime(periodic.startTime || dayjs().startOf('D').valueOf());
      setEndTime(periodic.endTime || dayjs().endOf('D').valueOf());

      // never date number
      setEndType(periodic.totalCount ? 'number' : 'date');

      if (periodic.totalCount) setEndNumber(periodic.totalCount);

      setCreateType(periodic.beforehand > 999999998 ? 'disposable' : 'early');
      if (Array.isArray(periodic.repeat)) {
        const v = periodic.repeat[0];

        if (v.specDay) {
          setRule('exactDate');
          setExactDate(periodic.exactDate);
          return;
        }

        let _repeatType = ''; // 重复类型
        let _months = []; // 每年 选的月份
        let _dates = []; // 每年 选的日期
        let atWeek = null; // 在 第几周
        let atDay = null; // 在 礼拜几
        let _days: any = []; // 每周：哪几天

        if (Array.isArray(v.countWeek) && v.countWeek.length) {
          atWeek = v.countWeek[0];
        }

        if (v.month) {
          _repeatType = 'everyYear';
          _months = v.month;
          _dates = v.day || [];
          if (atWeek) atDay = v.week[0];
        }

        if (v.day && !v.month) {
          _repeatType = 'everyMonth';
          _dates = v.day || [];
          if (atWeek) atDay = v.week[0];
        }

        if (!_repeatType && v.week) {
          _repeatType = 'everyWeek';
          _days = [...v.week];
        }

        if (![null, undefined].includes(v.oddDay)) _repeatType = 'nextDay';
        if (![null, undefined].includes(v.oddWeek)) _repeatType = 'nextWeek';

        setDays(_days);
        setRepeatType(_repeatType || 'everyDay');
        setMonths(_months);
        setDates(_dates);
        setCountWeek(atWeek);
        setCountWeekValue(atDay);
      }
    }
  }, []);

  const inExactDate = (v: any) => {
    return exactDate
      .map((t) => dayjs(t).format('YYYY/MM/DD'))
      .includes(v.format('YYYY/MM/DD'));
  };

  const changeRule = (v: any) => {
    setRule(v.target.value);
  };
  const changeCreateType = (v: any) => {
    setCreateType(v.target.value);
    if (v.target.value === 'disposable') setEarlyTime(0);
  };
  const changeEarlyTime = (v: any) => {
    setEarlyTime(v);
  };

  const changeExactDate = (v: any) => {
    if (inExactDate(v))
      setExactDate(
        exactDate.filter(
          (i) => dayjs(i).format('YYYY/MM/DD') !== v.format('YYYY/MM/DD'),
        ),
      );
    else setExactDate([...exactDate, v.valueOf()]);
  };

  const changeRepeatType = (v: any) => {
    setRepeatType(v.target.value);
  };
  const changeStartTime = (v: any) => {
    setStartTime(v.valueOf());
  };
  const changeEndTime = (v: any) => {
    setEndTime(v.valueOf());
  };
  const changeEndType = (v: any) => {
    setEndType(v.target.value);
  };
  const changeEndNumber = (v: any) => {
    setEndNumber(v);
  };

  const renderMonthSelector = () => {
    return Array(31)
      .fill(0)
      .map((_, index) => index + 1)
      .map((i) => (
        <div
          key={i}
          className={`${styles.date} ${dates.includes(i) ? styles.dateActive : ''}`}
          onClick={() => {
            if (disabled) return;
            if (monthType === 2) return;
            if (dates.includes(i))
              return setDates(dates.filter((d) => d !== i));
            setDates([...dates, i]);
          }}
        >
          {i < 10 ? `0${i}` : i}
        </div>
      ));
  };

  const renderYearSelector = () => {
    return Array(12)
      .fill(0)
      .map((_, index) => index + 1)
      .map((i) => (
        <div
          key={i}
          className={`${styles.month} ${months.includes(i) ? styles.monthActive : ''}`}
          onClick={() => {
            if (disabled) return;
            if (months.includes(i))
              return setMonths(months.filter((m) => m !== i));
            setMonths([...months, i]);
          }}
        >
          {`${i}月`}
        </div>
      ));
  };

  const renderWeekDays = () => {
    return (
      <div
        style={{ paddingLeft: 15 }}
        className={`${styles.item} ${disabled ? styles.disabledWeekDay : ''}`}
      >
        {weekDays.map((i) => (
          <div
            key={i.value}
            className={`${styles.weekDay} ${days.includes(i.value) ? styles.dayActive : ''}`}
            onClick={() => {
              if (disabled) return;
              if (days.includes(i.value))
                return setDays(days.filter((d) => d !== i.value));
              setDays([...days, i.value]);
            }}
          >
            {i.alias}
          </div>
        ))}
      </div>
    );
  };

  const renderEnd = () => {
    if (endType === 'never') return null;
    if (endType === 'date')
      return (
        <div className={styles.item}>
          <div className={styles.label} />
          <div className={styles.form}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DatePicker
                disabled={disabled}
                value={moment(endTime)}
                onChange={changeEndTime}
                format="YYYY/MM/DD"
              />
              <span style={{ marginLeft: 6 }}>结束</span>
            </div>
          </div>
        </div>
      );
    if (endType === 'number')
      return (
        <div className={styles.item}>
          <div className={styles.label} />
          <div className={styles.form}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InputNumber
                disabled={disabled}
                value={endNumber}
                min={1}
                onChange={changeEndNumber}
                style={{ width: 120 }}
              />
              <span style={{ marginLeft: 6, marginRight: 4 }}>次后结束</span>
              <Tooltip title="最多生成三年内的重复任务">
                <i
                  style={{ color: '#316EF5', cursor: 'pointer' }}
                  className="iconchangyong-bangzhu iconfont"
                />
              </Tooltip>
            </div>
          </div>
        </div>
      );
  };

  const renderMonthForm = () => (
    <div style={{ paddingLeft: 15, marginTop: 24 }}>
      <Radio
        onChange={() => {
          setCountWeek(undefined);
          setCountWeekValue(undefined);
          setMonthType(1);
        }}
        checked={monthType === 1}
        disabled={disabled}
      >
        日期
      </Radio>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: 350,
          margin: '12px 0',
        }}
      >
        {renderMonthSelector()}
      </div>
      <Radio
        onChange={() => {
          setDates([]);
          setMonthType(2);
        }}
        checked={monthType === 2}
        disabled={disabled}
      >
        在
      </Radio>
      <div style={{ marginTop: 12 }}>
        <Select
          disabled={monthType === 1 || disabled}
          onChange={setCountWeek}
          value={countWeek}
          style={{ width: 160, marginRight: 16 }}
        >
          {weeks.map((i) => (
            <Select.Option value={i.value} key={i.value}>
              {i.label}
            </Select.Option>
          ))}
        </Select>
        <Select
          onChange={setCountWeekValue}
          value={countWeekValue}
          disabled={monthType === 1 || disabled}
          style={{ width: 160 }}
        >
          {weekDays.map((i) => (
            <Select.Option value={i.value} key={i.value}>
              {i.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.item}>
        <div className={styles.label}>创建规则：</div>
        <div className={styles.form}>
          <Radio.Group disabled={disabled} onChange={changeRule} value={rule}>
            <Radio value="cycle">按周期创建</Radio>
            <Radio value="exactDate">按具体日期创建</Radio>
          </Radio.Group>
        </div>
      </div>
      {rule === 'cycle' && (
        <>
          <div className={styles.item}>
            <div className={styles.label}>重复类型：</div>
            <div className={styles.form}>
              <Radio.Group
                disabled={disabled}
                onChange={changeRepeatType}
                value={repeatType}
              >
                <Radio value="everyDay">每天</Radio>
                <Radio value="nextDay">隔天</Radio>
                <Radio value="everyWeek">每周</Radio>
                <Radio value="nextWeek">隔周</Radio>
                <Radio value="everyMonth">每月</Radio>
                <Radio value="everyYear">每年</Radio>
              </Radio.Group>
            </div>
          </div>
          {['everyWeek', 'nextWeek'].includes(repeatType) && renderWeekDays()}
          {repeatType === 'everyMonth' && renderMonthForm()}
          {repeatType === 'everyYear' && (
            <div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  width: 350,
                  marginTop: 12,
                }}
              >
                {renderYearSelector()}
              </div>
              {renderMonthForm()}
            </div>
          )}

          <div className={styles.item}>
            <div className={styles.label}>开始日期：</div>
            <div className={styles.form}>
              <DatePicker
                value={moment(startTime)}
                onChange={changeStartTime}
                format="YYYY/MM/DD"
                disabled={disabled}
              />
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.label}>结束日期：</div>
            <div className={styles.form}>
              <Radio.Group
                disabled={disabled}
                onChange={changeEndType}
                value={endType}
              >
                <Radio value="date">按日期结束</Radio>
                <Radio value="number">按生成次数结束</Radio>
                {/* <Radio value="never">永不结束</Radio> */}
              </Radio.Group>
            </div>
          </div>
          {renderEnd()}
        </>
      )}
      {rule === 'exactDate' && (
        <div className={styles.item}>
          <div className={styles.label} />
          <div className={styles.form}>
            <Calendar
              dateFullCellRender={(v) => {
                return (
                  <div
                    className="flex justify-center items-center"
                    onClick={() => changeExactDate(v)}
                  >
                    {inExactDate(v) ? (
                      <div
                        style={{
                          height: 22,
                          width: 22,
                          color: 'white',
                          borderRadius: 2,
                          backgroundColor: '#1890ff',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {v.format('DD')}
                      </div>
                    ) : (
                      v.format('DD')
                    )}
                  </div>
                );
              }}
              fullscreen={false}
            />
          </div>
        </div>
      )}
      <div className={styles.item}>
        <div className={styles.label}>生成方式：</div>
        <div className={styles.form}>
          <Radio.Group
            disabled={disabled}
            onChange={changeCreateType}
            value={createType}
          >
            <Radio value="early">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>提前</span>
                <InputNumber
                  value={earlyTime}
                  min={0}
                  onChange={changeEarlyTime}
                  style={{ width: 66, margin: '0 6px' }}
                  disabled={disabled}
                />
                <span>小时生成</span>
              </div>
            </Radio>
            <Radio value="disposable">一次性生成</Radio>
          </Radio.Group>
        </div>
      </div>
    </div>
  );
};

export default NewRepeatTaskCommonForm;
