import { PERMISSION_OPTION } from '@linkpi/core';
import { buildRecord } from '@linkpi/utils';
import { useDispatch } from '@umijs/max';
import { Input, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { useCurrentOrgId, useOrgUserGroup } from '@/hook';
const { Option } = Select;

const Group = (props) => {
  const { nodeGroupAclData, setNodeGroupAclData, changedGroupAclData, setChangedGroupAclData } =
    props;
  const [searchGroupName, setSearchGroupName] = useState('');

  const dispatch = useDispatch();
  const groupList = useOrgUserGroup();
  const orgId = useCurrentOrgId();

  useEffect(() => {
    dispatch({
      type: 'space/fetchGroups',
      payload: {
        org_id: orgId,
        avatar: true,
        role: true,
      },
    });
  }, [dispatch, orgId]);

  const groupMap = useMemo(() => {
    return buildRecord(groupList, 'group_id');
  }, [groupList]);

  const sendOp = async (permissionKey, group_id) => {
    setNodeGroupAclData({ ...nodeGroupAclData, [group_id]: permissionKey });
    setChangedGroupAclData({ ...changedGroupAclData, [group_id]: permissionKey });
  };

  const sendBatchOp = async (permissionKey) => {
    const newNodeGroupAclData = { ...nodeGroupAclData };
    Object.keys(newNodeGroupAclData).forEach((k) => {
      if (!['-1', '-2'].includes(k) && !groupMap[k].org_struct) {
        newNodeGroupAclData[k] = permissionKey;
      }
    });
    setNodeGroupAclData(newNodeGroupAclData);
    setChangedGroupAclData(newNodeGroupAclData);
  };

  return (
    <div id="Group" className="editForm">
      <div className="search">
        <Input
          bordered={false}
          suffix={<i className="iconfont iconsearch" />}
          value={searchGroupName}
          onChange={(e) => setSearchGroupName(e.target.value)}
          placeholder="请输入分组名称"
        />
      </div>
      <div className="header row">
        <div className="role">组</div>
        <div className="permission">权限</div>
      </div>
      {groupList
        .filter(
          (g) => g.group_name.indexOf(searchGroupName) >= 0 && !g.org_struct,
        )
        .filter((g) => g.group_id !== '-8' && g.group_id !== '-2')
        .map((group) => (
          <Row
            key={group.group_id}
            group={group}
            sendOp={sendOp}
            sendBatchOp={sendBatchOp}
            nodeGroupAclData={nodeGroupAclData}
          />
        ))}
    </div>
  );
};

const Row = (props) => {
  const { group, sendOp, sendBatchOp, nodeGroupAclData } = props;

  const [aclKey, setAclKey] = useState(null);

  // 获取当前节点对于当前组的权限key
  useEffect(() => {
    setAclKey(nodeGroupAclData[group.group_id]);
  }, [nodeGroupAclData]);

  const handleSelectChange = (e) => {
    
      sendOp(e, group.group_id);
    
  };

  return (
    <div className="row" key={group.group_id}>
      <div className="role">{group.group_name}</div>
      <div className="permission">
        <Select
          style={{ width: 95 }}
          bordered={false}
          disabled={group.group_id === '-2'}
          value={aclKey}
          allowClear={true}
          onChange={handleSelectChange}
          placeholder="请选择"
        >
          {(group.group_id === '-1' ?PERMISSION_OPTION.filter(i => i.key  !== 3) : PERMISSION_OPTION).map((x) => (
            <Option key={x.key}>{x.value}</Option>
          ))}
        </Select>
      </div>
    </div>
  );
};
export default Group;
