import { takeOne } from '@linkpi/utils';
import type { InputProps } from 'antd';
import { Input } from 'antd';
import React from 'react';

export const TextEditor: React.FC<
  Omit<InputProps, 'onChange'> & {
    onChange?: (value: string[]) => void;
  }
> = ({ value, onChange, ...rest }) => (
  <Input
    value={value ? takeOne(value as any) : undefined}
    onChange={(e) => onChange?.([e.target.value])}
    placeholder="请输入"
    allowClear
    {...rest}
  />
);
