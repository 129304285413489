import { Form, Radio } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import previewIMG from '../../assets/status.png';
import { AlignRadio, WidgetIcon } from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type INodeStatusConfig = {
  align: 'left' | 'center' | 'right';
  showCurrentStatus: boolean;
  showSwitchStatus: boolean;
  showStatusRecord: boolean;
};

const Preview: WidgetPreivew = () => {
  return (
    <div style={{ display: 'block', width: '100%' }}>
      <img src={previewIMG} style={{ display: 'block', height: 30 }} />
    </div>
  );
};

const visibleOptions = [
  { label: '显示', value: true },
  { label: '不显示', value: false },
];

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance<INodeStatusConfig>()!;

  const updateConfig = <T extends keyof INodeStatusConfig>(
    key: T,
    value: INodeStatusConfig[T],
  ) => {
    editor.store.updateWidgetInstanceConfig<INodeStatusConfig>(
      widgetIntance.id,
      (draft) => {
        draft[key] = value as any;
      },
    );
  };

  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <Form.Item label="对齐">
        <AlignRadio
          value={widgetIntance.config.align}
          onChange={(e) => updateConfig('align', e.target.value)}
        />
      </Form.Item>
      <Form.Item label="状态功能显示">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-1">
            <div>当前状态</div>
            <Radio.Group
              options={visibleOptions}
              value={widgetIntance?.config.showCurrentStatus}
              onChange={(e) =>
                updateConfig('showCurrentStatus', e.target.value)
              }
            />
            <div className="text-[#B6B8B9]">
              注：当状态样式为列表样式时，隐藏当前状态即同时隐藏下列两项
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div>状态切换按钮</div>
            <Radio.Group
              options={visibleOptions}
              value={widgetIntance?.config.showSwitchStatus}
              onChange={(e) => updateConfig('showSwitchStatus', e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1">
            <div>处理记录</div>
            <Radio.Group
              options={visibleOptions}
              value={widgetIntance?.config.showStatusRecord}
              onChange={(e) => updateConfig('showStatusRecord', e.target.value)}
            />
          </div>
        </div>
      </Form.Item>
    </Form>
  );
};

export const NodeStatusWidget = defineSystemWidget<INodeStatusConfig>()({
  id: 'NodeStatus',
  title: '状态',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#5ABCB0"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zhuangtai"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 2,
    defaultWidth: 6,
    minHeight: 1,
    minWidth: 6,
    maxCount: 1,
    maxHeight: 5,
    dynamicHeight: true,
  },
  metadata: {
    align: 'left',
    showCurrentStatus: true,
    showStatusRecord: true,
    showSwitchStatus: true,
  },
  preview: Preview,
  setting: Setting,
});
