import { useMemoizedFn } from 'ahooks';
import { useAtom, useSetAtom } from 'jotai';
import { useMemo } from 'react';

import type { Condition, LogicType } from '../types';
import {
  addConditionAtom,
  moveConditionGroupAtom,
  removeConditionAtom,
  rootConditionAtom,
  updateGroupLogicAtom,
} from './atoms';

export function useConditionGroup(groupIndex: number) {
  const [rootCondition] = useAtom(rootConditionAtom);
  const removeGroup = useSetAtom(removeConditionAtom);
  const updateGroupLogic = useSetAtom(updateGroupLogicAtom);
  const moveGroup = useSetAtom(moveConditionGroupAtom);
  const addCondition = useSetAtom(addConditionAtom);

  const group = useMemo(() => {
    return rootCondition.input[groupIndex];
  }, [rootCondition, groupIndex]);

  // 删除条件组
  const removeCurrentGroup = useMemoizedFn(() => {
    removeGroup([groupIndex]);
  });

  // 更新逻辑操作符
  const updateLogic = useMemoizedFn((logic: LogicType) => {
    updateGroupLogic(groupIndex, logic);
  });

  // 移动条件组位置
  const moveGroupPosition = useMemoizedFn(
    (fromIndex: number, toIndex: number) => {
      moveGroup(fromIndex, toIndex);
    },
  );

  // 在当前组添加条件
  const addNewCondition = useMemoizedFn((condition: Condition) => {
    addCondition(groupIndex, condition);
  });

  return {
    group,
    removeCurrentGroup,
    updateLogic,
    moveGroupPosition,
    addNewCondition,
  };
}
