import { assertExists, notEmpty } from '@linkpi/utils';
import { nanoid } from 'nanoid';
import { defaultTo, isNil, omit } from 'ramda';
import { match, P } from 'ts-pattern';
import { container, singleton } from 'tsyringe-neo';
import type { JsonObject } from 'type-fest';

import { defaultStoreVersion } from '../constants';
import { getInitalPageConfig } from '../utils/config';
import { BaseModel } from './BaseModel';
import type {
  LayoutPositionType,
  WidgetInstance,
  WidgetInstanceConfigUpdater,
} from './Store';
import { Store } from './Store';
import { WidgetManager } from './WidgetManager';

import '@abraham/reflection';

type IEmittery = {
  activeWidgetChanged: undefined;
  editorIdChanged: never;
};

@singleton()
export class EditorModel extends BaseModel<IEmittery> {
  store: Store;
  backupStore: Store;

  widgetManager: WidgetManager;
  id!: string;

  #activeWidgetInstanceId!: string | null;

  constructor(widgetManager: WidgetManager, store: Store, backupStore: Store) {
    super('editor');
    this.widgetManager = widgetManager;
    this.store = store;
    this.backupStore = backupStore;

    this.#init();
  }

  #init() {
    this.#activeWidgetInstanceId = null;
    this.id = nanoid();

    this.store.subscribeCreator('widgetInstanceChanged')((id: string) => {
      if (this.#activeWidgetInstanceId === id) {
        this.emitter.emit('activeWidgetChanged');
      }
    });
  }

  get data() {
    return this.store.data;
  }

  initData = (...args: Parameters<Store['initData']>) => {
    this.store.initData(...args);
    this.backupStore.initData(...args);
    this.id = nanoid();
    this.emitter.emit('editorIdChanged');
  };

  public setActiveWidgetInstance = (widgetInstanceId: string | null) => {
    this.#activeWidgetInstanceId = widgetInstanceId;
    this.emitter.emit('activeWidgetChanged');
  };

  public getActiveWidgetInstance = <T extends JsonObject = JsonObject>() => {
    if (!this.#activeWidgetInstanceId) return;
    const widgetInstance = this.store.getWidgetInstance<T>(
      this.#activeWidgetInstanceId,
    );

    return widgetInstance;
  };

  public updateActiveWidgetInstanceConfig = <T extends JsonObject = JsonObject>(
    config: WidgetInstanceConfigUpdater<T>,
  ) => {
    if (!this.#activeWidgetInstanceId) return;

    return this.store.updateWidgetInstanceConfig<T>(
      this.#activeWidgetInstanceId,
      config,
    );
  };

  public getActiveWidgetConfig = () => {
    if (!this.#activeWidgetInstanceId) return;

    const widgetInstance = this.getActiveWidgetInstance();

    try {
      assertExists(widgetInstance);
      return this.widgetManager.getWidget(widgetInstance.widgetId);
    } catch (error) {
      this.setActiveWidgetInstance(null);
      return;
    }
  };

  public removeWidgetInstance = (
    ...args: Parameters<Store['removeWidgetInstance']>
  ) => {
    this.setActiveWidgetInstance(null);
    this.store.removeWidgetInstance(...args);
    return;
  };

  /**
   * 同步添加组件
   */
  public addWidgetInstance = (
    widgetId: string,
    options: Pick<WidgetInstance['rect'], 'y' | 'x'> &
      Partial<Pick<WidgetInstance['rect'], 'w' | 'h'>> &
      Partial<Pick<WidgetInstance, 'parentId' | 'id'>> &
      Partial<Pick<WidgetInstance, 'config'>> & {
        type?: LayoutPositionType;
      },
  ) => {
    const widgetConfig = this.widgetManager.getWidget(widgetId);

    const metadata = widgetConfig.metadata;

    let config: WidgetInstance['config'] = {
      name: '',
      ...metadata,
      showName: false,
      ...defaultTo({}, options.config),
    };

    // 当 options.id 存在，则使用旧的
    if (options.id && this.store.getDeletedWidgetInstance(options.id)) {
      config = this.store.getDeletedWidgetInstance(options.id)!.config;
    }

    const newWidgetInstance = this.store.addWidgetInstance(
      {
        widgetId,
        rect: {
          h: widgetConfig.basic.defaultHeight,
          w: widgetConfig.basic.defaultWidth,
          ...omit(['parentId', 'id', 'config'], options),
        },
        children: null,
        config,
        id: options.id,
        type: options.type,
      },
      options.parentId,
    );

    return newWidgetInstance;
  };

  /**
   * 异步添加组件,
   * 例如弹窗新建
   */
  public addWidgetInstanceAsync = async (
    widgetId: string,
    options: Pick<WidgetInstance['rect'], 'y' | 'x'> &
      Partial<Pick<WidgetInstance['rect'], 'w' | 'h'>> &
      Partial<Pick<WidgetInstance, 'parentId' | 'id'>> &
      Partial<Pick<WidgetInstance, 'config'>>,
  ) => {
    const widgetConfig = this.widgetManager.getWidget(widgetId);

    const metadata = widgetConfig.metadata;

    let config = {
      name: '',
      ...metadata,
      showName: false,
      ...defaultTo({}, options.config),
    };

    if (typeof widgetConfig.onCreate === 'function' && isNil(options.id)) {
      const result = await widgetConfig.onCreate();
      config = {
        ...config,
        ...result,
      };
    }

    const newWidgetInstance = this.addWidgetInstance(widgetId, {
      ...options,
      config,
    });

    return newWidgetInstance;
  };

  public cloneWidgetInstance = (widgetId: string) => {
    const widgetInstance = this.getWidgetInstance(widgetId);
    const layoutType = this.store.getWidgetLayoutContent(widgetId);

    if (!widgetInstance) return;

    return (
      match(widgetInstance)
        // 复制组件
        .with({ parentId: P.string }, () => {
          const sectionWidgetInstance = this.addWidgetInstance(
            'GeneralSection',
            {
              y: this.store.getBottom() + 1,
              x: 0,
              w: widgetInstance.rect.w,
              h: widgetInstance.rect.h + 1,
            },
          );

          return this.addWidgetInstance(widgetInstance.widgetId, {
            x: 0,
            y: 0,
            w: 12,
            h: widgetInstance.rect.h,
            config: widgetInstance.config,
            parentId: sectionWidgetInstance?.id,
            type: layoutType,
          });
        })
        // 复制版块
        .otherwise(() => {
          const sectionWidgetInstance = this.addWidgetInstance(
            widgetInstance.widgetId,
            {
              y: this.store.getBottom() + 1,
              x: widgetInstance.rect.x,
              w: widgetInstance.rect.w,
              h: widgetInstance.rect.h,
              type: layoutType,
            },
          );

          let rawConfig = JSON.stringify(widgetInstance.config);
          // 复制子组件
          for (
            let index = 0;
            index < defaultTo([])(widgetInstance.children).length;
            index++
          ) {
            const subWidgetInstanceId = widgetInstance.children![index];
            const subWidgetInstance =
              this.getWidgetInstance(subWidgetInstanceId);

            assertExists(subWidgetInstance);

            // 插入子组件
            const newSubWidgetInstance = this.addWidgetInstance(
              subWidgetInstance.widgetId,
              {
                x: subWidgetInstance.rect.x,
                y: subWidgetInstance.rect.y,
                w: subWidgetInstance.rect.w,
                h: subWidgetInstance.rect.h,
                config: subWidgetInstance.config,
                parentId: sectionWidgetInstance.id,
              },
            );

            // 替换子组件
            rawConfig = rawConfig.replaceAll(
              subWidgetInstance.id,
              newSubWidgetInstance.id,
            );
          }

          this.store.updateWidgetInstanceConfig(
            sectionWidgetInstance.id,
            JSON.parse(rawConfig),
          );
        })
    );
  };

  public checkWidgetClonable(widgetInstanceId: string) {
    const widgetInstance = this.getWidgetInstance(widgetInstanceId);
    if (!widgetInstance) return false;
    const widgetConfig = this.widgetManager.getWidget(widgetInstance.widgetId);

    if (widgetInstance.deleted) return false;

    const currentWidgetCount = this.store.getWidgetCount();

    /**
     * 超出数量限制
     */
    if (
      widgetConfig.basic.maxCount &&
      currentWidgetCount[widgetInstance.widgetId] + 1 >
        widgetConfig.basic.maxCount
    ) {
      return false;
    }

    const moreWidgetCount: Record<string, number> = {};
    if (Array.isArray(widgetInstance.children)) {
      for (let index = 0; index < widgetInstance.children.length; index++) {
        const subWidgetInstanceId: string = widgetInstance.children[index];
        /**
         * 包含不可复制的组件
         */
        if (!this.checkWidgetClonable(subWidgetInstanceId)) return false;

        const subWidgetInstance =
          this.store.getWidgetInstance(subWidgetInstanceId);

        assertExists(subWidgetInstance);

        const subWidgetConfig = this.widgetManager.getWidget(
          subWidgetInstance?.widgetId,
        );

        if (isNil(moreWidgetCount[subWidgetInstance.widgetId])) {
          moreWidgetCount[subWidgetInstance.widgetId] = 0;
        }

        moreWidgetCount[subWidgetInstance.widgetId] += 1;

        if (
          subWidgetConfig.basic.maxCount &&
          moreWidgetCount[subWidgetInstance.widgetId] +
            currentWidgetCount[subWidgetInstance.widgetId] >
            subWidgetConfig.basic.maxCount
        ) {
          return false;
        }
      }
    }

    return true;
  }

  public override dispose() {
    super.dispose();
    this.widgetManager.dispose();
    this.store.dispose();

    this.#init();
  }

  public getWidgetInstance<T extends JsonObject = JsonObject>(id: string) {
    return this.store.getWidgetInstance<T>(id);
  }

  public getWidgetInstanceList() {
    return this.store.getWidgetInstanceList();
  }

  public getWidgetInstanceListByGroup<T extends JsonObject = JsonObject>(
    groupId: string,
  ) {
    const groupConfig = this.widgetManager.getGroup(groupId);
    if (!groupConfig) return [];

    return groupConfig.items
      .map((id) => this.getWidgetInstance<T>(id))
      .filter(notEmpty);
  }

  public reset() {
    this.store.initData(this.backupStore.data);
    this.emitter.emit('activeWidgetChanged');
    this.id = nanoid();
    this.emitter.emit('editorIdChanged');
  }

  public clear(orgId?: string) {
    this.store.initData({
      version: defaultStoreVersion,
      pageConfig: getInitalPageConfig(orgId),
      widgetTree: [],
    });
    this.setActiveWidgetInstance(null);
  }
}
container.register('EditorModel', EditorModel);
