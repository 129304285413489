import type { WidgetInstanceData } from '@linkpi/core';
import { tempValueDisplay } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useMemoizedFn } from 'ahooks';
import { Empty, Tooltip } from 'antd';
import { isNil } from 'lodash';
import { isEmpty } from 'ramda';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import attrWidgetNoData from '@/assets/img/attrWidgetNoData.png';
import type { IAttributeInfoWidget } from '@/components/PageModelEditor/src/widgets';
import { useOrgInfo, useOrgUserMap } from '@/hook';
import {
  use_deprecated_GlobalConditions,
  useGlobalConditions,
} from '@/hook/useGlobalConditions';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { useOrgTempMap } from '@/hook/useTemplate';
import { fetchDataSource } from '@/utils/dataManager';
import { cn } from '@/utils/utils';

import styles from './styles.less';

interface IProps {
  getterPiNode: GetterPiNode;
  data: WidgetInstanceData<IAttributeInfoWidget>;
}

export const AttributeInfoWidget: FC<IProps> = ({ data, getterPiNode }) => {
  const orgInfo = useOrgInfo()[0]!;
  const tempMap = useOrgTempMap(orgInfo.orgId);
  const userMap = useOrgUserMap();
  const departmentNodeMap = useOrgDepartmentNodesMap();

  const [node, setNode] = useState<any>(null);
  const defaultIcon =
    'https://test-inner-oss.linkerpi.com/storage/c4d14b2617764f66ad9c0eeedbe45c81/initIcon.png?OSSAccessKeyId=LTAI4GJJjJfFS4KtHxuFw5tZ&Expires=4947164349&Signature=si9tkyFYMpmU5W3X4vhNmrEbYZY%3D';
  const { config } = data;
  const {
    propsMap,
    arrange,
    bgColor,
    keyFontSize,
    keyFontColor,
    valueFontColor,
    valueFontSize,
    keyTextDecoration,
    valueTextDecoration,
    iconPosition = 'left',
    showIcon = false,
  } = config;

  const renderIcon = (prop: any, position: string) => {
    if (!showIcon) return null;

    const url: string = prop.iconUrl || defaultIcon;
    if (position !== iconPosition) return null;

    return (
      <div
        style={{
          backgroundImage: `url(${url})`,
          height: 24,
          width: 24,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          marginLeft: position === 'right' ? 12 : 0,
          marginRight: position === 'left' ? 12 : 0,
        }}
      ></div>
    );
  };

  const globalConditions = use_deprecated_GlobalConditions(data.id);

  const _conditions = useMemo(() => {
    if (!globalConditions) return config.conditions;
    if (!globalConditions?.length) return config.conditions;
    return [...config.conditions, ...globalConditions];
  }, [config.conditions, globalConditions]);

  const conditions = useGlobalConditions(_conditions, data.id);

  const init = useMemoizedFn(async () => {
    const res = await fetchDataSource({
      orgId: orgInfo.orgId,
      currentNodeId: getterPiNode.value.id,
      page: 1,
      pageSize: 1,
      ...config,
      conditions,
    });

    if (res.status !== 'ok' || !res.list.length) {
      return;
    }

    setNode(res.list[0]); // 找到第一个节点
  });

  useEffect(() => {
    init();
  }, [getterPiNode.value.id, conditions]);

  const templateId = conditions.find((i: any) => i.key === 'templateId')
    ?.input[0];

  const genProps = () => {
    if (!propsMap || propsMap.tempId !== templateId)
      return tempMap[templateId].prop
        .map((p: any, index: number) => ({ propIndex: index, ...p }))
        .filter((p: any) => p.type && !p.hide)
        .map((p: any, i: number) => ({
          propIndex: p.propIndex,
          name: p.name,
          switch: i < 8,
        }));

    return propsMap.props;
  };
  if (!node)
    return (
      <div
        className={styles.container}
        style={{ paddingTop: 12, width: '100%' }}
      >
        <Empty
          image={attrWidgetNoData}
          description={
            <div style={{ color: '#C9D0D9', fontSize: 12 }}>暂无数据</div>
          }
          imageStyle={{ height: 40, width: 40 }}
        />
      </div>
    );

  const renderValue = (p: any) => {
    const v = node.tempInfo.prop[p.propIndex];

    if (isNil(v) || isEmpty(v)) return '--';

    return tempValueDisplay({
      propConfig: tempMap[templateId]?.prop[p.propIndex],
      propValue: node.tempInfo.prop[p.propIndex],
      propIndex: p.propIndex,
      sysCascade: node.prop._sys_cascade,
      userMap: userMap,
      tempMap,
      departmentMap: departmentNodeMap,
      sysDisplay: node.prop._sys_display,
    });
  };

  let width = '100%';
  if (arrange === 'two') width = 'calc(50% - 6px)';
  if (arrange === 'three') width = 'calc(33.33% - 4px)';

  const _isEmpty = genProps().length === 0;

  if (_isEmpty)
    return (
      <div className="w-full h-full flex flex-wrap content-start">
        <div className={cn(styles.container, 'pt-3')}>
          <Empty
            image={attrWidgetNoData}
            description={
              <div style={{ color: '#C9D0D9', fontSize: 12 }}>暂无数据</div>
            }
            imageStyle={{ height: 40, width: 40 }}
          />
        </div>
      </div>
    );

  return (
    <div className="w-full flex flex-wrap content-start gap-[12px]">
      {genProps()
        .filter((p: any) => p.switch)
        .map((p: any) => (
          <div key={p.propIndex} style={{ width }}>
            <div className={styles.attr} style={{ backgroundColor: bgColor }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '40%',
                }}
              >
                {renderIcon(p, 'left')}
                <div
                  style={{
                    flex: 1,
                    width: 0,
                    fontSize: keyFontSize,
                    color: keyFontColor,
                    fontWeight: keyTextDecoration === 'bold' ? 700 : 400,
                    fontStyle:
                      keyTextDecoration === 'italic' ? 'italic' : 'normal',
                    textDecoration:
                      keyTextDecoration === 'underline' ? 'underline' : 'none',
                  }}
                  className="text-omit"
                >
                  {p.name}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '60%',
                }}
              >
                <Tooltip title={renderValue(p)} placement="top">
                  <div
                    className="text-omit"
                    style={{
                      flex: 1,
                      width: 0,
                      textAlign: 'right',
                      fontSize: valueFontSize,
                      color: valueFontColor,
                      fontWeight: valueTextDecoration === 'bold' ? 700 : 400,
                      fontStyle:
                        valueTextDecoration === 'italic' ? 'italic' : 'normal',
                      textDecoration:
                        valueTextDecoration === 'underline'
                          ? 'underline'
                          : 'none',
                    }}
                  >
                    {renderValue(p)}
                  </div>
                </Tooltip>
                {renderIcon(p, 'right')}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
