import type { ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { useControllableValue } from 'ahooks';
import { Form, Radio, Space } from 'antd';
import type { FC } from 'react';

import ConditionFilter from '@/components/ConditionFilter';
import { useCurrentOrgId, useNodeTreeData, useOrgInfo } from '@/hook';

import { useActiveWidgetInstance, useEditor } from '../hooks';
import { GlobalConditionFilterLink } from './DataSourceSetting';

import styles from './ConditionSetting.less';

const addButtonProps = {
  icon: null,
  className: 'text-[#242D3F]  shadow-none w-full',
  type: 'dashed',
} as any;

const radioOptions = [
  { value: 'current', label: '展示父主题下的子主题' },
  { value: 'others', label: '选择其他路径' },
];

export type IConditionItem = ViewList.ViewconditionV2Item;

export type IConditionSetting = {
  parentType: 'current' | 'others';
  conditions: IConditionItem[];
};

export const ConditionSetting: FC<{
  style?: React.CSSProperties;
  type?: 'formItem' | 'pure' | 'formPure';
  value?: IConditionSetting;
  onChange?: (v: IConditionSetting) => void;
  hideTitle?: boolean;
}> = ({ type = 'formItem', style, ...restProps }) => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IConditionSetting>();

  if (type === 'formItem' || type === 'formPure') {
    assertExists(widgetInstance);
  }

  const _value = type === 'pure' ? restProps.value : widgetInstance?.config;
  const [value, setValue] = useControllableValue({
    value: _value,
    onChange: restProps.onChange,
  }) as [IConditionSetting | undefined, (v: IConditionSetting) => void];

  const orgId = useCurrentOrgId();
  const [orgInfo] = useOrgInfo(orgId);
  const nodeTreeData = useNodeTreeData({ orgId });

  assertExists(orgInfo);
  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<IConditionSetting>;

  // FIXME: 冗余
  const setParentType = (result: IConditionSetting['parentType']) => {
    if (type === 'pure') {
      const parentType = result;
      let conditions = value?.conditions || [
        {
          op: 'intersect',
          key: 'templateId',
          input: [],
        },
      ];
      if (result === 'current') {
        conditions = conditions.filter((i) => i.key !== 'ancestor');
      } else if (!conditions.find((i) => i.key === 'ancestor')) {
        conditions = [
          {
            op: 'intersect',
            key: 'ancestor',
            input: [orgInfo.rootId],
          },
          ...conditions,
        ];
      }
      setValue({
        conditions,
        parentType,
      });
      return;
    }

    return updateConfig((w) => {
      w.parentType = result;
      if (result === 'current') {
        w.conditions = w.conditions.filter((i) => i.key !== 'ancestor');
      } else if (!w.conditions.find((i) => i.key === 'ancestor')) {
        w.conditions.unshift({
          op: 'intersect',
          key: 'ancestor',
          input: [orgInfo.rootId],
        });
      }
    });
  };

  const setCondition = (v: any) => {
    if (type === 'pure') {
      setValue({
        ...value,
        conditions: v,
      } as any);
      return;
    }
    return updateConfig((w) => {
      w.conditions = v;
    });
  };

  const content = (
    <div style={style} className={styles.wrapper}>
      <Space direction="vertical" className="full-w">
        <Radio.Group
          options={radioOptions}
          value={value?.parentType}
          onChange={(e) => setParentType(e.target.value)}
        />
        <ConditionFilter
          key={value?.parentType}
          getTreeData={() => nodeTreeData}
          disableParentId={value?.parentType === 'current'}
          defaultConditions={value?.conditions}
          onConditionsChange={setCondition}
          templateUsage="fullAccess"
          editable
          addButtonProps={addButtonProps}
        />
      </Space>
    </div>
  );

  if (type == 'pure' || type === 'formPure') return content;

  return (
    <Form.Item
      label={
        restProps.hideTitle ? (
          ''
        ) : (
          <span className="flex items-center justify-between w-full">
            内容设置
            <GlobalConditionFilterLink id={widgetInstance?.id || ''} />
          </span>
        )
      }
    >
      {content}
    </Form.Item>
  );
};
