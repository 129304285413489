import { generateAddOpId } from '@linkpi/core';
import { produce } from 'immer';

import type { IGlobalConditionFilterConfigV2 } from '../GlobalConditionFilterWidget';
import { GLOBAL_CONDITION_LATEST_VERSION } from './constant';
import type {
  IGlobalConditionFilterCondition,
  IGlobalConditionFilterConfig,
} from './types';

export const migrateConfig = (config: IGlobalConditionFilterConfigV2) => {
  const _config = produce(config, (draft) => {
    draft.version = GLOBAL_CONDITION_LATEST_VERSION as any;
    draft.conditionList.forEach((condition) => {
      (condition as IGlobalConditionFilterCondition).showName =
        !!condition.name;

      if (condition.filedType === 'cascader' && condition.cascaderConfig) {
        // 创建级联选项集 id
        const id = generateAddOpId().toUpperCase();
        (
          condition as IGlobalConditionFilterCondition
        ).cascaderConfig!.optionId = id;
      }

      // (condition as any).filedType = '$$TEMP_BY_PROP';
      // /**
      //  * 筛选对象应用的匹配列表迁移
      //  */
      // condition.targetList?.forEach((target) => {
      //   (target as any).matchingGroupOp = 'and';
      //   (target as any).matchingGroupList = [
      //     {
      //       list: target.matchingList?.length
      //         ? target.matchingList
      //         : [{ op: 'intersect', propKey: '', input: [] } as any],
      //       op: 'and',
      //     },
      //   ];
      //   delete target.matchingList;
      // });
    });
  }) as unknown as IGlobalConditionFilterConfig;

  return _config;
};
