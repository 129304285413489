import type { CurrentUser } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { InputNumber } from 'antd';
import { type FC, useRef } from 'react';

import type { LocationSelectRef } from '@/components/LocationSelect';
import LocationSelect from '@/components/LocationSelect';
import { cn } from '@/utils/utils';

type AddressRangerPickerValue = [CurrentUser.SysLocationItemType, number] | [];

type AddressRangerPickerProps = {
  value?: AddressRangerPickerValue;
  onChange?: (value: AddressRangerPickerValue) => void;
  disabled?: boolean;
  className?: string;
};

export const AddressRangerPicker: FC<AddressRangerPickerProps> = ({
  value,
  onChange,
  disabled,
  className,
}) => {
  const [input, onInputChange] = useControllableValue<AddressRangerPickerValue>(
    {
      value,
      onChange,
    },
  );

  const locationSelectRef = useRef<LocationSelectRef>(null);

  const handleLocationSelect = () => {
    locationSelectRef.current?.show({
      locationData: input[0] || null,
      onSave: (newData: any) => {
        const newInput = input;
        newInput[0] = newData;
        onInputChange(newInput);
      },
    });
  };

  return (
    <div>
      <div
        className={cn(
          'ant-select ant-select-single',
          'w-full leading-[30px]',
          'leading-[30px]',
          'mb-2',
          className,
          disabled && 'cursor-not-allowed pointer-events-none bg-#F5F5F5',
        )}
        onClick={handleLocationSelect}
      >
        <div className="ant-select-selector text-omit">
          {!input[0] ? '请选择' : input[0].name}
        </div>
      </div>
      <div className="flex mt-2">
        <InputNumber
          style={{ flex: 1 }}
          placeholder="请输入距离范围"
          value={input[1] || ''}
          disabled={disabled}
          onChange={(e) => {
            const newInput: any = input ? [...input] : [];
            newInput[1] = e;
            onInputChange(newInput);
          }}
        />
        <p className="ml-2 leading-[30px]">公里以内</p>
      </div>

      {/*  */}
      <LocationSelect ref={locationSelectRef} />
    </div>
  );
};
