/* eslint-disable react/no-array-index-key */
import { useUpdateEffect } from '@react-hookz/web';
import { Divider, Select } from 'antd';
import { useHydrateAtoms } from 'jotai/utils';
import { ScopeProvider } from 'jotai-scope';
import type { FC, PropsWithChildren } from 'react';
import React, { memo } from 'react';

import { PiButton } from '@/components/Button';
import { cn } from '@/utils/utils';

import { ConditionGroup } from './components/ConditionGroup';
import { MATCHING_OP_OPTIONS } from './const';
import {
  conditionFilterConfigAtom,
  rootConditionAtom,
  useConditionRoot,
} from './hooks';
import type { ConditionFilterV2Props } from './types';

import './styles/index.less';

/**
 * TODO
 * 基于 Atom 会有同步的问题
 *
 * - 不使用 atom，直接基于 useState + Context 实现
 */
const InnerConditionFilterV2: React.FC<ConditionFilterV2Props> = ({
  value,
  onChange,
  className,
  ...props
}) => {
  const { disabled } = props;
  const { root, addGroup, updateLogic, updateRootCondition } =
    useConditionRoot();

  /**
   * 将外部传入的 value 值同步到 atom 内
   *
   * NOTE
   *
   * 会多触发一次 render
   */
  useUpdateEffect(() => {
    if (!value) return;
    if (value === root) return;

    updateRootCondition(value);
  }, [value]);

  /**
   * 将 atom 内的值同步到外部
   */
  useUpdateEffect(() => {
    if (root === value) return;

    onChange?.(root);
  }, [root]);

  return (
    <div className={cn('flex flex-col', 'condition-filter-v2', className)}>
      {root.input.map((_, index) => (
        <div key={index} className="flex flex-col">
          {!!index && (
            <Divider dashed style={{ margin: 0 }}>
              <Select
                defaultValue="and"
                value={root.op}
                options={MATCHING_OP_OPTIONS}
                bordered={false}
                onChange={updateLogic as any}
              />
            </Divider>
          )}
          <ConditionGroup groupIndex={index} />
        </div>
      ))}

      {!disabled && (
        <Divider dashed style={{ margin: 0 }}>
          <PiButton type="link" onClick={addGroup} size="small">
            添加分组
          </PiButton>
        </Divider>
      )}
    </div>
  );
};

const HydrateAtom: FC<PropsWithChildren<ConditionFilterV2Props>> = ({
  children,
  value,
  ...props
}) => {
  useHydrateAtoms([
    [rootConditionAtom, value || { input: [], op: 'and' }],
    [conditionFilterConfigAtom, props],
  ]);

  return children;
};

export const ConditionFilterV2 = memo(
  ({ value, onChange, ...props }: ConditionFilterV2Props) => {
    console.log('😇 value', value);

    return (
      <ScopeProvider atoms={[rootConditionAtom, conditionFilterConfigAtom]}>
        <HydrateAtom {...props} value={value}>
          <InnerConditionFilterV2 {...props} onChange={onChange} />
        </HydrateAtom>
      </ScopeProvider>
    );
  },
);
