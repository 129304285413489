import type { FC } from 'react';

import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

interface CurrentNodePropDisplayProps {
  propIndex: number;
}

export const CurrentNodePropDisplay: FC<CurrentNodePropDisplayProps> = ({
  propIndex,
}) => {
  const tempInfo = useCurrentTemplate();

  const prop = tempInfo.prop[propIndex];

  return (
    <div className="ant-select-selector bg-white rounded-[8px] px-3 py-1 border w-full flex gap-1 items-center">
      {prop.name}
    </div>
  );
};
