import type { SelectProps } from 'antd';
import { isValidElement } from 'react';
import reactElementToJSXString from 'react-element-to-jsx-string';

const textIncludes = (text: string, input: string) =>
  text.toLowerCase().indexOf(input.toLowerCase()) >= 0;

/**
 * antd 下拉选框的搜索函数
 * 支持搜索label
 *
 * 不支持 options 嵌套
 */
export const selectSearchFn: SelectProps['filterOption'] = (input, option) => {
  if (!option) return false;

  if (isValidElement(option.label)) {
    const text = reactElementToJSXString(option.label);
    return textIncludes(text, input);
  } else if (typeof option.label === 'string') {
    return textIncludes(option.label, input);
  }
  return false;
};

/**
 * antd 下拉选框的搜索函数
 * 支持搜索值和label
 *
 * 不支持 options 嵌套
 */
export const selectSearchWithValueFn: SelectProps['filterOption'] = (
  input,
  option,
) => {
  if (!option) return false;

  let result = false;
  if (isValidElement(option.label) && !result) {
    const text = reactElementToJSXString(option.label);
    result = textIncludes(text, input);
  } else if (typeof option.label === 'string' && !result) {
    result = textIncludes(option.label, input);
  } else if (typeof option.value === 'string' && !result) {
    result = textIncludes(option.value, input);
  }
  return result;
};
