import type { CurrentUser } from '@linkpi/core';
import type { FC } from 'react';
import { match } from 'ts-pattern';

import AddressInput from '@/components/AddressInput';
import { ProvCascader } from '@/components/ProvCascader';
import type { TemplateMapUsage } from '@/hook';
import { cn } from '@/utils/utils';

import { useConditionItemInnerConfig } from '../../../hooks/useConditionItemInnerConfig';
import type { Condition } from '../../../types';

type AddressSelectProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  className?: string;
  type: NonNullable<CurrentUser.TemplateProp['accuracy']> | 'full';
  config: Pick<Condition, 'key' | 'index'>;
  templateId: string;
  templateUsage?: TemplateMapUsage;
};

export const AddressSelect: FC<AddressSelectProps> = ({
  value,
  onChange,
  disabled,
  className,
  type,
  config,
  templateId,
  templateUsage,
}) => {
  const conditionConfig = useConditionItemInnerConfig({
    templateId,
    templateUsage,
    key: config.key,
    index: config.index,
  });

  return match(type)
    .with('full', () => (
      <ProvCascader
        className={cn('condition-filter-v2-address-select', className)}
        disabled={disabled}
        multiple={false}
        value={value as string[]}
        onChange={(e: any) => {
          onChange?.(e);
        }}
      />
    ))
    .otherwise((t) => (
      <AddressInput
        className={cn(
          'condition-filter-v2-address-select',
          'rounded-[8px] border border-#EBEDF0 leading-[30px]',
          className,
        )}
        // address 要数组
        address={value?.[0] ? (value as any) : null}
        disabled={disabled}
        prop={{
          ...((conditionConfig?.data as any) || {}),
          conditionMatching: false,
          accuracy: t,
        }}
        onConfirm={(e: any) => {
          onChange?.(e);
        }}
        autoOpen={false}
        quoteOptions={[]}
      />
    ));
};
