import { getShareDBConnectionInstance, initConnection } from '@linkpi/core/web';
import { useIntl } from '@umijs/max';
import { Button, message, notification } from 'antd';

import { setupPiSDK } from './utils/piSDK';
import { consoleColorTag } from './utils/utils';

import './polyfills/localstorage';
import './utils/asciiArt';

import '@/assets/font/iconfont.css';
import 'jotai-devtools/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

/**
 * 初始化 core
 */
initConnection();

/**
 * 初始化 piSDK
 */
setupPiSDK();

getShareDBConnectionInstance().onMessageError((msg) => {
  message.warn(msg);
});

const { pwa } = false;
if (pwa) {
  // Notify user if offline now
  window.addEventListener('sw.offline', () => {
    message.warning(
      useIntl().formatMessage({
        id: 'app.pwa.offline',
      }),
    );
  }); // Pop up a prompt on the page asking the user if they want to use the latest version

  window.addEventListener('sw.updated', (event) => {
    const e = event;

    const reloadSW = async () => {
      // Check if there is sw whose state is waiting in ServiceWorkerRegistration
      // https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerRegistration
      const worker = e.detail && e.detail.waiting;

      if (!worker) {
        return true;
      } // Send skip-waiting event to waiting SW with MessageChannel

      await new Promise((resolve, reject) => {
        const channel = new MessageChannel();

        channel.port1.onmessage = (msgEvent) => {
          if (msgEvent.data.error) {
            reject(msgEvent.data.error);
          } else {
            resolve(msgEvent.data);
          }
        };

        worker.postMessage(
          {
            type: 'skip-waiting',
          },
          [channel.port2],
        );
      }); // Refresh current page to use the updated HTML and other assets after SW has skiped waiting

      window.location.reload(true);
      return true;
    };

    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        onClick={() => {
          notification.close(key);
          reloadSW();
        }}
      >
        {useIntl().formatMessage({
          id: 'app.pwa.serviceworker.updated.ok',
        })}
      </Button>
    );
    notification.open({
      message: useIntl().formatMessage({
        id: 'app.pwa.serviceworker.updated',
      }),
      description: useIntl().formatMessage({
        id: 'app.pwa.serviceworker.updated.hint',
      }),
      btn,
      key,
      onClose: async () => {},
    });
  });
} else if ('serviceWorker' in navigator) {
  // unregister service worker
  const { serviceWorker } = navigator;

  if (serviceWorker.getRegistrations) {
    serviceWorker.getRegistrations().then((sws) => {
      sws.forEach((sw) => {
        sw.unregister();
      });
    });
  }

  serviceWorker.getRegistration().then((sw) => {
    if (sw) sw.unregister();
  }); // remove all caches

  if (window.caches && window.caches.keys) {
    caches.keys().then((keys) => {
      keys.forEach((key) => {
        caches.delete(key);
      });
    });
  }
}

window.cpuHeartBeatTime = 0;
window.cpuHeartBeat = () => {
  if (window.cpuHeartBeatTime) {
    const t2 = Date.now() - window.cpuHeartBeatTime;
    if (t2 > 70) {
      consoleColorTag({
        tag: 'cpu heart beat',
        message: `CPU心跳异常，${t2}`,
      });
    }
  }

  window.cpuHeartBeatTime = Date.now();
  setTimeout(window.cpuHeartBeat, 50);
};
