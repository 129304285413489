import { PaperClipOutlined } from '@ant-design/icons';

import { WidgetIcon } from '../../components';
import { defineSystemWidget } from '../utils';

export const RollCall = defineSystemWidget()({
  id: 'RollCall',
  title: '点名',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#FF962C"
        icon={<PaperClipOutlined style={{ color: '#ffffffcc' }} />}
      />
    );
  },
  basic: {
    defaultHeight: 10,
    defaultWidth: 12,
  },
  metadata: {},
  preview: () => <div>点名</div>,
  setting: () => null,
});
