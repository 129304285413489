import { useQuery, useSuspenseQueries } from '@tanstack/react-query';
import { create, keyResolver, windowScheduler } from '@yornaath/batshit';
import { memoize } from 'lodash';

import { useCurrentOrgId } from '@/hook/useOrg';
import type { GetOptionListParams } from '@/services/options';
import { getOptionList } from '@/services/options';

const optionsConfig = memoize(({ org_id, configType }: GetOptionListParams) =>
  create({
    fetcher: async (id_list: string[]) => {
      const res = await getOptionList({
        org_id,
        configType,
        id_list,
      });
      if (res.status === 'ok') {
        return res.data;
      } else {
        return [];
      }
    },
    resolver: keyResolver('id'),
    scheduler: windowScheduler(30),
  }),
);

export const useOptionsConfig = (
  configType: GetOptionListParams['configType'],
  id?: string,
) => {
  const orgId = useCurrentOrgId();

  return useQuery({
    staleTime: 200,
    queryKey: ['options-list', orgId, configType, id],
    queryFn: async () => {
      if (id) {
        const res = await optionsConfig({
          org_id: orgId,
          configType,
        }).fetch(id);
        return res;
      } else {
        return undefined;
      }
    },
  });
};

export const useSuspenseOptionsConfigList = ({
  configType,
  idList,
}: {
  configType: GetOptionListParams['configType'];
  idList: string[];
}) => {
  const orgId = useCurrentOrgId();

  return useSuspenseQueries({
    queries: idList.map((id) => ({
      queryKey: ['options-list', orgId, configType, id],
      queryFn: async () => {
        const res = await optionsConfig({
          org_id: orgId,
          configType,
        }).fetch(id);
        return res;
      },
    })),
  });
};
