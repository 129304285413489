import type { Condition } from './types';

export const genDataPropKey = (key: string, index?: number) => {
  return [key, typeof index === 'number' ? index : undefined]
    .filter((i) => typeof i !== 'undefined')
    .join('_');
};

export const parseDataPropKey = (_key: string) => {
  const [key, index] = _key.split('_') as [
    Condition['key'],
    string | undefined,
  ];
  return { key, index: index ? Number(index) : undefined };
};
