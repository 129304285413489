import type { FC } from 'react';
import { allExpanded, defaultStyles } from 'react-json-view-lite';
import type { JsonObject } from 'type-fest';

import { lazyImport } from '@/utils/utils';

import 'react-json-view-lite/dist/index.css';

const { JsonView } = lazyImport(
  () => import('react-json-view-lite'),
  'JsonView',
);

export const Viewer: FC<{ data: JsonObject }> = ({ data }) => {
  return (
    <JsonView
      data={data}
      shouldExpandNode={allExpanded}
      style={defaultStyles}
    />
  );
};
