import type { CurrentUser } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { Popover } from 'antd';
import type { FC } from 'react';

export const DisplayPercentForView: FC<{
  template: CurrentUser.TemplateInfo;
  isDark?: boolean;
  disable?: boolean;
  value?: string | null;
  onChange?: (v: string | null) => void;
}> = ({ template, disable, isDark, ...props }) => {
  const [value, onChange] = useControllableValue(props);

  // 找到 数字 和 数字公式 百分比的属性
  const renderShowProgressByProp = () => {
    if (!template) return '';
    if (!value) return '无';

    const index = value.split('-')[1];
    return template.prop[index].name;
  };

  const renderNumericalFormatProps = () => {
    if (!template) return null;

    const showProgressByProp = value;
    const numericalFormatProps = template.prop
      .map((p, index) => ({ ...p, index }))
      .filter(
        (p) =>
          p.name &&
          p?.number?.numericalFormat &&
          ['number', 'formula'].includes(p.type),
      );
    if (numericalFormatProps.length === 0)
      return (
        <div
          style={{
            cursor: 'not-allowed',
            color: '#C9D0D9',
            padding: '6px 12px',
          }}
        >
          暂无相关属性
        </div>
      );
    return (
      <ul style={{ maxHeight: 600 }} className="view-setting-list">
        {numericalFormatProps.map((p) => (
          <li
            key={p.index}
            className={`view-setting-item text-omit${
              showProgressByProp === `prop-${p.index}` ? ' active' : ''
            }`}
            onClick={() => {
              onChange(`prop-${p.index}`);
            }}
          >
            {p.name}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Popover
      content={renderNumericalFormatProps()}
      trigger={!disable ? ['click'] : []}
      overlayClassName="view-setting-pop"
      placement={'bottomRight'}
    >
      <div
        className={`view-setting-block${isDark ? ' view-setting-block-dark' : ''}`}
      >
        <div className="text-omit">显示进度： {renderShowProgressByProp()}</div>
        {value && !disable && (
          <i
            className={'iconfont iconButton_cancel icon-right'}
            onClick={(e) => {
              e.stopPropagation();
              onChange(null);
            }}
          />
        )}
        {disable || <i className={`iconfont iconxiala icon-right`} />}
      </div>
    </Popover>
  );
};
