import { Cascader } from 'antd';
import { type FC, useMemo } from 'react';

import { useWidgetInstancesByWidgetId } from '../../hooks';
import { FILTER_TYPE_TEXT_MAP } from './const';
import type { IGlobalConditionFilterConfigV2 } from './types';

export const GlobalFilterItemCascader: FC<{
  value?: [string, string];
  onChange?: (value?: [string, string]) => void;
}> = ({ value, onChange }) => {
  const widgetInstances =
    useWidgetInstancesByWidgetId<IGlobalConditionFilterConfigV2>(
      'GlobalConditionFilter',
    );

  const options = useMemo(() => {
    return widgetInstances.map((instance) => ({
      value: instance.id,
      label: instance.config.name,
      children: instance.config.conditionList.map((condition) => ({
        value: condition.id,
        label: [
          FILTER_TYPE_TEXT_MAP[condition.filedType],
          condition.name || '未命名筛选项',
        ].join('-'),
      })),
    }));
  }, [widgetInstances]);

  return (
    <Cascader
      showSearch
      options={options}
      value={value}
      onChange={(v) => onChange?.(v as [string, string])}
    />
  );
};
