import type { FC } from 'react';

import { ConditionSetting, MatchingSetting, showSettingModal } from '..';

const CreationSetting: FC = () => {
  // const form = ProForm.useFormInstance();
  // const value = form.getFieldsValue();

  // ProForm.useWatch(['conditions']);
  // ProForm.useWatch(['parentType']);

  // useMount(() => {
  //   form.setFieldsValue({
  //     parentType: 'current',
  //     conditions: [
  //       {
  //         op: 'intersect',
  //         key: 'templateId',
  //         input: [],
  //       },
  //     ],
  //   });
  // });

  return (
    <>
      <>
        <ConditionSetting
          style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
          type="formPure"
        />
        <MatchingSetting
          style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
        />
      </>
      {/* <ProForm.Item name={['parentType']} hidden />
      <ProForm.Item
        name={['conditions']}
        className={styles.hiddeContent}
        rules={[
          {
            required: true,
            validator(rule, _value: ViewList.ViewconditionV2, callback) {
              const templateItem = _value.find((i) => i.key === 'templateId');
              if (!templateItem) {
                callback('请选择主题类型！');
                return;
              }
              if (isEmpty(templateItem?.input)) {
                callback('请选择主题类型！');
                return;
              }
              callback();
            },
          },
        ]}
      /> */}
    </>
  );
};

export function showConditionSettingModal<T>({ title }: { title: string }) {
  return showSettingModal<T>({ title, formContent: CreationSetting });
}
