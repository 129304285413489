import { useControllableValue } from 'ahooks';
import { Popover } from 'antd';

import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import type { FilterItemType } from '../../../types';
import ItemCard from './ItemCard';

import Styles from './index.less';

const CardList = [
  {
    label: '输入框',
    value: 'text',
  },
  {
    label: '日期',
    value: 'dateRangePicker',
  },
  {
    label: '下拉选择',
    value: 'select',
  },
  {
    label: '级联选择',
    value: 'cascader',
  },
  {
    label: '日月年',
    value: 'yearMonthDay',
  },
] as const;

interface ItemTypeProps {
  value?: string;
  onChange?: (v: string) => void;
}

const ItemType = (props: ItemTypeProps) => {
  const { ...rest } = props;
  const [value, onChange] = useControllableValue<FilterItemType>(rest);

  const cardLabelMap = CardList.reduce<Record<string, string>>(
    (result, item) => {
      result[item.value] = item.label;
      return result;
    },
    {},
  );

  const content = (
    <div className="w-[568px] flex flex-wrap gap-4">
      {CardList.map((item) => (
        <div
          key={item.value}
          className="w-[calc(33.33%-10.66px)] flex flex-col items-center cursor-pointer group"
          onClick={() => onChange(item.value)}
        >
          <div
            className={cn(
              'w-full mb-2 px-2 py-3 flex items-center bg-[#F8F9FB] rounded-[8px] pointer-events-none',
              {
                'border-2 border-[#316EF5]': value === item.value,
                'group-hover:bg-[rgba(49,110,245,0.05)]': value !== item.value,
              },
            )}
          >
            <ItemCard
              type={item.value}
              name="标题"
              showName={item.value !== 'yearMonthDay'}
              className="w-full"
            />
          </div>
          <span
            className={cn('shrink-0 group-hover:text-[#316EF5]', {
              'text-[#316EF5]': value === item.value,
            })}
          >
            {item.label}
          </span>
        </div>
      ))}
    </div>
  );

  return (
    <Popover
      overlayClassName={Styles['t-popover']}
      content={content}
      placement="bottom"
      trigger="click"
    >
      <div className="flex items-center border border-[#EBEDF0] rounded-[8px] px-2 py-3 cursor-pointer">
        <div className="w-full flex items-center pointer-events-none">
          <ItemCard type={value} name="标题" showName className="mr-2 flex-1" />
          <span className="shrink-0 mr-12">{cardLabelMap[value]}</span>
          <RegularIcon type="iconzhankaishouqi1" />
        </div>
      </div>
    </Popover>
  );
};

export default ItemType;
