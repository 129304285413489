import { useControllableValue } from 'ahooks';
import type { InputProps } from 'antd';
import { Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { match, P } from 'ts-pattern';

import { ColorPicker } from '@/components/ColorPicker';
import { RegularIcon } from '@/components/IconFont';
import { cn, hexToRgba } from '@/utils/utils';

export type RGBAColor = {
  r: number;
  g: number;
  b: number;
  a: number;
};
interface ColorInputProps {
  value?: RGBAColor;
  onChange?: (value: RGBAColor) => void;
  className?: string;
  style?: React.CSSProperties;
  type?: 'circle' | 'A';
}

const parseRgba = (
  rgba: string,
): { r: number; g: number; b: number; a: number } => {
  const matchResult = rgba.match(
    /^rgba\s*\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*([\d.]+)\s*\)$/,
  );
  if (!matchResult) {
    throw new Error(`不合法的 rgba: ${rgba}`);
  }
  return {
    r: parseInt(matchResult[1], 10),
    g: parseInt(matchResult[2], 10),
    b: parseInt(matchResult[3], 10),
    a: parseFloat(matchResult[4]),
  };
};

const rgbToHex = ({ r, g, b }: { r: number; g: number; b: number }) => {
  const toHex = (v: number) => {
    const hex = Math.max(0, Math.min(255, v)).toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  // '#' +
  return '' + toHex(r) + toHex(g) + toHex(b);
};

export const ColorInput = (props: ColorInputProps) => {
  const { value, onChange, className, style, type = 'circle' } = props;
  const [_value, _onChange] = useControllableValue<RGBAColor>({
    value,
    onChange,
  });

  const { alpha, rgb } = useMemo(() => {
    const { a, ..._rgb } = _value;
    return {
      alpha: a * 100,
      rgb: _rgb,
    };
  }, [_value]);
  const [color, onColorChange] = useState(rgbToHex(rgb));
  useEffect(() => {
    onColorChange(rgbToHex(rgb));
  }, [rgb]);

  const handleAlphaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      _onChange({
        ..._value,
        a: Math.max(0, Math.min(100, +inputValue)) / 100,
      });
    }
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^[0-9A-Fa-f]{1,6}$/;
    if (reg.test(inputValue) || inputValue === '') {
      onColorChange(inputValue);
    }
  };

  const handleColorBlur: InputProps['onBlur'] = (e) => {
    const { value: inputValue } = e.target;

    const { color: changeColor, rgb: changeRgb } = match(inputValue)
      .with(P.string.length(0), () => {
        return {
          color: rgbToHex(rgb),
          rgb,
        };
      })
      .with(
        P.when((v) => v.length < 6),
        (v) => {
          const colorValue = v.padEnd(6, '0');
          onColorChange(colorValue);
          const { red, green, blue } = hexToRgba(`#${colorValue}`, 0);
          return {
            color: colorValue,
            rgb: {
              r: red,
              g: green,
              b: blue,
            },
          };
        },
      )
      .otherwise((v) => {
        const { red, green, blue } = hexToRgba(`#${v}`, 0);
        return {
          color: v,
          rgb: {
            r: red,
            g: green,
            b: blue,
          },
        };
      });

    onColorChange(changeColor);
    _onChange({
      ..._value,
      ...changeRgb,
    });
  };

  let displayNode = null;
  if (type === 'circle') {
    displayNode = (
      <div
        style={style}
        className={cn(
          'flex items-center border border-solid border-[#EBEDF0] rounded-[8px] pr-[60px]',
          className,
        )}
      >
        <span>
          <ColorPicker
            value={_value}
            onChange={(c) => {
              const _color = c as unknown as string;
              _onChange(parseRgba(_color));
            }}
            itemRender={(backgroundColor) => (
              <div className="flex h-full px-3 border-r border-solid border-[#EBEDF0]">
                <span
                  className="w-6 h-6 rounded-full cursor-pointer border border-solid border-[#EBEDF0]"
                  style={{ backgroundColor }}
                />
              </div>
            )}
          />
        </span>
        <Input
          value={color}
          onChange={handleColorChange}
          onBlur={handleColorBlur}
          bordered={false}
          prefix="#"
        />
        <Input
          value={alpha}
          onChange={handleAlphaChange}
          bordered={false}
          suffix="%"
        />
      </div>
    );
  } else if (type === 'A') {
    displayNode = (
      <div
        style={style}
        className={cn(
          ' cursor-pointer flex border border-solid border-[#EBEDF0] rounded-[8px] w-[32px] h-[32px]',
          className,
        )}
      >
        <ColorPicker
          value={_value}
          onChange={(c) => {
            const _color = c as unknown as string;
            _onChange(parseRgba(_color));
          }}
          itemRender={(backgroundColor) => (
            <div className="flex relative w-full h-full items-center justify-center">
              {/* <FontColorsOutlined style={{ fontSize: 18, color: '#000' }} /> */}
              <RegularIcon type="iconwenzi" size={16} />
              <div
                style={{
                  backgroundColor,
                  position: 'absolute',
                  border: '1px solid #EBEDF0',
                  width: 16,
                  height: 5,
                  borderRadius: 16,
                  left: 7,
                  top: 19,
                }}
              ></div>
            </div>
          )}
        />
      </div>
    );
  }

  return displayNode;
};
