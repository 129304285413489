import type { ProFormSelectProps } from '@ant-design/pro-form';
import {
  ProForm,
  ProFormDependency,
  ProFormSelect,
} from '@ant-design/pro-form';
import { useQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { path } from 'ramda';
import { useMemo } from 'react';

import { useCurrentOrgId } from '@/hook';
import { getOptionList } from '@/services/options';

import { OptionListAtom } from '../../../store';
import { showOptionsListModal } from '../../OptionsListModal';
import {
  showCreateOptionsModal,
  showEditOptionsModal,
} from '../../OptionsModal';

type OptionsSelectProps = {
  type: 'xiala' | 'jilian';
};

const OptionsSelect = <T,>(
  props: ProFormSelectProps<T> & OptionsSelectProps,
): JSX.Element => {
  const { type, name = [], ..._props } = props;
  const orgId = useCurrentOrgId();
  const setOptionListAtom = useSetAtom(OptionListAtom);
  const form = ProForm.useFormInstance();

  const {
    data: selectOptionList = [],
    refetch: refresh,
    isLoading,
  } = useQuery({
    queryFn: async () => {
      const res = await getOptionList({
        org_id: orgId,
        configType: type,
      });

      if (res.status === 'ok') {
        setOptionListAtom(res.data as any[]);
        return res.data as any[];
      }

      return [];
    },
    queryKey: ['fetch_option_list', orgId, type],
    staleTime: 30 * 1000, // 30秒内不重新请求
    gcTime: 60 * 60 * 1000, // 1小时后清除缓存
  });

  const optionMap = useMemo(() => {
    return selectOptionList.reduce<Record<string, any>>((result, item) => {
      result[item.id] = item;
      return result;
    }, {});
  }, [selectOptionList]);

  return (
    <>
      <ProFormSelect
        placeholder="请选择选项集"
        valueEnum={selectOptionList?.reduce<Record<string, string>>(
          (result, item) => {
            result[item.id] = item.name;
            return result;
          },
          {} as Record<string, string>,
        )}
        name={[...name, 'optionId']}
        fieldProps={{
          loading: isLoading,
          onChange: (value) => {
            // form.setFieldValue(
            //   [...name, 'optionSourceConfig'],
            //   value ? optionMap[value as string].optionSourceConfig : undefined,
            // );
            form.setFieldValue([...name, 'optionSourceConfig'], undefined);
          },
        }}
        {..._props}
      />
      <ProFormDependency name={[...name, 'optionId']}>
        {(v) => {
          const optionId = path<string>([...name, 'optionId'], v);
          return (
            <div className="flex flex-wrap items-center gap-x-3 gap-y-2 ant-form-item">
              <span className="text-[#767C88]">没有合适的选项集？</span>
              <span
                className="text-[#316EF5] cursor-pointer"
                onClick={async () => {
                  await showCreateOptionsModal({
                    fieldType: type,
                  });
                  refresh();
                }}
              >
                立即创建
              </span>
              {optionId && (
                <span
                  className="text-[#316EF5] cursor-pointer"
                  onClick={async () => {
                    await showEditOptionsModal({
                      fieldType: type,
                      value: optionMap[optionId],
                    });
                    refresh();
                  }}
                >
                  编辑选项集
                </span>
              )}
              <span
                className="text-[#316EF5] cursor-pointer"
                onClick={() =>
                  showOptionsListModal({
                    update: refresh,
                    fieldType: type,
                  })
                }
              >
                管理选项集
              </span>
            </div>
          );
        }}
      </ProFormDependency>
    </>
  );
};

export default OptionsSelect;
