import { useControllableValue } from 'ahooks';
import { Popover } from 'antd';

import { ArrangementList } from '../../constant';
import SettingRadio from '../SettingItem/Radio';

import Styles from './index.less';

interface ArrangementPopoverProps {
  children: React.ReactNode;
  value?: string;
  onChange?: (value: string) => void;
}

const ArrangementPopover = (props: ArrangementPopoverProps) => {
  const { children, ...rest } = props;

  const [value, onChange] = useControllableValue<string>(rest);

  const content = (
    <div className="w-72">
      <p className="text-sm font-bold mb-3 text-[#242D3F]">排列方式</p>
      <SettingRadio list={ArrangementList} value={value} onChange={onChange} />
    </div>
  );

  return (
    <Popover
      trigger={['click']}
      overlayClassName={Styles['a-popover']}
      placement="bottom"
      content={content}
    >
      {children}
    </Popover>
  );
};

export default ArrangementPopover;
