// import { useSelector } from '@umijs/max';
import {
  atom,
  type ExtractAtomValue,
  getDefaultStore,
  useAtomValue,
} from 'jotai';

const defaultStore = getDefaultStore();

const selectOrgIdAtom = atom<string | null>(null);

export const setSelectOrgIdAtom = (orgId: string) =>
  defaultStore.set(selectOrgIdAtom, orgId);
export const getSelectOrgIdAtom = () => defaultStore.get(selectOrgIdAtom);

const selectNodeAtom = atom<string | null>(null);
export const setSelectNodeAtom = (nodeId: string) =>
  defaultStore.set(selectNodeAtom, nodeId);
export const getSelectNodeAtom = () => defaultStore.get(selectNodeAtom);

const selectViewIdAtom = atom<string | null>(null);
export const setSelectViewIdAtom = (viewId: string) =>
  defaultStore.set(selectViewIdAtom, viewId);
export const getSelectViewIdAtom = () => defaultStore.get(selectViewIdAtom);

const selectSideModeAtom = atom<string | null>(null);
export const setSelectSideModeAtom = (sideMode: string) =>
  defaultStore.set(selectSideModeAtom, sideMode);
export const getSelectSideModeAtom = () => defaultStore.get(selectSideModeAtom);

const selectFocusAtom = atom<string | null>(null);
export const setSelectFocusAtom = (focus: string) =>
  defaultStore.set(selectFocusAtom, focus);
export const getSelectFocusAtom = () => defaultStore.get(selectFocusAtom);

const currentSelectionAtom = atom((get) => {
  const selectSpace = get(selectOrgIdAtom);
  const selectNode = get(selectNodeAtom);
  const selectViewId = get(selectViewIdAtom);
  const selectSideMode = get(selectSideModeAtom);
  const selectFocus = get(selectFocusAtom);

  return { selectSpace, selectNode, selectViewId, selectSideMode, selectFocus };
});

export type ICurrentSelection = ExtractAtomValue<typeof currentSelectionAtom>;

export const getCurrentSelectionAtom = () =>
  defaultStore.get(currentSelectionAtom);

export const useCurrentSelection = () => {
  const currentSelection = useAtomValue(currentSelectionAtom, {
    store: defaultStore,
  });
  // const currentSelection = useSelector((state: any) => state.workspace.currentSelection as ICurrentSelection);

  return currentSelection;
};

// 调试使用
// @ts-ignore
window.__getCurrentSelection__ = getSelectOrgIdAtom;
