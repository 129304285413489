/* eslint-disable react-refresh/only-export-components */
import { FullscreenOutlined } from '@ant-design/icons';
import {
  antdModalV5,
  create as ModalCreate,
  show,
  useModal,
} from '@ebay/nice-modal-react';
import type { ApiResponse, CustomButtonOpenConfig } from '@linkpi/core';
import {
  DEFAULT_STATUS_PROP,
  STATUS_ICON,
  tempValueDisplay,
} from '@linkpi/core';
import type { PiNode } from '@linkpi/core/web';
import { useWindowSize } from '@react-hookz/web';
import { getDvaApp } from '@umijs/max';
import { useMemoizedFn, useRequest, useToggle } from 'ahooks';
import {
  Col,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import cls from 'classnames';
import { filter, pipe, sortBy } from 'ramda';
import type { FC } from 'react';
import { useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import type { ListRowRenderer } from 'react-virtualized';
import { AutoSizer, List } from 'react-virtualized';
import { match } from 'ts-pattern';

import { useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { useOrgTempMap } from '@/hook/useTemplate';
import { propDisplay } from '@/pages/home/components/TempStatus';
import { viewNodeContent } from '@/pages/home/components/View/ViewContent';
import { getAttachmentValue, toRecord } from '@/utils/utils';

import { PiButton } from '../Button';
import { ConditionFilterButton } from '../ConditionFilter';
import { filterChecker } from './utils';

import '@/pages/home/components/QuoteSelector/index.less';
import styles from './CopyNodeModal.less';

const { Text } = Typography;
const defaultWidth = 160;

const allowKey = ['prop', 'templateId', 'status'];
const allowPropTypes: ApiResponse.CurrentUser.propType[] = [
  'enum',
  'tag',
  'text',
  'number',
  'formula',
];

const toNodeRecord = toRecord((node: PiNode) => ({
  [node.id]: node,
}));

async function getFilterNode(node: PiNode, config: CustomButtonOpenConfig) {
  const { openTemplate: template, matchCondition, filterCondition } = config;

  // 指定被匹配数据的属性
  const groupBy = matchCondition?.map((item) => item.target) || [];
  // 筛选条件
  const tempProps =
    filterCondition?.map((item) =>
      item.value.map((v) => {
        if (item.prop === 'status') return `si-${v}`;
        else return [item.prop, v].join('-');
      }),
    ) || [];

  const filters = {
    groupBy,
    tempProps,
    temp_id: template,
    draft: node.metadata?.f === -2,
  };
  return node.nodeManager.getTempFilterNode(filters);
}

interface TableHeader {
  name: string;
  width: number;
  type: string;
  index: number;
  sort: number;
  sticky?: 'left' | 'right';
  key: string;
}

type OpenNodeModalProps = {
  config: ApiResponse.CurrentUser.CustomButton;
  orgId: string;
  node: PiNode;
};
const OpenNode: FC<OpenNodeModalProps> = ({ orgId, config, node }) => {
  const modal = useModal();
  const nodeId = node.id;

  const headerScrollRef = useRef<any>(null);
  const bodyScrollRef = useRef<any>(null);
  const [fullScreen, { toggle: toggleFullScreen }] = useToggle();

  const { height: windowHeight } = useWindowSize();

  const bodyHeight = windowHeight - (fullScreen ? 247 : 328);

  const [search, setSearch] = useState('');

  const { data: allNodeList = [] } = useRequest(async () => {
    const filterNode = await getFilterNode(node, config.openConfig!);

    // 根据当前节点，匹配数据
    const groupBy =
      config.openConfig!.matchCondition?.map((item) => item.source) || [];
    const nodes = filterNode.getMatchNode(node.id, groupBy, [], false);

    return sortBy((i: PiNode) => i.createTime * -1)(nodes);
  });

  const [filterValue, setFilterValue] =
    useState<ApiResponse.ViewList.ViewconditionV2>([
      {
        op: 'beIncluded',
        key: 'templateId',
        input: [config.openConfig!.openTemplate],
      },
    ]);

  const nodeList = useMemo(() => {
    if (filterValue.length > 1)
      return pipe(
        filter(filterChecker(filterValue)),
        toNodeRecord,
      )(allNodeList);

    return toNodeRecord(allNodeList);
  }, [allNodeList, filterValue]);

  const onOk = useMemoizedFn(async () => {
    const _config = config.openConfig!;

    return handleOpen(_config, {
      nodeId: propValue,
      orgId,
    });
  });

  const [propValue, setPropValue] = useState<string>('');

  const clickRow = useMemoizedFn((o: string) => {
    setPropValue(o);
  });

  const userMap = useOrgUserMap();
  const tempMap = useOrgTempMap(orgId);
  const departmentNodeMap = useOrgDepartmentNodesMap();

  const matchingTemplate = useMemo(() => {
    return tempMap[config.openConfig!.openTemplate!];
  }, [config]);

  const modalContent = useMemo(() => {
    const defaultHeaders: TableHeader[] = [
      {
        name: '状态',
        width: 120,
        type: 'status',
        index: -2,
        sort: -2,
        key: 'status',
      },
      {
        name: '标题',
        width: 200,
        type: 'text',
        index: -1,
        sort: -1,
        sticky: 'left',
        key: 'title',
      },
    ];
    if (Array.isArray(matchingTemplate?.prop)) {
      matchingTemplate.prop.forEach((p, i) => {
        if (p?.type && !p.hide) {
          defaultHeaders.push({
            name: p.name,
            width: p.type === 'text' ? 200 : defaultWidth,
            type: p.type,
            index: i,
            sort: 'sort' in p ? p.sort : i,
            key: `prop-${i}`,
          });
        }
      });
    }
    defaultHeaders.sort((b, a) => b.sort - a.sort);
    defaultHeaders.push.apply(
      defaultHeaders,
      DEFAULT_STATUS_PROP.map((s, i) => ({
        name: s.name,
        width: defaultWidth,
        type: s.type,
        index: i - 7,
        sort: i - 7,
        key: 'status-' + i,
      })),
    );

    const headers =
      // config.openConfig?.visibleProps ? [] :
      defaultHeaders;

    // if (config.openConfig?.visibleProps?.length) {
    //   config.openConfig?.visibleProps.forEach((e) => {
    //     const header = defaultHeaders.find((h) => h.key === e.key);
    //     if (header && e.checked) {
    //       headers.push(header);
    //     }
    //   });
    // }

    let tableWidth = 32;
    headers.forEach((h) => {
      tableWidth += h.width;
    });
    const searchKeys = Object.keys(nodeList).filter(
      (o) => ~nodeList[o].title.toLowerCase().indexOf(search.toLowerCase()),
    );

    const rowRenderer: ListRowRenderer = ({ index: i, key, style }) => {
      const o = searchKeys[i];
      const n = nodeList[o];

      return (
        <div key={key} style={style}>
          <div
            onClick={() => clickRow(o)}
            className={cls(
              `quote-option-body-column`,
              propValue === o && 'active',
              styles.column,
            )}
          >
            <Radio
              checked={propValue === o}
              style={{ marginLeft: 6, marginTop: n ? -2 : -4 }}
            />
            <div className={'quote-option-body-column-item'}>
              {n ? (
                <div className={'quote-option-body-column-item-main'}>
                  {headers.map((h) => {
                    if (h.index < 0) {
                      if (h.index === -1) {
                        return (
                          <div
                            className={
                              'quote-option-body-column-item-main-cell text-omit'
                            }
                            style={{ width: h.width }}
                            key={h.key}
                          >
                            {n.title || '无标题'}
                          </div>
                        );
                      } else {
                        const curStatus =
                          matchingTemplate?.task_status[n.tempInfo.status];
                        if (h.index === -2) {
                          // 状态
                          return curStatus && !curStatus.delete ? (
                            <div
                              className={`quote-option-body-column-item-main-cell text-omit status`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              <i
                                className={`iconfont ${curStatus.icon}`}
                                // @ts-ignore
                                style={{
                                  color: STATUS_ICON[curStatus.icon]?.color,
                                }}
                              />
                              {curStatus.name}
                            </div>
                          ) : (
                            <div
                              className={
                                'quote-option-body-column-item-main-cell'
                              }
                              style={{ width: h.width }}
                              key={h.key}
                            />
                          );
                        } else {
                          const si = h.index + 7;
                          return (
                            <div
                              className={`quote-option-body-column-item-main-cell text-omit`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              {curStatus && curStatus.prop?.[si]?.display
                                ? propDisplay(
                                    curStatus.prop[si],
                                    n.tempInfo.statusProp[si],
                                    userMap,
                                    tempMap,
                                    n.prop._sys_cascade,
                                    h.index,
                                    n,
                                    departmentNodeMap,
                                  )
                                : null}
                            </div>
                          );
                        }
                      }
                    } else {
                      let res: any = {};
                      if (matchingTemplate?.prop[h.index].type === 'quote') {
                        res = propDisplay(
                          matchingTemplate?.prop[h.index],
                          n.tempInfo.prop[h.index],
                          userMap,
                          tempMap,
                          n.prop._sys_cascade,
                          h.index,
                          n,
                          departmentNodeMap,
                        );
                      } else if (
                        matchingTemplate?.prop[h.index].type === 'attachment'
                      ) {
                        res.value = getAttachmentValue(n, h.index);
                        res.type = 'quote-attachment';
                      } else {
                        res = tempValueDisplay({
                          propConfig: matchingTemplate?.prop[h.index],
                          propValue: n.tempInfo.prop[h.index],
                          propIndex: h.index,
                          sysCascade: n.prop._sys_cascade,
                          userMap: userMap,
                          tempMap,
                          departmentMap: departmentNodeMap,
                        });
                      }

                      return (
                        <div
                          className={`quote-option-body-column-item-main-cell text-omit`}
                          style={{ width: h.width }}
                          key={h.key}
                        >
                          {res?.type === 'quote-attachment'
                            ? res.value.map((f: any, index: number) => (
                                <div
                                  key={index}
                                  style={{
                                    backgroundImage: `url(${f.src})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: 32,
                                    width: 32,
                                    marginRight: 6,
                                  }}
                                />
                              ))
                            : res}
                        </div>
                      );
                    }
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div id={'QuoteSelector'}>
        <div className={'quote-option'}>
          <Row gutter={8} align="middle">
            <Col flex="auto">
              <Input
                prefix={
                  <i
                    className={'iconfont iconsearch'}
                    style={{ color: '#EBEDF0', width: 'auto' }}
                  />
                }
                placeholder={'请输入关键字搜索'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col flex="none">
              <ConditionFilterButton
                orgId={orgId}
                value={filterValue}
                onChange={setFilterValue}
                allowKey={allowKey}
                allowPropTypes={allowPropTypes}
                displayParentId={false}
                displayTemplate={false}
              />
            </Col>
          </Row>
          <PerfectScrollbar
            ref={headerScrollRef}
            onScrollX={(e) =>
              (bodyScrollRef.current._container.scrollLeft = e.scrollLeft)
            }
          >
            <div
              className={'quote-option-header'}
              style={{ width: tableWidth }}
            >
              <div style={{ width: 16, margin: '0 8px 0 6px' }} />
              {headers.map((h, i) => (
                <div
                  key={i}
                  className={`quote-option-header-cell text-omit`}
                  style={{
                    width: h.width,
                  }}
                >
                  <span>{h.name}</span>
                </div>
              ))}
            </div>
          </PerfectScrollbar>
          <PerfectScrollbar
            ref={bodyScrollRef}
            onScrollX={(e) =>
              (headerScrollRef.current._container.scrollLeft = e.scrollLeft)
            }
            style={{ height: bodyHeight }}
          >
            <div
              className={'quote-option-body'}
              style={{ width: tableWidth, height: bodyHeight }}
            >
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    height={bodyHeight}
                    rowCount={searchKeys.length}
                    rowHeight={58}
                    overscanRowCount={10}
                    rowRenderer={rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }, [
    matchingTemplate.prop,
    matchingTemplate?.task_status,
    nodeList,
    search,
    orgId,
    filterValue,
    propValue,
    clickRow,
    userMap,
    tempMap,
    departmentNodeMap,
    bodyHeight,
  ]);

  const modalSizeProps = fullScreen
    ? {
        width: '100vw',
        height: '100%',
      }
    : {
        width: 'min(1200px, 80vw)',
      };

  const modalProps = antdModalV5(modal);

  return (
    <Modal
      {...modalProps}
      // 降低模态框的层级，防止覆盖节点模态框
      zIndex={999}
      title={
        <Row justify="space-between">
          <Space>
            选择主题
            <Text type="secondary">{`共 ${Object.keys(nodeList)?.length} 条记录/已选 ${
              propValue ? 1 : 0
            } 条`}</Text>
          </Space>
          <div>
            <Tooltip title="最大化">
              <PiButton
                type="secondary"
                shape="circle"
                style={{ position: 'absolute', right: 54, top: 10 }}
                icon={
                  <FullscreenOutlined
                    type="iconContent_Download"
                    style={{ color: '#7b8aa3' }}
                  />
                }
                onClick={toggleFullScreen}
              />
            </Tooltip>
          </div>
        </Row>
      }
      destroyOnClose
      centered
      {...modalSizeProps}
      onOk={onOk}
      onCancel={() => {
        modalProps.onCancel();
        modal.resolve();
      }}
      okButtonProps={{ disabled: !propValue }}
    >
      {modalContent}
    </Modal>
  );
};

const OpenNodeModal = ModalCreate(OpenNode);

export const showOpenNodeModal = (props: OpenNodeModalProps) =>
  show(OpenNodeModal, props);

const handleOpen = async (
  config: CustomButtonOpenConfig,
  {
    nodeId,
    orgId,
    openId,
  }: {
    nodeId: string;
    orgId: string;
    openId?: string;
  },
) => {
  return match(config)
    .with({ openType: 'modal' }, () => {
      return viewNodeContent(
        {
          nodeIndex: 0,
          dataList: [nodeId],
          modalConfig: config.modalConfig,
          pageModelConfigId: config.pageModel,
        },
        openId,
      );
    })
    .with({ openType: 'drawer' }, () => {
      return viewNodeContent(
        {
          nodeIndex: 0,
          dataList: [nodeId],
          modalConfig: config.modalConfig,
          pageModelConfigId: config.pageModel,
          openType: 'drawer',
        },
        openId,
      );
    })
    .with({ openType: 'content' }, () => {
      getDvaApp()._store.dispatch({
        type: 'workspace/setCurrentSelection',
        payload: {
          selectNode: nodeId,
          pageModelId: config.pageModel,
          reservePageModelId: true,
        },
      });
    })
    .with({ openType: 'tab' }, () => {
      window.open(
        `/home/${orgId}/${nodeId}/${nodeId}${config.pageModel ? `?pmid=${config.pageModel}` : ''}`,
      );
    });
};

export const triggerOpenNodeButton = async (
  props: OpenNodeModalProps & {
    openId?: string;
  },
) => {
  const config = props.config.openConfig;
  const { node, orgId, openId } = props;

  if (!config) {
    return;
  }

  return match(config)
    .with({ openTempType: 'current' }, () => {
      return handleOpen(config, {
        nodeId: node.id,
        orgId,
        openId,
      });
    })
    .with({ openTempType: 'specify' }, () => {
      return handleOpen(config, {
        nodeId: config.source!,
        orgId,
        openId,
      });
    })
    .with({ openTempType: 'match' }, async () => {
      const filterNode = await getFilterNode(node, props.config.openConfig!);
      const groupBy =
        props.config.openConfig!.matchCondition?.map((item) => item.source) ||
        [];
      const nodes = filterNode.getMatchNode(node.id, groupBy, [], false);

      if (nodes.length === 1) {
        const [n] = nodes;
        return handleOpen(config, {
          nodeId: n.id,
          orgId,
          openId,
        });
      }

      return showOpenNodeModal(props);
    })
    .otherwise(() => null);
};
