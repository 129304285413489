import {
  getShareDBConnectionInstance,
  ShareDBConnectionOfChatAndTable,
} from '@linkpi/core/web';
import { useDebounceEffect } from 'ahooks';
import { throttle } from 'lodash';
import { useRef, useState } from 'react';
import type sharedb from 'sharedb';

import { getAuth } from '@/utils/auth';
import request from '@/utils/request';
import { consoleColorTag } from '@/utils/utils';
/**
 * 视图排序数据
 */

const useViewRecordSort = (
  orgId: string,
  nodeId: string,
  viewId: string,
  getViewCurrentRecordSorts: () => string[],
): [
  string[],
  (
    handleNodeId: string,
    borderNodeId: string | null,
    addRelation?: 'next' | 'prev',
    type?: 'add' | 'move',
  ) => Promise<void>,
  (allRecordSorts: string[]) => Promise<void>,
] => {
  const { ad, token } = getAuth();
  const shareDBConnectionInstance = getShareDBConnectionInstance();
  const sdk = useRef(
    new ShareDBConnectionOfChatAndTable(request, shareDBConnectionInstance, {
      ad,
      token,
    }),
  );
  const docRef = useRef<sharedb.Doc | null>();

  const [recordSorts, setRecordSorts] = useState<string[]>([]);
  const recordSortsRef = useRef<string[]>([]);
  recordSortsRef.current = recordSorts;

  // 全量更新排序
  const fullUpdate = async (allRecordSorts: string[]) => {
    setRecordSorts(allRecordSorts);
    await sdk.current.Table.initTable(nodeId, orgId, {
      view_id: viewId,
      c_md5: '',
      n_list: allRecordSorts,
    });
  };

  // 更新单个节点排序
  const patchUpdate = async (
    handleNodeId: string,
    borderNodeId: string | null,
    addRelation: 'next' | 'prev' = 'next',
    type: 'add' | 'move' = 'add',
  ) => {
    // 如果兄弟节点的排序不存在，则全量更新
    let borderIndex = !borderNodeId
      ? -1
      : recordSortsRef.current.indexOf(borderNodeId);
    if (!~borderIndex) {
      const newSorts = getViewCurrentRecordSorts();
      // 如果已存在当前节点，先移除
      const hasHandleNodeIndex = newSorts.indexOf(handleNodeId);
      if (~hasHandleNodeIndex) {
        newSorts.splice(hasHandleNodeIndex, 1);
      }

      if (borderNodeId) {
        borderIndex = newSorts.indexOf(borderNodeId);
        const insertIndex =
          addRelation === 'next' ? borderIndex + 1 : borderIndex;
        newSorts.splice(insertIndex, 0, handleNodeId);
      } else {
        if (addRelation === 'next') {
          newSorts.push(handleNodeId);
        } else {
          newSorts.unshift(handleNodeId);
        }
      }
      fullUpdate(newSorts);
    } else {
      let posIndex = addRelation === 'next' ? borderIndex + 1 : borderIndex;
      if (type === 'move') {
        // 节点往下，需要减掉自身1
        const handleNodeIndex = recordSortsRef.current.indexOf(handleNodeId);
        if (handleNodeIndex < posIndex) {
          posIndex--;
        }
      }

      await sdk.current.Table.modifyTableNode(nodeId, orgId, {
        view_id: viewId,
        node_id: handleNodeId,
        pos: posIndex, // 最终位置
      });
    }
  };

  const obInstance = throttle((isFirst?: boolean) => {
    if (!docRef.current) {
      consoleColorTag({
        tag: '[useViewRecordSort',
        messageText: 'not docRef.current',
      });
      return;
    }

    if (docRef.current.type === null || !docRef.current.data) {
      setRecordSorts([]);
      return;
    }

    if (isFirst && docRef.current.data.n_list.length) {
      // 有的数据有问题 有重复的在第一次连接后修复 去重
      const duplicate = [...new Set(docRef.current.data.n_list as string[])];
      const hasRepetData =
        docRef.current.data.n_list.length !== duplicate.length;
      if (hasRepetData) {
        fullUpdate(duplicate);
        return;
      }
    }

    setRecordSorts(docRef.current.data.n_list);
  }, 100);

  useDebounceEffect(
    () => {
      if (
        !(
          typeof viewId === 'string' &&
          viewId.length === 32 &&
          /^[0-9A-Fa-f]+$/.test(viewId)
        )
      ) {
        return;
      }
      /**
       * 关闭连接
       */
      if (docRef.current) {
        docRef.current.destroy();
        docRef.current = null;
      }
      /**
       * 连接
       */
      docRef.current = sdk.current.Table.getTableDoc(viewId);
      docRef.current.subscribe((err) => {
        if (err) {
          consoleColorTag({
            tag: '[useViewRecordSort',
            messageText: 'sort connection err',
          });
        }
        obInstance(true);
      });
      docRef.current.on('op', () => {
        obInstance();
      });
      docRef.current.on('create', () => {
        obInstance();
      });

      return () => {
        if (docRef.current) {
          docRef.current.destroy();
        }
      };
    },
    [viewId],
    { wait: 300 },
  );

  return [recordSorts, patchUpdate, fullUpdate];
};

export default useViewRecordSort;
