import { RegularIcon } from '@/components/IconFont';

import type { EditorTypeConfig } from '../types';
import { CurrentNodePropDisplay } from './CurrentNodePropDisplay';
import { CurrentNodePropMenu } from './CurrentNodePropMenu';

export const currentNode: EditorTypeConfig = {
  label: '当前主题类型属性',
  icon: <RegularIcon type="iconzhutileixingshuxing" />,
  renderer: ({ value: v }) => <CurrentNodePropDisplay propIndex={v.target} />,
  menuSub: ({ onSelect, propType }) => (
    <CurrentNodePropMenu onChange={onSelect} propType={propType} />
  ),
};
