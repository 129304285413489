import type { ApiResponse } from '@linkpi/core';
import { execCalcFormula, propIsNull } from '@linkpi/core';
import { useQuery } from '@tanstack/react-query';
import { head, isNil } from 'ramda';
import { useMemo } from 'react';

import request from '@/utils/request';
import { getRealPropConfig } from '@/utils/utils';

import { useOrgTempMap } from './useTemplate';

const ENABLE_TYPE = ['enum', 'tag'];

/**
 * NOTE: 不传 propIndex 会有性能问题，应该单个属性获取选址范围
 *
 * @description 返回 主题类型所有的 匹配 或 引用 选值范围
 */
export const useQuoteOptions = (
  orgId: string,
  template: ApiResponse.CurrentUser.TemplateInfo | undefined,
  /**
   * @description 指定部分属性
   */
  propIndex?: number,
  options?: {
    // 'labelInValue' 时，返回的选项label是辅助显示
    mode?: 'onlyValue' | 'labelInValue';
  },
) => {
  const tempMap = useOrgTempMap(orgId);

  const propIndexList = useMemo(() => {
    if (!template || !orgId) return [];

    if (Number.isInteger(propIndex)) return [propIndex];

    // 获取主题类型的选值属性index
    const result: number[] = [];
    template.prop.forEach((p, i) => {
      if (!p || !p.type) return;

      // 获取真实的属性类型
      const realPropConfig = getRealPropConfig(p, tempMap);
      if (typeof realPropConfig === 'string' || !realPropConfig) return;

      if (
        ENABLE_TYPE.includes(realPropConfig.type!) &&
        p.conditionMatching &&
        p.matchingType <= 0
      )
        result.push(i);
    });

    return result;
  }, [orgId, propIndex, tempMap, template]);

  // 获取匹配或引用属性的选项
  const { data: quoteOptions = {}, isLoading } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'quote_options',
      orgId,
      template?.template_id,
      options,
      propIndexList,
    ],
    queryFn: async () => {
      if (!template || !orgId || template.template_id === 'ff') return {};

      const enums: Record<
        number,
        (string | { label: string; value: string })[]
      > = {};

      const [_, res] = await execCalcFormula(request, {
        temp_id: template.template_id,
        org_id: orgId,
        index: propIndexList,
        ignoreQuotaCondition: true,
        getQuoteArray: true,
      });

      if (res && res.status === 'ok' && Array.isArray(res.data)) {
        propIndexList.forEach((index, i) => {
          if (isNil(index)) return;
          if (propIsNull(res.data[i])) return;
          const d = Array.isArray(res.data[i]) ? res.data[i] : [res.data[i]];
          enums[index] = [];
          d.forEach((d: any) => {
            // 值
            if (typeof d === 'string') {
              if (d && !enums[index].includes(d)) enums[index].push(d);
            }
            // 根据节点获取选值范围
            else if (typeof d === 'object' && d !== null && 'v' in d) {
              if (typeof d.v === 'string') {
                if (d.v && !enums[index].includes(d.v)) {
                  if (d.aux && options?.mode === 'labelInValue') {
                    enums[index].push({
                      label: d.aux,
                      value: d.v,
                    });

                    return;
                  }
                  if (d.l) {
                    enums[index].push({
                      label: head(d.l) as string,
                      value: d.v,
                    });

                    return;
                  }
                  enums[index].push(d.v);
                }
              } else if (Array.isArray(d.v)) {
                d.v.forEach((v: any) => {
                  if (typeof v === 'string' && v && !enums[index].includes(v))
                    enums[index].push(v);
                });
              }
            }
          });
        });
      }

      return enums;
    },
    enabled: Boolean(template && orgId && propIndexList.length > 0),
    staleTime: 30 * 1000, // 30秒内不重新请求
    gcTime: 60 * 60 * 1000, // 1小时后清除缓存
  });

  return [quoteOptions, { loading: isLoading }] as const;
};
