import { SearchOutlined } from '@ant-design/icons';
import type { CurrentUser } from '@linkpi/core';
import type { InputRef } from 'antd';
import { Button, Input, Modal } from 'antd';
import cls from 'classnames';
import { debounce } from 'lodash';
import type { ForwardRefRenderFunction } from 'react';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import AddrList from './AddrList';
import type { MapEditorHandleType } from './MapEditor';
import MapEditor from './MapEditor';
import type { GaodeAddrDataType } from './utils';
import {
  converGaodeDataToSysLocationData,
  converSysLocationDataToGaodeData,
  isEqualPoint,
} from './utils';

import styles from './styles.less';

type SysLocationItemType = CurrentUser.SysLocationItemType;

export type LocationSelectRef = {
  show: (prop: {
    readOnly?: boolean;
    locationData?: SysLocationItemType | null;
    markers?: SysLocationItemType[];
    onSave?: any;
  }) => void;
};
const LocationSelect: ForwardRefRenderFunction<LocationSelectRef> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => ({
    show,
  }));

  const [visible, setVisible] = useState(false);
  const [addrListLoading, setAddrListLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  // 是否可以编辑
  const [readOnly, setReadOnly] = useState(false);
  // 当前节点的sysLocation字段
  const [curAddrData, setCurAddrData] = useState<SysLocationItemType | null>(
    null,
  );
  // 需要辅助显示的其他属性
  const [markers, setMarkers] = useState<SysLocationItemType[]>([]);
  // 搜索关键字
  const [searchText, setSearchText] = useState('');
  // 选择列表
  const [addrList, setAddrList] = useState<GaodeAddrDataType[]>([]);

  const onSaveRef = useRef<any>(null);
  const searchIptRef = useRef<InputRef>(null);
  const mapEditorRef = useRef<MapEditorHandleType>(null);

  const onCancel = () => {
    setAddrListLoading(false);
    setSaveLoading(false);
    setCurAddrData(null);
    setMarkers([]);
    setSearchText('');
    setAddrList([]);

    const ipt = searchIptRef.current?.input as any;
    if (ipt) {
      ipt.value = '';
    }

    setVisible(false);
  };

  const show = (data: {
    readOnly?: boolean;
    locationData?: SysLocationItemType | null;
    markers?: SysLocationItemType[];
    onSave?: any;
  }) => {
    const { locationData, markers, onSave, readOnly = false } = data;
    onSaveRef.current = onSave || null;
    setReadOnly(readOnly || false);
    setCurAddrData(locationData || null);
    setMarkers(markers || []);
    setVisible(true);
  };

  /** 地图加载成功 */
  const onMapEditorLoad = async () => {
    if (!mapEditorRef.current) return;

    if (curAddrData) {
      /**
       * 有数据时：
       * 1. 通过数据位置 获取列表
       */
      const point: [number, number] = [curAddrData.lng, curAddrData.lat];
      setAddrListLoading(true);
      setAddrList([]);

      const res = (await mapEditorRef.current?.searchNearBy(
        point,
      )) as GaodeAddrDataType[];
      // 判断第一个的名称和数据是否一致，不一致的话就把第一个push进去，确保列表里一定有
      const hasMatchIndex = res.findIndex((x) => isEqualPoint(curAddrData, x));
      if (~hasMatchIndex) {
        const firstMatch = res.splice(hasMatchIndex, 1)[0];
        res.unshift(firstMatch);
      } else {
        res.unshift(converSysLocationDataToGaodeData(curAddrData));
      }

      setAddrListLoading(false);
      setAddrList(readOnly ? res.slice(0, 1) : res);
    } else {
      /**
       * 没有数据时：
       * 1. 移动地图 定位到当前位置 会触发onChange 获取列表
       */
      setAddrListLoading(true);
      mapEditorRef.current.selectCurrentPosition();
    }
  };
  const onChange = async ([lng, lat]: [number, number]) => {
    // 移动时，清空搜索框
    // 通过当前中心点 获取周围，并定位到搜索结果的第一个
    setAddrListLoading(true);
    setAddrList([]);
    setSearchText('');
    const ipt = searchIptRef.current?.input as any;
    if (ipt) {
      ipt.value = '';
    }
    const res = (await mapEditorRef.current?.searchNearBy([
      lng,
      lat,
    ])) as GaodeAddrDataType[];

    setAddrListLoading(false);
    setAddrList(readOnly ? res.slice(0, 1) : res);

    if (res.length) {
      const addrData = res[0];
      setCurAddrData(converGaodeDataToSysLocationData(addrData));
    }
  };

  const onSearch = async (text: string) => {
    setSearchText(text);
    setAddrListLoading(true);
    setAddrList([]);

    if (!text) {
      // 没有搜索关键字时，return
      setAddrListLoading(false);
      return;
    } else {
      // 有关键字时，搜索关键字. 定位到搜索结果的第一个
      const res = (await mapEditorRef.current?.search(
        text,
      )) as GaodeAddrDataType[];
      setAddrList(res);
      if (res.length) {
        const addrData = res[0];
        setCurAddrData(converGaodeDataToSysLocationData(addrData));
        mapEditorRef.current?.select(
          [addrData.location.lng, addrData.location.lat],
          true,
        );
      }
    }

    setAddrListLoading(false);
  };
  const debounceSearch = useCallback(debounce(onSearch, 500), []);

  const onAddrClick = (addr: GaodeAddrDataType) => {
    if (readOnly) return;
    setCurAddrData(converGaodeDataToSysLocationData(addr));
    mapEditorRef.current?.select([addr.location.lng, addr.location.lat], true);
  };
  const onLoadNextPage = async () => {
    // 加载下一页
    if (!mapEditorRef.current) return;
    const nextList = await mapEditorRef.current.loadNextPage();
    setAddrList((list) => [...list, ...nextList]);
  };

  const handleSave = async () => {
    if (!curAddrData) return;

    if (readOnly) {
      return onCancel();
    }

    if (onSaveRef.current) {
      setSaveLoading(true);
      const res = await onSaveRef.current(curAddrData);
      setSaveLoading(false);
      if (res === false) {
        return;
      }
    }

    onCancel();
  };

  const modalRender = () => {
    return (
      <div className={styles.locationSelect}>
        <div className={styles.search}>
          <div className={styles.searchInput}>
            <Input
              disabled={readOnly}
              className={styles.input}
              ref={searchIptRef}
              prefix={
                <SearchOutlined
                  style={{
                    fontSize: 16,
                    color: '#BFC6D2',
                  }}
                />
              }
              bordered={false}
              placeholder="搜索位置"
              allowClear
              onChange={(e) => debounceSearch(e.target.value)}
            />
          </div>
          <AddrList
            keyword={searchText}
            value={curAddrData}
            dataSource={addrList}
            onClick={onAddrClick}
            onLoadNextPage={onLoadNextPage}
            loading={addrListLoading}
          />
          <div className={styles.saveBtns}>
            <Button onClick={onCancel}>取消</Button>
            <Button
              onClick={handleSave}
              type="primary"
              style={{ marginLeft: 16 }}
              loading={saveLoading}
            >
              确认
            </Button>
          </div>
        </div>
        <div className={styles.map}>
          <MapEditor
            ref={mapEditorRef}
            onLoad={onMapEditorLoad}
            onChange={onChange}
            markers={markers}
            initCenter={
              curAddrData ? [curAddrData.lng, curAddrData.lat] : undefined
            }
            disableChange={readOnly}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      className={cls('ant-modal-content', styles.locationSelectModal)}
      open={visible}
      onCancel={onCancel}
      modalRender={modalRender}
      width={900}
      style={{ maxWidth: '70vw' }}
      destroyOnClose
      zIndex={2000}
    />
  );
};

export default forwardRef(LocationSelect);

export const getLocationSelectProps = (props: {
  node: PiNode | null;
  propIndex: number;
  tempMap: any;
  readOnly: boolean;
  onSave?: any;
}) => {
  const { node, propIndex, tempMap } = props;
  let locationData: any = null;
  let markers: any[] = [];

  if (node) {
    locationData = node.prop._sys_location?.[propIndex]?.[0];

    // 辅助显示
    const { tempInfo } = node;
    const tempId = tempInfo.id;
    const tempConfig = tempMap[tempId];
    const propConfig = tempConfig.prop[propIndex];
    if (propConfig.auxiliaryDisplay && propConfig.auxiliaryDisplay.length) {
      const datas: SysLocationItemType[] = [];
      propConfig.auxiliaryDisplay.map((x: any) => {
        const data = node.prop._sys_location?.[x];
        if (data) {
          datas.push(...data);
        }
      });
      markers = datas;
    }
  }

  return { ...props, locationData, markers };
};
