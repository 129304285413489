import { container } from 'tsyringe-neo';

import { RegularIcon } from '@/components/IconFont';
import { EditorModel } from '@/components/PageModelEditor/src/models/Editor';
import type {
  FilterItemType,
  IGlobalConditionFilterConfig,
} from '@/components/PageModelEditor/src/widgets/GlobalFilterWidget/types';

export const getIcon = (type: FilterItemType) => {
  switch (type) {
    case 'cascader':
      return <RegularIcon type="iconjilian" size={16} />;
    case 'select':
      return <RegularIcon type="iconxiala1" size={16} />;
    case 'text':
      return <RegularIcon type="iconshurukuang" size={16} />;
    case 'dateRangePicker':
      return <RegularIcon type="iconriqi" size={16} />;
    case 'yearMonthDay':
      return <RegularIcon type="iconnianyueri" size={16} />;
    default:
      return null;
  }
};

export const getGlobalConditionFilterItemElement = ({
  widgetId,
  itemId,
  index,
}: {
  widgetId: string;
  itemId: string;
  index?: number;
}) => {
  const store = container.resolve(EditorModel).store;
  const widgetInstances =
    store.getWidgetInstancesByWidgetId<IGlobalConditionFilterConfig>(
      'GlobalConditionFilter',
    );
  const w = widgetInstances?.find((w) => w.id === widgetId);

  const item = w?.config.conditionList.find((c) => c.id === itemId);

  if (!item)
    return (
      <div className="ant-select-selector bg-white rounded-[8px] px-3 py-1 border w-full flex gap-1 items-center text-red-500">
        关联筛选项已删除
      </div>
    );

  if (item?.filedType === 'cascader') {
    return (
      <div className="ant-select-selector  bg-white rounded-[8px] px-3 py-1 border w-full flex gap-1 items-center">
        {w?.config.name || '未命名组件'}
        <span>/</span>
        {getIcon(item.filedType as any)}
        {item.name || '未命名'}
        <span>/</span>
        {[item.name, index].join('-')}
      </div>
    );
  }

  return (
    <div className="ant-select-selector bg-white rounded-[8px] px-3 py-1 border w-full flex gap-1 items-center">
      {w?.config.name || '未命名组件'}
      <span>/</span>
      {getIcon(item.filedType as any)}
      {item.name || '未命名'}
    </div>
  );
};
