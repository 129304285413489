/* eslint-disable react-refresh/only-export-components */
import { CloseOutlined } from '@ant-design/icons';
import {
  antdDrawerV5,
  create as ModalCreate,
  show,
  useModal,
} from '@ebay/nice-modal-react';
import type { OpenViewConfig } from '@linkpi/core';
import { useDispatch } from '@umijs/max';
import { Button, Drawer, Spin } from 'antd';
import type { FC } from 'react';

import { RegularIcon } from '@/components/IconFont';
import { NodeViewComponent } from '@/components/ViewComponent';
import { useViewConfig } from '@/hook/useOrgViewListRequest';

const bodyStyle = { padding: 0 };

type ViewDrawerProp = {
  config: OpenViewConfig;
};
const ViewDrawer: FC<ViewDrawerProp> = ({ config }) => {
  const modal = useModal();
  const drawerProps = antdDrawerV5(modal);

  const { data, isLoading } = useViewConfig(config.viewId);

  const title =
    config.drawerConfig!.visibleSetting?.includes('title') && data
      ? data.view_name
      : undefined;

  const closeable =
    config.drawerConfig!.visibleSetting?.includes('closeButton');

  const titleContent =
    title && closeable ? (
      <div className="flex justify-between items-center">
        <div className="font-bold">{title}</div>
        {closeable && (
          <div onClick={drawerProps.onClose} className="cursor-pointer">
            <CloseOutlined />
          </div>
        )}
      </div>
    ) : null;

  const width = config.drawerConfig!.width || '50vw';

  const dispatch = useDispatch();

  return (
    <Drawer
      {...drawerProps}
      title={titleContent}
      destroyOnClose
      closable={false}
      bodyStyle={bodyStyle}
      width={width}
    >
      {isLoading ? (
        <Spin />
      ) : (
        [
          <NodeViewComponent
            nodeId={data!.node_id}
            viewId={config.viewId}
            key="view"
          />,
          !!config.drawerConfig?.visibleSetting?.includes(
            'viewInPageButton',
          ) && (
            <Button
              key="open-in-page"
              className="absolute top-2 left-[-148px] bg-[#626363] hover:bg-[#626363] text-white hover:text-white rounded-[8px]"
              type="text"
              onClick={() =>
                dispatch({
                  type: 'workspace/setCurrentSelection',
                  payload: {
                    selectNode: data!.node_id,
                    selectViewId: config.viewId,
                  },
                })
              }
              icon={<RegularIcon type="iconzaineirongyedakai" size={12} />}
            >
              在内容页打开
            </Button>
          ),
        ]
      )}
    </Drawer>
  );
};

const ViewNiceDrawer = ModalCreate(ViewDrawer);

export const openViewDrawer = (props: ViewDrawerProp) =>
  show(ViewNiceDrawer, props);
