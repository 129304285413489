import type { CurrentUser, ViewList } from '@linkpi/core';
import { getViewSettingOptionWithGroup } from '@linkpi/core';
import { buildRecord } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import { message, Popover } from 'antd';
import { isNil, pick } from 'ramda';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { match } from 'ts-pattern';

import { PiButton, RegularIcon } from '@/components';
import { checkPropType, getRealPropConfig } from '@/utils/utils';

import './styles.less';

const GroupForView: FC<{
  template: CurrentUser.TemplateInfo;
  curViewData: ViewList.ViewListItem<1 | 2 | 4 | 6>;
  orgInfo: CurrentUser.OrgInfo;
  templateList: CurrentUser.TemplateInfo[];
  onChange: (
    group: string | null,
    saveBackend?: boolean,
    groupOrderDesc?: boolean,
  ) => void;
  isDark?: boolean;
  type?: 'view';
}> = (props) => {
  const {
    template,
    curViewData,
    orgInfo,
    templateList,
    onChange,
    isDark,
    type,
  } = props;

  const { view_type } = curViewData;

  const tempMap = useMemo(
    () => buildRecord(templateList, 'template_id'),
    [templateList],
  );

  const { groupOption } = getViewSettingOptionWithGroup({
    templateIdList: template?.template_id,
    orgInfo: pick(['groupList', 'role'], orgInfo),
    tempMap,
  });

  const allGroupOptions = Object.keys(groupOption).reduce(
    (res: any, cur: any) => {
      res[cur] = groupOption[cur];
      if (groupOption[cur].subMenu) {
        (groupOption[cur].subMenu || []).forEach((i: any) => {
          res[i.key] = groupOption[cur];
        });
      }
      return res;
    },
    {},
  );

  const group = curViewData.view_info.group || '-';
  const groupName = allGroupOptions[group]?.name || '无';

  const groupOrderDesc = useMemo(() => {
    const [_type, _, _propIndex] = group.split('_');

    if (_type !== 'templateProp') return undefined;

    const propIndex = parseInt(_propIndex);
    if (typeof propIndex !== 'number') return undefined;

    const realPropConfig = getRealPropConfig(template.prop[propIndex], tempMap);
    if (typeof realPropConfig === 'string' || isNil(realPropConfig))
      return undefined;

    if (
      !checkPropType(realPropConfig.type!, [
        'number',
        'date',
        'datetime',
        'currency',
        'text',
        'formula',
      ])
    )
      return undefined;

    return curViewData.view_info.groupOrderDesc ?? true;
  }, [curViewData.view_info.groupOrderDesc, group, tempMap, template?.prop]);

  const changeGroup = useMemoizedFn(
    (g: string | null, _groupOrderDesc?: boolean) => {
      onChange(g, false, _groupOrderDesc);
    },
  );

  const saveBackend = useMemoizedFn(() => {
    onChange(group, true, groupOrderDesc);
    message.success('保存成功');
  });

  return (
    <Popover
      content={
        <ul
          style={{ maxHeight: 400, overflow: 'auto' }}
          className="view-setting-list"
        >
          <li
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            <span>分组</span>
            {type === 'view' && (
              <div onClick={saveBackend} className="saveGroupByConfig">
                <i
                  style={{ fontSize: 12, paddingRight: 2, color: '#316ef5' }}
                  className="iconchangyong-baocun iconfont"
                />
                <span style={{ color: '#316ef5' }}>保存配置</span>
              </div>
            )}
          </li>
          {Object.keys(groupOption).map((i: any) => {
            if (i === 'dayHours' && view_type !== 6) return null;
            if (i === 'template') return null;

            let active = group === i;
            if (groupOption[i].subMenu) active = group.startsWith(i);

            const li = (
              <li
                key={i}
                className={`view-setting-item text-omit${active ? ' active' : ''}`}
                onClick={() => changeGroup(i)}
              >
                {groupOption[i].name}
                {groupOption[i].subMenu ? (
                  <i className="iconfont iconroute" />
                ) : null}
                {active &&
                  match(groupOrderDesc)
                    .with(true, () => (
                      <PiButton
                        type="text"
                        size="tiny"
                        onClick={(e) => {
                          e.stopPropagation();
                          changeGroup(i, false);
                        }}
                        icon={<RegularIcon type="iconDescending" />}
                      />
                    ))
                    .with(false, () => (
                      <PiButton
                        type="text"
                        size="tiny"
                        onClick={(e) => {
                          e.stopPropagation();
                          changeGroup(i, true);
                        }}
                        icon={<RegularIcon type="iconAscending" />}
                      />
                    ))
                    .otherwise(() => null)}
              </li>
            );
            if (!groupOption[i].subMenu) return li;

            return (
              <Popover
                key={i}
                overlayClassName="view-setting-pop small"
                placement="right"
                content={
                  <ul className="view-setting-list">
                    {(groupOption[i].subMenu || []).map((j: any) => (
                      <li
                        key={j.key}
                        className={`view-setting-item${group === j.key ? ' active' : ''}`}
                        onClick={() => changeGroup(j.key)}
                      >
                        {j.name}
                        {group === j.key && (
                          <i className="iconfont iconselected" />
                        )}
                      </li>
                    ))}
                  </ul>
                }
              >
                {li}
              </Popover>
            );
          })}
        </ul>
      }
      trigger={['click']}
      overlayClassName="view-setting-pop"
      placement="bottomRight"
    >
      <div
        className={`view-setting-block${isDark ? ' view-setting-block-dark' : ''}`}
      >
        <div className="text-omit">分组： {groupName}</div>
        {group && (
          <i
            className="iconfont iconButton_cancel icon-right"
            onClick={() => changeGroup(null)}
          />
        )}
        <i
          style={{ display: 'flex' }}
          className={`iconfont iconxiala icon-right ${
            group && view_type === 2 ? 'hover-hide' : ''
          }`}
        />
      </div>
    </Popover>
  );
};

export default memo(GroupForView);
