import type { ViewList } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { type FC, useMemo } from 'react';
import { match } from 'ts-pattern';

import { useOrgTemplatesSettingInfoMap } from '@/hook';
import { cn, getRealPropConfig } from '@/utils/utils';

import ConditionFilter from './ConditionFilter';
import { getPropTypeOptions } from './ConditionItem/ConditionOperator';

import styles from './CondtionFilterValue.module.less';

export type ConditionFilterValueProp = {
  className?: string;
  value?: any[];
  onChange?: (v: any[]) => void;
  editable?: boolean;
  templateId: string;
  propKey: string;
  defaultValue?: any[];
  multiple?: boolean;
  onlyShowAuxInEnumProp?: boolean;
};

const getTreeData = () => [];

/**
 * 基于 ConditionFilter 精简的 value 组件
 *
 * 临时方案
 */
export const ConditionFilterValue: FC<ConditionFilterValueProp> = ({
  className,
  value,
  defaultValue,
  onChange,
  editable,
  templateId,
  propKey,
  multiple = true,
  onlyShowAuxInEnumProp = false,
}) => {
  const [_value = [], _onChange] = useControllableValue<
    ConditionFilterValueProp['value']
  >({ value, onChange, defaultValue });
  const tempMap = useOrgTemplatesSettingInfoMap();
  const template = tempMap[templateId];

  const conditions = useMemo(() => {
    const result: ViewList.ViewconditionV2 = [
      {
        key: 'templateId',
        op: 'intersect',
        input: [templateId],
      },
    ];
    const [key, _index] = propKey?.split('_') || [];

    /**
     * TODO
     *
     * 继续获取默认 op 的方法
     */
    if (_index) {
      const index = Number(_index);
      const prop = template!.prop[index];

      const op = match(key)
        .with('statusProp', () => {
          const type = ['user', 'user', 'datetime', 'datetime'][index];
          return getPropTypeOptions(type as any, true, true, {})[0].value;
        })
        .otherwise(() => {
          if (!prop) return 'equal';

          const config = getRealPropConfig(prop, tempMap);

          if (typeof config === 'string' || !config) {
            throw new Error('prop is not exist');
          }

          return getPropTypeOptions(config.type!, true, true, {})[0].value;
        });

      result.push({
        key,
        index,
        op,
        input: _value,
      });
    } else {
      const type = {
        sysCreator: 'user',
        sysCreateTime: 'datetime',
        sysUpdateTime: 'datetime',
        sysTag: 'tag',
      }[key];

      const op =
        key === 'status'
          ? 'beIncluded'
          : getPropTypeOptions(type as any, true, true, {})[0].value;

      result.push({
        key,
        op,
        input: _value,
      });
    }

    return result;
  }, [_value, propKey, tempMap, template, templateId]);

  const onConditionFilterChange = (v: ViewList.ViewconditionV2) => {
    const [c] = v.filter((i) => i.key !== 'templateId');
    _onChange(c.input);
  };

  return (
    <ConditionFilter
      className={cn(styles.conditionFilterValue, className)}
      templateUsage="fullAccess"
      defaultConditions={conditions}
      onChange={(v) => onConditionFilterChange(v.conditionsV2)}
      editable={editable || true}
      getTreeData={getTreeData}
      hideDelete
      displayTemplate={false}
      displayAddBtn={false}
      experimental_disableHistoryStatus
      experimental_multiple={multiple}
      experimental_onlyShowAuxInEnumProp={onlyShowAuxInEnumProp}
    />
  );
};
