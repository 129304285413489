import { assertExists } from '@linkpi/utils';
import { useMount } from 'ahooks';
import { Divider, Form, Input } from 'antd';

import { useCurrentTemplateSetting } from '@/hook';

import type { IDataSourceSetting } from '../../components';
import {
  DataSourceSettingCore,
  GlobalConditionFilterLink,
  RichText,
} from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetSetting } from '../../models/WidgetManager';

export type IRichTextConfig = {
  name: string;
  // value?: ReactQuill.Value;
  value?: string | any;
  // contentsValue?: Delta;
  contentsValue?: any;
} & IDataSourceSetting;

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IRichTextConfig>();
  const [currentTemp] = useCurrentTemplateSetting();
  assertExists(widgetInstance);

  const setRichTextValue = (value: Partial<IRichTextConfig>) => {
    editor.store.updateWidgetInstanceConfig<IRichTextConfig>(
      widgetInstance.id,
      {
        ...widgetInstance?.config,
        ...value,
      },
    );
  };

  // 默认为当前主题类型
  useMount(() => {
    if (
      !widgetInstance?.config?.conditions.find((c) => c.key === 'templateId')
        ?.input?.[0]
    ) {
      setRichTextValue({
        conditions: [
          {
            key: 'templateId',
            input: [currentTemp.template_id],
            op: 'intersect',
          },
        ],
      });
    }
  });

  return (
    <Form layout="vertical">
      <Form.Item label="组件名称">
        <Input
          placeholder="请输入..."
          defaultValue={widgetInstance.config.name}
          maxLength={200}
          // value={widgetInstance.config.value}
          onChange={(e) => setRichTextValue({ name: e.target.value })}
        />
      </Form.Item>
      <Divider dashed />
      <Form.Item
        label={
          <span className="flex items-center justify-between w-full">
            数据源
            <GlobalConditionFilterLink id={widgetInstance.id} />
          </span>
        }
      >
        <DataSourceSettingCore
          value={{
            ...widgetInstance.config,
            id: widgetInstance.id,
          }}
          onChange={setRichTextValue}
        />
      </Form.Item>
      <Form.Item label="文本内容">
        <RichText
          placeholder="这里是富文本内的一段话"
          value={widgetInstance.config.value}
          onChange={(v, contents) => {
            setRichTextValue({ value: v, contentsValue: contents });
          }}
          onBlur={(v) => {
            if (!v.length) {
              setRichTextValue({ value: '这里是富文本内的一段话' });
            }
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default Setting;
