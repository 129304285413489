import { SettingOutlined } from '@ant-design/icons';
import { assertExists } from '@linkpi/utils';
import type { WidgetSetting } from '@mylinkpi/widget-core';
import { Button } from 'antd';
import { produce } from 'immer';
import { Archive } from 'lucide-react';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { RegularIcon } from '@/components/IconFont';
import { useCurrentOrgId, useCurrentUser } from '@/hook';
import type { OptionsItem } from '@/services/options';
import { createOption } from '@/services/options';

import type {
  // IGlobalConditionFilterConfig,
  IGlobalConditionFilterConfigV1,
} from '../../components';
import { WidgetIcon } from '../../components';
import {
  useActiveWidgetInstance,
  useSetActiveWidgetInstanceConfig,
  useWidgetInstance,
} from '../../hooks';
import type { WidgetPreivew } from '../../models/WidgetManager';
import type { IGlobalConditionFilterConfigV2 } from '../GlobalConditionFilterWidget';
import { GlobalConditionFilter } from '../GlobalConditionFilterWidget/GlobalConditionFilter';
import { migrateConfig as migrateConfigV2 } from '../GlobalConditionFilterWidget/utils';
import { defineSystemWidget } from '../utils';
import {
  showCreateFilterSettingModal,
  showEditFilterSettingModal,
} from './components/FilterSettingModal';
import { GLOBAL_CONDITION_LATEST_VERSION } from './constant';
import { useOptionsList } from './hooks';
import settingIcon from './setting.png';
import type { IGlobalConditionFilterConfig } from './types';
import { migrateConfig } from './utils';

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<IGlobalConditionFilterConfig>(id);

  assertExists(widgetInstance);

  if (widgetInstance.config.conditionList.length === 0) {
    return (
      <div className="text-gray-500 gap-2 w-full pt-2 flex items-center justify-center">
        <Archive />
        暂未配置筛选条件
      </div>
    );
  }

  return <GlobalConditionFilter widgetInstance={widgetInstance as any} />;
};

const Setting: WidgetSetting = () => {
  const orgId = useCurrentOrgId();
  const userInfo = useCurrentUser();
  const { config } = useActiveWidgetInstance<IGlobalConditionFilterConfig>()!;

  const setConfig =
    useSetActiveWidgetInstanceConfig<IGlobalConditionFilterConfig>();

  console.log('🤡', config);

  const { refetch: refetchCascaderOptionsList } = useOptionsList('jilian');
  const { refetch: refetchSelectOptionsList } = useOptionsList('xiala');

  const finalConfig = useMemo(() => {
    if ((config as any).version === 3) {
      return config;
    }

    const configV2 = match(config as any)
      .with({ version: 1 }, (_config: IGlobalConditionFilterConfigV1) =>
        migrateConfigV2(_config),
      )
      .with({ version: 2 }, (_config) => _config)
      .otherwise(() => config) as IGlobalConditionFilterConfigV2;

    const configV3 = migrateConfig(configV2);

    Promise.all(
      configV3.conditionList
        .filter(
          (c) =>
            c.filedType === 'cascader' &&
            c.cascaderConfig &&
            c.cascaderConfig.optionId,
        )
        .map((c) => {
          return createOption({
            id: c.cascaderConfig!.optionId!,
            org_id: orgId,
            configType: 'jilian',
            user: userInfo.userid,
            name: `级联选项集-${c.name}`,
            desc: `创建级联选项集-${c.name}`,
            optionSourceConfig: c.cascaderConfig?.optionSourceConfig ?? [],
          });
        }),
    );

    return configV3;

    /*
     * 版本迁移
     */
    // if ((config as any).version === 1) {
    //   const configV2 = migrateConfigV2(config as any);
    //   return migrateConfig({
    //     config: configV2,
    //     orgId,
    //     userInfo,
    //   });
    // }

    // if ((config as any).version === 2) {
    //   return migrateConfig({
    //     config,
    //     orgId,
    //     userInfo,
    //   });
    // }
  }, [config]);

  /*
   * 版本迁移
   */
  if (finalConfig.version !== config.version) {
    setConfig(finalConfig as any);
  }

  const removeInvalidOptionId = async (
    _config: IGlobalConditionFilterConfig,
  ) => {
    const { data: cascaderOptionsList } = await refetchCascaderOptionsList();
    const { data: selectOptionsList } = await refetchSelectOptionsList();
    const cascaderOptionsMap =
      cascaderOptionsList?.reduce<Record<string, OptionsItem>>(
        (result, item) => {
          result[item.id] = item;
          return result;
        },
        {},
      ) ?? {};
    const selectOptionsMap =
      selectOptionsList?.reduce<Record<string, OptionsItem>>((result, item) => {
        result[item.id] = item;
        return result;
      }, {}) ?? {};

    return produce(_config, (draft) => {
      draft.conditionList.forEach((item) => {
        match(item)
          .with(
            { filedType: 'cascader', cascaderConfig: { optionId: P.string } },
            (c) => {
              if (!cascaderOptionsMap[c.cascaderConfig.optionId]) {
                item.cascaderConfig!.optionId = undefined;
              }
            },
          )
          .with(
            { filedType: 'select', selectConfig: { optionId: P.string } },
            (c) => {
              if (!selectOptionsMap[c.selectConfig.optionId]) {
                // @ts-ignore
                item.selectConfig!.optionId = undefined;
              }
            },
          )
          .otherwise(() => null);
      });
    });
  };

  return (
    <div className=" pt-[80px] flex flex-col items-center">
      <img src={settingIcon} className=" w-[120px] mb-[70px]" />

      <Button
        onClick={async () => {
          const _config = await removeInvalidOptionId(finalConfig as any);
          showEditFilterSettingModal(_config);
        }}
        icon={<SettingOutlined color="#fff" />}
        type="primary"
      >
        配置筛选组件
      </Button>
    </div>
  );
};

export const GlobalFilterWidget =
  defineSystemWidget<IGlobalConditionFilterConfig>()({
    id: 'GlobalConditionFilter',
    title: '筛选',
    icon: () => {
      return (
        <WidgetIcon
          bgColor="#FF962C"
          icon={
            <RegularIcon type="iconshaixuanqu-shaixuan" color={'#ffffffcc'} />
          }
        />
      );
    },
    basic: {
      defaultHeight: 5,
      defaultWidth: 12,
      minHeight: 1,
      minWidth: 3,
      dynamicHeight: true,
    },
    // 在oncreate里去初始化
    metadata: {
      name: '未命名筛选',
      // templateId: '',
      version: GLOBAL_CONDITION_LATEST_VERSION,
      enableSubmitButton: true,
      conditionList: [
        { name: '未命名', showName: true, id: '1', filedType: 'text' as const },
      ],
    },
    preview: Preview,
    setting: Setting,
    onCreate: () => showCreateFilterSettingModal(),
  });
