import { Input, InputNumber } from 'antd';
import React from 'react';

type NumberRangerInputValue = [number, number] | [];

type NumberRangerInputProps = {
  value?: NumberRangerInputValue;
  onChange?: (value: NumberRangerInputValue) => void;
  disabled?: boolean;
  className?: string;
};

export const NumberRangerInput: React.FC<NumberRangerInputProps> = ({
  value: _value,
  onChange,
  disabled,
}) => {
  const value = _value || [];

  return (
    <Input.Group
      compact
      style={{ display: 'flex' }}
      className={'condition-filter-v2-number-range-input'}
    >
      <InputNumber
        style={{ textAlign: 'center', flex: 1 }}
        placeholder="最小值"
        value={!isNaN(value[0] as any) ? value[0] : undefined}
        disabled={disabled}
        onChange={(val) => {
          const newValue: any = [...(value || [])];
          newValue[0] = val;
          onChange?.(newValue);
        }}
      />
      <Input
        style={{
          width: 30,
          borderLeft: 0,
          borderRight: 0,
          pointerEvents: 'none',
        }}
        placeholder="~"
        disabled
      />
      <InputNumber
        style={{ textAlign: 'center', flex: 1 }}
        disabled={disabled}
        placeholder="最大值"
        value={!isNaN(value[1] as any) ? value[1] : undefined}
        onChange={(val) => {
          const newValue: any = [...(value || [])];
          newValue[1] = val;
          onChange?.(newValue);
        }}
      />
    </Input.Group>
  );
};
