/* eslint-disable react-hooks/rules-of-hooks */
import { produceConditionV2, type ViewList } from '@linkpi/core';
import { notEmpty } from '@linkpi/utils';
import { useWidgetSharedState } from '@mylinkpi/widget-react';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { atom, useAtomValue, useSetAtom } from 'jotai';
import { last } from 'ramda';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import type {
  GlobalConditionFilterValue,
  IGlobalConditionFilterTarget,
} from '@/components/PageModelEditor/src/components/GlobalConditionFilter';
import {
  ExtraGlobalConditionsMapAtom,
  GlobalConditionConfigMapAtom,
} from '@/components/PageModelEditor/src/widgets/GlobalConditionFilterWidget/hook';

import { useComponentId } from './useComponentId';

interface IMatchingItem {
  op: ViewList.ViewconditionV2Item['op'];
  propKey: string;
  input: any[];
  level?: number;
}

// 定义类型
type CascaderValue = {
  value: string;
  templateId: string;
  level: number;
};

// 提取常量
const FIELD_TYPES = {
  CASCADER: 'cascader',
  YEAR_MONTH_DAY: 'yearMonthDay',
} as const;

// 在文件开头添加类型定义
type ViewConditionV2Item = ViewList.ViewconditionV2Item;

/**
 * 处理级联选择器的值
 * @param input 原始输入值
 * @param targetLevel 目标层级
 */
const processCascaderInput = (
  input: CascaderValue[] | CascaderValue[][] | undefined,
  targetLevel?: number,
): string[] => {
  if (!input?.length) return [];

  if (Array.isArray(input[0])) {
    return (input as CascaderValue[][])
      .map((list) => last(list))
      .filter((v) => v && v.level === targetLevel)
      .map((v) => v!.value);
  }

  const cascaderValue = input as CascaderValue[];
  const lastValue = last(cascaderValue);
  return lastValue && lastValue.level === targetLevel ? [lastValue.value] : [];
};

const processYearMonthDayInput = (input: {
  selected?: string;
  selectedType?: 'year' | 'month' | 'date';
}) => {
  if (!input?.selected || !input?.selectedType) return [];

  const dayjsValue = dayjs(input.selected);

  return [
    dayjsValue.startOf(input.selectedType).valueOf(),
    // dayjsValue.endOf(input.selectedType).valueOf(),
  ];
};

/**
 * 解析单个条件配置
 */
const parseConditionConfig = (
  config: ConditionConfig,
  value: Record<string, GlobalConditionFilterValue>,
) => {
  try {
    // 现在 propKey 存储的是一个 group 的条件列表
    const matchingList = JSON.parse(config.propKey) as IMatchingItem[];

    // 处理单个 group 内的条件
    const subConditions = matchingList.map((matchItem) => {
      const [key, _index] = matchItem.propKey.split('_');
      const input =
        value[config.conditionFilterWidgetId]?.[config.conditionItemId]
          ?.value || [];

      let processedInput = input;
      if (
        config.filedType === FIELD_TYPES.CASCADER &&
        matchItem.level !== undefined
      ) {
        processedInput = processCascaderInput(input as any, matchItem.level);
      } else if (config.filedType === FIELD_TYPES.YEAR_MONTH_DAY) {
        processedInput = processYearMonthDayInput(input as any);
      }

      const condition: ViewList.ViewconditionV2Item = {
        op: matchItem.op,
        key,
        input: processedInput,
      };

      if (_index) {
        condition.index = Number(_index);
      }

      return condition;
    });

    // 如果组内只有一个条件，直接返回该条件
    if (subConditions.length === 1) {
      return subConditions[0];
    }

    // 如果组内有多个条件，根据组的 op 组合它们
    return {
      op: config.op as 'and' | 'or',
      input: subConditions,
    } as ViewList.ViewconditionV2Item;
  } catch (error) {
    console.error('Error parsing condition:', error);
    return null;
  }
};

/**
 * 添加组件额外筛选条件
 */
export const useSetExtraGlobalConditions = () => {
  const componentId = useComponentId();
  const setExtraGlobalConditionsMap = useSetAtom(ExtraGlobalConditionsMapAtom);

  const setGlobalConditions = useMemoizedFn(
    (targetId: string, conditions: ViewList.ViewconditionV2) => {
      setExtraGlobalConditionsMap((oldValue) => {
        const newValue = produce(oldValue, (draft) => {
          if (!draft[targetId]) {
            draft[targetId] = {};
          }

          const item = draft[targetId];
          item[componentId] = conditions;
        });
        return newValue;
      });
    },
  );

  return setGlobalConditions;
};

type ConditionConfig = {
  conditionFilterWidgetId: string;
  conditionItemId: string;
  op: ViewList.ViewconditionV2Item['op'];
  propKey: string;
  filedType: string;
  groupOp?: 'and' | 'or'; // 新增字段，表示 group 之间的组合方式
};

const migrateTargetConfig = (target: unknown) => {
  return match(target)
    .with(
      {
        matchingList: P.array(),
        matchingGroupList: P.nullish,
        matchingGroupOp: P.nullish,
      },
      ({ matchingList, ...v }) =>
        ({
          ...v,
          matchingGroupList: [
            {
              list: matchingList.length
                ? matchingList
                : [{ op: 'intersect', propKey: '', input: [] } as any],
              op: 'and',
            },
          ],
          matchingGroupOp: 'and',
        }) as IGlobalConditionFilterTarget,
    )
    .otherwise((v) => v as IGlobalConditionFilterTarget);
};

const GlobalConditionsMapByTargetAtom = atom((get) => {
  const globalConditionMap = get(GlobalConditionConfigMapAtom);
  const mapByTarget: Record<string, ConditionConfig[]> = {};

  Object.keys(globalConditionMap).forEach((widgetId) => {
    const configs = globalConditionMap[widgetId];

    for (const config of configs) {
      if (!config.targetList) continue;

      for (const target of config.targetList) {
        if (!mapByTarget[target.id]) {
          mapByTarget[target.id] = [];
        }

        const targetConfig = migrateTargetConfig(target);
        if (!targetConfig.matchingGroupList) continue;

        // 为每个 group 创建一个条件配置
        targetConfig.matchingGroupList.forEach((group) => {
          mapByTarget[target.id].push({
            conditionFilterWidgetId: widgetId,
            conditionItemId: config.id,
            op: group.op, // 组内条件的组合方式
            propKey: JSON.stringify(group.list),
            filedType: config.filedType,
            groupOp: targetConfig.matchingGroupOp, // 保存 group 之间的组合方式
          });
        });
      }
    }
  });

  return mapByTarget;
});

/**
 * 简化条件表达式，去除不必要的嵌套
 */
const simplifyCondition = (condition: ViewConditionV2Item) => {
  if (!condition.input || !Array.isArray(condition.input)) {
    return condition;
  }

  // 添加类型声明
  let simplifiedInput: ViewConditionV2Item[] = condition.input
    .filter(notEmpty)
    .map(simplifyCondition)
    .filter((c): c is ViewConditionV2Item => Boolean(c));

  // 如果没有子条件，返回 null
  if (simplifiedInput.length === 0) {
    return null;
  }

  // 合并相同操作符的嵌套条件
  simplifiedInput = simplifiedInput.reduce(
    (acc: ViewConditionV2Item[], curr: ViewConditionV2Item) => {
      if (curr.input && Array.isArray(curr.input) && curr.op === condition.op) {
        return [...acc, ...curr.input];
      }
      return [...acc, curr];
    },
    [] as ViewConditionV2Item[],
  );

  return {
    ...condition,
    input: simplifiedInput,
  };
};

/**
 * @deprecated
 * 获取全局条件
 */
export const use_deprecated_GlobalConditions = (targetId: string) => {
  const globalConditionsMapByTarget = useAtomValue(
    GlobalConditionsMapByTargetAtom,
  );
  const [sharedState] = useWidgetSharedState();
  const extraGlobalConditionsMap = useAtomValue(ExtraGlobalConditionsMapAtom);

  // 获取全局条件值
  const value = useMemo(() => {
    return match(sharedState)
      .with(
        { globalConditions: P.nonNullable },
        ({ globalConditions }) =>
          globalConditions as Record<string, GlobalConditionFilterValue>,
      )
      .otherwise(() => ({}));
  }, [sharedState]);

  // 获取额外条件
  const extraConditions = useMemo(() => {
    const cMap = extraGlobalConditionsMap[targetId] || {};
    return Object.values(cMap).flat();
  }, [extraGlobalConditionsMap, targetId]);

  // 合并所有条件
  const conditions = useMemo(() => {
    const conditionConfigs = globalConditionsMapByTarget[targetId];
    if (!conditionConfigs?.length) {
      return extraConditions;
    }

    const groupOp = conditionConfigs[0].groupOp || 'and';

    const parsedConditions = conditionConfigs
      .map((config) => parseConditionConfig(config, value))
      .filter(notEmpty);

    // 构造全局条件
    const globalCondition = {
      op: groupOp,
      input: parsedConditions,
    };

    const result = [globalCondition, ...extraConditions];

    /**
     * 简化条件表达式，去除不必要的嵌套
     */
    const simplifiedResult = simplifyCondition({
      op: 'and',
      input: result,
      key: '',
    });

    return simplifiedResult?.input || [];
  }, [extraConditions, globalConditionsMapByTarget, targetId, value]);

  return conditions;
};

export const useGlobalConditions = (
  condition: ViewList.ViewconditionV2,
  targetId?: string,
) => {
  if (typeof condition === 'string') {
    console.warn('正在使用旧的 useGlobalConditions 参数，请尽快更新为最新参数');
    return use_deprecated_GlobalConditions(condition);
  }

  const [sharedState] = useWidgetSharedState();
  const extraGlobalConditionsMap = useAtomValue(ExtraGlobalConditionsMapAtom);

  // 获取全局条件值
  const globalConditionFilterValue = useMemo(() => {
    return match(sharedState)
      .with(
        { globalConditions: P.nonNullable },
        ({ globalConditions }) =>
          globalConditions as Record<string, GlobalConditionFilterValue>,
      )
      .otherwise(() => ({}));
  }, [sharedState]);

  // 获取额外条件
  const extraConditions = useMemo(() => {
    if (!targetId) return [];
    const cMap = extraGlobalConditionsMap[targetId] || {};
    return Object.values(cMap).flat();
  }, [extraGlobalConditionsMap, targetId]);

  const newCondition = useMemo(() => {
    // 先处理基础条件
    const result = produceConditionV2(condition, (draft) => {
      match(draft)
        .with(
          { extends: { inputType: 'globalConditionFilter' } },
          ({ extends: { widgetId, itemId, index } }) => {
            const item = globalConditionFilterValue[widgetId]?.[itemId];

            draft.input = match(item)
              .with({ filedType: FIELD_TYPES.CASCADER }, ({ value }) => {
                return typeof index === 'number'
                  ? processCascaderInput(value as any, index)
                  : [];
              })
              .with({ filedType: FIELD_TYPES.YEAR_MONTH_DAY }, ({ value }) => {
                return processYearMonthDayInput(value as any);
              })
              .otherwise(() => item?.value || []);

            if (draft.extends) {
              draft.extends = {
                type: draft.extends.type,
                inputType: 'customEditor',
              };
            }
          },
        )
        .otherwise(() => {});

      return true;
    });

    // 如果有额外条件，合并它们
    if (extraConditions.length > 0) {
      return [...result, ...extraConditions];
    }

    return result;
  }, [condition, globalConditionFilterValue, extraConditions]);

  return newCondition;
};
