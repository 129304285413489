import { STATUS_ICON, tempValueDisplay } from '@linkpi/core';
import { GetterPiNode } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import { useQuery } from '@tanstack/react-query';
import type { InputNumberProps, TableProps } from 'antd';
import { InputNumber, Pagination, Table } from 'antd';
import dayjs from 'dayjs';
import { type FC, useEffect, useMemo, useRef, useState } from 'react';
import { match } from 'ts-pattern';

import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import {
  useCurrentUser,
  useOrgInfo,
  useOrgUserMap,
  useTemplateMap,
} from '@/hook';
import {
  use_deprecated_GlobalConditions,
  useGlobalConditions,
} from '@/hook/useGlobalConditions';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { propDisplay } from '@/pages/home/components/TempStatus';
import { isPropCanEdit, loadNodes } from '@/utils/utils';

import styles from './VerticalTable.less';

function paginate<T>(data: T[], page: number, pageSize: number): T[] {
  // 计算起始索引
  const startIndex = (page - 1) * pageSize;
  // 计算结束索引
  const endIndex = startIndex + pageSize;
  // 返回分页数据
  return data.slice(startIndex, endIndex);
}

function renderCell(config: any, data: any, dindex: number) {
  if (typeof config.render === 'function') {
    return config.render(data, dindex);
  }
  return data[config.dataIndex];
}
const useVerticalTable = (props: any) => {
  // TODO 空数据
  let columns: any[] = [];
  const ds: any = [];
  const mergeCell: any = [];

  for (let cindex = 0; cindex < props.columns.length; cindex++) {
    const config = props.columns[cindex];
    if (!cindex) {
      // 转化 columns
      columns = [
        {
          title: config.title,
          dataIndex: 0,
          width: 100,
          className: 'headerClass',
          fixed: 'left',
        },
        ...props.dataSource.map((i: any, dindex: number) => ({
          title: renderCell(config, i, dindex),
          dataIndex: dindex + 1,
        })),
      ];
      continue;
    }

    // 转化 dataSource
    const item: any = {
      0: config.title,
    };
    if (config.type === 'inlineTitle') {
      mergeCell.push(cindex - 1);
    } else {
      props.dataSource.forEach((i: any, dindex: number) => {
        item[dindex + 1] = renderCell(config, i, dindex);
      });
    }
    ds.push(item);
  }

  // 标题
  const sharedOnCell = (colSpan: any) => (_: any, index: number) => {
    return mergeCell.indexOf(index) >= 0 ? { className: 'cellClass' } : {};
    // return mergeCell.indexOf(index) >= 0 ? { colSpan } : { colSpan: 1 }
  };
  columns = columns.map((i, index) => ({
    ...i,
    onCell: sharedOnCell(index ? 0 : props.dataSource.length + 1),
  }));

  const tableProps = { columns, dataSource: ds };
  return { tableProps };
};

const _VerticalTable: FC<TableProps<any> & { columns: any[] }> = ({
  columns = [],
  dataSource = [],
  ...props
}) => {
  const { tableProps } = useVerticalTable({ columns, dataSource });

  return (
    <Table
      {...props}
      {...tableProps}
      bordered
      scroll={{ x: 'auto' }}
      className={styles.verticalTable}
      pagination={false}
    />
  );
};

type EditNumberPropCellProps = {
  displayValue?: number | string;
  value?: number | string;
  onChange?: (value: number | string) => void;
} & Omit<InputNumberProps, 'value' | 'onChange'>;
const EditNumberPropCell = (props: EditNumberPropCellProps) => {
  const { value, onChange, displayValue, ...inputNumberProps } = props;
  const [editing, toggleEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const handleWrapperClick = () => {
    toggleEditing(true);
  };

  const handleBlur: InputNumberProps['onBlur'] = async (e) => {
    toggleEditing(false);

    const inputValue = e.target.value;
    if (inputValue !== value) {
      onChange?.(inputValue);
    }
  };

  return (
    <div className="w-full h-full">
      {editing ? (
        <InputNumber
          className={styles['cell-number-input']}
          autoFocus
          ref={inputRef}
          defaultValue={value}
          onBlur={handleBlur}
          {...inputNumberProps}
        />
      ) : (
        <span onClick={handleWrapperClick}>{displayValue}</span>
      )}
    </div>
  );
};

export const VerticalTable: FC<{
  config: (IWidgetInstanceData & { widgetId: 'VerticalTable' })['config'];
  id: string;
  getterPiNode: GetterPiNode;
  isDrafts: boolean;
  draftParentId?: string;
}> = ({ config, getterPiNode, isDrafts, draftParentId, id }) => {
  const templateId = useMemo(
    () => config.conditions.find((i) => i.key === 'templateId')?.input[0],
    [config.conditions],
  );
  const tempMap = useTemplateMap();
  const tempInfo = tempMap[templateId];
  const userMap = useOrgUserMap();
  const departmentNodeMap = useOrgDepartmentNodesMap();
  const [orgInfo] = useOrgInfo();
  assertExists(orgInfo);
  const currentUser = useCurrentUser();

  const globalConditions = use_deprecated_GlobalConditions(id);

  const _conditions = useMemo(() => {
    if (!globalConditions) return config.conditions;
    if (!globalConditions?.length) return config.conditions;
    return [...config.conditions, ...globalConditions];
  }, [config.conditions, globalConditions]);

  const conditions = useGlobalConditions(_conditions, id);

  const { data: nodeList } = useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'VerticalTable',
      orgInfo.rootId,
      config.parentType,
      conditions,
      config.matchings,
      config.orderBy,
      config.orderDesc,
    ],
    queryFn: async () => {
      const payload = {
        ...config,
        conditions,
      };

      const ancestor = (payload.conditions || []).find(
        (i: any) => i.key === 'ancestor',
      );
      if (ancestor && ancestor.input[0] === orgInfo.rootId) {
        payload.conditions = payload.conditions.filter(
          (i: any) => i.key !== 'ancestor',
        );
      }
      const _nodeList = await loadNodes(getterPiNode.value, {
        ...payload,
        currentUserId: currentUser.userid,
      });

      // const _nodeList = [...getNodeList(nodes)];
      // treeId
      if (config.orderBy === 'treeLevel' && _nodeList.length) {
        const node = _nodeList[0];
        const treeLevelMap =
          node.nodeManager.getNodeTreeSerialNumber(_nodeList);
        _nodeList.forEach((n: any) => {
          n.treeLevelId = treeLevelMap[n.id];
        });
      }

      // 更新时间排序
      if (config.orderBy === '_sys_updateTime') {
        _nodeList.sort((nodeA, nodeB) => nodeB.metadata.m - nodeA.metadata.m);
      }
      // 系统属性
      if (config.orderBy === '_sys_createTime') {
        _nodeList.sort((nodeA, nodeB) => {
          return config.orderDesc
            ? nodeA.createTime - nodeB.createTime
            : nodeB.createTime - nodeA.createTime;
        });
      }

      return _nodeList;
    },
  });

  const columns = useMemo(() => {
    const getName = (item: (typeof config.displayAttrs)[number]) => {
      if (item.key.slice(0, 4) !== 'prop') return item.name;
      const propIndex = parseInt(item.key.split('_')[1]);
      const propConfig = tempInfo.prop[propIndex];

      let name = propConfig.name;
      const isNumber = match(propConfig)
        .with(
          { type: 'number' },
          { type: 'formula', formulaFormat: 0 },
          () => true,
        )
        .otherwise(() => false);

      if (
        config.tableDisplayConfig?.showHeaderUnit &&
        isNumber &&
        propConfig?.number?.unit
      ) {
        if (propConfig.number.precision) {
          name = `(${propConfig.number.unit})${name}`;
        } else {
          name = `${name} (${propConfig.number.unit})`;
        }
      }

      return name;
    };

    return config.displayAttrs
      .filter((i) => !i.disable)
      .map((item) => ({
        title: getName(item),
        render: (node: PiNode, dataIndex: number) =>
          match(item)
            .with({ key: 'index' }, () => node.prop._sys_node_seq)
            .with({ key: 'title' }, () => node.title)
            .with({ key: 'status' }, () => {
              const status = node.tempInfo.status;
              const statusInfo = tempInfo?.task_status?.[status];
              if (!statusInfo || statusInfo.delete) return '--';
              const icon =
                STATUS_ICON[statusInfo.icon as keyof typeof STATUS_ICON];
              return (
                <span className="inline-flex gap-1">
                  <i
                    className={`iconfont ${statusInfo.icon}`}
                    // @ts-ignore
                    style={{ color: icon.color }}
                  />
                  {statusInfo.name}
                </span>
              );
            })
            .with({ key: 'treeLevelId' }, () => node.treeLevelId)
            .with({ key: 'createTime' }, () =>
              dayjs(node.createTime).format('YYYY/MM/DD HH:mm'),
            )
            .with({ key: 'sysTag' }, () => {
              const list =
                node.prop._sys_tag && typeof node.prop._sys_tag === 'object'
                  ? Object.keys(node.prop._sys_tag)
                  : [];
              if (!list.length) return '--';
              return list.join(', ');
            })
            .with({ key: 'order' }, () => dataIndex + 1)
            .when(
              (i) => i.key.slice(0, 4) === 'prop',
              ({ key }) => {
                const propIndex = parseInt(key.split('_')[1]);
                const propInfo = tempInfo.prop[propIndex];
                const propValue = node.tempInfo.prop[propIndex];

                const displayValue = tempValueDisplay({
                  // 根据配置是否展示单位
                  propConfig: config.tableDisplayConfig?.showCellUnit
                    ? propInfo
                    : {
                        ...propInfo,
                        number: {
                          ...propInfo.number,
                          unit: '',
                        },
                      },
                  propValue,
                  propIndex,
                  userMap,
                  tempMap,
                  sysCascade: node.prop._sys_cascade,
                  departmentMap: departmentNodeMap,
                  sysDisplay: node.prop._sys_display,
                });
                const piNode = new GetterPiNode(node);
                const canEdit = isPropCanEdit(
                  propInfo,
                  piNode,
                  propIndex,
                  orgInfo,
                  isDrafts
                    ? node.nodeManager.findChildren(draftParentId!)
                    : node.parent!,
                );

                return match([propInfo, canEdit])
                  .with([{ type: 'number' }, true], () => {
                    const handleChange = async (v: string | number) => {
                      node.updateProp({
                        // org_id: node.orgId,
                        // node_id: node.id,
                        temp_id: node.tempInfo.id,
                        index: [propIndex],
                        value: [v],
                      });
                    };
                    const numberCellProps: InputNumberProps = {};
                    if (propInfo.number?.numericalFormat) {
                      numberCellProps.addonAfter = '%';
                    } else if (propInfo.number?.unit) {
                      numberCellProps[
                        propInfo.number?.unitPosition
                          ? 'addonBefore'
                          : 'addonAfter'
                      ] = propInfo.number.unit;
                    }
                    return (
                      <EditNumberPropCell
                        value={propValue}
                        displayValue={displayValue || '--'}
                        onChange={handleChange}
                        precision={propInfo.number?.precision}
                        {...numberCellProps}
                      />
                    );
                  })
                  .otherwise(() => displayValue || '--');
              },
            )
            .when(
              (i) => i.key.includes('tatusProp_common_'),
              ({ key }) => {
                const statusPropIndex = parseInt(
                  key.replace('tatusProp_common_', '')[1],
                );
                const statusInfo = tempInfo.task_status[node.tempInfo.status];

                if (
                  !statusInfo ||
                  statusInfo.delete ||
                  !statusInfo.prop?.[statusPropIndex]?.display
                )
                  return '--';
                return (
                  propDisplay(
                    statusInfo.prop[statusPropIndex] as any,
                    node.tempInfo.statusProp[statusPropIndex],
                    userMap,
                    tempMap,
                    node.prop._sys_cascade,
                    0,
                    node,
                    departmentNodeMap,
                  ) || '--'
                );
              },
            )
            .otherwise(() => '--'),
      }));
  }, [
    config,
    departmentNodeMap,
    draftParentId,
    isDrafts,
    orgInfo,
    tempInfo.prop,
    tempInfo.task_status,
    tempMap,
    userMap,
  ]);

  const { pagination = true } = config;

  const [pageInfo, setPageInfo] = useState({
    page: 1,
    pageSize: 10,
  });

  const dataSource = useMemo(() => {
    if (!nodeList) return [];
    if (!pagination) {
      return nodeList;
    }

    return paginate(nodeList, pageInfo.page, pageInfo.pageSize);
  }, [pagination, nodeList, pageInfo.page, pageInfo.pageSize]);

  return (
    <div className="flex flex-col gap-2">
      <_VerticalTable columns={columns} dataSource={dataSource} />
      {pagination && (
        <div className="flex flex-row-reverse">
          <Pagination
            showQuickJumper
            current={pageInfo.page}
            total={nodeList?.length || 0}
            pageSize={pageInfo.pageSize}
            onChange={(page, pageSize) => {
              setPageInfo({
                page,
                pageSize,
              });
            }}
          />
        </div>
      )}
    </div>
  );
};
