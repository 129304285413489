import type { OperatorConfig, TypeConfig } from './types';

export const MATCHING_OP_OPTIONS = [
  { label: <span className="font-bold">且</span>, value: 'and' },
  { label: <span className="font-bold">或</span>, value: 'or' },
];

const EMPTY_CONFIG: OperatorConfig = {
  label: '为空',
  value: 'empty',
  supportInputType: [],
};
const NOT_EMPTY_CONFIG: OperatorConfig = {
  label: '非空',
  value: 'notEmpty',
  supportInputType: [],
};

const ENUM_TYPE_CONFIG: OperatorConfig[] = [
  { label: '包含', value: 'intersect', onlyMultiple: true },
  { label: '被包含', value: 'beIncluded' },
  // TODO 1.0.5
  // { label: '等于', value: 'equal' },
  EMPTY_CONFIG,
  NOT_EMPTY_CONFIG,
];

const DATE_TIME_TYPE_CONFIG: OperatorConfig[] = [
  {
    label: '处于',
    value: 'timeBein',
    supportInputType: ['globalConditionFilter', 'customEditor'],
  },
  { label: '早于', value: 'timeBefore' },
  { label: '早于等于', value: 'timeBeforeEqual' },
  // { label: '等于', value: 'timeEqual' },
  { label: '晚于等于', value: 'timeAfterEqual' },
  { label: '晚于', value: 'timeAfter' },
  EMPTY_CONFIG,
  NOT_EMPTY_CONFIG,
  { label: '年等于', value: 'yearInclude' },
  { label: '月等于', value: 'monthInclude' },
  { label: '日等于', value: 'dayInclude' },
  { label: '时等于', value: 'hourInclude' },
];

export const DEFAULT_OPERATORS: Partial<TypeConfig> = {
  status: [{ label: '等于', value: 'intersect', hide: true }],
  text: [
    { label: '包含', value: 'textInclude' },
    { label: '相交', value: 'intersect' },
    // TODO 1.0.5
    // { label: '等于', value: 'equal' },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
    { label: '前缀匹配', value: 'textPrefix' },
  ],
  number: [
    { label: '=', value: 'equal' },
    { label: '≠', value: 'notEqual' },
    { label: '>', value: 'greater' },
    { label: '≥', value: 'greaterEqual' },
    { label: '<', value: 'less' },
    { label: '≤', value: 'lessEqual' },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
  ],
  date: DATE_TIME_TYPE_CONFIG,
  datetime: DATE_TIME_TYPE_CONFIG,
  enum: ENUM_TYPE_CONFIG,
  tag: ENUM_TYPE_CONFIG,
  cascade: [
    {
      label: '包含',
      value: 'include',
      onlyMultiple: true,
      supportInputType: ['customEditor'],
    },
    {
      label: '被包含',
      value: 'beIncluded',
      supportInputType: ['customEditor'],
    },
    // TODO 1.0.5
    // {
    //   label: '等于',
    //   value: 'equal',
    //   supportInputType: ['customEditor'],
    // },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
  ],
  attachment: [EMPTY_CONFIG, NOT_EMPTY_CONFIG],
  location: [
    // TODO 1.0.5
    // {
    //   label: '等于',
    //   value: 'equal',
    //   supportInputType: ['currentNode', 'customEditor'],
    // },
    {
      label: '距离',
      value: 'distance',
      supportInputType: ['currentNode', 'customEditor'],
    },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
  ],
  address: [
    // TODO 1.0.5
    // {
    //   label: '等于',
    //   value: 'equal',
    //   supportInputType: ['currentNode', 'customEditor'],
    // },
    {
      label: '距离',
      value: 'distance',
      supportInputType: ['currentNode', 'customEditor'],
    },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
  ],
  auto_inc: [EMPTY_CONFIG, NOT_EMPTY_CONFIG],
  department: [
    // TODO 1.0.5
    // {
    //   label: '等于',
    //   value: 'equal',
    //   supportInputType: ['currentNode', 'customEditor'],
    // },
    {
      label: '相交',
      value: 'intersect',
      onlyMultiple: true,
      supportInputType: ['currentNode', 'customEditor'],
    },
    {
      label: '属于',
      value: 'beIncluded',
      onlySingle: true,
      supportInputType: ['currentNode', 'customEditor'],
    },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
  ],
  user: [
    // TODO 1.0.5
    // {
    //   label: '等于',
    //   value: 'equal',
    //   supportInputType: ['currentNode', 'customEditor'],
    // },
    {
      label: '属于',
      value: 'beIncluded',
      supportInputType: ['currentNode', 'customEditor'],
    },
    // TODO 1.0.5
    // {
    //   label: '不属于',
    //   value: 'notBeIncluded',
    //   supportInputType: ['currentNode', 'customEditor'],
    // },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
  ],
  sysTag: [
    { label: '相交', value: 'intersect', supportInputType: ['customEditor'] },
    EMPTY_CONFIG,
    NOT_EMPTY_CONFIG,
  ],
};
