import { useControllableValue } from 'ahooks';

import {
  ColorInput,
  FontStyle,
  SelectInput,
} from '@/components/PageModelEditor/src/components';

import { DefaultFontValue } from '../../../constant';

interface FontValue {
  fontSize: number;
  color: {
    r: number;
    g: number;
    b: number;
    a: number;
  };
  bold: boolean;
  italic: boolean;
  underline: boolean;
}

interface FontProps {
  title?: string;
  value?: FontValue;
  onChange?: (value: FontValue) => void;
  noSize?: boolean;
  sizeRender?: () => React.ReactNode;
}

const Font = (props: FontProps) => {
  const { title, noSize, sizeRender, ...rest } = props;

  const [value, onChange] = useControllableValue<FontValue>(rest);

  const { fontSize, color, ...fontStyle } = {
    ...DefaultFontValue,
    ...(value ?? {}),
  };

  return (
    <div className="flex flex-col gap-4">
      {title && <p>{title}</p>}
      <div className="flex items-center gap-2">
        {noSize ? sizeRender?.() : <SelectInput
          className="h-8 flex-1"
          value={fontSize}
          onChange={(e) => onChange({ ...value, fontSize: e })}
        />}
        <ColorInput
          type="A"
          value={color}
          onChange={(e) => onChange({ ...value, color: e })}
        />
        <FontStyle
          style={{ height: 32 }}
          value={fontStyle}
          onChange={(e) => onChange({ ...value, ...e })}
        />
      </div>
    </div>
  );
};

export default Font;
