import { match } from 'ts-pattern';

import { cn } from '@/utils/utils';

interface SearchFormItemWrapperProps {
  readonly: boolean;
  onClick: () => void;
  arrangement?: 'one' | 'two' | 'three';
  selected: boolean;
  children: React.ReactNode;
}

const SearchFormItemWrapper = ({
  children,
  readonly,
  onClick,
  arrangement,
  selected,
}: SearchFormItemWrapperProps) => {
  return (
    <div
      className={cn(
        'flex items-baseline overflow-y-hidden px-3 py-2 rounded-[8px] group gap-x-2 relative',
        match(arrangement)
          .with('one', () => 'w-full')
          .with('two', () => 'w-[calc(50%-16px)]')
          .otherwise(() => 'w-[calc(33.33%-10.66px)]'),
        {
          'cursor-pointer': readonly,
          'border-2 border-solid border-[#316EF5]': selected,
        },
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          'hidden absolute top-0 left-0 bottom-0 right-0 rounded-[8px] border border-dashed border-[#316EF5] bg-[rgba(255,255,255,0.2)]',
          {
            'group-hover:block': readonly && !selected,
          },
        )}
      />
      {children}
    </div>
  );
};

export default SearchFormItemWrapper;
