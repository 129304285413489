import { getPiSDK } from '@linkpi/sdk';
import { merge } from 'lodash';
import { match, P } from 'ts-pattern';

import { parseOrgIdFromLocation } from '@/utils/parseOrgIdFromLocation';

import { initalPageConfig } from '../constants';

export const getInitalPageConfig = (_orgId?: string) => {
  const piSDK = getPiSDK();

  let orgId = _orgId;

  if (!orgId) {
    try {
      orgId = parseOrgIdFromLocation(window.location);
    } catch (error) {
      console.error(error);
    }
  }

  if (!orgId) return initalPageConfig;

  const orgOption = piSDK.orgManager.getOrgOption(orgId);

  return match(orgOption)
    .with({ defaultPageConfig: P.nonNullable }, (v) =>
      merge(initalPageConfig, v.defaultPageConfig),
    )
    .otherwise(() => initalPageConfig);
};
