import * as widgets from '../widgets';
import type { IWidget } from './WidgetManager';

const getIds = <T extends IWidget<any, any>[]>(s: T) => s.map((i) => i.id);

/**
 * 用于配置组件的分组
 */
export const DEFAULT_GROUPS = [
  {
    id: 'section',
    title: '基础版块',
    layout: 'list',
    items: getIds([
      widgets.GeneralSectionWidget,
      widgets.TabSectionWidget,
      widgets.DynamicHeightSectionWidget,
    ]),
  },
  {
    id: 'basicComponent',
    title: '基础组件',
    layout: 'grid',
    items: getIds([
      widgets.NodeHeaderWidget,
      widgets.NodeInfoWidget,
      widgets.NodeTitleWidget,
      widgets.NodeStatusPropsWidget,
      widgets.NodeStatusWidget,
      widgets.ButtonWidget,
      widgets.NodeStatusLineWidget,
      widgets.CustomPropsWidget,
      widgets.NodeMainBodyWidget,
      widgets.StatusHistoryWidget,
      widgets.PictureWidget,
      widgets.LineTextWidget,
      widgets.AttributeInfoWidget,
      widgets.RichTextWidget,
      // widgets.GlobalConditionFilterWidget,
      widgets.GlobalFilterWidget,
    ]),
  },
  {
    id: 'viewComponent',
    title: '视图组件',
    layout: 'grid',
    items: getIds([
      widgets.SubThemeTreeWidget,
      widgets.TableWidget,
      widgets.CustomTableWidget,
      widgets.KanbanWidget,
      widgets.GanttWidget,
      // @todo 大模型-p1
      // widgets.CalendarWidget,
      widgets.FrameWidget,
      widgets.VerticalTableWidget,
      widgets.ElewattTable,
    ]),
  },
  {
    id: 'statisticsComponent',
    title: '统计组件',
    layout: 'grid',
    items: getIds([
      widgets.IndicatorWidget,
      widgets.BarChartWidget,
      widgets.HorizontalBarChartWidget,
      widgets.LineChartWidget,
      widgets.MultidimensionalStatisticWidget,
      widgets.PieChartWidget,
      widgets.DataIndicatorWidget,
    ]),
  },
  {
    id: 'tiyi',
    title: '艺体',
    layout: 'grid',
    orgId: 'A889536B039C614F5C58196A9742DCED',
    items: getIds([widgets.GrowFiles, widgets.RollCall]),
  },
] as const;

export const CUSTOM_GROUPS = [
  {
    id: 'defaultCustomWidgetGroups',
    title: ' ',
    layout: 'grid',
    items: [],
  },
] as const;
