import { request } from '@linkpi/request';

import type { ICascaderOptionSource } from '@/components/PageModelEditor/src/widgets/GlobalFilterWidget/types';

export interface CreateOptionParams {
  org_id: string;
  /**
   * 必选，jilian  或者 xiala
   */
  configType: 'jilian' | 'xiala';
  /**
   * 前端生成32长度大写id
   */
  id: string;
  name: string;
  desc?: string;
  optionSourceConfig: OptionSourceConfig[];
  user: string;
}

export interface OptionSourceConfig {
  [key: string]: any;
}

export const createOption = async (params: CreateOptionParams) => {
  return request('/docapi/addSelectionSet', {
    method: 'POST',
    data: params,
  });
};

export interface GetOptionListParams {
  org_id: string;
  configType: 'jilian' | 'xiala';
  key_word?: string;
  id_list?: string[];
}

export interface OptionsItem extends UpdateOptionParams {
  update_time: number;
  create_time: number;
  user: string;
}

export const getOptionList = async (params: GetOptionListParams) => {
  return request<OptionsItem[]>('/docapi/getSelectionSet', {
    method: 'POST',
    data: params,
  });
};

export interface UpdateOptionParams {
  org_id: string;
  id: string;
  multiple: boolean;
  name: string;
  desc?: string;
  optionSourceConfig: ICascaderOptionSource[];
}

export const updateOption = async (params: UpdateOptionParams) => {
  return request('/docapi/updateSelectionSet', {
    method: 'POST',
    data: params,
  });
};

export interface DeleteOptionParams {
  org_id: string;
  id: string;
}

export const deleteOption = async (params: DeleteOptionParams) => {
  return request('/docapi/delSelectionSet', {
    method: 'POST',
    data: params,
  });
};
