import type { OpenViewConfig } from '@linkpi/core';
import { match } from 'ts-pattern';

import { jumpToView } from '@/utils/customButton';

import { openViewDrawer } from './Drawer';

export const triggerOpenView = async ({
  config,
  orgId,
}: {
  config: OpenViewConfig;
  orgId: string;
}) => {
  return match(config)
    .with({ openType: 'modal' }, () => {
      // TODO: 打开弹窗
      // return handleOpen(config);
    })
    .with({ openType: 'drawer' }, () => {
      return openViewDrawer({ config });
    })
    .otherwise(() => {
      return jumpToView({
        viewId: config.viewId,
        orgId,
      });
    });
};
