import cls from 'classnames';
import type { FC, ReactElement, StyleHTMLAttributes } from 'react';

import NodeDeleteGuideButton from './components/NodeDeleteGuideButton';

import styles from './styles.less';

type FallbackPanelConfig = {
  img?: string;
  imgSize?: number;
  title?: string | ReactElement;
  desc?: string;
  content?: string | ReactElement;
};

export type FallbackPanelType =
  | 'crash'
  | 'nodeDeleteGuide'
  | 'sidebarCrash'
  | 'settingFailed'
  | 'widgetCrash'
  | 'inlineFailed';

type FallbackPanelProps = {
  type: FallbackPanelType;
};

const interalConfig: Record<FallbackPanelType, FallbackPanelConfig> = {
  crash: {
    img: 'https://www.mylinkpi.com/static/media/%E5%8A%A0%E8%BD%BD%E5%A4%B1%E8%B4%A5.png',
    title: '加载失败',
    desc: '请刷新重试，或联系客服人员',
  },
  sidebarCrash: {
    img: 'https://www.mylinkpi.com/static/media/%E5%8A%A0%E8%BD%BD%E5%A4%B1%E8%B4%A5.png',
    imgSize: 120,
    title: '加载失败',
    desc: '请刷新重试，或联系客服人员',
  },
  widgetCrash: {
    img: 'https://www.mylinkpi.com/static/media/%E5%8A%A0%E8%BD%BD%E5%A4%B1%E8%B4%A5.png',
    imgSize: 100,
    title: '加载失败',
    desc: '请刷新重试，或联系客服人员',
  },
  nodeDeleteGuide: {
    img: 'https://xjmind.oss-cn-hangzhou.aliyuncs.com/webImg/nodeDel.png',
    title: '该主题已被删除',
    content: <NodeDeleteGuideButton />,
  },
  settingFailed: {
    img: 'http://ljp-static.oss-cn-hangzhou.aliyuncs.com/settingFailed.png',
    imgSize: 120,
    title: '配置错误',
    content: '请联系客服人员，或重新配置',
  },
  inlineFailed: {
    content: <span className="text-red-500">配置错误</span>,
  },
};

const FallbackPanel: FC<FallbackPanelProps> = (props) => {
  const config: FallbackPanelConfig = interalConfig[props.type];
  const imgStyle: StyleHTMLAttributes<HTMLImageElement>['style'] =
    typeof config.imgSize !== 'undefined'
      ? { width: config.imgSize, height: config.imgSize }
      : {};

  return (
    <div className={cls(styles.wrapper, 'fallback-panel')}>
      {config.img && (
        <img
          className={styles.image}
          src={config.img}
          alt=""
          style={imgStyle}
        />
      )}
      {config.title && <p className={styles.title}>{config.title}</p>}
      {config.desc && <p className={styles.desc}>{config.desc}</p>}
      {config.content}
    </div>
  );
};

export default FallbackPanel;
