import type { ProFormColumnsType } from '@ant-design/pro-form';
import ProForm, {
  BetaSchemaForm,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-form';
import { Button, Popconfirm, Segmented } from 'antd';
import { useAtom, useAtomValue } from 'jotai';
import { path } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import { match } from 'ts-pattern';

import { ColorPicker } from '@/components/ColorPicker';
import { ConditionFilterValue } from '@/components/ConditionFilter';
import { RegularIcon } from '@/components/IconFont';
import { DataPropSelect, TemplateSelect } from '@/components/LinkPiForm';
import { cn } from '@/utils/utils';

import {
  CascaderSelect,
  YearMonthDayPicker,
} from '../../../GlobalConditionFilterWidget/GlobalConditionFilter';
import { OptionSelect } from '../../../GlobalConditionFilterWidget/GlobalConditionFilter/Select';
import { DefaultFontValue, FoldButtonId, SubmitButtonId } from '../../constant';
import {
  OptionListAtom,
  SelectedFilterIdAtom,
  SelectedFilterPrefixNamesAtom,
  SelectedFilterSettingTypeAtom,
} from '../../store';
import type {
  IGlobalConditionFilterConfig,
  ISelectConfig,
  IYearMonthDayConfig,
} from '../../types';
import Font from '../SettingItem/Font';
import ItemType from '../SettingItem/ItemType';
import OptionsSelect from '../SettingItem/Options';
import SettingRadio from '../SettingItem/Radio';
import Title from '../SettingItem/Title';

import Styles from './index.less';

const FilterItemSetting = () => {
  const [segmentedValue, setSegmentedValue] = useState<'props' | 'styles'>(
    'props',
  );

  const [selectedFilterId, setSelectedFilterId] = useAtom(SelectedFilterIdAtom);
  const selectedFilterPrefixNames = useAtomValue(SelectedFilterPrefixNamesAtom);
  const selectedFilterSettingType = useAtomValue(SelectedFilterSettingTypeAtom);
  const optionList = useAtomValue(OptionListAtom);
  const optionMap = useMemo(() => {
    return optionList.reduce<Record<string, any>>((result, item) => {
      result[item.id] = item;
      return result;
    }, {});
  }, [optionList]);

  const form = ProForm.useFormInstance<IGlobalConditionFilterConfig>();

  const filterList =
    ProForm.useWatch<IGlobalConditionFilterConfig['conditionList']>(
      'conditionList',
    );

  useEffect(() => {
    setSegmentedValue('props');
  }, [selectedFilterSettingType]);

  const foldNames = ['foldConfig'];

  const foldPropsCols: ProFormColumnsType<IGlobalConditionFilterConfig>[] = [
    {
      title: null,
      renderFormItem: () => (
        <>
          <ProFormText
            name={[...foldNames, 'foldButtonText']}
            initialValue="收起"
            label={<span className="text-sm font-bold">收起时文字</span>}
          />
          <ProFormText
            name={[...foldNames, 'unfoldButtonText']}
            initialValue="更多筛选"
            label={<span className="text-sm font-bold">展开时文字</span>}
          />
          <ProFormSelect
            name={[...foldNames, 'foldLines']}
            initialValue={1}
            label={<span className="text-sm font-bold">收起时显示行数</span>}
            valueEnum={{ 1: '1行', 2: '2行', 3: '3行' }}
          />
        </>
      ),
      formItemProps: {
        className: cn({
          hidden: match({
            type: selectedFilterSettingType,
            segmented: segmentedValue,
          })
            .with({ type: FoldButtonId, segmented: 'props' }, () => false)
            .otherwise(() => true),
        }),
      },
    },
  ];

  const foldStylesCols: ProFormColumnsType<IGlobalConditionFilterConfig>[] = [
    {
      title: '文字',
      dataIndex: foldNames,
      renderFormItem: () => <Font title="属性名称" />,
      formItemProps: {
        className: cn({
          hidden: match({
            type: selectedFilterSettingType,
            segmented: segmentedValue,
          })
            .with({ type: FoldButtonId, segmented: 'styles' }, () => false)
            .otherwise(() => true),
        }),
      },
    },
  ];

  const submitNames = ['submitButtonConfig', 'submit'];
  const resetNames = ['submitButtonConfig', 'reset'];

  const submitButtonCols: ProFormColumnsType<IGlobalConditionFilterConfig>[] = [
    {
      title: null,
      dataIndex: submitNames,
      renderFormItem: () => (
        <>
          <ProForm.Item
            label="查询按钮"
            name={submitNames}
            initialValue={{
              ...DefaultFontValue,
              color: { r: 255, g: 255, b: 255, a: 1 },
            }}
          >
            <Font
              sizeRender={() => <span className="flex-1">文字</span>}
              noSize
            />
          </ProForm.Item>
          <div className="flex items-center justify-between mb-4">
            <span>背景颜色</span>
            <ProForm.Item
              noStyle
              name={[...submitNames, 'bgColor']}
              initialValue="#316EF5"
            >
              <ColorPicker />
            </ProForm.Item>
          </div>
          <div className="flex items-center justify-between mb-4">
            <span>边框颜色</span>
            <ProForm.Item
              noStyle
              name={[...submitNames, 'borderColor']}
              initialValue="#316EF5"
            >
              <ColorPicker />
            </ProForm.Item>
          </div>
        </>
      ),
      formItemProps: {
        className: cn({
          hidden: selectedFilterSettingType !== SubmitButtonId,
        }),
      },
    },
    {
      title: null,
      dataIndex: resetNames,
      renderFormItem: () => (
        <>
          <ProForm.Item
            label="重置按钮"
            name={resetNames}
            initialValue={DefaultFontValue}
          >
            <Font
              sizeRender={() => <span className="flex-1">文字</span>}
              noSize
            />
          </ProForm.Item>
          <div className="flex items-center justify-between mb-4">
            <span>背景颜色</span>
            <ProForm.Item
              noStyle
              name={[...resetNames, 'bgColor']}
              initialValue="#FFF"
            >
              <ColorPicker />
            </ProForm.Item>
          </div>
          <div className="flex items-center justify-between mb-4">
            <span>边框颜色</span>
            <ProForm.Item
              noStyle
              name={[...resetNames, 'borderColor']}
              initialValue="#EBEDF0"
            >
              <ColorPicker />
            </ProForm.Item>
          </div>
        </>
      ),
      formItemProps: {
        className: cn({
          hidden: selectedFilterSettingType !== SubmitButtonId,
        }),
      },
    },
    {
      title: '尺寸',
      dataIndex: ['submitButtonConfig', 'size'],
      valueType: 'select',
      valueEnum: {
        large: '大',
        medium: '标准',
        small: '小',
      },
      initialValue: 'medium',
      formItemProps: {
        className: cn({
          hidden: selectedFilterSettingType !== SubmitButtonId,
        }),
      },
    },
  ];

  const columns = useMemo(() => {
    const ItemTypeFormItemRender: ProFormColumnsType['renderFormItem'] = (
      schema,
      config,
      _form,
    ) => {
      const onChange = (value: string) => {
        const { id, name, showName } = _form.getFieldValue(
          selectedFilterPrefixNames,
        );
        _form.setFieldValue(selectedFilterPrefixNames, {
          id,
          name,
          showName,
          filedType: value,
        });
      };
      return <ItemType onChange={onChange} />;
    };

    const _cols = match<
      {
        type: typeof selectedFilterSettingType;
        segmented: typeof segmentedValue;
      },
      ProFormColumnsType<IGlobalConditionFilterConfig>[]
    >({
      type: selectedFilterSettingType,
      segmented: segmentedValue,
    })
      .with(
        { type: 'text', segmented: 'styles' },
        { type: 'select', segmented: 'styles' },
        { type: 'cascader', segmented: 'styles' },
        { type: 'dateRangePicker', segmented: 'styles' },
        () => [
          {
            title: '文字',
            dataIndex: selectedFilterPrefixNames,
            renderFormItem: () => <Font title="筛选项名称" />,
          },
          {
            title: '尺寸',
            dataIndex: [...selectedFilterPrefixNames, 'size'],
            valueType: 'select',
            valueEnum: {
              large: '大',
              medium: '标准',
              small: '小',
            },
            initialValue: 'medium',
          },
        ],
      )
      .with({ type: 'text', segmented: 'props' }, () => [
        {
          title: '筛选项名称',
          dataIndex: selectedFilterPrefixNames,
          renderFormItem: () => <Title />,
        },
        {
          title: '组件样式',
          dataIndex: [...selectedFilterPrefixNames, 'filedType'],
          renderFormItem: ItemTypeFormItemRender,
        },
        {
          title: '默认值',
          dataIndex: [...selectedFilterPrefixNames, 'defaultValue'],
          valueType: 'text',
          fieldProps: {
            placeholder: '可输入默认值',
          },
        },
      ])
      .with({ type: 'select', segmented: 'props' }, () => {
        const selectConfigName = [...selectedFilterPrefixNames, 'selectConfig'];
        const dataSourceName = [...selectConfigName, 'dataSource'];

        return [
          {
            title: '筛选项名称',
            dataIndex: selectedFilterPrefixNames,
            renderFormItem: () => <Title />,
          },
          {
            title: '组件样式',
            dataIndex: [...selectedFilterPrefixNames, 'filedType'],
            renderFormItem: ItemTypeFormItemRender,
          },
          {
            title: null,
            dataIndex: [...selectConfigName, 'multiple'],
            initialValue: false,
            renderFormItem: () => (
              <SettingRadio
                list={[
                  { label: '单选', value: false },
                  { label: '多选', value: true },
                ]}
              />
            ),
          },
          {
            title: '数据源',
            dataIndex: dataSourceName,
            initialValue: 'template',
            renderFormItem: () => (
              <SettingRadio
                list={[
                  { label: '下拉选项集', value: 'option' },
                  { label: '主题类型', value: 'template' },
                ]}
              />
            ),
          },
          {
            valueType: 'dependency',
            name: [dataSourceName],
            columns: (v) => {
              const dataSource = path<'template' | 'option'>(dataSourceName, v);
              return match<
                'template' | 'option' | undefined,
                ProFormColumnsType<IGlobalConditionFilterConfig>[]
              >(dataSource)
                .with('template', () => [
                  {
                    title: null,
                    renderFormItem: () => (
                      <TemplateSelect
                        noStyle
                        name={[...selectConfigName, 'templateId']}
                      />
                    ),
                  },
                  {
                    valueType: 'dependency',
                    name: [[...selectConfigName, 'templateId']],
                    columns: (_v) => {
                      const templateId = path<string>(
                        [...selectConfigName, 'templateId'],
                        _v,
                      );

                      if (templateId) {
                        return [
                          {
                            title: '显示属性',
                            dataIndex: [...selectConfigName, 'propKey'],
                            renderFormItem: () => (
                              <DataPropSelect
                                templateId={templateId}
                                placeholder="请选择"
                              />
                            ),
                          },
                          {
                            valueType: 'dependency',
                            name: [
                              [...selectedFilterPrefixNames, 'multiple'],
                              [...selectConfigName, 'propKey'],
                            ],
                            columns: (__v) => {
                              const propKey = path<string>(
                                [...selectConfigName, 'propKey'],
                                __v,
                              );
                              const multiple = form.getFieldValue([
                                ...selectedFilterPrefixNames,
                                'multiple',
                              ]);
                              // const multiple = path<boolean>(
                              //   [...selectedFilterPrefixNames, 'multiple'],
                              //   __v,
                              // );

                              if (!propKey) {
                                return [];
                              }
                              return [
                                {
                                  title: '默认选中选项',
                                  dataIndex: [
                                    ...selectedFilterPrefixNames,
                                    'defaultValue',
                                  ],
                                  renderFormItem: () => (
                                    <ConditionFilterValue
                                      multiple={multiple ?? false}
                                      propKey={propKey}
                                      templateId={templateId}
                                      key={propKey + multiple}
                                    />
                                  ),
                                },
                              ];
                            },
                          } as ProFormColumnsType,
                        ];
                      }
                      return [];
                    },
                  },
                ])
                .with('option', () => [
                  {
                    title: null,
                    formItemProps: {
                      noStyle: true,
                    },
                    renderFormItem: () => (
                      <OptionsSelect
                        name={[...selectConfigName]}
                        type="xiala"
                      />
                    ),
                  },
                  {
                    valueType: 'dependency',
                    name: [[...selectConfigName]],
                    columns: (__v) => {
                      const config = path<
                        Extract<ISelectConfig, { dataSource: 'option' }>
                      >([...selectConfigName], __v);

                      if (config?.optionId) {
                        const option = optionMap[config.optionId];
                        if (!config) {
                          return [];
                        }
                        return [
                          {
                            title: '默认选中选项',
                            dataIndex: [
                              ...selectedFilterPrefixNames,
                              'defaultValue',
                            ],
                            renderFormItem: () => (
                              <OptionSelect
                                config={{
                                  ...config,
                                  optionSourceConfig:
                                    option?.optionSourceConfig ?? [],
                                }}
                              />
                            ),
                          },
                        ];
                      }

                      return [];
                    },
                  },
                ])
                .otherwise(() => []);
            },
          },
        ];
      })
      .with({ type: 'cascader', segmented: 'props' }, () => {
        const cascaderConfigName = [
          ...selectedFilterPrefixNames,
          'cascaderConfig',
        ];

        return [
          {
            title: '筛选项名称',
            dataIndex: selectedFilterPrefixNames,
            renderFormItem: () => <Title />,
          },
          {
            title: '组件样式',
            dataIndex: [...selectedFilterPrefixNames, 'filedType'],
            renderFormItem: ItemTypeFormItemRender,
          },
          {
            title: null,
            dataIndex: [...cascaderConfigName, 'multiple'],
            initialValue: false,
            renderFormItem: () => (
              <SettingRadio
                list={[
                  { label: '单选', value: false },
                  { label: '多选', value: true },
                ]}
              />
            ),
          },
          {
            title: '选项集',
            dataIndex: [...cascaderConfigName, 'optionId'],
            formItemProps: {
              noStyle: true,
            },
            renderFormItem: (...v) => (
              <OptionsSelect name={cascaderConfigName} type="jilian" />
            ),
          },
          {
            valueType: 'dependency',
            name: [[...cascaderConfigName, 'optionId']],
            columns: (__v) => {
              const optionId = path<string>(
                [...cascaderConfigName, 'optionId'],
                __v,
              );

              if (optionId) {
                const config = optionMap[optionId];
                if (!config) {
                  return [];
                }
                return [
                  {
                    title: '默认选中选项',
                    dataIndex: [...selectedFilterPrefixNames, 'defaultValue'],
                    renderFormItem: () => <CascaderSelect config={config} />,
                  },
                ];
              }

              return [];
            },
          },
        ];
      })
      .with({ type: 'dateRangePicker', segmented: 'props' }, () => {
        const dateConfigName = [...selectedFilterPrefixNames, 'dateConfig'];
        return [
          {
            title: '筛选项名称',
            dataIndex: selectedFilterPrefixNames,
            renderFormItem: () => <Title />,
          },
          {
            title: '组件样式',
            dataIndex: [...selectedFilterPrefixNames, 'filedType'],
            renderFormItem: ItemTypeFormItemRender,
          },
          {
            title: '数据源',
            renderFormItem: () => (
              <TemplateSelect
                noStyle
                name={[...dateConfigName, 'templateId']}
              />
            ),
          },
          {
            valueType: 'dependency',
            name: [[...dateConfigName, 'templateId']],
            columns: (_v) => {
              const templateId = path<string>(
                [...dateConfigName, 'templateId'],
                _v,
              );

              if (templateId) {
                return [
                  {
                    title: '显示属性',
                    dataIndex: [...dateConfigName, 'propKey'],
                    renderFormItem: () => (
                      <DataPropSelect
                        templateId={templateId}
                        placeholder="请选择"
                      />
                    ),
                  },
                ];
              }
              return [];
            },
          },
          {
            title: '默认日期',
            dataIndex: [...selectedFilterPrefixNames, 'defaultValue'],
            valueType: 'dateRange',
            fieldProps: {
              style: { width: '100%', borderRadius: 8 },
            },
          },
        ];
      })
      .with({ type: 'yearMonthDay', segmented: 'props' }, () => {
        const ymdConfigName = [
          ...selectedFilterPrefixNames,
          'yearMonthDayConfig',
        ];
        return [
          {
            title: '筛选项名称',
            dataIndex: selectedFilterPrefixNames,
            renderFormItem: () => <Title />,
          },
          {
            title: '组件样式',
            dataIndex: [...selectedFilterPrefixNames, 'filedType'],
            renderFormItem: ItemTypeFormItemRender,
          },
          {
            title: null,
            dataIndex: [...ymdConfigName, 'type'],
            valueType: 'select',
            initialValue: 'yearMonthDayAndDatePicker',
            valueEnum: {
              yearMonthDayAndDatePicker: '日月年 - 日期选择',
              yearMonthDay: '日月年',
              datePicker: '日期选择',
            },
          },
          {
            valueType: 'dependency',
            name: [[...ymdConfigName]],
            columns: (_v) => {
              const yearMonthDayConfig = path<IYearMonthDayConfig>(
                [...ymdConfigName],
                _v,
              );

              return [
                {
                  title: '默认选中选项',
                  dataIndex: [...selectedFilterPrefixNames, 'defaultValue'],
                  initialValue: {
                    selectedType: 'date'
                  },
                  renderFormItem: () => (
                    <YearMonthDayPicker
                      config={
                        yearMonthDayConfig?.type
                          ? {
                              type: yearMonthDayConfig.type,
                            }
                          : undefined
                      }
                      enableYearMonthDay
                    />
                  ),
                },
              ];
            },
          },
        ];
      })
      .with({ type: 'yearMonthDay', segmented: 'styles' }, () => {
        const unselectedNames = [
          ...selectedFilterPrefixNames,
          'yearMonthDayConfig',
          'unselected',
        ];

        const selectedNames = [
          ...selectedFilterPrefixNames,
          'yearMonthDayConfig',
          'selected',
        ];

        return [
          {
            title: '筛选项名称',
            dataIndex: selectedFilterPrefixNames,
            renderFormItem: () => <Font />,
          },
          {
            title: null,
            renderFormItem: () => (
              <>
                <ProForm.Item
                  label="未选中"
                  name={unselectedNames}
                  initialValue={DefaultFontValue}
                >
                  <Font
                    sizeRender={() => <span className="flex-1">文字</span>}
                    noSize
                  />
                </ProForm.Item>
                <div className="flex items-center justify-between mb-4">
                  <span>背景颜色</span>
                  <ProForm.Item noStyle name={[...unselectedNames, 'bgColor']}>
                    <ColorPicker />
                  </ProForm.Item>
                </div>
                <div className="flex items-center justify-between mb-4">
                  <span>边框颜色</span>
                  <ProForm.Item
                    noStyle
                    name={[...unselectedNames, 'borderColor']}
                    initialValue="#EBEDF0"
                  >
                    <ColorPicker />
                  </ProForm.Item>
                </div>
              </>
            ),
          },
          {
            title: null,
            renderFormItem: () => (
              <>
                <ProForm.Item
                  label="选中"
                  name={selectedNames}
                  initialValue={{
                    ...DefaultFontValue,
                    color: { r: 255, g: 255, b: 255, a: 1 },
                  }}
                >
                  <Font
                    sizeRender={() => <span className="flex-1">文字</span>}
                    noSize
                  />
                </ProForm.Item>
                <div className="flex items-center justify-between mb-4">
                  <span>背景颜色</span>
                  <ProForm.Item
                    noStyle
                    name={[...selectedNames, 'bgColor']}
                    initialValue="#316EF5"
                  >
                    <ColorPicker />
                  </ProForm.Item>
                </div>
                <div className="flex items-center justify-between mb-4">
                  <span>边框颜色</span>
                  <ProForm.Item
                    noStyle
                    name={[...selectedNames, 'borderColor']}
                    initialValue="#316EF5"
                  >
                    <ColorPicker />
                  </ProForm.Item>
                </div>
              </>
            ),
          },
          {
            title: '尺寸',
            dataIndex: [
              ...selectedFilterPrefixNames,
              'yearMonthDayConfig',
              'size',
            ],
            valueType: 'select',
            valueEnum: {
              large: '大',
              medium: '标准',
              small: '小',
            },
            initialValue: 'medium',
          },
        ];
      })
      // .with({ type: SubmitButtonId }, () => {
      //   const submitNames = ['submitButtonConfig', 'submit'];
      //   const resetNames = ['submitButtonConfig', 'reset'];

      //   return [
      //     {
      //       title: null,
      //       renderFormItem: () => (
      //         <>
      //           <ProForm.Item
      //             label="查询按钮"
      //             name={submitNames}
      //             initialValue={{
      //               ...DefaultFontValue,
      //               color: { r: 255, g: 255, b: 255, a: 1 },
      //             }}
      //           >
      //             <Font title="文字" />
      //           </ProForm.Item>
      //           <div className="flex items-center justify-between mb-4">
      //             <span>背景颜色</span>
      //             <ProForm.Item
      //               noStyle
      //               name={[...submitNames, 'bgColor']}
      //               initialValue="#316EF5"
      //             >
      //               <ColorPicker />
      //             </ProForm.Item>
      //           </div>
      //           <div className="flex items-center justify-between mb-4">
      //             <span>边框颜色</span>
      //             <ProForm.Item
      //               noStyle
      //               name={[...submitNames, 'borderColor']}
      //               initialValue="#316EF5"
      //             >
      //               <ColorPicker />
      //             </ProForm.Item>
      //           </div>
      //         </>
      //       ),
      //     },
      //     {
      //       title: null,
      //       renderFormItem: () => (
      //         <>
      //           <ProForm.Item
      //             label="重置按钮"
      //             name={resetNames}
      //             initialValue={DefaultFontValue}
      //           >
      //             <Font title="文字" />
      //           </ProForm.Item>
      //           <div className="flex items-center justify-between mb-4">
      //             <span>背景颜色</span>
      //             <ProForm.Item
      //               noStyle
      //               name={[...resetNames, 'bgColor']}
      //               initialValue="#FFF"
      //             >
      //               <ColorPicker />
      //             </ProForm.Item>
      //           </div>
      //           <div className="flex items-center justify-between mb-4">
      //             <span>边框颜色</span>
      //             <ProForm.Item
      //               noStyle
      //               name={[...resetNames, 'borderColor']}
      //               initialValue="#EBEDF0"
      //             >
      //               <ColorPicker />
      //             </ProForm.Item>
      //           </div>
      //         </>
      //       ),
      //     },
      //     {
      //       title: '尺寸',
      //       dataIndex: [...selectedFilterPrefixNames, 'size'],
      //       valueType: 'select',
      //       valueEnum: {
      //         large: '大',
      //         medium: '标准',
      //         small: '小',
      //       },
      //       initialValue: 'medium',
      //     },
      //   ];
      // })
      // .with({ type: FoldButtonId, segmented: 'styles' }, () => {
      //   return [
      //     {
      //       title: '文字',
      //       dataIndex: selectedFilterPrefixNames,
      //       renderFormItem: () => <Font title="属性名称" />,
      //     },
      //   ];
      // })
      // .with({ type: FoldButtonId, segmented: 'props' }, () => {
      //   return [
      //     {
      //       title: null,
      //       renderFormItem: () => (
      //         <>
      //           <ProFormText
      //             name={[...selectedFilterPrefixNames, 'foldButtonText']}
      //             initialValue="收起"
      //             label={<span className="text-sm font-bold">收起时文字</span>}
      //           />
      //           <ProFormText
      //             name={[...selectedFilterPrefixNames, 'unfoldButtonText']}
      //             initialValue="更多筛选"
      //             label={<span className="text-sm font-bold">展开时文字</span>}
      //           />
      //           <ProFormSelect
      //             name={[...selectedFilterPrefixNames, 'foldLines']}
      //             initialValue={1}
      //             label={
      //               <span className="text-sm font-bold">收起时显示行数</span>
      //             }
      //             valueEnum={{ 1: '1行', 2: '2行', 3: '3行' }}
      //           />
      //         </>
      //       ),
      //     },
      //   ];
      // })
      .otherwise(() => []);

    return _cols;
  }, [
    selectedFilterSettingType,
    segmentedValue,
    selectedFilterPrefixNames,
    form,
    optionMap,
  ]);

  const segmented = match(selectedFilterSettingType)
    .with(SubmitButtonId, () => false)
    .otherwise(() => true);

  const isFilterItem = match(selectedFilterSettingType)
    .with(SubmitButtonId, FoldButtonId, () => false)
    .otherwise(() => true);

  const handleDelete = () => {
    if (filterList?.length > 1) {
      const _filterList = filterList.filter(
        (item) => item.id !== selectedFilterId,
      );
      form.setFieldValue('conditionList', _filterList);
      setSelectedFilterId(_filterList[0].id);
    }
  };

  return (
    <div
      className={cn('w-80', Styles['wrapper'], {
        hidden: !selectedFilterSettingType,
      })}
    >
      <div
        className={cn(
          'h-12 flex items-center justify-between px-4 bg-[#F7F7F7]',
          Styles['title'],
        )}
      >
        <span className="font-bold text-sm">
          {match(selectedFilterSettingType)
            .with(SubmitButtonId, () => '查询重置')
            .with(FoldButtonId, () => '展开收起')
            .otherwise(() => '筛选项')}
        </span>
        {filterList?.length > 1 && isFilterItem && (
          <Popconfirm title="确认删除该筛选项？" onConfirm={handleDelete}>
            <Button
              type="link"
              danger
              icon={<RegularIcon type="iconshanchu2" />}
            />
          </Popconfirm>
        )}
      </div>
      <div>
        <div className="p-3">
          {segmented && (
            <Segmented
              value={segmentedValue}
              onChange={(v) => setSegmentedValue(v as any)}
              block
              options={[
                {
                  label: '属性设置',
                  value: 'props',
                },
                {
                  label: '样式设置',
                  value: 'styles',
                },
              ]}
              className="mb-6"
            />
          )}
          <BetaSchemaForm<IGlobalConditionFilterConfig>
            layoutType="Embed"
            columns={columns}
            form={form}
          />
          <BetaSchemaForm<IGlobalConditionFilterConfig>
            layoutType="Embed"
            columns={submitButtonCols}
            form={form}
          />
          <BetaSchemaForm<IGlobalConditionFilterConfig>
            layoutType="Embed"
            columns={[...foldPropsCols, ...foldStylesCols]}
            form={form}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterItemSetting;
