import { InputNumber } from 'antd';

type NumberInputValue = [number] | [];

type NumberInputProps = {
  value?: NumberInputValue;
  onChange?: (value: NumberInputValue) => void;
  disabled?: boolean;
  className?: string;
};

export const NumberInput: React.FC<NumberInputProps> = ({
  value: _value,
  onChange,
  disabled,
}) => {
  const value = _value || [];

  return (
    <InputNumber
      className="w-full"
      onChange={(e) => {
        onChange?.([e as any]);
      }}
      placeholder="请输入"
      value={!isNaN(value[0] as any) ? value[0] : ''}
      disabled={disabled}
    />
  );
};
