import { getPiSDK,setuPiSDK as _setupPiSDK } from '@linkpi/sdk';

import { getAuth } from './auth';

export const setupPiSDKAuth = () => {
  const piSDK = getPiSDK();
  const { ad, token } = getAuth();

  if (ad && token) {
    piSDK.setAuthToken({
      ad,
      token,
    });
  }
};

export const setupPiSDK = () => {
  setupPiSDKAuth();
  _setupPiSDK();
};
