import { compose, concat, isNil, uniqBy } from 'ramda';

import { LINKPI_ORG_ID } from '@/consts';
import {
  addSpace,
  chatRead,
  checkInvitationCode,
  queryCurrent,
  updateAvatar,
  updateInfo,
} from '@/services/user';
import { parseOrgIdFromLocation } from '@/utils/parseOrgIdFromLocation';

const LinkPiOrgId = LINKPI_ORG_ID[0];

const UserModel = {
  namespace: 'user',
  state: {
    currentUser: {},
    currentStep: 'info',
    spaceInvite: null,
    latestOrgId: '',
  },
  effects: {
    /**
     *
     * @param {{
     *  payload: {
     *  orgId?: string;
     *  diff?: boolean;
     * }}} action
     */
    *fetchCurrent({ payload }, { call, put, select }) {
      const getDiff = payload?.diff === undefined ? true : payload.diff;

      const queryCurrentParams = isNil(payload?.orgId)
        ? {}
        : { template: [payload.orgId] };

      const curOrgId = payload?.orgId || parseOrgIdFromLocation(location);

      if (curOrgId) {
        yield put({
          type: 'setLatestOrgId',
          payload: curOrgId,
        });
      }

      const response = yield call(queryCurrent, queryCurrentParams);

      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });

      yield put({
        type: 'space/saveCurrentUser',
        payload: response,
      });

      const { spaceList } = yield select((state) => state.workspace);
      const oldSpaceIds = spaceList.map((x) => x.orgId);
      // console.log(response);
      if (response && response?.status !== 'error') {
        const hasLJP =
          response?.organization &&
          response.organization.find((x) => x.orgId === LinkPiOrgId);
        if (hasLJP) {
          yield put({ type: 'workspace/switchMaintenanceMode' });
        }
        // 获取到新增空间id
        const diff = response.organization.find(
          (x) => !~oldSpaceIds.indexOf(x.orgId),
        );
        yield put({
          type: 'workspace/setSpaceList',
          payload: response.organization,
        });

        yield put({ type: 'workspace/fetchCurrentSuccess' });
        return getDiff ? { diff } : response;
      }

      yield put({ type: 'workspace/fetchCurrentSuccess' });
      return {};
    },

    *addSpace({ payload }, { call, put }) {
      const response = yield call(addSpace, payload);
      if (response && response?.status === 'ok') {
        const userResponse = yield call(queryCurrent);
        yield put({
          type: 'saveCurrentUser',
          payload: userResponse,
        });
      }
      return response;
    },

    *chatRead({ payload }, { call }) {
      return yield call(chatRead, payload);
    },

    *invitation({ payload }, { call, put }) {
      const response = yield call(checkInvitationCode, payload);
      if (response && response?.status === 'ok') {
        const userResponse = yield call(queryCurrent);
        yield put({
          type: 'saveCurrentUser',
          payload: userResponse,
        });
      }
      return response;
    },

    *updateInfo({ payload }, { call, put }) {
      const updateResponse = yield call(updateInfo, payload);
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
      yield put({
        type: 'workspace/setSpaceList',
        payload: response.organization,
      });
      return updateResponse;
    },

    *updateAvatar({ payload }, { call, put }) {
      const uploadResponse = yield call(updateAvatar, payload);
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response,
      });
      return uploadResponse;
    },
  },
  reducers: {
    /**
     *
     * @param {{
     *  currentUser: import('@linkpi/core').CurrentUser
     * }} state
     * @param {{
     *  payload: import('@linkpi/core').CurrentUser
     * }} action
     */
    saveCurrentUser(state, { payload }) {
      const { currentUser } = state;
      const oldOrgList = currentUser.organization || [];
      const newOrgList = compose(
        uniqBy((org) => org.orgId),
        concat(payload.organization || []),
      )(oldOrgList);

      return {
        ...state,
        currentUser: {
          ...currentUser,
          ...payload,
          organization: newOrgList,
        },
      };
    },
    updateLayoutProperty(state, { payload: { key, value } }) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          [key]: value,
        },
      };
    },
    resetLayoutProperty(state, { payload }) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload,
        },
      };
    },
    saveCurrentStep(state, { payload }) {
      return { ...state, currentStep: payload };
    },
    saveSpaceInvite(state, { payload }) {
      return { ...state, spaceInvite: payload };
    },
    changeNotifyCount(
      state = {
        currentUser: {},
      },
      action,
    ) {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifyCount: action.payload.totalCount,
          unreadCount: action.payload.unreadCount,
        },
      };
    },
    setLatestOrgId(state, { payload }) {
      return {
        ...state,
        latestOrgId: payload,
      };
    },
  },
};
export default UserModel;
