import type { CascaderProps } from 'antd';
import { Cascader } from 'antd';

import { useProvData } from '@/hook';

const FIELD_NAMES = {
  label: 'name',
  value: 'adcode',
  children: 'districts',
};

type ProvCascaderProps = CascaderProps;

const EMPTY_OPTIONS: any[] = [];

export const ProvCascader = ({
  changeOnSelect = true,
  allowClear = true,
  ...props
}: ProvCascaderProps) => {
  const { provData = EMPTY_OPTIONS } = useProvData();

  return (
    <Cascader
      options={provData}
      fieldNames={FIELD_NAMES}
      {...props}
      changeOnSelect={changeOnSelect}
      allowClear={allowClear}
    />
  );
};
