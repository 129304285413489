import { useSelector } from '@umijs/max';
import { Button, Drawer, Form, message, Modal, Progress, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { RegularIcon } from '@/components';
import { ENV_CONFIG } from '@/consts';
import { getAuth } from '@/utils/auth';
import request from '@/utils/request';

import './index.less';

const { Option } = Select;
let optionUsersMap = {},
  targetPropType = [],
  qrTimer = null;
export const QrExport = (props) => {
  const { currentSelection, spaceUserList } = useSelector(
    (state) => state.workspace,
  );
  const [targetTemplate, setTargetTemplate] = useState(null);
  const [sourceTemplate, setSourceTemplate] = useState(null);
  const [progress, setProgress] = useState(0);
  const [qrUrl, setQrUrl] = useState('');
  const [qrReqId, setQrReqId] = useState('');
  const [loading, setLoading] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [qrHistory, setQrHistory] = useState([]);

  const { templateList, visible, setVisible } = props;
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    targetPropType = [];
    setTargetTemplate(null);
    setSourceTemplate(null);
    setProgress(0);
    setLoading(false);
    setQrUrl('');
    setQrReqId('');
    setHistoryVisible(false);
    setQrHistory([]);
  }, [visible]);

  useEffect(() => {
    const userList = spaceUserList[currentSelection.selectSpace];
    optionUsersMap = userList || {};
  }, [currentSelection.selectSpace, spaceUserList]);

  const handleQrUrl = async (reqId) => {
    clearTimeout(qrTimer);
    if (reqId && !qrUrl) {
      const statusRes = await request('/api/getReqTaskStatus', {
        method: 'POST',
        data: {
          // node_id: currentSelection.selectNode,
          req_id: reqId,
        },
      });
      if (Array.isArray(statusRes?.data) && statusRes.data.length) {
        const data = statusRes.data[0];
        if (!data.url) {
          setProgress(Number.isFinite(data.progress) ? data.progress : 0);
          qrTimer = setTimeout(() => {
            handleQrUrl(reqId);
          }, 2000);
        } else {
          setQrUrl(data.url);
          setProgress(100);
          setLoading(false);
        }
      }
    }
  };

  const handleQrHistory = async () => {
    const statusRes = await request('/api/getReqTaskStatus', {
      method: 'POST',
      data: {
        node_id: currentSelection.selectNode,
      },
    });
    if (Array.isArray(statusRes?.data) && statusRes.data.length) {
      setQrHistory(
        statusRes.data.sort((a, b) => b.create_time - a.create_time),
      );
    }
    setHistoryVisible(true);
  };

  const onFinish = async (values) => {
    const prop_map = {};
    if (Array.isArray(values?.propMap) && values.propMap.length) {
      values.propMap.map((x) => {
        if (!(x.value.toString() in prop_map)) {
          prop_map[x.value.toString()] = x.key;
        }
      });
    } else {
      return;
    }

    const { ad, token } = getAuth();

    const reqData = {
      org_id: currentSelection.selectSpace,
      node_id: currentSelection.selectNode,
      target_temp_id: values.target_temp_id,
      source_temp_id: values.source_temp_id,
      prop_map,
      key: 'Nrd2lkC90cjovmDgxWHVMfiFsGYOn5XT',
      ad: ad,
      token: token,
    };
    setLoading(true);
    const res = await request(ENV_CONFIG.WX_SET_UNLIMITED, {
      method: 'POST',
      data: reqData,
    });
    if (res?.status === 'ok') {
      setQrReqId(res.req_id);
      qrTimer = setTimeout(() => {
        handleQrUrl(res.req_id);
      }, 1000);
    } else {
      message.error(res?.message || '生成二维码失败，请重试');
      setLoading(false);
    }
  };

  const handleExport = async () => {
    if (!qrReqId) {
      form.submit();
    } else if (qrUrl) {
      window.open(qrUrl);
      setVisible();
    }
  };

  const handleSourceTempChange = (e) => {
    setSourceTemplate(templateList.find((x) => x.template_id === e));
    const { propMap } = form.getFieldsValue();
    if (Array.isArray(propMap) && propMap.length) {
      propMap.map((x) => {
        if (x) {
          x.key = null;
        }
      });
      form.setFieldsValue({ propMap });
    }
  };

  const handleTargetTempChange = (e) => {
    setTargetTemplate(templateList.find((x) => x.template_id === e));
    targetPropType = [];
    form.resetFields(['propMap']);
    // form.setFieldsValue({propMap: {}});
  };

  return (
    <Modal
      className="node-import-modal"
      title={'导出二维码'}
      open={visible}
      okButtonProps={{ loading: loading }}
      width={600}
      maskClosable={false}
      footer={null}
      onCancel={() => setVisible()}
    >
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
      >
        <div>
          <div className="export-tips">
            导出的二维码为当前节点下应用了某一模板的子节点的属性值信息，每个节点对应一个二维码。通过微信扫码可以在连接派小程序新增所选模板的节点，并将二维码中的属性填入新增模板的对应属性中
          </div>
          <div className="qr-export">
            <div className="qr-template">
              <div className={'qr-form-label'}>导出节点的模板</div>
              <Form.Item
                name="source_temp_id"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: '请选择模板',
                  },
                ]}
              >
                <Select
                  name="source_temp_id"
                  placeholder="请选择模板"
                  onChange={(e) => handleSourceTempChange(e)}
                  optionLabelProp="label"
                >
                  {templateList.map((template, i) => (
                    <Option
                      key={i}
                      value={template.template_id}
                      label={template.name}
                    >
                      {template.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="qr-template">
              <div className={'qr-form-label'}>新增主题的模板</div>
              <Form.Item
                name="target_temp_id"
                wrapperCol={{ span: 24 }}
                rules={[
                  {
                    required: false,
                    message: '请选择模板',
                  },
                ]}
              >
                <Select
                  name="target_temp_id"
                  placeholder="请选择模板"
                  onChange={(e) => handleTargetTempChange(e)}
                  optionLabelProp="label"
                >
                  {templateList.map((template, i) => (
                    <Option
                      key={i}
                      value={template.template_id}
                      label={template.name}
                    >
                      {template.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className={'qr-prop-map'}>
            <div className={'qr-form-label'}>设置导出属性和引用关系</div>
            <Form.List name="propMap">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <div key={key} className={'form-list-item'}>
                      <Form.Item
                        {...restField}
                        name={[name, 'key']}
                        fieldKey={[fieldKey, 'key']}
                        rules={[{ required: true, message: '请选择导出属性' }]}
                        className={'list-item'}
                      >
                        <Select
                          name="conditionProp"
                          placeholder="导出属性"
                          // disabled={!targetPropType[name]}
                        >
                          {sourceTemplate?.prop?.map(
                            (prop, i) =>
                              prop.name && (
                                <Option
                                  key={i}
                                  value={i}
                                  disabled={
                                    targetPropType[name] &&
                                    prop.type !== targetPropType[name] &&
                                    !(
                                      targetPropType[name] === 'user' &&
                                      prop.type === 'text'
                                    ) &&
                                    !(
                                      targetPropType[name] === 'enum' &&
                                      prop.type === 'text'
                                    )
                                  }
                                >
                                  {prop.name}
                                </Option>
                              ),
                          )}
                        </Select>
                      </Form.Item>
                      <i
                        className={'iconfont item-split iconContent_Correspond'}
                      />
                      <Form.Item
                        {...restField}
                        name={[name, 'value']}
                        fieldKey={[fieldKey, 'value']}
                        rules={[
                          { required: true, message: '请选择新增主题属性' },
                        ]}
                        className={'list-item'}
                      >
                        <Select
                          name="newProp"
                          placeholder="新增主题属性"
                          onChange={(e) => {
                            const prop = targetTemplate?.prop[e];
                            if (
                              prop?.type &&
                              targetPropType[name] !== prop.type
                            ) {
                              targetPropType[name] = prop.type;
                              const { propMap } = form.getFieldsValue();
                              if (propMap[name]) {
                                if (Number.isFinite(propMap[name].key)) {
                                  const keyType =
                                    sourceTemplate?.prop[propMap[name].key]
                                      ?.type;
                                  if (
                                    keyType !== prop.type &&
                                    !(
                                      prop.type === 'user' && keyType === 'text'
                                    ) &&
                                    !(
                                      prop.type === 'enum' && keyType === 'text'
                                    )
                                  ) {
                                    propMap[name].key = null;
                                    form.setFieldsValue({ propMap });
                                  }
                                }
                              }
                            }
                          }}
                        >
                          {targetTemplate?.prop?.map((prop, i) => {
                            if (!!prop && Object.keys(prop)?.length > 0) {
                              return (
                                <Option
                                  key={i}
                                  value={i}
                                  disabled={
                                    !!form
                                      .getFieldValue('propMap')
                                      ?.find((x) => x?.value === i)
                                  }
                                >
                                  {prop.name}
                                </Option>
                              );
                            }
                          })}
                        </Select>
                      </Form.Item>
                      <i
                        className="list-remove-btn iconfont iconclose1"
                        onClick={() => {
                          remove(name);
                          const { propMap } = form.getFieldsValue();
                          targetPropType = [];
                          propMap.map((x, i) => {
                            const prop = targetTemplate?.prop[x.value];
                            targetPropType.push(prop.type);
                          });
                        }}
                      />
                    </div>
                  ))}
                  <Form.Item className={'form-list-add'}>
                    <Button
                      type="default"
                      onClick={() =>
                        !!sourceTemplate && !!targetTemplate && add()
                      }
                      block
                    >
                      <i className={'iconfont iconadd'} />
                      增加导出属性
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </div>
        </div>
      </Form>

      <div className="export-footer">
        <div className="footer-buttons">
          <Button
            className="footer-btn footer-cancel"
            onClick={() => setVisible()}
          >
            取消
          </Button>
          <Button
            loading={loading}
            className="footer-btn footer-ok"
            onClick={() => handleExport()}
          >
            导出
          </Button>
        </div>
        <div className="footer-download">
          <div className="history-btn" onClick={() => handleQrHistory()}>
            导出历史
            <i className="iconfont iconxiala" />{' '}
          </div>
          {!!qrReqId && <Progress percent={progress} />}
        </div>
      </div>

      <Drawer
        title={<span className="history-title">导出历史</span>}
        placement="bottom"
        closable={false}
        onClose={() => setHistoryVisible(false)}
        visible={historyVisible}
        getContainer={false}
        className="qr-history-drawer"
        height={300}
        headerStyle={{ height: 48, padding: '12px 24px' }}
        style={{ display: historyVisible ? 'block' : 'none' }}
      >
        {qrHistory.map((qr) => {
          const zipName = qr.url?.substring(qr.url.lastIndexOf('/') + 1) || '';
          const zipProgress = qr.progress;
          return (
            <div className="history-item">
              <div className="history-item-main">
                <RegularIcon size="30px" type="iconzip" />
                <div className="history-name">
                  {zipName ? (
                    <div className="zip-name">{zipName}</div>
                  ) : (
                    <Progress percent={zipProgress} />
                  )}
                  <div className="history-time">
                    {dayjs(qr.create_time * 1000).format('YY/MM/DD hh:mm')}
                  </div>
                </div>
              </div>
              {!!qr.url && (
                <div
                  className={`history-item-btn`}
                  onClick={() => window.open(qr.url)}
                >
                  <i className="iconfont iconTopic_Download" />
                  重新导出
                </div>
              )}
            </div>
          );
        })}
      </Drawer>
    </Modal>
  );
};
