import ProForm from '@ant-design/pro-form';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { useActiveWidgetInstance } from '@/components/PageModelEditor/src/hooks';
import { cn } from '@/utils/utils';

import { GlobalConditionFilter } from '../../../GlobalConditionFilterWidget/GlobalConditionFilter';
import { DefaultFontValue } from '../../constant';
import { SelectedFilterIdAtom } from '../../store';
import type { IGlobalConditionFilterConfig } from '../../types';

interface FilterListProps {
  className?: string;
  defaultValue?: IGlobalConditionFilterConfig;
}

const FilterList = (props: FilterListProps) => {
  const { className, defaultValue } = props;
  const _widgetInstance =
    useActiveWidgetInstance<IGlobalConditionFilterConfig>();

  const widgetInstance = _widgetInstance ?? defaultValue;

  const [selectedFilterId, setSelectedFilterId] = useAtom(SelectedFilterIdAtom);

  const form = ProForm.useFormInstance();

  const _list = ProForm.useWatch<IGlobalConditionFilterConfig['conditionList']>(
    ['conditionList'],
    form,
  );
  const list = _list ?? [];

  const _submitButtonConfig = ProForm.useWatch<
    IGlobalConditionFilterConfig['submitButtonConfig']
  >(['submitButtonConfig'], form);

  const _arrangement = ProForm.useWatch<
    IGlobalConditionFilterConfig['arrangement']
  >(['arrangement'], form);
  const arrangement = _arrangement ?? 'three';

  const enableSubmitButton = ProForm.useWatch<
    IGlobalConditionFilterConfig['enableSubmitButton']
  >(['enableSubmitButton'], form);

  const enableFold = ProForm.useWatch<
    IGlobalConditionFilterConfig['enableFold']
  >(['enableFold'], form);

  const _foldConfig = ProForm.useWatch<
    IGlobalConditionFilterConfig['foldConfig']
  >(['foldConfig'], form);
  const foldConfig = _foldConfig ?? {
    unfoldButtonText: '更多筛选',
    foldButtonText: '收起',
    foldLines: 1,
    ...DefaultFontValue,
    color: {
      r: 49,
      g: 110,
      b: 245,
      a: 1,
    },
  };

  const {
    reset = { ...DefaultFontValue, bgColor: '#FFF', borderColor: '#EBEDF0' },
    submit = {
      ...DefaultFontValue,
      bgColor: '#316EF5',
      borderColor: '#316EF5',
      color: { r: 255, g: 255, b: 255, a: 1 },
    },
    size: buttonSize,
  } = _submitButtonConfig ?? {};

  const GlobalConditionFilterValue = useMemo(
    () =>
      list.reduce<Record<string, any>>((result, item) => {
        match(item)
          .with({ filedType: 'yearMonthDay' }, () => {
            result[item.id] = {
              id: item.id,
              propKey: item.propKey!,
              filedType: item.filedType,
              value: {
                selected: undefined,
                selectedType: 'date',
                // selectedType: item.defaultValue?.selectedType ?? 'date',
              },
            };
          })
          .otherwise(() => null);

        return result;
      }, {}),
    [list],
  );

  const GlobalConditionFilterKey = Object.keys(GlobalConditionFilterValue).join(
    ',',
  );

  return (
    <div className={cn('flex flex-wrap gap-y-2', className)}>
      <div className="flex flex-wrap gap-y-2 w-full h-full">
        <GlobalConditionFilter
          className="w-full"
          key={GlobalConditionFilterKey}
          readonly
          value={GlobalConditionFilterValue}
          widgetInstance={
            {
              ...widgetInstance,
              config: {
                version: 3,
                conditionList: list,
                enableSubmitButton,
                arrangement,
                submitButtonConfig: _submitButtonConfig,
                enableFold,
                foldConfig,
              },
            } as any
          }
          selected={selectedFilterId}
          onSelected={setSelectedFilterId}
        />
        {/* {list.map((item) => {
          return (
            <ItemWrapper
              key={item.id}
              arrangement={arrangement}
              selected={selectedFilterId === item.id}
              onClick={() => {
                setSelectedFilterId(item.id);
              }}
            >
              <FilterItem
                className={cn('pointer-events-none w-full')}
                data={item}
              />
            </ItemWrapper>
          );
        })} */}
        {/* {enableSubmitButton && (
          <ItemWrapper
            key="submitButton"
            arrangement={arrangement}
            selected={selectedFilterId === SubmitButtonId}
            onClick={() => {
              setSelectedFilterId(SubmitButtonId);
            }}
          >
            <div className="w-full h-full flex items-center gap-3 px-3 pointer-events-none py-2">
              <Button
                size={buttonSize}
                style={{
                  fontSize: reset.fontSize,
                  color: reset.color
                    ? `rgba(${reset.color.r}, ${reset.color.g}, ${reset.color.b}, ${reset.color.a})`
                    : undefined,
                  fontWeight: reset.bold ? 'bold' : 'normal',
                  fontStyle: reset.italic ? 'italic' : 'normal',
                  textDecoration: reset.underline ? 'underline' : 'none',
                  backgroundColor: reset.bgColor,
                  borderColor: reset.borderColor,
                }}
              >
                <span
                  style={{
                    textDecoration: reset.underline ? 'underline' : 'none',
                  }}
                >
                  查询
                </span>
              </Button>
              <Button
                size={buttonSize}
                style={{
                  fontSize: submit.fontSize,
                  color: submit.color
                    ? `rgba(${submit.color.r}, ${submit.color.g}, ${submit.color.b}, ${submit.color.a})`
                    : undefined,
                  fontWeight: submit.bold ? 'bold' : 'normal',
                  fontStyle: submit.italic ? 'italic' : 'normal',
                  textDecoration: submit.underline ? 'underline' : 'none',
                  backgroundColor: submit.bgColor,
                  borderColor: submit.borderColor,
                }}
              >
                <span
                  style={{
                    textDecoration: submit.underline ? 'underline' : 'none',
                  }}
                >
                  查询
                </span>
              </Button>
            </div>
          </ItemWrapper>
        )} */}
      </div>
      {/* <div className="flex flex-wrap gap-y-2 w-full h-full">
        {enableFold && (
          <ItemWrapper
            key="submitButton"
            arrangement={arrangement}
            selected={selectedFilterId === FoldButtonId}
            onClick={() => {
              setSelectedFilterId(FoldButtonId);
            }}
          >
            <div className="w-full h-12 flex items-center gap-3 px-3 pointer-events-none">
              <div
                className="text-sm text-[#316EF5] flex items-center"
                style={{
                  fontSize: foldConfig.fontSize,
                  color: foldConfig.color
                    ? `rgba(${foldConfig.color.r}, ${foldConfig.color.g}, ${foldConfig.color.b}, ${foldConfig.color.a})`
                    : undefined,
                  fontWeight: foldConfig.bold ? 'bold' : 'normal',
                  fontStyle: foldConfig.italic ? 'italic' : 'normal',
                  textDecoration: foldConfig.underline ? 'underline' : 'none',
                }}
              >
                <span className="mr-1">{foldConfig.foldButtonText}</span>
                <RegularIcon type="iconzhankai2" rotate={180} />
              </div>
            </div>
          </ItemWrapper>
        )}
      </div> */}
    </div>
  );
};

export default FilterList;
