import {
  CheckOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { convertPropDefaultValueToPropDefaultFormula } from '@linkpi/core';
import {
  Button,
  Divider,
  Input,
  Popover,
  Select,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { defaultTo, isNil } from 'ramda';
import type { FC } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { ColorPicker, RegularIcon } from '@/components';
import { cn } from '@/utils/utils';

import { useTempPropConfig } from '../hooks';

const BatchEdit: FC<{ value: string[]; onChange: (v: string[]) => void }> = ({
  value,
  onChange,
}) => {
  const [_value, setValue] = useState<string>(value?.join('\n') || '');

  useEffect(() => {
    setValue(value?.join('\n') || '');
  }, [value]);

  return (
    <Popover
      trigger={'click'}
      onOpenChange={(open) => {
        if (!open) {
          const list = _value.replace('\r', '\n').split('\n');
          onChange([...new Set(list.filter((item) => !!item.trim()))]);
        }
      }}
      destroyTooltipOnHide
      content={
        <div className="flex flex-col gap-2">
          <span className="text-[#767C88]">请通过换行来添加选项</span>
          <Input.TextArea
            style={{ height: 343, width: 240 }}
            draggable={false}
            value={_value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </div>
      }
    >
      <span className="inline-flex items-center gap-2">
        <RegularIcon type="iconshaixuanqu-fenzu" />
        <span>批量编辑选项</span>
      </span>
    </Popover>
  );
};

const DEFAULT_COLOR = [
  '#5ABCB0',
  '#FDA84A',
  '#316EF5',
  '#F0665E',
  '#9F85F0',
  '#F680BD',
  '#76E1AD',
  '#FFBE82',
  '#93C8FF',
  '#FF9C9C',
  '#ACACFA',
  '#FF9FCC',
];

const ExtendColorPicker: FC<{
  value: string;
  onChange: (v: string) => void;
}> = (props) => {
  const { value, onChange } = props;

  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const isDefaultColor = DEFAULT_COLOR.includes(value);

  const content = (
    <div className="w-[156px]">
      <p className="mb-3">预设颜色</p>
      <div className="flex flex-wrap">
        {DEFAULT_COLOR.map((backgroundColor, index) => (
          <span
            key={backgroundColor}
            className={cn(
              'flex w-4 h-4 rounded-full items-center justify-center mr-3 mb-3 cursor-pointer',
              {
                ['mr-0']: index % 6 === 5,
              },
            )}
            style={{ backgroundColor }}
            onClick={() => onChange(backgroundColor)}
          >
            {value === backgroundColor && (
              <CheckOutlined className="text-white" style={{ fontSize: 8 }} />
            )}
          </span>
        ))}
      </div>
      <Divider style={{ margin: '4px 0 16px' }} />
      <p className="mb-3">自定义颜色</p>
      <div>
        <ColorPicker
          value={isDefaultColor ? undefined : value}
          onChange={(v) => onChange(v.toString())}
          itemRender={(backgroundColor) => (
            <span
              className="flex w-4 h-4 rounded-full items-center justify-center border border-solid border-[#E5E7EA]"
              style={{ backgroundColor }}
            />
          )}
        />
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
    >
      <span
        className="flex w-4 h-4 rounded-full cursor-pointer"
        style={{ backgroundColor: value }}
      />
    </Popover>
  );
};

export const EnumType: FC = () => {
  const {
    propParams: _propParams,
    setPropParams,
    savePropParams,
    isLock,
  } = useTempPropConfig();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputValue, setEditInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const editInput = useRef(null),
    input = useRef(null);

  const propParams = useMemo(() => {
    return {
      ..._propParams,
      extend: defaultTo([])(_propParams.extend),
    };
  }, [_propParams]);

  const handleInputConfirm = () => {
    if (inputValue && propParams.extend.indexOf(inputValue) === -1) {
      setPropParams({
        ...propParams,
        extend: [...propParams.extend, inputValue],
      });
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const showInput = () => {
    if (isLock) return;
    setEditInputIndex(-1);
    setEditInputValue('');
    setInputVisible(true);
    // @ts-ignore
    input?.current && input.current.focus();
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    if (editInputValue && propParams.extend.indexOf(editInputValue) === -1) {
      const newTags = [...propParams.extend];
      newTags[editInputIndex] = editInputValue;
      setPropParams({ ...propParams, extend: newTags });
      if (
        propParams.defaultValue &&
        !newTags.includes(propParams.defaultValue)
      ) {
        setPropParams({ ...propParams, defaultValue: undefined });
      }
    }
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const handleClose = (removedTag: string) => {
    const newTags = propParams.extend.filter(
      (tag: string) => tag !== removedTag,
    );
    setPropParams({ ...propParams, extend: newTags });
  };

  const defaultValue = useMemo(() => {
    if (isNil(propParams.defaultFormula)) {
      if (propParams.defaultValue) return propParams.defaultValue;
      return undefined;
    }

    const { defaultFormula } = propParams;
    if (defaultFormula.type === 'const') {
      return defaultFormula.const as string;
    }
    return undefined;
  }, [propParams.defaultFormula]);

  return (
    <>
      <div className={'prop-item'} key={'extend'}>
        <div className={'prop-item-label'}>
          <span className="flex justify-between">
            选项设置
            <span className="flex items-center">
              <span className="mr-1">选项颜色</span>
              <Switch
                size="small"
                checked={propParams.extendColorMode}
                onChange={(checked) =>
                  setPropParams({
                    ...propParams,
                    extendColorMode: checked,
                    extendColor: checked
                      ? ((propParams.extend as string[])?.map(
                          (_, index) =>
                            DEFAULT_COLOR[index % DEFAULT_COLOR.length],
                        ) ?? [])
                      : [],
                  })
                }
              />
            </span>
          </span>
        </div>
        {propParams.extendColorMode ? (
          <>
            <div className="flex items-center flex-wrap">
              {propParams.extend
                .filter((x: string | number) => typeof x === 'string')
                .map((tag: string, index: number) => {
                  return (
                    <Input
                      key={index}
                      className={cn('mb-3 mr-[14px]', {
                        'mr-0': index % 3 === 2,
                      })}
                      style={{ width: 'calc(33% - 9px)' }}
                      placeholder="请输入选项"
                      prefix={
                        <ExtendColorPicker
                          value={propParams.extendColor![index]}
                          onChange={(color) =>
                            setPropParams({
                              ...propParams,
                              extendColor: propParams.extendColor!.map(
                                (t, i) => (i === index ? color : t),
                              ),
                            })
                          }
                        />
                      }
                      addonAfter={
                        <MinusCircleOutlined
                          className="cursor-pointer"
                          onClick={() =>
                            setPropParams({
                              ...propParams,
                              extend: propParams.extend!.filter(
                                (t: string, i: number) => i !== index,
                              ),
                            })
                          }
                        />
                      }
                      // suffix={<MinusCircleOutlined />}
                      value={tag}
                      onChange={(e) =>
                        setPropParams({
                          ...propParams,
                          extend: propParams.extend!.map(
                            (t: string, i: number) =>
                              i === index ? e.target.value : t,
                          ),
                        })
                      }
                    />
                  );
                })}
            </div>
            <div className="flex items-center">
              <Button
                className="site-tag-plus text-[#316ef5] bg-[#f7f9fb] mr-[10px]"
                style={{
                  color: '#316ef5',
                  backgroundColor: '#f7f9fb',
                  marginRight: 10,
                  border: 0,
                  borderRadius: 8,
                }}
                onClick={() => {
                  const extend = [...(propParams.extend ?? []), ''];
                  const extendColor =
                    extend.map(
                      (_, index) => DEFAULT_COLOR[index % DEFAULT_COLOR.length],
                    ) ?? [];
                  setPropParams({ ...propParams, extend, extendColor });
                }}
                icon={<PlusOutlined />}
              >
                添加选项
              </Button>
              <BatchEdit
                value={propParams.extend
                  .filter((x: string | number) => !!x && typeof x === 'string')
                  .map((t: string) => t)}
                onChange={(v) => {
                  setPropParams({ ...propParams, extend: v });
                }}
              />
            </div>
          </>
        ) : (
          <>
            <div className={'prop-item-input'}>
              {propParams.extend
                .filter((x: string | number) => !!x && typeof x === 'string')
                .map((tag: string, index: number) => {
                  if (editInputIndex === index) {
                    return (
                      <Input
                        ref={editInput}
                        key={[tag, index].join('@')}
                        size="small"
                        className="tag-input"
                        value={editInputValue}
                        onChange={handleEditInputChange}
                        onBlur={handleEditInputConfirm}
                        onPressEnter={handleEditInputConfirm}
                      />
                    );
                  }
                  const isLongTag = tag.length > 20;
                  const tagElem = (
                    <Tag
                      className="edit-tag"
                      key={[tag, index].join('@')}
                      closable={!isLock}
                      onClose={() => handleClose(tag)}
                    >
                      <span
                        onDoubleClick={(e) => {
                          if (isLock) return;
                          setEditInputIndex(index);
                          setEditInputValue(tag);
                          // @ts-ignore
                          editInput?.current && editInput.current.focus();
                          e.preventDefault();
                        }}
                      >
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                      </span>
                    </Tag>
                  );
                  return isLongTag ? (
                    <Tooltip title={tag} key={[tag, index].join('@')}>
                      {tagElem}
                    </Tooltip>
                  ) : (
                    tagElem
                  );
                })}
              {inputVisible && (
                <Input
                  ref={input}
                  type="text"
                  size="small"
                  className="tag-input"
                  value={inputValue}
                  onChange={handleInputChange}
                  onBlur={handleInputConfirm}
                  onPressEnter={handleInputConfirm}
                />
              )}
            </div>
            <div className="flex items-center">
              {!inputVisible && (
                <Button
                  className="site-tag-plus text-[#316ef5] bg-[#f7f9fb] mr-[10px]"
                  style={{
                    color: '#316ef5',
                    backgroundColor: '#f7f9fb',
                    marginRight: 10,
                    border: 0,
                    borderRadius: 8,
                  }}
                  onClick={showInput}
                  icon={<PlusOutlined />}
                >
                  添加选项
                </Button>
              )}
              <BatchEdit
                value={propParams.extend
                  .filter((x: string | number) => !!x && typeof x === 'string')
                  .map((t: string) => t)}
                onChange={(v) => {
                  setPropParams({ ...propParams, extend: v });
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className={'prop-item'} key={'defaultValue'}>
        <div className={'prop-item-label'}>默认值</div>
        <div className={'prop-item-input'}>
          <Select
            placeholder={'请选择'}
            value={defaultValue}
            options={propParams.extend?.map((x: string) => {
              return {
                label: x,
                value: x,
              };
            })}
            onChange={(e) => {
              if (isNil(e)) {
                savePropParams(null, 'defaultFormula');
                savePropParams(null, 'defaultValue');
                return;
              }
              const value = convertPropDefaultValueToPropDefaultFormula(e);
              savePropParams(value, 'defaultFormula');
            }}
            allowClear={true}
          />
        </div>
      </div>
    </>
  );
};
