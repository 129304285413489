import {
  Button,
  Divider,
  Form,
  Input,
  Popconfirm,
  Segmented,
  Select,
} from 'antd';
import { useMemo, useState } from 'react';

import { GradientColorPicker } from '@/components/GradientColorPicker';
import { RegularIcon } from '@/components/IconFont';

import { ColorInput } from '../ColorInput';
import {
  DataSourceSettingCore,
  GlobalConditionFilterLink,
} from '../DataSourceSetting';
import { FontStyle } from '../FontStyle';
import { SelectInput } from '../SelectInput';
//
import type { IDataIndicatorWidget } from '.';
import { IndexIconForm } from './IndexIconForm';
import { SubitemsForm } from './SubitemsForm';

import styles from './SettingPanel.less';

export const SettingPanel = (props: {
  editIndexId: string;
  onEditIndexIdChange: (newId: string) => void;
  onCancal?: () => void;
}) => {
  const { editIndexId, onCancal, onEditIndexIdChange } = props;
  const form = Form.useFormInstance<IDataIndicatorWidget>();
  const indexes = Form.useWatch('indexes', form);
  const indexSort = Form.useWatch('indexSort', form);

  const [settingTab, setSettingTab] = useState<'propSet' | 'styleSet'>(
    'propSet',
  );

  const editIndexData = useMemo(() => {
    if (!indexes || !editIndexId || !indexes[editIndexId]) return null;
    return indexes[editIndexId];
  }, [indexes, editIndexId]);

  const dataSourceTempId: string | null = useMemo(() => {
    if (!editIndexData || !editIndexData.dataSource) return null;
    const tempIdCondition = editIndexData.dataSource.conditions.find(
      (x: any) => x.key === 'templateId',
    );
    return tempIdCondition?.input?.[0] || null;
  }, [editIndexData]);

  const onConditionFilterLinkClick = () => {
    if (onCancal) {
      onCancal();
    }
  };

  const onDeleteIndex = () => {
    const newIndexes = JSON.parse(JSON.stringify(indexes));
    delete newIndexes[editIndexId];
    const newIndexSort = indexSort.filter((x) => x !== editIndexId);

    form.resetFields(['indexes']);

    form.setFieldsValue({
      indexSort: newIndexSort,
      indexes: newIndexes,
    });

    onEditIndexIdChange(newIndexSort[0]);
  };

  const showDelete = indexes && Object.keys(indexes).length > 1;
  console.log('indexes', indexes);
  if (!editIndexData) return null;

  return (
    <div className={styles.setting}>
      <div className={styles.head}>
        指标
        {showDelete ? (
          <Popconfirm title="确认删除该指标？" onConfirm={onDeleteIndex}>
            <Button
              type="link"
              danger
              icon={<RegularIcon type="iconshanchu2"></RegularIcon>}
            ></Button>
          </Popconfirm>
        ) : null}
      </div>
      <div className={styles.content}>
        <Segmented
          className={styles.tab}
          block
          options={[
            { label: '属性设置', value: 'propSet' },
            { label: '样式设置', value: 'styleSet' },
          ]}
          value={settingTab}
          onChange={(e: any) => setSettingTab(e)}
        />
        <Form form={form} layout="vertical">
          <Form.Item hidden={settingTab !== 'propSet'}>
            <Form.Item label="组件名称" name={['indexes', editIndexId, 'name']}>
              <Input />
            </Form.Item>

            <Divider dashed style={{}} />

            <Form.Item
              label={
                <span className="flex items-center justify-between w-full">
                  数据源
                  {editIndexId ? (
                    <GlobalConditionFilterLink
                      id={editIndexId}
                      onShowBtnClick={onConditionFilterLinkClick}
                    />
                  ) : null}
                </span>
              }
              name={['indexes', editIndexId, 'dataSource']}
            >
              {/* @ts-ignore */}
              <DataSourceSettingCore />
            </Form.Item>

            <Form.Item
              label="指标项"
              name={['indexes', editIndexId, 'subitems']}
            >
              {/* @ts-ignore */}
              <SubitemsForm dataSourceTempId={dataSourceTempId} />
            </Form.Item>

            <Form.Item label="指标图标">
              <div
                className={styles.switchIndexIcon}
                onClick={() => {
                  form.setFieldValue(
                    ['indexes', editIndexId, 'showIcon'],
                    !editIndexData.showIcon,
                  );
                }}
              >
                <RegularIcon
                  color="#316EF5"
                  type={editIndexData.showIcon ? 'iconshanchu3' : 'icontianjia'}
                />
              </div>
              <Form.Item hidden={!editIndexData.showIcon}>
                <div className={styles.indexIconForm}>
                  <Form.Item noStyle name={['indexes', editIndexId, 'iconSrc']}>
                    {/* @ts-ignore */}
                    <IndexIconForm />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    name={['indexes', editIndexId, 'iconPlacement']}
                  >
                    <Select
                      options={[
                        { label: '图片居左', value: 'left' },
                        { label: '图片居右', value: 'right' },
                      ]}
                    />
                  </Form.Item>
                </div>
              </Form.Item>
            </Form.Item>
          </Form.Item>

          {/* 样式 */}
          <Form.Item hidden={settingTab !== 'styleSet'}>
            <Form.Item label="文字">
              <Form.Item>
                <div className={styles.subLabel}>属性名称</div>
                <div className="flex justify-between">
                  <Form.Item
                    noStyle
                    name={[
                      'indexes',
                      editIndexId,
                      'styles',
                      'propName',
                      'fontSize',
                    ]}
                  >
                    <SelectInput style={{ height: 32, width: 144 }} />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name={[
                      'indexes',
                      editIndexId,
                      'styles',
                      'propName',
                      'color',
                    ]}
                  >
                    <ColorInput type="A" />
                  </Form.Item>

                  <FontStyle
                    style={{ height: 32 }}
                    value={editIndexData.styles.propName}
                    onChange={(e) => {
                      form.setFieldValue(
                        ['indexes', editIndexId, 'styles', 'propName'],
                        {
                          ...editIndexData.styles.propName,
                          ...e,
                        },
                      );
                    }}
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <div className={styles.subLabel}>属性值</div>
                <div className="flex justify-between">
                  <Form.Item
                    noStyle
                    name={[
                      'indexes',
                      editIndexId,
                      'styles',
                      'propValue',
                      'fontSize',
                    ]}
                  >
                    <SelectInput style={{ height: 32, width: 144 }} />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name={[
                      'indexes',
                      editIndexId,
                      'styles',
                      'propValue',
                      'color',
                    ]}
                  >
                    <ColorInput type="A" />
                  </Form.Item>

                  <FontStyle
                    style={{ height: 32 }}
                    value={editIndexData.styles.propValue}
                    onChange={(e) => {
                      form.setFieldValue(
                        ['indexes', editIndexId, 'styles', 'propValue'],
                        {
                          ...editIndexData.styles.propValue,
                          ...e,
                        },
                      );
                    }}
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <div className={styles.subLabel}>统计属性</div>
                <div className="flex justify-between">
                  <Form.Item
                    noStyle
                    name={[
                      'indexes',
                      editIndexId,
                      'styles',
                      'statisticalProp',
                      'fontSize',
                    ]}
                  >
                    <SelectInput style={{ height: 32, width: 144 }} />
                  </Form.Item>
                  <Form.Item
                    noStyle
                    name={[
                      'indexes',
                      editIndexId,
                      'styles',
                      'statisticalProp',
                      'color',
                    ]}
                  >
                    <ColorInput type="A" />
                  </Form.Item>

                  <FontStyle
                    style={{ height: 32 }}
                    value={editIndexData.styles.statisticalProp}
                    onChange={(e) => {
                      form.setFieldValue(
                        ['indexes', editIndexId, 'styles', 'statisticalProp'],
                        {
                          ...editIndexData.styles.statisticalProp,
                          ...e,
                        },
                      );
                    }}
                  />
                </div>
              </Form.Item>
            </Form.Item>

            <Form.Item
              label="间距"
              name={['indexes', editIndexId, 'styles', 'spacingType']}
            >
              <Select
                style={{ width: 144 }}
                options={[
                  { label: '标准', value: 'normal' },
                  { label: '小', value: 'small' },
                ]}
              />
            </Form.Item>

            <Form.Item label="背景">
              <div className="flex items-center">
                <div className={styles.subLabel} style={{ width: 109 }}>
                  背景颜色
                </div>
                <Form.Item
                  name={['indexes', editIndexId, 'styles', 'background']}
                >
                  <GradientColorPicker />
                </Form.Item>
              </div>
            </Form.Item>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
