import { getPiSDK } from '@linkpi/sdk';
import { getWidgetUtilsContext } from '@mylinkpi/widget-react';
import { omit, pick } from 'ramda';
import {
  type ContextType,
  type FC,
  type PropsWithChildren,
  useMemo,
} from 'react';

import {
  useCurrentNodeContext,
  useCurrentOrgId,
  useCurrentUser as _useCurrentUser,
  useJumpNode,
  useNodeContentModalClose,
  useNodeTreeData,
  useOrgTempInfo,
  useTemplateList as _useTemplateList,
  useUrlQuerys,
} from '@/hook';
import {
  useAllGlobalConditionFilterValue,
  useGlobalConditionFilterValue,
} from '@/hook/useGlobalConditionFilterValue';
import {
  useGlobalConditions,
  useSetExtraGlobalConditions,
} from '@/hook/useGlobalConditions';

import { PublicViewSelect, TemplatePureSelect } from '../LinkPiForm';
import {
  DataSourceSettingWidgetSetting,
  GlobalConditionFilterLink,
  GlobalFilterItemCascader,
  useWidgetInstanceList,
  useWidgetInstancesByWidgetId,
} from '../PageModelEditor';
import { useWidgetInstanceListByGroupId } from '../PageModelEditor/src/hooks';
import { NodeViewComponent } from '../ViewComponent';

const _context = getWidgetUtilsContext();
type UtilsContextType = ContextType<typeof _context>;

const useCurrentUser = () => {
  const currentUser = _useCurrentUser();

  const result = useMemo(
    () => omit(['organization'], currentUser),
    [currentUser],
  );

  return result;
};

const useTemplateList = () => {
  const list = _useTemplateList();

  const result = useMemo(
    () => list.map((item) => pick(['name', 'status', 'template_id'], item)),
    [list],
  );

  return result;
};

const useTemplateInfo = (templateId: string, orgId: string) => {
  return useOrgTempInfo(orgId, templateId);
};

const useCurrentNode = () => {
  return useCurrentNodeContext().node!;
};

type NodeTreeData = {
  value: string;
  title: string;
  children: NodeTreeData[];
  disabled?: boolean;
};

const value: UtilsContextType = {
  useUrlQuerys,
  useCurrentOrgId: useCurrentOrgId,
  useCurrentUser,
  useTemplateInfo: useTemplateInfo as any,
  useTemplateList,
  piSDK: getPiSDK() as any,
  useCurrentNode,
  useNodeTreeData: useNodeTreeData as () => NodeTreeData[],
  useJumpNode,
  useNodeContentModalClose,
  NodeViewComponent,
  useWidgetInstancesByWidgetId: useWidgetInstancesByWidgetId as any,
  useWidgetInstanceList: useWidgetInstanceList as any,
  useWidgetInstanceListByGroupId: useWidgetInstanceListByGroupId as any,
  DataSourceSettingWidgetSetting,
  GlobalConditionFilterLink: GlobalConditionFilterLink as any,
  useGlobalConditions,
  useSetExtraGlobalConditions,
  GlobalConditionFilterItemSelect: GlobalFilterItemCascader,
  useAllGlobalConditionFilterValue,
  useGlobalConditionFilterValue,
  ViewSelect: PublicViewSelect as any,
  TemplateSelect: TemplatePureSelect as any,
};

export const CustomWidgeUtilsProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const WidgetUtilsConetext = getWidgetUtilsContext();

  return (
    <WidgetUtilsConetext.Provider value={value}>
      {children}
    </WidgetUtilsConetext.Provider>
  );
};
