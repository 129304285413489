import CascadeSelect from '@/components/CascadeSelect';
import type { TemplateMapUsage } from '@/hook';
import { useCurrentOrgId, useTemplateInfo } from '@/hook';
import { cn } from '@/utils/utils';

import { useConditionItemInnerConfig } from '../../../hooks/useConditionItemInnerConfig';
import type { Condition } from '../../../types';

type CascadeEditorProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  className?: string;
  config: Pick<Condition, 'key' | 'index'>;
  multiple?: boolean;
  templateId: string;
  templateUsage?: TemplateMapUsage;
};

export const CascadeEditor: React.FC<CascadeEditorProps> = ({
  value: _value,
  onChange,
  disabled,
  config,
  multiple,
  templateId,
  templateUsage,
  className,
}) => {
  const orgId = useCurrentOrgId();
  const conditionConfig = useConditionItemInnerConfig({
    templateId,
    templateUsage,
    key: config.key,
    index: config.index,
  });
  const tempInfo = useTemplateInfo(templateId);

  const value = _value || [];

  return (
    <CascadeSelect
      className={cn('condition-filter-v2-cascade-select', className)}
      value={value}
      disabled={disabled}
      handleChange={(e) => {
        onChange?.(e);
      }}
      placeholder="请选择"
      changeOnSelect
      prop={conditionConfig!.data!}
      orgId={orgId}
      propIndex={0}
      multiple={multiple ?? !!conditionConfig?.data?.multiple}
      template={tempInfo}
    />
  );
};
