import type { CurrentUser, ViewList } from '@linkpi/core';
import type { FC } from 'react';

import { RegularIcon } from '@/components/IconFont';
import {
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/components/ui/dropdown-menu';

import { GlobalConditionFilterItemSelect } from './GlobalConditionFilterItemSelect';

interface GlobalConditionFilterMenuProps {
  propType: CurrentUser.propType;
  op: ViewList.ViewconditionV2Item['op'];
  onChange: (value: any) => void;
}

export const GlobalConditionFilterMenu: FC<GlobalConditionFilterMenuProps> = ({
  propType,
  op,
  onChange,
}) => {
  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>
        <RegularIcon type="iconguolvshaixuan" color="#242D3F" />
        筛选组件
      </DropdownMenuSubTrigger>
      <DropdownMenuPortal>
        <DropdownMenuSubContent className="z-[1000] min-w-[180px] max-w-[280px] pi-light-theme">
          <GlobalConditionFilterItemSelect
            propType={propType}
            op={op}
            onChange={(v) => {
              onChange({
                ...v,
              });
            }}
          />
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
