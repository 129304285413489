/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { useToggle } from '@react-hookz/web';
import { Modal, Segmented, Spin, Tooltip } from 'antd';
import { Braces, ListTree } from 'lucide-react';
import { Suspense } from 'react';
import type { JsonObject } from 'type-fest';

import { ScrollArea } from '../ui/scroll-area';
import { PureViewer } from './PureViewer';
import { Viewer } from './Viewer';

import styles from './Modal.module.less';

export type JsonViewerModalProps = {
  data: JsonObject;
  title?: string;
};

const viewerOptions = [
  {
    label: (
      <span className="flex items-center h-[28px]">
        <Tooltip title="JSON 树">
          <ListTree size={14} />
        </Tooltip>
      </span>
    ),
    value: 'json',
  },
  {
    label: (
      <span className="flex items-center h-[28px]">
        <Tooltip title="纯 JSON">
          <Braces size={14} />
        </Tooltip>
      </span>
    ),
    value: 'pure',
  },
];

const JsonViewerModal = ({ data, ...rest }: JsonViewerModalProps) => {
  const modal = useModal();
  const modalProps = antdModalV5(modal);
  const [isPure, togglePure] = useToggle(false);

  return (
    <Modal
      footer={null}
      {...modalProps}
      {...rest}
      title={
        <span className="flex gap-2 items-center">
          <Segmented
            size="small"
            options={viewerOptions}
            value={isPure ? 'pure' : 'json'}
            onChange={(v) => togglePure(v === 'pure')}
          />
          {rest.title || 'JSON 查看器'}
        </span>
      }
      centered
      bodyStyle={{ padding: 0 }}
      width={720}
    >
      <div className={styles.jsonViewer}>
        <ScrollArea className="h-[500px]">
          <Suspense fallback={<Spin />}>
            {isPure ? <PureViewer data={data} /> : <Viewer data={data} />}
          </Suspense>
        </ScrollArea>
      </div>
    </Modal>
  );
};

const JsonViewerNiceModal = create(JsonViewerModal);

export const viewJSON = (
  data: JsonObject,
  options?: Omit<JsonViewerModalProps, 'data'>,
) => {
  return show(JsonViewerNiceModal, { data, ...options });
};
