import { assertExists } from '@linkpi/utils';
import { Form, Input, Switch } from 'antd';

import { RegularIcon } from '@/components/IconFont';
import { CustomUpload } from '@/components/PublishTemplateModal/config';
import { cn } from '@/utils/utils';

import type { RGBAColor } from '../../components';
import { ColorInput, FontAlign, SelectInput } from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetSetting } from '../../models/WidgetManager';

import Styles from './index.less';

const { TextArea } = Input;

export type ILineTextConfig = {
  value?: string;
  icon?: string;
  fontSize: number;
  fontBold?: boolean;
  fontAlign?: 'left' | 'center' | 'right';
  color: RGBAColor;
  backgroundColor: RGBAColor;
  useIcon: boolean;
  useBgColor: boolean;
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ILineTextConfig>();
  assertExists(widgetInstance);

  const setLineTextValue = (value: Partial<ILineTextConfig>) => {
    editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
      ...widgetInstance?.config,
      ...value,
    });
  };

  return (
    <Form layout="vertical">
      <Form.Item label="文本内容">
        <TextArea
          placeholder="请输入..."
          autoSize={{ minRows: 3 }}
          defaultValue={widgetInstance.config.value}
          // value={widgetInstance.config.value}
          onChange={(e) => setLineTextValue({ value: e.target.value })}
        />
      </Form.Item>
      <Form.Item label="文字样式">
        <div className="flex justify-between">
          <SelectInput
            value={widgetInstance.config.fontSize}
            onChange={(fontSize) => setLineTextValue({ fontSize })}
          />
          <FontAlign
            value={{
              bold: widgetInstance.config.fontBold,
              align: widgetInstance.config.fontAlign,
            }}
            onChange={(value) => {
              setLineTextValue({
                fontBold: value.bold,
                fontAlign: value.align,
              });
            }}
          />
        </div>
      </Form.Item>
      <Form.Item label="文字颜色">
        <ColorInput
          value={widgetInstance.config.color}
          onChange={(color) => setLineTextValue({ color })}
        />
      </Form.Item>
      <Form.Item
        label={
          <div className="w-full flex items-center justify-between">
            <span>背景色</span>
            <Switch
              size="small"
              checked={widgetInstance.config.useBgColor}
              onChange={(useBgColor) => {
                setLineTextValue({ useBgColor });
              }}
            />
          </div>
        }
      >
        <ColorInput
          value={widgetInstance.config.backgroundColor}
          onChange={(backgroundColor) => setLineTextValue({ backgroundColor })}
        />
      </Form.Item>
      <Form.Item
        label={
          <div className="w-full flex items-center justify-between">
            <span>图标</span>
            <Switch
              size="small"
              checked={widgetInstance.config.useIcon}
              onChange={(useIcon) => {
                setLineTextValue({ useIcon });
              }}
            />
          </div>
        }
      >
        {widgetInstance.config.icon ? (
          <div
            className={cn(
              'group w-full h-[180px] rounded-[8px] relative overflow-hidden',
              Styles[''],
            )}
          >
            <img
              className="w-full h-full object-contain"
              src={widgetInstance.config.icon}
              alt="上传的图片"
            />
            <div className="group-hover:flex absolute w-full h-full top-0 left-0 hidden items-center justify-center bg-[rgba(0,0,0,0.3)]">
              <span
                className="text-white cursor-pointer"
                onClick={() => {
                  setLineTextValue({
                    icon: undefined,
                  });
                }}
              >
                删除
              </span>
            </div>
          </div>
        ) : (
          <CustomUpload
            showUploadList={false}
            className={cn('w-full', Styles['line-text-upload'])}
            onSuccess={(url) =>
              setLineTextValue({
                icon: url,
              })
            }
          >
            <div className="w-full flex-col flex items-center justify-center">
              <div className="text-[#B6B8B9] text-[14px]">上传图标</div>
            </div>
          </CustomUpload>
        )}
      </Form.Item>
      <div className="text-[#B6B8B9] mb-2">图标将显示在文本前，示例如下</div>
      <div className="flex items-center">
        <RegularIcon type="iconzianicon14" className="mr-1" color="#5ABCB0" />
        <p className="text-[#3D3D3D]">这是文本内容</p>
      </div>
    </Form>
  );
};

export default Setting;
