import ProForm, { BetaSchemaForm } from '@ant-design/pro-form';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { generateAddOpId } from '@linkpi/core';
import { message, Modal } from 'antd';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { useCurrentOrgId, useCurrentUser } from '@/hook';
import type { CreateOptionParams } from '@/services/options';
import { createOption, updateOption } from '@/services/options';

import { OptionSetting } from '../OptionSetting/Setting';

import Styles from './index.less';

type OptionsModalProps =
  | {
      type: 'create';
      fieldType: CreateOptionParams['configType'];
    }
  | {
      type: 'edit';
      fieldType: CreateOptionParams['configType'];
      value: any;
    };

const FormItemLayout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};

const OptionsModal = create((props: OptionsModalProps) => {
  const modal = useModal();
  const modalV5 = antdModalV5(modal);
  const orgId = useCurrentOrgId();
  const userInfo = useCurrentUser();

  const [form] = ProForm.useForm();

  const title = useMemo(() => {
    return match(props.type)
      .with('create', () => '新建选项集')
      .with('edit', () => '编辑选项集')
      .otherwise(() => '');
  }, [props.type]);

  const initialValue = useMemo(() => {
    return match(props)
      .with({ type: 'create' }, () => ({}))
      .with({ type: 'edit' }, (_props) => {
        const { value } = _props;
        const { multiple, optionSourceConfig, ..._values } = value;

        return {
          ..._values,
          optionConfig: {
            multiple,
            optionSourceConfig,
          },
        };
      })
      .otherwise(() => ({}));
  }, [props]);

  const columns = useMemo(() => {
    return [
      {
        title: '选项集名称',
        dataIndex: 'name',
        formItemProps: {
          rules: [{ required: true, message: '请输入选项集名称' }],
        },
      },
      {
        title: '描述',
        dataIndex: 'desc',
      },
    ];
  }, []);

  const handleOk = async () => {
    const values = await form.validateFields();
    // console.log(values);
    match(props)
      .with({ type: 'create' }, async (_props) => {
        const res = await createOption({
          id: generateAddOpId().toUpperCase(),
          org_id: orgId,
          configType: _props.fieldType,
          user: userInfo.userid,
          name: values.name,
          desc: values.desc ?? '',
          optionSourceConfig: values.optionConfig?.optionSourceConfig ?? [],
        });

        if (res.status === 'ok') {
          message.success('创建成功');
          modal.resolve();
          modalV5.onCancel();
        } else {
          message.error((res.message as string) || '创建失败');
        }
      })
      .with({ type: 'edit' }, async (_props) => {
        const res = await updateOption({
          id: _props.value.id,
          org_id: orgId,
          name: values.name,
          desc: values.desc ?? '',
          multiple:
            typeof values.optionConfig.multiple === 'number'
              ? values.optionConfig.multiple === 1
              : values.optionConfig.multiple,
          optionSourceConfig: values.optionConfig.optionSourceConfig,
        });
        if (res.status === 'ok') {
          message.success('更新成功');
          modal.resolve();
          modalV5.onCancel();
        } else {
          message.error((res.message as string) || '更新失败');
        }
      })
      .otherwise(() => null);
  };

  const optionsType = useMemo(() => {
    return match<CreateOptionParams['configType'], 'select' | 'cascader'>(
      props.fieldType,
    )
      .with('xiala', () => 'select')
      .with('jilian', () => 'cascader')
      .otherwise(() => 'select');
  }, [props.fieldType]);

  return (
    <Modal
      className={Styles['options-modal']}
      width={520}
      title={title}
      maskClosable={false}
      {...modalV5}
      onOk={handleOk}
    >
      <ProForm
        form={form}
        layout="horizontal"
        submitter={false}
        initialValues={initialValue}
        {...FormItemLayout}
      >
        <BetaSchemaForm layoutType="Embed" columns={columns} />

        <ProForm.Item
          noStyle
          wrapperCol={{ span: 24 }}
          label={null}
          name={['optionConfig']}
        >
          <OptionSetting type={optionsType} />
        </ProForm.Item>
      </ProForm>
    </Modal>
  );
});

export const showCreateOptionsModal = (
  props: Omit<OptionsModalProps, 'type'>,
): Promise<any> => show(OptionsModal, { type: 'create', ...props });

export const showEditOptionsModal = (
  props: Omit<Extract<OptionsModalProps, { type: 'edit' }>, 'type'>,
): Promise<any> => show(OptionsModal, { type: 'edit', ...props });
