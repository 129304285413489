import { useQuery } from '@tanstack/react-query';
import { useDispatch } from '@umijs/max';
import { useCallback } from 'react';

type ProvNode = {
  name: string;
  adcode: `${number}`;
  center: `${number},${number}`;
  districts: ProvNode[];
};

export function useProvData() {
  const dispatch = useDispatch();

  const fetchProvData = useCallback(async (): Promise<ProvNode[]> => {
    const result = await (dispatch({
      type: 'space/fetchProvData',
    }) as unknown as Promise<ProvNode[]>);
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { isLoading: loading, data: provData } = useQuery({
    queryKey: ['provData'],
    queryFn: fetchProvData,
    gcTime: 12 * 60 * 60 * 1000, // 12小时的缓存时间
    staleTime: 6 * 60 * 60 * 1000, // 6小时的过期时间
  });

  return { loading, provData };
}
