import type { ViewList } from '@linkpi/core';
import { type CurrentUser } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { type FC } from 'react';
import { match } from 'ts-pattern';

import { useGlobalConditionFilterItemTree } from '@/components/PageModelEditor/src/components/DataSourceSetting/hook';
import {
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '@/components/ui/dropdown-menu';

import { getIcon } from './utils';

export const GlobalConditionFilterItemSelect: FC<{
  value?: string;
  onChange?: (value: {
    widgetId: string;
    itemId: string;
    index?: number;
  }) => void;
  propType: CurrentUser.propType;
  op: ViewList.ViewconditionV2Item['op'];
}> = ({ propType, op, ...props }) => {
  const [value, onChange] = useControllableValue(props);
  const globalConditionFilterItemTree = useGlobalConditionFilterItemTree();

  return (
    <>
      {globalConditionFilterItemTree.map((w) => {
        const children = w.children
          .filter((item) => {
            return match(propType)
              .with('text', 'str', () =>
                match({ op, type: item.type })
                  .with({ type: 'select' }, { type: 'text' }, () => true)
                  .with({ type: 'cascader', op: 'intersect' }, () => true)
                  .otherwise(() => false),
              )
              .with(
                'enum',
                'tag',
                () => item.type === 'select' || item.type === 'cascader',
              )
              .with('date', 'datetime', () =>
                match({ op, type: item.type })
                  .with(
                    {
                      op: 'yearInclude',
                      type: 'yearMonthDay',
                    },
                    {
                      op: 'monthInclude',
                      type: 'yearMonthDay',
                    },
                    {
                      op: 'dayInclude',
                      type: 'yearMonthDay',
                    },
                    () => true,
                  )
                  .with({ op: 'timeBein', type: 'dateRangePicker' }, () => true)
                  .otherwise(() => false),
              )
              .with('number', 'currency', () => item.type === 'number')
              .otherwise(() => false);
          })
          .map((item) => {
            if (item.type === 'cascader') {
              return (
                <DropdownMenuSub key={item.id}>
                  <DropdownMenuSubTrigger>
                    {getIcon(item.type as any)}
                    {item.name || '未命名'}
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent className="z-[1000] w-[156px]">
                      {item.children?.map((child, index) => (
                        <DropdownMenuItem
                          key={child.id}
                          onClick={() =>
                            onChange({
                              widgetId: w.id,
                              itemId: item.id,
                              index: index + 1,
                            })
                          }
                        >
                          {child.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              );
            }
            return (
              <DropdownMenuItem
                key={item.id}
                onClick={() =>
                  onChange({
                    widgetId: w.id,
                    itemId: item.id,
                  })
                }
              >
                {getIcon(item.type as any)}
                {item.name || '未命名'}
              </DropdownMenuItem>
            );
          });
        return (
          <DropdownMenuSub key={w.id}>
            <DropdownMenuSubTrigger>{w.name}</DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent className="z-[1000] w-[280px]">
                {children.length > 0 ? (
                  children
                ) : (
                  <div className="py-2 text-center text-sm text-gray-300">
                    暂无筛选项
                  </div>
                )}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
        );
      })}
      {globalConditionFilterItemTree.length === 0 && (
        <div className="py-2 text-center text-sm text-gray-300">
          暂无筛选组件
        </div>
      )}
    </>
  );
};
