import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Input } from 'antd';
import { createElement } from 'react';

interface TitleValue {
  name: string;
  showName: boolean;
}

interface TitleProps {
  value?: TitleValue;
  onChange?: (value: TitleValue) => void;
}

const Title = (props: TitleProps) => {
  const { ...rest } = props;

  const [value, onChange] = useControllableValue<TitleValue>(rest);

  return (
    <Input
      value={value?.name}
      onChange={(e) => onChange({ ...value, name: e.target.value })}
      suffix={createElement(
        value?.showName ? EyeOutlined : EyeInvisibleOutlined,
        {
          style: {
            color: '#6B7A96',
          },
          onClick: () => onChange({ ...value, showName: !value?.showName }),
        },
      )}
    />
  );
};

export default Title;
