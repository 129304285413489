import type { FC } from 'react';

import {
  useActiveWidgetInstance,
  useSetActiveWidgetInstanceConfig,
} from '../../hooks';
import { DataSourceSettingCore } from './Setting';
import type { IDataSourceSetting } from './types';

export const DataSourceSettingWidgetSetting: FC = () => {
  const widgetInstance = useActiveWidgetInstance<IDataSourceSetting>();

  const setConfig = useSetActiveWidgetInstanceConfig<IDataSourceSetting>();

  return (
    <DataSourceSettingCore
      value={widgetInstance?.config}
      onChange={(value) => {
        setConfig((c) => {
          c.conditions = value.conditions;
          c.type = value.type;
          c.parentType = value.parentType;
        });
      }}
    />
  );
};
