import { Form } from 'antd';

import { RegularIcon } from '@/components/IconFont';

import previewIMG from '../../assets/nodeBaseInfo.png';
import type { AlignType } from '../../components';
import { AlignRadio, WidgetIcon } from '../../components';
import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

export type INodeInfoConfig = { align: AlignType };

const Preview: WidgetPreivew = () => {
  return (
    <div className="flex w-full h-full items-center">
      <img src={previewIMG} style={{ display: 'block', height: 30 }} />
    </div>
  );
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetIntance = useActiveWidgetInstance<INodeInfoConfig>()!;

  const setAlign = (r: AlignType) => {
    editor.store.updateWidgetInstanceConfig<INodeInfoConfig>(widgetIntance.id, {
      ...widgetIntance?.config,
      align: r,
    });
  };

  return (
    <Form layout="vertical">
      {/* <BasicSetting type="component" /> */}
      <Form.Item label="对齐">
        <AlignRadio
          value={widgetIntance.config.align}
          onChange={(e) => setAlign(e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};

export const NodeInfoWidget = defineSystemWidget<INodeInfoConfig>()({
  id: 'NodeInfo',
  title: '信息',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#598CFD"
        icon={
          <RegularIcon type="iconneirongyepeizhi-xinxi" color={'#ffffffcc'} />
        }
      />
    );
  },
  basic: {
    defaultHeight: 1,
    defaultWidth: 4,
    minHeight: 1,
    minWidth: 3,
    maxCount: 1,
    maxHeight: 1,
    dynamicHeight: true,
  },
  metadata: {
    align: 'left',
  },
  preview: Preview,
  setting: Setting,
});
