import type { Condition } from '../types';

export interface LegacyCondition {
  key: string;
  input: any;
  op: string;
}

export class DataAdapter {
  static fromLegacy(legacyConditions: LegacyCondition[]): Condition[] {
    return legacyConditions.map((legacy) => ({
      id: String(Math.random()),
      field: legacy.key,
      operator: legacy.op,
      value: legacy.input,
      type: DataAdapter.inferType(legacy.input),
    }));
  }

  static toLegacy(conditions: Condition[]): LegacyCondition[] {
    return conditions.map((condition) => ({
      key: condition.key,
      op: condition.op,
      input: condition.input,
    }));
  }

  private static inferType(value: any): Condition['type'] {
    if (typeof value === 'string') return 'string';
    if (typeof value === 'number') return 'number';
    if (typeof value === 'boolean') return 'boolean';
    if (value instanceof Date) return 'datetime';
    return 'string';
  }
}
