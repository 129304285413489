import { SearchOutlined } from '@ant-design/icons';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { Button, Input, Modal, Popconfirm, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';

import { useCurrentOrgId, useOrgUserMap } from '@/hook';
import type { CreateOptionParams } from '@/services/options';
import { deleteOption } from '@/services/options';

import { OptionListAtom } from '../../store';
import { showCreateOptionsModal, showEditOptionsModal } from '../OptionsModal';

import Styles from './index.less';

type OptionsListModalProps = {
  update?: () => void;
  fieldType?: CreateOptionParams['configType'];
};

const OptionsListModal = create((props: OptionsListModalProps) => {
  const { update, fieldType = 'xiala' } = props;
  const modal = useModal();
  const modalV5 = antdModalV5(modal);
  const userMap = useOrgUserMap();
  const orgId = useCurrentOrgId();
  const selectOptionList = useAtomValue(OptionListAtom);

  const columns: ColumnsType<any> = [
    {
      title: '选项集名称',
      dataIndex: 'name',
      ellipsis: true,
      width: 160,
      render: (text) => (
        <Tooltip title={text}>
          <div className="truncate">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: '描述',
      dataIndex: 'desc',
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <div className="truncate">{text}</div>
        </Tooltip>
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      render: (text) => (text ? dayjs(text).format('YYYY-MM-DD HH:mm:ss') : ''),
      width: 180,
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => (
        <div className="flex gap-4">
          <span
            className="text-[#316EF5] cursor-pointer"
            onClick={() => handleEdit(record)}
          >
            编辑
          </span>
          <Popconfirm
            title="确认删除？"
            onConfirm={() => handleDelete(record.id)}
          >
            <span className="text-[#F0665E] cursor-pointer">删除</span>
          </Popconfirm>
        </div>
      ),
      width: 120,
    },
  ];

  const handleAdd = async () => {
    await showCreateOptionsModal({
      fieldType,
    });
    update?.();
  };

  const handleEdit = async (value: any) => {
    await showEditOptionsModal({
      fieldType,
      value,
    });
    update?.();
  };

  const handleDelete = async (id: string) => {
    await deleteOption({
      id,
      org_id: orgId,
    });
    update?.();
  };

  return (
    <Modal
      className={Styles['options-modal']}
      width={900}
      title="管理选项集"
      maskClosable={false}
      footer={null}
      {...modalV5}
    >
      <div className="flex items-center justify-between mb-4">
        <Input
          className="w-64"
          suffix={<SearchOutlined />}
          placeholder="搜索"
        />
        <Button type="primary" onClick={handleAdd}>
          新建选项集
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={selectOptionList}
        rowKey={(record) => record.id}
        scroll={{
          y: 450,
        }}
        pagination={false}
      />
    </Modal>
  );
});

export const showOptionsListModal = (props: OptionsListModalProps) =>
  show(OptionsListModal, props);
