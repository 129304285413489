import { produceConditionV2 } from '@linkpi/core';
import { getPiSDK } from '@linkpi/sdk';
import { toArray } from '@linkpi/utils';
import { isEmpty, isNil, omit } from 'ramda';
import { match, P } from 'ts-pattern';

import type {
  IDataSourceSetting,
  Legacy_IDataSourceSetting_v1,
} from '@/components/PageModelEditor/src/components';

import {
  convertGetTempFilterNodeConditionV2,
  convertGetTempFilterNodeConditionV2Item,
  loadNodes,
} from './utils';

function paginate<T>(data: T[], page: number, pageSize: number): T[] {
  // 计算起始索引
  const startIndex = (page - 1) * pageSize;
  // 计算结束索引
  const endIndex = startIndex + pageSize;
  // 返回分页数据
  return data.slice(startIndex, endIndex);
}

type BaseFetchDataSourceParam = {
  /**
   * 仅在 type 未 'dataSource' 时有效
   */
  pageSize: number;
  /**
   * 仅在 type 未 'dataSource' 时有效
   */
  page: number;
  orgId: string;
  currentNodeId?: string;
  group?: string;
  orderBy?: string;
  orderDesc?: boolean;
};

type Lgeacy_FetchDataSourceParam = Legacy_IDataSourceSetting_v1 &
  BaseFetchDataSourceParam;

type FetchDataSourceParam = IDataSourceSetting & BaseFetchDataSourceParam;

// 在全局注入 piSDK
// @ts-ignore
window.__PI_SDK__ = getPiSDK();

export const legacy_fetchDataSource = async ({
  type,
  orgId,
  currentNodeId,
  ...params
}: Lgeacy_FetchDataSourceParam) => {
  const piSDK = getPiSDK();
  const nodeManager =
    piSDK.piConnectionManager.getPiConnection(orgId).nodeManager;
  switch (type) {
    case 'dataSource': {
      const conditions = convertGetTempFilterNodeConditionV2({
        conditionV2: params.conditions,
        currentUserId: nodeManager._accountId,
      });

      // 追加匹配条件
      if (params?.matchings?.length && currentNodeId) {
        const piNode = await nodeManager.getNode(currentNodeId);
        // 追加conditionV2
        const tempProps = piNode.tempInfo.prop;
        params.matchings.forEach((x) => {
          const target = Number(x.target);
          if (isNaN(target)) return;
          const value = tempProps[target];
          const inp = Array.isArray(value) ? value : [value];
          conditions.push({
            op: 'intersect',
            key: 'prop',
            input: inp,
            index: x.origin,
          });
        });
      }

      const res = await piSDK.tableHeaderManager.queryByTemplate({
        pageSize: params.pageSize,
        page: params.page,
        orgId,
        condition: conditions,
      });

      if (res.status !== 'ok') {
        return {
          status: 'error',
          message: res.message,
          list: [] as PiNode[],
          total: 0,
          page: params.page,
        };
      }

      const nodeIdList = await piSDK.piNodeManager.getDbNodeList(
        orgId,
        res.thmId,
        res.originResult,
        {
          templateHeaderMap: res.templateHeaderMap,
          tableHeaderMap: res.tableHeaderMap,
        },
      );

      return {
        list: nodeIdList.map((id) => nodeManager.getNode(id)),
        total: res.total,
        page: params.page,
        status: 'ok',
      };
    }
    case 'temp': {
      const rootId = nodeManager._rootId;

      await (nodeManager._waitInit as unknown as Promise<void>);

      const piNode = await nodeManager.getNode(currentNodeId || rootId);

      /**
       * 移除移除根节点筛选
       */
      const ancestor = (params.conditions || []).find(
        (i: any) => i.key === 'ancestor',
      );
      if (ancestor && ancestor.input[0] === rootId) {
        params.conditions = params.conditions.filter(
          (i: any) => i.key !== 'ancestor',
        );
      }

      /**
       * 移除空条件
       */
      params.conditions = params.conditions.filter((i) => !isEmpty(i.input));

      const nodes = await loadNodes(piNode, {
        currentUserId: nodeManager._accountId,
        ...params,
      });

      // 更新时间排序
      if (params.orderBy === '_sys_updateTime') {
        nodes.sort((nodeA, nodeB) => nodeB.metadata.m - nodeA.metadata.m);
      }

      // 系统属性
      if (params.orderBy === '_sys_createTime') {
        nodes.sort((nodeA, nodeB) => {
          return params.orderDesc
            ? nodeA.createTime - nodeB.createTime
            : nodeB.createTime - nodeA.createTime;
        });
      }

      return {
        list: paginate(nodes, params.page, params.pageSize),
        total: nodes.length,
        page: params.page,
        status: 'ok',
      };
    }
    default:
      throw new Error('Invalid type');
  }
};

export const fetchDataSource = async ({
  type,
  orgId,
  currentNodeId,
  ...params
}: FetchDataSourceParam) => {
  if (type !== 'conditionFilterV2')
    return legacy_fetchDataSource({
      type,
      orgId,
      currentNodeId,
      ...params,
    } as unknown as Lgeacy_FetchDataSourceParam);

  const piSDK = getPiSDK();
  const nodeManager =
    piSDK.piConnectionManager.getPiConnection(orgId).nodeManager;

  const templateId = params.conditions.find((i) => i.key === 'templateId')!
    .input[0];

  if (!templateId) throw new Error('templateId is required');

  // piSDK.tableHeaderManager.
  let sourceType: 'temp' | 'dataSource' = 'temp';
  try {
    const { maps: thmList } =
      await piSDK.tableHeaderManager.getTableHeaderByTemplate({
        orgId,
        tempId: templateId,
      });
    if (thmList.length) sourceType = 'dataSource';
  } catch (error) {
    console.error('getTableHeaderByTemplate: ', error);
  }

  const currentPiNode = await nodeManager.getNode(
    currentNodeId ?? nodeManager._rootId,
  );

  const conditions = produceConditionV2(params.conditions, (draft) => {
    match(draft)
      .with(
        { extends: { inputType: 'currentNode', target: P.select() } },
        (_propIndex) => {
          // 追加conditionV2
          const tempProps = currentPiNode.tempInfo.prop;
          const propIndex = Number(_propIndex);
          const propValue = tempProps[propIndex];

          if (isNaN(propIndex)) return;

          if (isNil(draft.input)) {
            draft.input = [];
          }

          draft.input = toArray(propValue);

          // TODO
          // 地址属性的匹配
        },
      )
      .otherwise(() => {});

    convertGetTempFilterNodeConditionV2Item({
      condition: draft,
      currentUserId: nodeManager._accountId,
    });

    if (Array.isArray(draft.input) && draft.input.length === 0) {
      return false;
    }
    return true;
  });

  switch (sourceType) {
    case 'dataSource': {
      const res = await piSDK.tableHeaderManager.queryByTemplate({
        pageSize: params.pageSize,
        page: params.page,
        orgId,
        condition: conditions as any,
      });

      if (res.status !== 'ok') {
        return {
          status: 'error',
          message: res.message,
          list: [] as PiNode[],
          total: 0,
          page: params.page,
        };
      }

      const nodeIdList = await piSDK.piNodeManager.getDbNodeList(
        orgId,
        res.thmId,
        res.originResult,
        {
          templateHeaderMap: res.templateHeaderMap,
          tableHeaderMap: res.tableHeaderMap,
        },
      );

      return {
        list: nodeIdList.map((id) => nodeManager.getNode(id)),
        total: res.total,
        page: params.page,
        status: 'ok',
      };
    }
    case 'temp': {
      const rootId = nodeManager._rootId;

      await (nodeManager._waitInit as unknown as Promise<void>);

      const piNode = await nodeManager.getNode(currentNodeId || rootId);

      const nodes = await loadNodes(piNode, {
        currentUserId: nodeManager._accountId,
        // @ts-ignore
        ...omit(['matchings'], params),
        conditions,
      });

      // 更新时间排序
      if (params.orderBy === '_sys_updateTime') {
        nodes.sort((nodeA, nodeB) => nodeB.metadata.m - nodeA.metadata.m);
      }

      // 系统属性
      if (params.orderBy === '_sys_createTime') {
        nodes.sort((nodeA, nodeB) => {
          return params.orderDesc
            ? nodeA.createTime - nodeB.createTime
            : nodeB.createTime - nodeA.createTime;
        });
      }

      return {
        list: paginate(nodes, params.page, params.pageSize),
        total: nodes.length,
        page: params.page,
        status: 'ok',
      };
    }
    default:
      throw new Error('Invalid type');
  }
};
