import { convertTimeByModule, Date_Format_Options } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { LinkPiDateProp } from './index';
import LinkPiDate from './index';

/**
 * 返回时间戳的 LinkPiDate
 */
export const LinkPiDateTimePicker: FC<
  LinkPiDateProp & {
    type?: 'date' | 'datetime';
    dateValue?: number;
    onChange?: (v: number) => void;
    onConfirm?: (v: number) => void;
  }
> = ({
  type = 'date',
  onChange: _onChange,
  onConfirm: _onConfirm,
  ...props
}) => {
  const dateModule = useMemo(
    () =>
      Date_Format_Options.find((x) => x.value === props.dateFormat)?.module ||
      (type === 'date' ? 'day' : 'min'),
    [props.dateFormat, type],
  );

  const onChange = useMemoizedFn((v: string) => {
    return _onChange?.(convertTimeByModule(dayjs(v), dateModule));
  });

  const onConfirm = useMemoizedFn((v: string) => {
    return _onConfirm?.(convertTimeByModule(dayjs(v), dateModule));
  });

  return <LinkPiDate {...props} onChange={onChange} onConfirm={onConfirm} />;
};
