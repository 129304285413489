import { useControllableValue } from 'ahooks';
import { Radio } from 'antd';

type RadioValue = string | boolean | number;

interface RadioProps {
  list: {
    label: string;
    value: RadioValue;
  }[];
  value?: RadioValue;
  onChange?: (value: RadioValue) => void;
}

const SettingRadio = (props: RadioProps) => {
  const { list, ...rest } = props;

  const [value, onChange] = useControllableValue<RadioValue>(rest);

  return (
    <div className="flex gap-3 w-full">
      {list.map((item) => (
        <div
          key={`${item.value}`}
          className="pl-4 pr-3 py-2 flex-1 h-10 rounded-[8px] bg-[#F8F9FB] flex items-center justify-between cursor-pointer"
          onClick={() => onChange(item.value)}
        >
          <span>{item.label}</span>
          <Radio checked={value === item.value} />
        </div>
      ))}
    </div>
  );
};

export default SettingRadio;
