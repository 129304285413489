import { PlusOutlined } from '@ant-design/icons';
import { numberToChinese } from '@codog/number-to-chinese';
import { useControllableValue } from 'ahooks';
import { Divider } from 'antd';
import { produce } from 'immer';
import type { FC } from 'react';

import { PiButton } from '@/components/Button';
import { RegularIcon } from '@/components/IconFont';

import type { ICascaderConfig, ICascaderOptionSource } from '../../types';
import { LevelConfig } from './LevelConfig';

const DEFAULT_VALUE: ICascaderConfig = {
  optionSourceConfig: [
    {
      dataSource: undefined,
      labelProp: undefined,
      valueProp: undefined,
      checkable: true,
    },
  ],
  multiple: false,
};

export const OptionSetting: FC<{
  type: 'select' | 'cascader';
  value?: ICascaderConfig;
  onChange?: (value: ICascaderConfig) => void;
}> = ({ type, ...props }) => {
  const [value = DEFAULT_VALUE, onChange] =
    useControllableValue<ICascaderConfig>(props, {
      defaultValue: DEFAULT_VALUE,
    });

  const addOption = () => {
    const newV = produce(value, (draft) => {
      draft.optionSourceConfig.push({
        dataSource: undefined,
        labelProp: undefined,
        valueProp: undefined,
        checkable: true,
      });
    });
    onChange?.(newV);
  };

  const removeOption = (index: number) => {
    const newV = produce(value, (draft) => {
      draft.optionSourceConfig = draft.optionSourceConfig.filter(
        (_, i) => i !== index,
      );
    });
    onChange?.(newV);
  };

  /**
   * 基于 immer 实现
   *
   * mutable
   */
  const updateOption = (index: number, v: ICascaderOptionSource[number]) => {
    const newV = produce(value, (draft) => {
      draft.optionSourceConfig[index] = v;

      // 跳过最后一级
      if (index === draft.optionSourceConfig.length - 1) return;

      /**
       * 将当前级以前所有 checkable 设置为当前的 checkable
       */
      draft.optionSourceConfig.forEach((item, _index) => {
        if (_index >= index) return;

        item.checkable = v.checkable;
      });
    });
    onChange?.(newV);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col gap-2">
        {value.optionSourceConfig.map((level, index) => {
          return (
            <div
              key={index}
              className="flex flex-col gap-2 rounded-[8px] bg-[#F8F9FB] pt-3 px-4"
            >
              <div className="flex items-center justify-between">
                <span className="font-bold">
                  {type === 'cascader'
                    ? `${numberToChinese(index + 1)}级选项配置`
                    : '选项配置'}
                </span>
                {value.optionSourceConfig.length > 1 && (
                  <span
                    className="cursor-pointer"
                    onClick={() => removeOption(index)}
                  >
                    <RegularIcon
                      type="iconshanchu2"
                      color="#F0665E"
                      size={16}
                    />
                  </span>
                )}
              </div>
              <Divider dashed style={{ margin: '0' }} />
              <LevelConfig
                isFirstLevel={index === 0}
                isLastLevel={index === value.optionSourceConfig.length - 1}
                value={level}
                onChange={(v) => updateOption(index, v)}
              />
            </div>
          );
        })}
      </div>
      {type === 'cascader' && (
        <PiButton
          type="dashed"
          className="mt-2"
          onClick={addOption}
          icon={<PlusOutlined />}
        >
          选项层级
        </PiButton>
      )}
    </div>
  );
};
