import { MinusCircleOutlined } from '@ant-design/icons';
import { useMemoizedFn } from 'ahooks';
import { Select } from 'antd';
import { head, pick } from 'ramda';
import React, { useMemo } from 'react';
import { match } from 'ts-pattern';

import ErrorBoundary from '@/components/ErrorBoundary';
import { DataPropSelect } from '@/components/LinkPiForm';

import { useConditionFilterConfig, useConditionItem } from '../hooks';
import {
  useConditionItemInnerConfig,
  useGetConditionItemInnerConfig,
} from '../hooks/useConditionItemInnerConfig';
import { useTypeManager } from '../hooks/useTypeManager';
import type { Condition, ConditionPropType } from '../types';
import { genDataPropKey, parseDataPropKey } from '../utils';
import { ValueEditor } from './ValueEditor/ValueEditor';

export interface ConditionItemProps {
  groupIndex: number;
  conditionIndex: number;
}

const ConditionItem: React.FC<ConditionItemProps> = ({
  groupIndex,
  conditionIndex,
}) => {
  const { disabled, templateId, templateUsage } = useConditionFilterConfig();

  const {
    item: condition,
    removeItem,
    updateItem,
  } = useConditionItem(groupIndex, conditionIndex);

  const { getOperatorsForType } = useTypeManager();

  const conditionConfig = useConditionItemInnerConfig({
    key: condition.key,
    index: condition.index,
    templateId,
    templateUsage,
  });

  const getOpList = useMemoizedFn(
    ({
      key,
      type,
      multiple,
    }: {
      key: Condition['key'];
      type: ConditionPropType;
      multiple?: boolean;
    }) => {
      const opList = match(key)
        .with(
          'prop',
          'statusProp',
          'templateStatusOwnerAndParticipant',
          'sysCreateTime',
          'sysCreator',
          () => getOperatorsForType(type),
        )
        .otherwise(() => getOperatorsForType(key))
        .filter((op) => (multiple ? !op.onlySingle : !op.onlyMultiple));

      return opList;
    },
  );

  const multiple = conditionConfig?.data?.multiple;

  const operatorOptions = useMemo(() => {
    const opList = getOpList({
      key: condition.key,
      type: conditionConfig?.type as ConditionPropType,
      multiple,
    });

    return opList;
  }, [getOpList, condition.key, conditionConfig?.type, multiple]);

  const dataPropKey = genDataPropKey(condition.key, condition.index);

  const getConditionItemInnerConfig = useGetConditionItemInnerConfig({
    templateId,
    templateUsage,
  });

  const onDataPropKeyChange = (propKey: string) => {
    const { key, index } = parseDataPropKey(propKey);

    const newConditionConfig = getConditionItemInnerConfig(key, index);
    const newOpList = getOpList({
      key,
      type: newConditionConfig?.type as ConditionPropType,
      multiple: newConditionConfig?.data?.multiple,
    });

    updateItem({
      key,
      index: typeof index === 'number' ? Number(index) : undefined,
      op: head(newOpList)!.value,
      input: null,
      extends: { type: newConditionConfig.type, inputType: 'customEditor' },
    });
  };

  /***
   * 当 op 改变时，可能需要指定 inputType
   */
  const onOpChange = (op: typeof condition.op) => {
    updateItem({
      op,
      input: null,
      extends: condition.extends
        ? pick(['type'], condition.extends)
        : undefined,
    });
  };

  const onValueChange = (value: typeof condition.input) => {
    updateItem({
      input: value,
    });
  };

  const onExtendsChange = (value: typeof condition.extends) => {
    const newExtends = {
      ...pick(['type'], condition.extends || ({} as any)),
      ...value,
    };
    updateItem({
      input: [],
      extends: newExtends,
    });
  };

  const opConfig = operatorOptions.find((op) => op.value === condition.op);
  const valueDisabled = disabled || opConfig?.supportInputType?.length === 0;

  console.log('opConfig', opConfig);

  return (
    <ErrorBoundary type="inlineFailed">
      <div className="condition-item flex-auto">
        <div className="condition-item-content">
          <DataPropSelect
            className="w-[200px] flex-none"
            templateId={templateId}
            value={dataPropKey}
            disabled={disabled}
            onChange={onDataPropKeyChange}
          />

          {!opConfig?.hide && (
            <Select
              className="w-[96px] flex-none"
              value={condition.op}
              onChange={onOpChange}
              disabled={disabled}
              options={operatorOptions}
            />
          )}

          <div className="flex-auto">
            <ErrorBoundary type="inlineFailed">
              <ValueEditor
                value={condition.input}
                onChange={onValueChange}
                onExtendsChange={onExtendsChange}
                disabled={valueDisabled}
                config={condition}
                supportInputType={opConfig?.supportInputType}
              />
            </ErrorBoundary>
          </div>

          {!disabled && (
            <div className="flex-none flex items-center h-[32px] pr-2">
              <MinusCircleOutlined
                onClick={removeItem}
                style={{
                  color: '#B9BBBC',
                  cursor: 'pointer',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ConditionItem;
