import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { useSysTags } from '@/hook';
import { cn } from '@/utils/utils';

import { BaseSelect } from '../BaseSelect';

type SysTagsSelectProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  maxTagCount?: number;
  className?: string;
  disabled?: boolean;
};

export const SysTagsSelect: FC<SysTagsSelectProps> = memo(
  ({ value, onChange, maxTagCount, className, disabled }) => {
    const sysTags = useSysTags();
    const options = useMemo(() => {
      return sysTags.map((tag) => ({
        label: tag.tag,
        value: tag.tag,
        filterText: tag.tag,
      }));
    }, [sysTags]);

    return (
      <BaseSelect
        className={cn('w-full', className)}
        value={value}
        onChange={onChange}
        options={options as any}
        allowChooseAll
        maxTagCount={maxTagCount}
        disabled={disabled}
      />
    );
  },
);
