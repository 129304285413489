import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { useMemoizedFn } from 'ahooks';
import { Form, Modal } from 'antd';
import { useEffect, useRef } from 'react';

import { useOrgInfo } from '@/hook';

import { useActiveWidgetInstance, useEditor } from '../../hooks';
import type { RGBAColor } from '../ColorInput';
import type { IDataSourceSetting } from '../DataSourceSetting';
import { DataIndicatorForm, getDefaultIndexData } from './Form';

type IndexIdType = string;
export type IDataIndicatorIndexType = {
  name: string;
  dataSource: IDataSourceSetting;
  subitems: {
    propIndex?: number;

    showStatistical: boolean;
    statisticalName: string;
    statisticalPropIndex?: number;

    showSuffixIcon: boolean;
    suffixIconPropIndex?: number;
  }[];
  /** 是否显示Icon */
  showIcon: boolean;
  /** icon位置 左/右 */
  iconPlacement: 'left' | 'right';
  /** icon 图片地址 */
  iconSrc: string;

  styles: {
    propName: {
      fontSize: number;
      color: RGBAColor;
      bold: boolean;
      italic: boolean;
      underline: boolean;
    };
    propValue: {
      fontSize: number;
      color: RGBAColor;
      bold: boolean;
      italic: boolean;
      underline: boolean;
    };
    statisticalProp: {
      fontSize: number;
      color: RGBAColor;
      bold: boolean;
      italic: boolean;
      underline: boolean;
    };
    background: string;
    spacingType: 'normal' | 'small';
  };
};
export type IDataIndicatorWidget = {
  indexes: Record<IndexIdType, IDataIndicatorIndexType>;
  indexSort: IndexIdType[];
};

/**
 * 新增Modal
 * 通过 modal.resolve 返回
 */
const AddModal = () => {
  const modal = useModal();

  const [form] = Form.useForm<IDataIndicatorWidget>();

  const DataIndicatorFormRef = useRef<any>();
  const [orgInfo] = useOrgInfo();

  useEffect(() => {
    if (!orgInfo) return;

    const defaultData = getDefaultIndexData(orgInfo);
    form.setFieldsValue({
      indexSort: [defaultData.id],
      indexes: {
        [defaultData.id]: defaultData.data,
      },
    });

    setTimeout(() => {
      DataIndicatorFormRef.current?.selectIndex(defaultData.id);
    }, 500);
  }, []);

  const onOk = useMemoizedFn(async () => {
    const values = await form.validateFields();
    modal.resolve(values);
    modal.hide();
  });

  const onCanel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <Modal
      {...antdModalV5(modal)}
      onOk={onOk}
      onCancel={onCanel}
      centered
      title="添加指标"
      width={1200}
      bodyStyle={{
        height: '70vh',
        overflowY: 'auto',
        padding: 0,
      }}
    >
      <Form form={form} style={{ height: '100%' }}>
        <DataIndicatorForm ref={DataIndicatorFormRef} />
      </Form>
    </Modal>
  );
};
const AddModalNice = create(AddModal);
export const showAddDataIndicatorModal = (): Promise<IDataIndicatorWidget> => {
  return show(AddModalNice);
};

/**
 * 编辑modal
 *
 */
const EditModal = () => {
  const modal = useModal();
  const DataIndicatorFormRef = useRef<any>();

  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IDataIndicatorWidget>();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      indexSort: widgetInstance?.config.indexSort,
      indexes: widgetInstance?.config.indexes,
    });

    setTimeout(() => {
      DataIndicatorFormRef.current?.selectIndex(
        widgetInstance?.config.indexSort[0],
      );
    }, 500);
  }, []);

  const onOk = useMemoizedFn(async () => {
    const values = await form.validateFields();
    editor.updateActiveWidgetInstanceConfig<IDataIndicatorWidget>((c) => {
      c.indexSort = values.indexSort;
      c.indexes = values.indexes;
    });
    modal.hide();
  });

  const onCanel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <Modal
      {...antdModalV5(modal)}
      onOk={onOk}
      onCancel={onCanel}
      centered
      title="编辑指标"
      width={1200}
      bodyStyle={{
        height: '70vh',
        overflowY: 'auto',
        padding: 0,
      }}
    >
      <Form form={form} style={{ height: '100%' }}>
        <DataIndicatorForm ref={DataIndicatorFormRef} onCancel={onCanel} />
      </Form>
    </Modal>
  );
};

const EditModalNice = create(EditModal);
export const showEditDataIndicatorModal = () => {
  return show(EditModalNice);
};
