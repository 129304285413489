import { createDefu } from 'defu';
import { produce } from 'immer';
import { isNil } from 'ramda';
import { match, P } from 'ts-pattern';
import { container } from 'tsyringe-neo';

import type {
  IConditionSetting,
  IGlobalConditionFilterConfigV1,
  IGlobalConditionFilterConfigV2,
} from '../../components';
import { GLOBAL_CONDITION_LATEST_VERSION } from '../../components';
import type { IDataIndicatorWidget } from '../../components/DataIndicatorSettingModal';
import { EditorModel } from '../../models';
import { type WidgetInstance } from '../../models/Store';

export const getTemplateIdByWidgetInstance = (
  widgetInstance: WidgetInstance,
) => {
  return match(
    widgetInstance as WidgetInstance<
      IConditionSetting & {
        matchOriginConfig?: IConditionSetting;
      }
    >,
  )
    .with(
      { config: { matchOriginConfig: { conditions: P.array() } } },
      ({
        config: {
          matchOriginConfig: { conditions },
        },
      }) => {
        return conditions.find((c) => c.key === 'templateId')?.input[0];
      },
    )
    .with(
      { config: { conditions: P.array() } },
      ({ config: { conditions } }) => {
        return conditions.find((c) => c.key === 'templateId')?.input[0];
      },
    )
    .otherwise(() => '') as string | undefined;
};

const getDataIndicatorItemConfig = (itemId: string) => {
  const store = container.resolve(EditorModel).store;

  const widgetInstanceList =
    store.getWidgetInstancesByWidgetId<IDataIndicatorWidget>('dataIndicator');

  for (let index = 0; index < widgetInstanceList.length; index++) {
    const widgetInstance = widgetInstanceList[index];

    const config = widgetInstance.config;

    const itemConfig = config.indexes[itemId];

    if (itemConfig) {
      return itemConfig;
    }
  }

  return undefined;
};

export const getTemplateIdByDataIndicatorWidgetItemId = (itemId: string) => {
  const itemConfig = getDataIndicatorItemConfig(itemId);

  if (itemConfig) {
    return itemConfig.dataSource.conditions.find((c) => c.key === 'templateId')
      ?.input[0];
  }

  return undefined;
};

export const getTemplateIdByDatasourceId = (datasourceId: string) => {
  const store = container.resolve(EditorModel).store;
  const widgetInstance = store.getWidgetInstance(datasourceId);

  const latestTargetTemplateId = widgetInstance
    ? getTemplateIdByWidgetInstance(widgetInstance)!
    : getTemplateIdByDataIndicatorWidgetItemId(datasourceId)!;

  return latestTargetTemplateId;
};

export const getNameByDataIndicatorWidgetItemId = (itemId: string) => {
  const itemConfig = getDataIndicatorItemConfig(itemId);

  return itemConfig?.name;
};

export const getNameByDatasourceId = (datasourceId: string) => {
  const store = container.resolve(EditorModel).store;
  const widgetInstance = store.getWidgetInstance(datasourceId);

  const name = widgetInstance
    ? widgetInstance.config.name
    : getNameByDataIndicatorWidgetItemId(datasourceId);

  return name;
};

export const migrateConfig = (config: IGlobalConditionFilterConfigV1) => {
  return produce(config, (draft) => {
    draft.version = GLOBAL_CONDITION_LATEST_VERSION as any;
    draft.conditionList.forEach((condition) => {
      (condition as any).filedType = '$$TEMP_BY_PROP';

      /**
       * 筛选对象应用的匹配列表迁移
       */
      condition.targetList?.forEach((target) => {
        (target as any).matchingGroupOp = 'and';
        (target as any).matchingGroupList = [
          {
            list: target.matchingList?.length
              ? target.matchingList
              : [{ op: 'intersect', propKey: '', input: [] } as any],
            op: 'and',
          },
        ];
        delete target.matchingList;
      });
    });
  }) as unknown as IGlobalConditionFilterConfigV2;
};

/**
 * 配置合并
 *
 * 如果 value 为空字符串|null|undefined，则不覆盖
 */
export const configDefu = createDefu((obj, key, value) => {
  if (typeof obj[key] === 'string' && typeof value === 'string' && !value) {
    return true;
  }

  if (isNil(value)) {
    return true;
  }

  obj[key] = value;
  return true;
});
