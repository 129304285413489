import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
} from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Divider } from 'antd';
import React, { Children, cloneElement } from 'react';

import { cn } from '@/utils/utils';

const FontSize = 18;
const Color = '#242D3F';
const AlignOptions = [
  {
    icon: <AlignLeftOutlined style={{ fontSize: FontSize, color: Color }} />,
    key: 'left',
  },
  {
    icon: <AlignCenterOutlined style={{ fontSize: FontSize, color: Color }} />,
    key: 'center',
  },
  {
    icon: <AlignRightOutlined style={{ fontSize: FontSize, color: Color }} />,
    key: 'right',
  },
] as const;

interface WrapperProps {
  children: React.ReactElement;
  active?: boolean;
  onClick?: () => void;
}
const Wrapper = (props: WrapperProps) => {
  const { children, active, onClick } = props;

  const colorStyle = active ? { color: '#fff' } : {};
  const _children = Children.map(children, (child) =>
    cloneElement(child, { style: { ...child.props.style, ...colorStyle } }),
  );

  return (
    <span
      className={cn(
        'w-9 h-9 flex items-center justify-center rounded-[8px] hover:bg-[#F7F7F9] cursor-pointer',
        {
          'bg-[#316EF5] hover:bg-[#316EF5]': active,
        },
      )}
      onClick={onClick}
    >
      {_children}
    </span>
  );
};

interface IValue {
  align?: 'left' | 'center' | 'right';
  bold?: boolean;
}
interface FontAlign {
  value?: IValue;
  onChange?: (value: IValue) => void;
  className?: string;
  style?: React.CSSProperties;
}

export const FontAlign = (props: FontAlign) => {
  const { value, onChange, className, style } = props;
  const [_value, _onChange] = useControllableValue<IValue>({ value, onChange });

  return (
    <div
      style={style}
      className={cn(
        'h-10 border rounded-[8px] border-solid border-[#EBEDF0] flex items-center p-[2px]',
        className,
      )}
    >
      <Wrapper
        active={_value?.bold}
        onClick={() => _onChange({ ..._value, bold: !_value?.bold })}
      >
        <BoldOutlined style={{ fontSize: 18, color: '#000' }} />
      </Wrapper>
      <Divider type="vertical" />
      {AlignOptions.map((align) => (
        <Wrapper
          key={align.key}
          active={_value?.align === align.key}
          onClick={() => _onChange({ ..._value, align: align.key })}
        >
          {align.icon}
        </Wrapper>
      ))}
    </div>
  );
};
