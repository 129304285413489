import { takeOne } from '@linkpi/utils';
import { omit } from 'ramda';
import React, { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import NodeSelect from '@/components/ConditionFilter/ConditionItem/NodeSelect';
import { RegularIcon } from '@/components/IconFont';
import { SysTagsSelect } from '@/components/LinkPiForm';
import type { EditorTypeConfig } from '@/components/ValueEditorWithMenu';
import {
  currentNodeConfig,
  ValueEditorWithMenu,
} from '@/components/ValueEditorWithMenu';

import { useConditionFilterConfig } from '../../hooks';
import { useConditionItemInnerConfig } from '../../hooks/useConditionItemInnerConfig';
import type { Condition } from '../../types';
import {
  AddressRangerPicker,
  AddressSelect,
  CascadeEditor,
  DatePicker,
  DateRangerPicker,
  EnumSelect,
  NumberInput,
  NumberRangerInput,
  StatusSelectEditor,
  TextEditor,
  UserSelect,
} from './editors';
import {
  getGlobalConditionFilterItemElement,
  type InputType,
} from './ValueTypeSelect';
import { GlobalConditionFilterMenu } from './ValueTypeSelect/GlobalConditionFilterMenu';

interface ValueEditorProps {
  config: Pick<Condition, 'key' | 'op' | 'index' | 'extends'>;
  value: any;
  onChange: (value: any) => void;
  onExtendsChange?: (value: any) => void;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
  style?: React.CSSProperties;
  supportInputType?: InputType[];
}

type ValueEditorWithMenuValue = NonNullable<
  Omit<Condition['extends'], 'inputType'> & {
    type: NonNullable<Condition['extends']>['inputType'];
  }
>;

/**
 * 值编辑器
 *
 * 不处理主题类型
 */
export const ValueEditor: React.FC<ValueEditorProps> = ({
  value,
  onChange,
  onExtendsChange,
  disabled,
  className,
  style,
  multiple,
  config,
  supportInputType,
}) => {
  const commonProps = {
    value,
    onChange,
    disabled,
    className,
    style,
    supportInputType,
  };
  const { nodeSelectExtraCurrent, templateId, templateUsage } =
    useConditionFilterConfig();

  const conditionConfig = useConditionItemInnerConfig({
    ...config,
    templateId,
    templateUsage,
  })!;

  console.log('conditionConfig', conditionConfig);

  const customEditor = match(config)
    .with({ key: 'ancestor' }, () => {
      return (
        <NodeSelect
          {...commonProps}
          extraCurrent={nodeSelectExtraCurrent}
          hideBtn
        />
      );
    })
    .with({ key: 'status' }, () => {
      return (
        <StatusSelectEditor
          {...commonProps}
          templateId={templateId}
          templateUsage={templateUsage}
        />
      );
    })
    .with({ key: 'sysTag' }, () => {
      return <SysTagsSelect {...commonProps} />;
    })
    // TODO 支持当前用户角色
    // .with({ key: 'currentUserRoles' }, () => {
    //   return (
    //     <StatusUserPropConfigSelect
    //       className={cn('w-full', className)}
    //       style={style}
    //       allowTypes={['group']}
    //       mode="multiple"
    //       value={value}
    //       onChange={onChange}
    //     />
    //   );
    // })
    .otherwise(() => {
      if (!conditionConfig)
        return (
          <TextEditor className={className} style={style} disabled={disabled} />
        );

      return match({ propType: conditionConfig.type, op: config.op })
        .with({ propType: P.union('enum', 'tag') }, () => {
          const disableMultiple =
            !conditionConfig.data?.multiple && config.op === 'equal';

          return (
            <EnumSelect
              {...commonProps}
              config={config}
              templateId={takeOne(templateId)}
              templateUsage={templateUsage}
              disableMultiple={disableMultiple}
            />
          );
        })
        .with({ propType: 'user' }, () => {
          const disableMultiple =
            !conditionConfig.data?.multiple && config.op === 'equal';

          return (
            <UserSelect
              {...commonProps}
              templateId={takeOne(templateId)}
              templateUsage={templateUsage}
              disableMultiple={disableMultiple}
              disableUserGroup={disableMultiple}
            />
          );
        })
        .with({ propType: 'cascade' }, () => {
          return (
            <CascadeEditor
              {...commonProps}
              config={config}
              templateId={takeOne(templateId)}
              templateUsage={templateUsage}
              multiple={config.op === 'beIncluded' ? true : multiple}
            />
          );
        })
        .with({ propType: 'address', op: 'distance' }, () => {
          return <AddressRangerPicker {...commonProps} />;
        })
        .with({ propType: 'address', op: P.select() }, (_op) => {
          return (
            <AddressSelect
              {...commonProps}
              config={config}
              templateId={takeOne(templateId)}
              templateUsage={templateUsage}
              // @ts-ignore
              type={
                // @ts-ignore
                {
                  rangeEqualProv: 'province',
                  rangeEqualCity: 'city',
                  rangeEqualDist: 'district',
                  rangeEqualStreet: 'street',
                  equal: 'full',
                }[_op]
              }
            />
          );
        })
        .with({ propType: P.union('date', 'datetime'), op: 'timeBein' }, () => {
          return (
            <DateRangerPicker
              {...commonProps}
              config={config}
              templateId={templateId}
              templateUsage={templateUsage}
            />
          );
        })
        .with({ propType: P.union('date', 'datetime') }, () => {
          const justify = match(config.op)
            .with('timeBefore', 'timeAfterEqual', () => 'startOf')
            .with('timeAfter', 'timeBeforeEqual', () => 'endOf')
            .otherwise(() => undefined);
          return (
            <DatePicker
              {...commonProps}
              templateId={templateId}
              config={config}
              justify={justify}
            />
          );
        })
        .with({ propType: P.union('text', 'str', 'string' as any) }, () => {
          return <TextEditor {...commonProps} />;
        })
        .with(
          { propType: P.union('number', 'currency'), op: 'beIncluded' },
          () => {
            return <NumberRangerInput {...commonProps} />;
          },
        )
        .with(
          { propType: P.union('number', 'currency'), op: P.select() },
          () => {
            return <NumberInput {...commonProps} />;
          },
        )
        .otherwise(() => {
          return (
            <TextEditor
              className={className}
              style={style}
              disabled={disabled}
            />
          );
        });
    });

  console.log('supportInputType', supportInputType);

  const propType = conditionConfig?.data?.type;

  const typeConfigs: Record<string, EditorTypeConfig> = useMemo(
    () => ({
      currentNode: currentNodeConfig,
      globalConditionFilter: {
        label: '筛选组件',
        icon: <RegularIcon type="iconguolvshaixuan" />,
        renderer: ({ value: v }) =>
          getGlobalConditionFilterItemElement({
            widgetId: v.widgetId,
            itemId: v.itemId,
            index: v.index,
          }),
        menuSub: ({ onSelect, propType: _propType, op }) => (
          <GlobalConditionFilterMenu
            onChange={onSelect}
            propType={_propType || 'datetimeRange'}
            op={op}
          />
        ),
      },
    }),
    [],
  );

  return (
    <ValueEditorWithMenu<ValueEditorWithMenuValue>
      disabled={disabled}
      supportInputType={supportInputType}
      value={
        config?.extends && {
          type: config.extends.inputType,
          ...omit(['inputType', 'type'], config.extends),
        }
      }
      onChange={(v) => {
        onExtendsChange?.({
          ...omit(['type'], v),
          inputType: v.type,
        });
      }}
      typeConfigs={propType ? typeConfigs : undefined}
      menuSubProps={{
        propType,
        op: config.op,
      }}
    >
      {customEditor}
    </ValueEditorWithMenu>
  );
};
