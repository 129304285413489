import { produce } from 'immer';
import { atom } from 'jotai';

import { DEFAULT_OPERATORS } from '../const';
import type {
  Condition,
  ConditionFilterConfig,
  ConditionGroup,
  LogicType,
  RootCondition,
  TypeConfig,
} from '../types';

// 存储整个条件树的atom
export const rootConditionAtom = atom<RootCondition>({
  op: 'and',
  input: [],
});

// 更新指定路径的条件
export const updateConditionAtom = atom(
  null,
  (get, set, path: number[], updates: Partial<Condition>) => {
    set(
      rootConditionAtom,
      produce((draft: RootCondition) => {
        if (path.length === 0) {
          // 更新根节点
          Object.keys(updates).forEach((key) => {
            draft[key as keyof RootCondition] =
              updates[key as keyof RootCondition];
          });
          return;
        }

        // 遍历路径找到目标节点的父节点
        let current: RootCondition | ConditionGroup = draft;
        for (let i = 0; i < path.length - 1; i++) {
          const index = path[i];
          current = current.input[index] as ConditionGroup;
        }

        // 更新目标节点
        const lastIndex = path[path.length - 1];
        Object.keys(updates).forEach((key) => {
          (current.input[lastIndex] as Condition)[key as keyof Condition] =
            updates[key as keyof Condition];
        });
      }),
    );
  },
);

// 修改addConditionAtom,专门用于添加Condition
export const addConditionAtom = atom(
  null,
  (get, set, groupIndex: number, condition: Condition) => {
    set(
      rootConditionAtom,
      produce((draft: RootCondition) => {
        draft.input[groupIndex].input.push(condition);
      }),
    );
  },
);

// 修改removeConditionAtom的实现
export const removeConditionAtom = atom(
  null,
  (get, set, path: [number, number]) => {
    set(
      rootConditionAtom,
      produce((draft: RootCondition) => {
        {
          // 删除具体条件
          const [groupIndex, conditionIndex] = path;
          draft.input[groupIndex].input.splice(conditionIndex, 1);
          // 如果条件组为空，则删除条件组
          if (draft.input[groupIndex].input.length === 0) {
            draft.input.splice(groupIndex, 1);
          }
        }
      }),
    );
  },
);

// 清空所有条件
export const clearConditionsAtom = atom(null, (_, set) => {
  set(rootConditionAtom, {
    op: 'and',
    input: [{ op: 'and', input: [{ key: '', op: 'intersect', input: [] }] }],
  });
});

// 添加条件组
export const addConditionGroupAtom = atom(null, (get, set) => {
  set(
    rootConditionAtom,
    produce((draft: RootCondition) => {
      draft.input.push({
        op: 'and',
        input: [
          {
            key: '',
            op: 'intersect',
            input: [],
          },
        ],
      });
    }),
  );
});

// 更新条件组的逻辑操作符
export const updateGroupLogicAtom = atom(
  null,
  (get, set, groupIndex: number, logic: LogicType) => {
    set(
      rootConditionAtom,
      produce((draft: RootCondition) => {
        draft.input[groupIndex].op = logic;
      }),
    );
  },
);

// 更新根节点的逻辑操作符
export const updateRootLogicAtom = atom(null, (get, set, logic: LogicType) => {
  set(
    rootConditionAtom,
    produce((draft: RootCondition) => {
      draft.op = logic;
    }),
  );
});

// 移动条件组的位置
export const moveConditionGroupAtom = atom(
  null,
  (get, set, fromIndex: number, toIndex: number) => {
    set(
      rootConditionAtom,
      produce((draft: RootCondition) => {
        const [removed] = draft.input.splice(fromIndex, 1);
        draft.input.splice(toIndex, 0, removed);
      }),
    );
  },
);

// 配置atom
export const conditionFilterConfigAtom = atom<ConditionFilterConfig>({
  templateId: 'ff',
});

// 存储类型配置的atom
export const typeConfigsAtom = atom(DEFAULT_OPERATORS);

// 注册类型配置的atom
export const registerTypeAtom = atom(null, (get, set, config: TypeConfig) => {
  set(typeConfigsAtom, (prev) => {
    return { ...prev, ...config };
  });
});
