import { useMemoizedFn } from 'ahooks';
import { useAtom, useSetAtom } from 'jotai';
import { useMemo } from 'react';

import type { Condition } from '../types';
import {
  addConditionAtom,
  removeConditionAtom,
  rootConditionAtom,
  updateConditionAtom,
} from './atoms';

export function useConditionItem(groupIndex: number, conditionIndex: number) {
  const [rootCondition] = useAtom(rootConditionAtom);
  const updateCondition = useSetAtom(updateConditionAtom);
  const removeCondition = useSetAtom(removeConditionAtom);
  const addCondition = useSetAtom(addConditionAtom);

  const item = useMemo(() => {
    // 返回具体条件
    return rootCondition.input[groupIndex].input[conditionIndex];
  }, [rootCondition, groupIndex, conditionIndex]);

  const updateItem = useMemoizedFn((updates: Partial<Condition>) => {
    if (item) {
      updateCondition([groupIndex, conditionIndex], updates);
    }
  });

  const removeItem = useMemoizedFn(() => {
    removeCondition([groupIndex, conditionIndex]);
  });

  const addNewCondition = useMemoizedFn((condition: Condition) => {
    addCondition(groupIndex, condition);
  });

  return {
    item,
    updateItem,
    removeItem,
    addNewCondition,
  };
}
