import {
  type CurrentUser,
  getQuoteOriginPropInfo,
  type ViewList,
} from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';

import { useConditionOptions } from '@/components/ConditionFilter/hook/useConditionOptions';
import { type TemplateMapUsage, useUnsafeTemplateMap } from '@/hook';

import type { Condition } from '../types';
import { genDataPropKey } from '../utils';

type UseConditionFilterInnerConfigProps = {
  templateId: string | string[];
  allowKey?: ViewList.ViewconditionV2Item['key'][];
  allowPropTypes?: CurrentUser.propType[];
  /**
   * 主题类型使用方式
   */
  templateUsage?: TemplateMapUsage;
};

/**
 * 获取条件项的配置
 *
 * TODO:
 *
 * 应该将不同类型的配置逻辑抽离出去，
 * 放到每个组件的内部逻辑中
 */
export const useConditionFilterInnerConfig = ({
  templateId,
  templateUsage = 'filteredProps',
  allowKey,
  allowPropTypes,
}: UseConditionFilterInnerConfigProps) => {
  const tempMap = useUnsafeTemplateMap(templateUsage);

  const curTemplate =
    typeof templateId === 'string' ? tempMap[templateId] : undefined;

  const curTemplateList = useMemo(() => {
    return Array.isArray(templateId)
      ? templateId.map((id) => tempMap[id])
      : undefined;
  }, [templateId, tempMap]);

  const result = useConditionOptions({
    curTemplate,
    curTemplateList,
    tempMap,
    allowKey,
    allowPropTypes,
  });

  return result;
};

export const useGetConditionItemInnerConfig = (
  args: UseConditionFilterInnerConfigProps,
) => {
  const { filterConditionConfigMapV2 } = useConditionFilterInnerConfig(args);
  const tempMap = useUnsafeTemplateMap('fullAccess');

  const getConfig = useMemoizedFn(
    (key: Condition['key'], index: Condition['index']) => {
      const dataPropKey = genDataPropKey(key, index);
      const config = cloneDeep(filterConditionConfigMapV2[dataPropKey]);
      if (config?.data?.type === 'quote') {
        config.data = getQuoteOriginPropInfo(
          config.data,
          tempMap,
        ) as unknown as (typeof config)['data'];
      }

      if (config?.data?.type === 'formula') {
        if (config.data.formulaFormat === 0) config.data.type = 'number';
        else config.data.type = 'text';
      }

      if (config?.data?.type && config.data.type !== config.type) {
        config.type = config.data.type;
      }
      return config;
    },
  );

  return getConfig;
};

type UseConditionItemInnerConfigProps = Pick<Condition, 'key' | 'index'> &
  UseConditionFilterInnerConfigProps;
export const useConditionItemInnerConfig = ({
  key,
  index,
  templateId,
  templateUsage,
}: UseConditionItemInnerConfigProps) => {
  const getConfig = useGetConditionItemInnerConfig({
    templateId,
    templateUsage,
  });

  const tempMap = useUnsafeTemplateMap(templateUsage || 'fullAccess');

  const config = getConfig(key, index);

  if (config?.data?.type === 'quote') {
    config.data = getQuoteOriginPropInfo(
      config.data,
      tempMap,
    ) as unknown as (typeof config)['data'];
  }

  if (config?.data?.type === 'formula') {
    if (config.data.formulaFormat === 0) config.data.type = 'number';
    else config.data.type = 'text';
  }

  if (config?.data?.type && config.data.type !== config.type) {
    config.type = config.data.type;
  }

  return config as typeof config | undefined;
};
