import { FullscreenOutlined } from '@ant-design/icons';
import type { ApiResponse } from '@linkpi/core';
import {
  DEFAULT_STATUS_PROP,
  generateAddOpId,
  getDefaultTempProp,
  propIsNull,
  STATUS_ICON,
  tempValueDisplay,
  updateProp,
} from '@linkpi/core';
import type { PiConnection } from '@linkpi/core/web';
import { useSelector } from '@umijs/max';
import { useMemoizedFn, useToggle } from 'ahooks';
import {
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Tooltip,
} from 'antd';
import { filter, pipe, values } from 'ramda';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { PiButton } from '@/components';
import { ConditionFilterButton } from '@/components/ConditionFilter';
import { filterChecker } from '@/components/CustomButton/utils';
import type { DraftsModalProps } from '@/components/DraftNodeModal/DraftsModal';
import DraftsModal from '@/components/DraftNodeModal/DraftsModal';
import LinkPiModal from '@/components/LinkPiModal';
import { useOrgTempMap, useOrgUserMap } from '@/hook';
import {
  useOrgDepartmentNodes,
  useOrgDepartmentNodesMap,
} from '@/hook/useOrgStructure';
import { propDisplay } from '@/pages/home/components/TempStatus';
import request from '@/utils/request';
import { getAttachmentValue, toRecord } from '@/utils/utils';

import './index.less';

const allowKey = ['prop', 'templateId', 'status'];
const allowPropTypes: ApiResponse.CurrentUser.propType[] = [
  'enum',
  'tag',
  'text',
  'number',
  'formula',
];

interface propsType {
  visible: boolean;
  onCancel: () => void;
  node: any;
  propIndex: number;
  orgConnection: PiConnection;
  orgId: string;
  mode: string;
  afterClose?: () => void;
  onOk?: any;
}
interface tableHeader {
  name: string;
  width: number;
  type: string;
  index: number;
  sort: number;
  sticky?: 'left' | 'right';
  key: string;
}

type NodeItem = {
  v: string;
  aux?: string;
  node: PiNode;
};

const toNodeRecord = toRecord((item: NodeItem) => ({
  [item.v]: item,
}));

const defaultWidth = 160;

/**
 * 选值属性-一次性引用-选值范围-节点列表样式
 */
const QuoteSelector: FC<propsType> = (props) => {
  const {
    visible,
    onCancel,
    node,
    propIndex,
    orgConnection,
    orgId,
    mode,
    afterClose,
    onOk,
  } = props;
  const { spaceUserList } = useSelector((state: any) => state.workspace);
  const currentUser: ApiResponse.CurrentUser = useSelector(
    (rootState: any) => rootState.user.currentUser,
  );
  const departmentMap = useOrgDepartmentNodesMap();
  const headerScrollRef = useRef<any>(null);
  const bodyScrollRef = useRef<any>(null);
  const [showDraftsModal, setShowDraftsModal] = useState(false);
  const [parentId, setParentId] = useState('');
  const [draftsNodeData, setDraftsNodeData] = useState<
    DraftsModalProps['draftsNodeData'] | null
  >(null);
  const [flag, setFlag] = useState(0);
  const [quoteNodes, setQuoteNodes] = useState<any[]>([]);
  const [propValue, setPropValue] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const tempMap = useOrgTempMap();

  const currentTemplate = tempMap[node?.prop?._sys_temp[0]] || null;

  const matchingTemplate = useMemo(() => {
    if (currentTemplate?.prop?.[propIndex]?.matchingTemplate) {
      return (
        tempMap[currentTemplate?.prop?.[propIndex]?.matchingTemplate] || null
      );
    }
    return null;
  }, [currentTemplate, propIndex, tempMap]);

  const [filterValue, setFilterValue] =
    useState<ApiResponse.ViewList.ViewconditionV2>([
      {
        op: 'beIncluded',
        key: 'templateId',
        input: [matchingTemplate?.template_id || 'ff'],
      },
    ]);

  const [_quoteOptions, setQuoteOptions] = useState<Record<string, NodeItem>>(
    {},
  );
  const quoteOptions = useMemo(() => {
    if (filterValue.length > 1) {
      return pipe(
        values,
        filter((_n: NodeItem) => filterChecker(filterValue)(_n.node)),
        toNodeRecord,
      )(_quoteOptions);
    }
    return _quoteOptions;
  }, [_quoteOptions, filterValue]);

  // 从接口获取节点
  useEffect(() => {
    if (visible) {
      fetchNodes();
      if (!propIsNull(node?.prop._sys_temp?.[1]?.[propIndex])) {
        setPropValue(
          Array.isArray(node.prop._sys_temp[1][propIndex])
            ? node.prop._sys_temp[1][propIndex]
            : [node.prop._sys_temp[1][propIndex]],
        );
      } else {
        setPropValue([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const fetchNodes = useMemoizedFn(async () => {
    const res = await request('/docapi/execCalcFormula', {
      method: 'POST',
      data: {
        org_id: orgId,
        node_id: node.id,
        index: propIndex,
        // getNodeId: true,
        getQuoteArray: true,
      },
    });
    if (res.status === 'ok') {
      const nodes = (Array.isArray(res.data) ? res.data : [res.data])
        .map((d: any) => orgConnection.nodeManager.findChildren(d?.n))
        .filter((n: any) => !!n);
      setQuoteNodes(nodes);
      const newOptions: Record<string, any> = {};
      const prop = currentTemplate?.prop[propIndex];
      (Array.isArray(res.data) ? res.data : [res.data]).forEach((d: any) => {
        const node = nodes.find((n: any) => n.id === d.n);
        const aux =
          d?.aux && prop?.auxiliaryProp
            ? Array.isArray(d.aux)
              ? d.aux.join(';')
              : d.aux
            : undefined;
        if (node) {
          if (typeof d.v === 'string') {
            // TODO 相同选项，不同的辅助属性如何处理
            if (!newOptions[d.v]) {
              newOptions[d.v] = {
                v: [d.v],
                aux: aux,
                node,
              };
            }
          } else if (Array.isArray(d.v)) {
            d.v.forEach((v: any) => {
              if (typeof v === 'string') {
                if (!newOptions[v]) {
                  newOptions[v] = {
                    v: v,
                    aux: aux,
                    node,
                  };
                }
              }
            });
          }
        }
      });
      if (Array.isArray(prop?.extend) && prop?.extend.length) {
        prop.extend.forEach((e: string) => {
          if (!newOptions[e]) {
            newOptions[e] = {};
          }
        });
      }
      setQuoteOptions(newOptions);
    }
  });

  const updateNodeProp = useMemoizedFn(async (value: string[]) => {
    if (!currentTemplate) return;
    const newActionReq = {
      node_id: node.id,
      org_id: orgId,
      temp_id: currentTemplate.template_id,
      index: [propIndex],
      value: [
        value.length && !currentTemplate.prop[propIndex]?.multiple
          ? value[0]
          : value,
      ],
      commit: {},
    };
    if (
      currentTemplate.prop[propIndex]?.recordModification &&
      mode === 'edit'
    ) {
      newActionReq.commit = { [propIndex]: '' };
      // 未开启requireModificationRemark commit空，否则弹窗拿备注
      if (currentTemplate.prop[propIndex]?.requireModificationRemark) {
        // @ts-ignore
        const [err, commit] = await recordCommit();
        if (err) return;
        newActionReq.commit = { [propIndex]: commit };
      }
    }
    updateProp(request, newActionReq).then(([, res]) => {
      if (res?.status === 'ok') {
        message.success('编辑成功');
      } else {
        return message.error('内容重复，请重新选择');
      }
      onCancel();
    });
  });

  const [commitForm] = Form.useForm();
  const recordCommit = useMemoizedFn(() => {
    return new Promise((r) => {
      const commitModal = Modal.confirm({
        title: '提交修改',
        content: (
          <Form form={commitForm} layout="vertical" autoComplete="off">
            <Form.Item
              rules={[{ required: true, message: '请输入备注' }]}
              name="commit"
              label="请填写备注，否则修改的属性值将无法保存"
            >
              <Input />
            </Form.Item>
          </Form>
        ),
        okText: '提交',
        onOk: () => {
          commitForm.validateFields().then(() => {
            commitModal.destroy();
            r([null, commitForm.getFieldValue('commit')]);
            commitForm.resetFields();
          });
        },
        onCancel: () => {
          commitModal.destroy();
          r([true, null]);
        },
      });
    });
  });

  const clickRow = useMemoizedFn((o: string) => {
    const prop = currentTemplate?.prop[propIndex];

    if (prop?.multiple) {
      const index = propValue.indexOf(o);
      const newValue = [...propValue];
      if (~index) {
        newValue.splice(index, 1);
      } else {
        newValue.push(o);
      }
      setPropValue(newValue);
    } else {
      if (onOk) {
        onOk([quoteOptions[o].node]);
        return;
      }

      if (propValue.includes(o)) {
        updateNodeProp([]);
      } else updateNodeProp([o]);
    }
  });

  const selectAll = useMemoizedFn((e: boolean) => {
    if (!search) {
      setPropValue(e ? Object.keys(quoteOptions) : []);
    } else {
      const searchKeys = Object.keys(quoteOptions).filter(
        (o) =>
          ~o.toLowerCase().indexOf(search.toLowerCase()) ||
          (quoteOptions[o].aux &&
            ~quoteOptions[o].aux.toLowerCase().indexOf(search.toLowerCase())),
      );
      if (searchKeys.length) {
        setPropValue(
          e
            ? [
                ...propValue,
                ...searchKeys.filter((s) => !propValue.includes(s)),
              ]
            : [...propValue.filter((x) => !searchKeys.includes(x))],
        );
      }
    }
  });

  const modalContent = useMemo(() => {
    const prop = currentTemplate?.prop[propIndex];
    if (!prop) return null;
    const defaultHeaders: Array<tableHeader> = [
      {
        name: '状态',
        width: 120,
        type: 'status',
        index: -2,
        sort: -2,
        key: 'status',
      },
      {
        name: '标题',
        width: 200,
        type: 'text',
        index: -1,
        sort: -1,
        sticky: 'left',
        key: 'title',
      },
    ];
    if (
      matchingTemplate &&
      Array.isArray(matchingTemplate.prop) &&
      matchingTemplate.prop.length
    ) {
      matchingTemplate.prop.forEach((p, i) => {
        if (p?.type && !p.hide) {
          defaultHeaders.push({
            name: p.name,
            width: p.type === 'text' ? 200 : defaultWidth,
            type: p.type,
            index: i,
            sort: 'sort' in p ? p.sort : i,
            key: `prop-${i}`,
          });
        }
      });
    }

    defaultHeaders.sort((b, a) => b.sort - a.sort);
    defaultHeaders.push.apply(
      defaultHeaders,
      DEFAULT_STATUS_PROP.map((s, i) => ({
        name: s.name,
        width: defaultWidth,
        type: s.type,
        index: i - 7,
        sort: i - 7,
        key: 'status-' + i,
      })),
    );
    const headers = prop.enumColumns ? [] : defaultHeaders;
    if (prop.enumColumns?.length) {
      prop.enumColumns.forEach((e) => {
        const header = defaultHeaders.find((h) => h.key === e.key);
        if (header && e.checked) {
          headers.push(header);
        }
      });
    }
    let tableWidth = prop.multiple ? 32 : 0;
    headers.forEach((h) => {
      tableWidth += h.width;
    });

    const searchKeys = Object.keys(quoteOptions).filter(
      (o) =>
        ~o.toLowerCase().indexOf(search.toLowerCase()) ||
        (quoteOptions[o].aux &&
          ~quoteOptions[o].aux.toLowerCase().indexOf(search.toLowerCase())),
    );
    const filterQuoteOptions = Object.keys(quoteOptions);
    const newOption = search.split(/[,;，；]/).filter((x) => {
      return !filterQuoteOptions.includes(x);
    });
    type QuoteOptionsType = Record<string, {}>;
    const handleKeyDown = (v: any) => {
      const prop = currentTemplate?.prop?.[propIndex];
      if (!prop) return;
      if (prop.type !== 'tag') return;

      if (v.key === 'Enter') {
        const { nativeEvent } = v;
        const { isComposing } = nativeEvent;
        if (isComposing) return;
        if (newOption.length <= 0) return;
        if (prop.type === 'tag' && !prop.enumNotSave && newOption.length) {
          request('/docapi/addTempPropTag', {
            method: 'POST',
            data: {
              org_id: orgId,
              temp_id: currentTemplate.template_id,
              prop_index: [propIndex],
              tag: [newOption],
            },
          });
        }
        const value = prop.multiple
          ? [...propValue, ...newOption]
          : [newOption[0]];

        setPropValue(value);

        const newQuoteOptions: QuoteOptionsType = {};

        newOption.map((key) => {
          newQuoteOptions[key] = {};
        });

        Object.keys(quoteOptions).forEach((key) => {
          newQuoteOptions[key] = quoteOptions[key];
        });
        setQuoteOptions(newQuoteOptions);
        setTimeout(() => {
          setSearch('');
        }, 50);
      }
    };
    return (
      <div id={'QuoteSelector'} style={{ height: '100%', width: '100%' }}>
        <div
          className={'quote-option'}
          style={{ paddingBottom: prop.multiple ? 0 : 24 }}
        >
          <Row gutter={8} align="middle">
            <Col flex="auto">
              <Input
                prefix={
                  <i
                    className={'iconfont iconsearch'}
                    style={{ color: '#EBEDF0' }}
                  />
                }
                placeholder={'请输入关键字搜索'}
                value={search}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {prop?.type === 'tag' &&
              search.length > 0 &&
              !searchKeys.includes(search) ? (
                <div className="new-option">{`输入“，；”可分开选项，按回车创建 ${newOption}`}</div>
              ) : null}
            </Col>
            <Col flex="none">
              <ConditionFilterButton
                orgId={orgId}
                value={filterValue}
                onChange={setFilterValue}
                allowKey={allowKey}
                allowPropTypes={allowPropTypes}
                displayParentId={false}
                displayTemplate={false}
              />
            </Col>
          </Row>

          <PerfectScrollbar
            ref={headerScrollRef}
            onScrollX={(e) =>
              (bodyScrollRef.current._container.scrollLeft = e.scrollLeft)
            }
          >
            <div
              className={'quote-option-header'}
              style={{ width: tableWidth }}
            >
              {prop.multiple ? (
                <Checkbox
                  checked={
                    !!searchKeys.length &&
                    !~searchKeys.findIndex((o) => !propValue.includes(o))
                  }
                  disabled={!searchKeys.length}
                  style={{ marginLeft: 6 }}
                  onChange={(e) => selectAll(e.target.checked)}
                />
              ) : null}
              {headers.map((h, i) => (
                <div
                  key={i}
                  className={`quote-option-header-cell text-omit`}
                  style={{
                    width: h.width,
                  }}
                >
                  <span>{h.name}</span>
                </div>
              ))}
            </div>
          </PerfectScrollbar>
          <PerfectScrollbar
            ref={bodyScrollRef}
            onScrollX={(e) =>
              (headerScrollRef.current._container.scrollLeft = e.scrollLeft)
            }
            // style={{ height: 'calc(100vh - 290px)' }}
          >
            <div className={'quote-option-body'} style={{ width: tableWidth }}>
              {searchKeys.map((o, i) => {
                const n = quoteOptions[o].node;
                return (
                  <div
                    key={i}
                    onClick={() => clickRow(o)}
                    className={`quote-option-body-column${propValue.includes(o) ? ' active' : ''}`}
                  >
                    {prop.multiple ? (
                      <Checkbox
                        checked={propValue.includes(o)}
                        style={{ marginLeft: 6, marginTop: n ? -2 : -4 }}
                      />
                    ) : null}
                    <div className={'quote-option-body-column-item'}>
                      <div
                        className={'quote-option-body-column-item-label'}
                        style={{ paddingBottom: n ? 0 : 12 }}
                      >
                        {/*{quoteOptions[o].aux && prop?.auxiliaryProp?.position ? (
                          <div className={'quote-option-body-column-item-label-aux'}>
                            {quoteOptions[o].aux}-
                          </div>
                        ) : null}*/}
                        {o}
                        {/*{quoteOptions[o].aux && !prop?.auxiliaryProp?.position ? (
                          <div className={'quote-option-body-column-item-label-aux'}>
                            -{quoteOptions[o].aux}
                          </div>
                        ) : null}*/}
                      </div>
                      {n ? (
                        <div className={'quote-option-body-column-item-main'}>
                          {headers.map((h) => {
                            if (h.index < 0) {
                              if (h.index === -1) {
                                return (
                                  <div
                                    className={
                                      'quote-option-body-column-item-main-cell text-omit'
                                    }
                                    style={{ width: h.width }}
                                    key={h.key}
                                  >
                                    {n.title || '无标题'}
                                  </div>
                                );
                              } else {
                                const curStatus =
                                  matchingTemplate?.task_status[
                                    n.tempInfo.status
                                  ];
                                if (h.index === -2) {
                                  // 状态
                                  return curStatus && !curStatus.delete ? (
                                    <div
                                      className={`quote-option-body-column-item-main-cell text-omit status`}
                                      style={{ width: h.width }}
                                      key={h.key}
                                    >
                                      <i
                                        className={`iconfont ${curStatus.icon}`}
                                        // @ts-ignore
                                        style={{
                                          color:
                                            STATUS_ICON[curStatus.icon]?.color,
                                        }}
                                      />
                                      {curStatus.name}
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        'quote-option-body-column-item-main-cell'
                                      }
                                      style={{ width: h.width }}
                                      key={h.key}
                                    />
                                  );
                                } else {
                                  const si = h.index + 7;
                                  return (
                                    <div
                                      className={`quote-option-body-column-item-main-cell text-omit`}
                                      style={{ width: h.width }}
                                      key={h.key}
                                    >
                                      {curStatus &&
                                      curStatus.prop?.[si]?.display
                                        ? propDisplay(
                                            curStatus.prop[si],
                                            n.tempInfo.statusProp[si],
                                            spaceUserList[orgId],
                                            tempMap,
                                            n.prop._sys_cascade,
                                            h.index,
                                            n,
                                            departmentMap,
                                          )
                                        : null}
                                    </div>
                                  );
                                }
                              }
                            } else {
                              let res: any = {};
                              if (
                                matchingTemplate?.prop[h.index].type === 'quote'
                              ) {
                                res = propDisplay(
                                  matchingTemplate?.prop[h.index],
                                  n.tempInfo.prop[h.index],
                                  spaceUserList[orgId],
                                  tempMap,
                                  n.prop._sys_cascade,
                                  h.index,
                                  n,
                                  departmentMap,
                                );
                              } else if (
                                matchingTemplate?.prop[h.index].type ===
                                'attachment'
                              ) {
                                res.value = getAttachmentValue(n, h.index);
                                res.type = 'quote-attachment';
                              } else {
                                res = tempValueDisplay({
                                  propConfig: matchingTemplate?.prop[h.index],
                                  propValue: n.tempInfo.prop[h.index],
                                  propIndex: h.index,
                                  sysCascade: n.prop._sys_cascade,
                                  userMap: spaceUserList[orgId],
                                  tempMap,
                                  departmentMap,
                                });
                              }

                              return (
                                <div
                                  className={`quote-option-body-column-item-main-cell text-omit`}
                                  style={{ width: h.width }}
                                  key={h.key}
                                >
                                  {res?.type === 'quote-attachment'
                                    ? res.value.map((f: any, index: number) => (
                                        <div
                                          key={index}
                                          style={{
                                            backgroundImage: `url(${f.src})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: 32,
                                            width: 32,
                                            marginRight: 6,
                                          }}
                                        />
                                      ))
                                    : res}
                                </div>
                              );
                            }
                          })}
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
  }, [
    currentTemplate?.prop,
    currentTemplate.template_id,
    propIndex,
    matchingTemplate,
    quoteOptions,
    search,
    propValue,
    orgId,
    selectAll,
    clickRow,
    spaceUserList,
    tempMap,
    departmentMap,
  ]);

  const userMap = useOrgUserMap();
  const departmentNodes = useOrgDepartmentNodes();

  const addQuoteNode = useMemoizedFn(async () => {
    const quoteNode = quoteNodes[0];
    const parent_id =
      quoteNode && quoteNode.parent
        ? quoteNode.parent.id
        : currentTemplate?.prop[propIndex].matchingSource;
    const id = generateAddOpId();

    if (!currentTemplate?.prop[propIndex] || !matchingTemplate) return;

    const { conditionProp, quoteProp } = currentTemplate.prop[propIndex];

    // 新节点的属性
    const newProps = getDefaultTempProp(
      {},
      matchingTemplate,
      currentUser,
      null,
      userMap,
      departmentNodes,
      null,
    );

    // 条件匹配的值
    (quoteProp || []).forEach((i: string, index: number) => {
      const [_, pIndex] = i.split('-');
      newProps._sys_temp[1][Number(pIndex)] =
        node.tempInfo.prop[conditionProp[index]];
    });

    // 新建草稿节点
    setDraftsNodeData({
      org_id: orgId,
      parentId: parent_id,
      siblingId: null,
      draft: true,
      node: {
        node_id: id,
        prop: newProps,
        title: '',
      },
    });
    setParentId(parent_id);
    setShowDraftsModal(true);
  });

  const [fullScreen, { toggle: toggleFullScreen }] = useToggle();
  const modalSizeProps = fullScreen
    ? {
        width: '100vw',
        height: '100%',
      }
    : {
        width: 'min(1200px, 80vw)',
      };

  const title = useMemo(
    () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>请选择</span>
          <span style={{ color: '#B7BAC0', fontSize: 14, paddingLeft: 8 }}>
            已选{propValue.filter((x) => !!quoteOptions[x]).length}条
          </span>
        </div>
        <Space>
          {currentTemplate?.prop[propIndex]?.matchingAdd ? (
            <div onClick={addQuoteNode} className="quote-node-add-btn">
              <i className="iconfont iconButton_add" />
              <span> 新增{matchingTemplate?.name || '主题'}</span>
            </div>
          ) : null}
          <Tooltip title="最大化">
            <PiButton
              type="secondary"
              shape="circle"
              icon={
                <FullscreenOutlined
                  type="iconContent_Download"
                  style={{ color: '#7b8aa3' }}
                />
              }
              onClick={toggleFullScreen}
            />
          </Tooltip>
        </Space>
      </div>
    ),
    [
      addQuoteNode,
      currentTemplate?.prop,
      matchingTemplate?.name,
      propIndex,
      propValue,
      quoteOptions,
      toggleFullScreen,
    ],
  );

  return (
    <>
      <LinkPiModal
        {...modalSizeProps}
        centered
        open={visible}
        modalContent={modalContent}
        onCancel={onCancel}
        title={title}
        noFooter={!currentTemplate?.prop[propIndex]?.multiple}
        onOk={() => {
          if (onOk) {
            onOk(propValue.map((i) => quoteOptions[i].node));
          }
          updateNodeProp(propValue);
        }}
        afterClose={afterClose}
      />
      {showDraftsModal && (
        <DraftsModal
          visible={showDraftsModal}
          mode="add"
          onCancel={() => setShowDraftsModal(false)}
          orgId={orgId}
          draftsNodeData={draftsNodeData!}
          initParentId={parentId}
          onSuccess={() => {
            setTimeout(() => {
              setFlag(flag + 1);
              fetchNodes();
            }, 500);
          }}
        />
      )}
    </>
  );
};

export default QuoteSelector;
