let storageSupported = false;
try {
  storageSupported = window.localStorage && true;
} catch (e) {}
if (!storageSupported) {
  Object.defineProperty(window, 'localStorage', {
    value: {
      _$data: {} as Record<string, string>,
      setItem: function (id: string, val: string): string {
        return (this._$data[id] = String(val));
      },
      getItem: function (id: string): string | undefined {
        return this._$data.hasOwnProperty(id) ? this._$data[id] : undefined;
      },
      removeItem: function (id: string): boolean {
        return delete this._$data[id];
      },
      clear: function (): Record<string, string> {
        return (this._$data = {});
      },
    },
  });
  Object.defineProperty(window, 'sessionStorage', {
    value: {
      _$data: {} as Record<string, string>,
      setItem: function (id: string, val: string): string {
        return (this._$data[id] = String(val));
      },
      getItem: function (id: string): string | undefined {
        return this._$data.hasOwnProperty(id) ? this._$data[id] : undefined;
      },
      removeItem: function (id: string): boolean {
        return delete this._$data[id];
      },
      clear: function (): Record<string, string> {
        return (this._$data = {});
      },
    },
  });
}
