import { QueryClientProvider } from '@tanstack/react-query';
import type { ReactElement } from 'react';

import { VersionInfoWrapper } from '@/components/VersionInfo';

import { CustomWidgeUtilsProvider } from './components';
import { NodeContentModalProvider } from './hook';
import { getTanstackQueryClient } from './utils/tanstackQuery';

import 'reflect-metadata';

export function rootContainer(container: ReactElement) {
  const queryClient = getTanstackQueryClient();

  return (
    <NodeContentModalProvider>
      <CustomWidgeUtilsProvider>
        <QueryClientProvider client={queryClient}>
          <VersionInfoWrapper>{container}</VersionInfoWrapper>
        </QueryClientProvider>
      </CustomWidgeUtilsProvider>
    </NodeContentModalProvider>
  );
}
