import { DEFAULT_AVATAR } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { Avatar } from 'antd';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { RegularIcon } from '@/components/IconFont';
import { BaseSelect } from '@/components/LinkPiForm';
import { type TemplateMapUsage, useOrgUserGroup, useOrgUserList } from '@/hook';
import { cn, filterUsersByPinyin, getUserNickName } from '@/utils/utils';

type UserSelectEditorProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  disableCurrentUser?: boolean;
  disableUserGroup?: boolean;
  templateId: string;
  templateUsage?: TemplateMapUsage;
  maxTagCount?: number;
  className?: string;
  disableMultiple?: boolean;
};

export const UserSelect: FC<UserSelectEditorProps> = memo(
  ({
    value,
    onChange,
    maxTagCount,
    className,
    disabled,
    disableCurrentUser,
    disableUserGroup,
    disableMultiple,
  }) => {
    const userList = useOrgUserList();
    const userGroupList = useOrgUserGroup();

    const options = useMemo(() => {
      const userOptions = userList.map((x) => ({
        label: (
          <>
            <Avatar
              src={x.avatar || DEFAULT_AVATAR}
              className="mr-2"
              size={20}
            />
            {getUserNickName(x)}
          </>
        ),
        value: x.account_id,
        filterText: getUserNickName(x),
      }));

      const userGroupOptions = userGroupList.map((x) => ({
        label: (
          <>
            <span className="mr-[4px] rounded-full size-[20px] bg-[#5ABCB0] inline-flex items-center justify-center">
              <RegularIcon type="iconChat_Member" size={12} color="#fff" />
            </span>
            {x.group_name}
          </>
        ),
        value: x.group_id,
        filterText: x.group_name,
      }));

      const _options = [
        {
          label: '用户',
          value: 'user',
          options: userOptions,
        },
      ];
      if (!disableUserGroup) {
        _options.push({
          label: '用户组' as any,
          value: 'userGroup',
          options: userGroupOptions,
        } as any);
      }
      if (!disableCurrentUser) {
        _options.unshift({
          label: (
            <>
              <span className="mr-2 rounded-full bg-white p-1">
                <RegularIcon type="iconuser" size={14} />
              </span>
              当前账户
            </>
          ),
          value: null,
          filterText: '当前账户',
        } as any);
      }
      return _options;
    }, [disableCurrentUser, disableUserGroup, userGroupList, userList]);

    const userSelectConfig = useMemo(() => {
      return Object.values(userList).reduce((res, cur) => {
        const nickName = getUserNickName(cur);
        res[nickName] = cur;

        return res;
      }, {} as any);
    }, [userList]);

    const filterOption = useMemoizedFn((input, option) => {
      return filterUsersByPinyin(
        input,
        { children: option?.filterText },
        userSelectConfig,
      );
    });

    return (
      <BaseSelect
        className={cn('w-full', className)}
        value={value}
        onChange={onChange}
        disabled={disabled}
        options={options as any}
        allowChooseAll
        maxTagCount={maxTagCount}
        filterOption={filterOption}
        mode={disableMultiple ? undefined : 'multiple'}
      />
    );
  },
);
