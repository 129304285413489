import type { FilterItemType, IYearMonthDayConfig } from './types';

export const GLOBAL_CONDITION_LATEST_VERSION = 2 as const;

export const FILTER_TYPE_TEXT_MAP: Record<FilterItemType, string> = {
  cascader: '级联选择',
  text: '输入框',
  yearMonthDay: '日月年',
  number: '数字',
  dateRangePicker: '日期',
  select: '下拉选择',
  $$TEMP_BY_PROP: '动态样式',
};

export const YEAR_MONTH_DAY_TYPE_TEXT_MAP: Record<
  IYearMonthDayConfig['type'],
  string
> = {
  yearMonthDayAndDatePicker: '日月年 - 日期选择',
  yearMonthDay: '日月年',
  datePicker: '日期选择',
};
