import { PlusOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Button, DatePicker, Input, InputNumber, Select } from 'antd';
import moment from 'moment';
import { nanoid } from 'nanoid';
import type { FC } from 'react';
import { useState } from 'react';

import request from '@/utils/request';

import styles from './styles.less';

interface IProps {
  headers: Array<{ id: string; name: string; type: string }>;
  table: { db_id: string; table_id: string; header_map: any } | undefined;
  defaultValue?: any[];
  onChange?: (v: any[]) => void;
}

const notSupport: string[] = [
  'rich_text',
  'address',
  'positioning',
  'attachment',
];

const { Option } = Select;

const TreeTableCondition: FC<IProps> = ({
  headers,
  table,
  onChange,
  defaultValue,
}) => {
  const [conditions, setConditions] = useControllableValue({
    onChange: onChange,
    defaultValue: defaultValue,
  });

  // const [conditions, setConditions] = useState<any[]>(
  //   parseParamsToConditionsAndMatchings(params).conditions,
  // );

  const [valueMap, setValueMap] = useState<any>({});

  // 根据字段类型 获取 op
  const getOpOption = (item: any) => {
    const { field } = item;
    const t = headers.find((i) => i.id === field)?.type;

    if (t === 'user')
      return [
        { label: '包含', value: 'include' },
        { label: '等于', value: 'equal' },
      ];

    if (t === 'date' || t === 'datetime')
      return [
        { label: '处于', value: 'timeBein' },
        { label: '早于', value: 'timeBefore' },
        { label: '早于等于', value: 'timeBeforeEqual' },
        { label: '等于', value: 'timeEqual' },
        { label: '晚于等于', value: 'timeAfterEqual' },
        { label: '晚于', value: 'timeAfter' },
      ];
    if (t === 'enum' || t === 'auto_inc')
      return [{ label: '包含', value: 'include' }];

    if (t === 'text' || t === 'formula_text')
      return [
        { label: '包含', value: 'include' },
        { label: '等于', value: 'equal' },
      ];

    if (t === 'number' || t === 'formula_number' || t === 'currencyV2')
      return [
        { label: '=', value: 'equal' },
        { label: '≠', value: 'notEqual' },
        { label: '>', value: 'greater' },
        { label: '≥', value: 'greaterEqual' },
        { label: '<', value: 'less' },
        { label: '≤', value: 'lessEqual' },
      ];
    return [];
  };

  const add = () => {
    const item = {
      op: '',
      field: undefined,
      value: undefined,
      id: nanoid(),
    };

    setConditions([...conditions, item]);
  };

  const changeField = async (v: string, item: any) => {
    const newRes = [...conditions];
    const opOption = getOpOption({ field: v });
    const _item = newRes.find((i) => i.id === item.id);
    _item.op = opOption[0].value;
    _item.field = v;
    _item.value = undefined;

    setConditions(newRes);

    const t = headers.find((i) => i.id === v)?.type;
    if (['user', 'auto_inc', 'enum'].includes(t as string)) {
      if (!table || !table.header_map) return;

      const { header_map, db_id, table_id } = table;
      const column_name = header_map[v].column;

      const selectRes: any = await request('/api/dbProxy/selectDistinct', {
        method: 'POST',
        data: {
          db_id,
          table_id,
          column_name,
        },
      });
      if (selectRes.result.success) {
        setValueMap({
          ...valueMap,
          [v]: selectRes.result.data,
        });
      }
    }
  };

  const changeOp = (v: string, item: any) => {
    const newRes = [...conditions];
    const _item = newRes.find((i) => i.id === item.id);
    if (_item.op === 'timeBein') _item.value = undefined;

    _item.op = v;

    setConditions(newRes);
  };

  const changeValue = (v: any, item: any) => {
    const newRes = [...conditions];
    newRes.find((i) => i.id === item.id).value = v;

    setConditions(newRes);
  };

  const renderField = (item: any) => {
    return (
      <Select
        value={item.field}
        style={{ width: 140, marginRight: 8 }}
        placeholder="请选择字段"
        onChange={(v) => changeField(v, item)}
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        showSearch
      >
        {headers
          .filter((h) => !notSupport.includes(h.type))
          .map((h) => (
            <Option
              disabled={conditions.find((i) => i.field === h.id)}
              value={h.id}
              key={h.id}
            >
              {h.name}
            </Option>
          ))}
      </Select>
    );
  };
  const renderOp = (item: any) => {
    if (!item.field) return null;

    return (
      <Select
        value={item.op}
        style={{ width: 100, marginRight: 4 }}
        onChange={(v) => changeOp(v, item)}
      >
        {getOpOption(item).map((i) => (
          <Option value={i.value} key={i.value}>
            {i.label}
          </Option>
        ))}
      </Select>
    );
  };

  const getDateValue = (v: any) => {
    if (!v) return [];

    const [start, end] = v;

    return [moment(start), moment(end)];
  };

  const renderValue = (item: any) => {
    if (!item.field)
      return (
        <Input
          style={{ flex: 1, marginRight: 4 }}
          disabled
          value="请先选择字段"
        />
      );
    const t = headers.find((i) => i.id === item.field)?.type;

    if (t === 'date' || t === 'datetime') {
      if (item.op === 'timeBein')
        return (
          <DatePicker.RangePicker
            showTime
            style={{ flex: 1, marginRight: 4 }}
            value={getDateValue(item.value)}
            onChange={(v) => {
              if (v && v[0] && v[1]) {
                changeValue([v[0].valueOf(), v[1].valueOf()], item);
              }
            }}
          />
        );

      return (
        <DatePicker
          style={{ flex: 1, marginRight: 4 }}
          value={item.value ? moment(item.value) : undefined}
          onChange={(v) => {
            changeValue(v?.valueOf(), item);
          }}
          showTime
        />
      );
    }

    if (['user', 'auto_inc', 'enum'].includes(t as string))
      return (
        <Select
          value={item.value}
          style={{ flex: 1, marginRight: 4 }}
          mode="multiple"
          onChange={(v) => {
            changeValue(v, item);
          }}
        >
          {(valueMap[item.field] || []).map((i: any) => (
            <Option value={i.r} key={i.r}>
              {i.r}
            </Option>
          ))}
        </Select>
      );

    if (t === 'number' || t === 'formula_number' || t === 'currencyV2')
      return (
        <InputNumber
          onChange={(v) => {
            changeValue(v, item);
          }}
          placeholder="请输入"
          value={item.value}
          style={{ flex: 1, marginRight: 4 }}
        />
      );

    if (t === 'text' || t === 'formula_text')
      return (
        <Input
          style={{ flex: 1, marginRight: 4 }}
          value={item.value}
          onChange={(v) => {
            changeValue(v.target.value, item);
          }}
          placeholder="请输入"
        />
      );

    return null;
  };

  const del = (id: string) => {
    const newRes = conditions.filter((i) => i.id !== id);
    setConditions(newRes);
  };

  return (
    <div className={styles.container}>
      {conditions.map((i) => (
        <div key={i.id} className={styles.item}>
          {renderField(i)}
          {renderOp(i)}
          {renderValue(i)}
          <i onClick={() => del(i.id)} className="iconfont icona-zujian67" />
        </div>
      ))}
      <Button
        onClick={add}
        style={{ color: '#6B7A96', width: '100%' }}
        type="dashed"
        icon={<PlusOutlined />}
      >
        添加筛选条件
      </Button>
    </div>
  );
};

export default TreeTableCondition;
