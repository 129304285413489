import type { ViewList } from '@linkpi/core';
import { getViewSettingOptionWithGroup } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { Cascader, Form } from 'antd';
import { head, last, pick, toPairs } from 'ramda';
import { type FC, useMemo } from 'react';

import { useCurrentOrgId, useOrgInfo, useOrgTempMap } from '@/hook';
import { traverseTreeCreator } from '@/utils/tree';
import { renameKeys } from '@/utils/utils';

import { useActiveWidgetInstance, useEditor } from '../hooks';
import type { IConditionSetting } from './ConditionSetting';

export type INodeGroupSetting = Pick<ViewList.ViewInfo, 'group'>;

export const NodeGroupSetting: FC = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<
    IConditionSetting & INodeGroupSetting
  >();

  assertExists(widgetInstance);

  const value = widgetInstance?.config;

  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);
  const [orgInfo] = useOrgInfo(orgId);

  assertExists(orgInfo);

  const curTemplateId = head(
    value.conditions.find((i) => i.key === 'templateId')?.input,
  ) as string;

  const { groupOption } = useMemo(() => {
    return getViewSettingOptionWithGroup({
      templateIdList: curTemplateId,
      orgInfo: pick(['groupList', 'role'], orgInfo),
      tempMap,
    });
  }, [curTemplateId, orgInfo, tempMap]);

  const groupCascadarOption = useMemo(() => {
    return toPairs(groupOption).map(([key, o]) => {
      return {
        value: key,
        label: o.name,
        children: o.subMenu?.map((s) =>
          renameKeys(
            {
              key: 'value',
              name: 'label',
            },
            s,
          ),
        ),
      };
    });
  }, [groupOption]);

  const cascadarValue = useMemo(() => {
    const result = [value.group];
    traverseTreeCreator<(typeof groupCascadarOption)[number]>(
      (option, _, parent) => {
        if (option.value === value.group && parent && parent !== 'ROOT') {
          result.unshift(parent.value);
        }
      },
    )(groupCascadarOption);
    return result;
  }, [groupCascadarOption, value.group]);

  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<INodeGroupSetting>;

  return (
    <Form.Item label="分组">
      <Cascader
        expandTrigger="hover"
        options={groupCascadarOption}
        value={cascadarValue}
        onChange={(v) =>
          updateConfig((c) => {
            if (v) c.group = last(v) as string;
            else c.group = undefined;
          })
        }
      />
    </Form.Item>
  );
};
