import { PROP_TYPE } from '@linkpi/core';
import { Input, message, Popconfirm, Select } from 'antd';
import cls from 'classnames';
import { pick } from 'ramda';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { useOrgStructureNode } from '@/hook/useOrgStructure';
import type {
  BasicProp,
  PropType,
} from '@/pages/space/components/TemplateProp/components/TempPropModal/types';
import { TEMP_PROP_OPTIONS } from '@/pages/space/components/TemplateProp/constants';
import { checkCustomerEnv } from '@/utils/checkCustomerEnv';
import { checkPropType as _checkPropType } from '@/utils/utils';

import { useTempPropConfig } from '../hooks';

interface IBasicParams {
  propNameInputRef: any;
  spaceKey: string;
  curUserId: string;
  editingProp: BasicProp | null;
  openConditionMatching: (p: BasicProp) => void;
}

export const BasicParams: FC<IBasicParams> = (props) => {
  const {
    propParams,
    savePropParams,
    setPropParams,
    groups: _groups,
    isDebugMode,
  } = useTempPropConfig();
  const groups = _groups.filter((g) => g.group_id !== '-8');

  const {
    spaceKey,
    curUserId,
    editingProp,
    openConditionMatching,
    propNameInputRef,
  } = props;

  const [temporaryParams, setTemporaryParams] = useState<Partial<BasicProp>>(
    {},
  );

  const { orgStructureNodeId } = useOrgStructureNode({});
  const enableOrgStruct = !!orgStructureNodeId;

  const propOptions = useMemo(() => {
    let po = [...TEMP_PROP_OPTIONS];

    /**
     * 只给未来数智开放「时间段」和「货币」属性
     */
    if (!checkCustomerEnv('DigitalFuture', spaceKey)) {
      po = po.filter((p) => !['datetimeRange', 'currencyV2'].includes(p.value));
    }

    if (spaceKey === curUserId) {
      po.shift();
      const quoteIndex = po.findIndex((x) => x.value === 'quote');
      if (~quoteIndex) po.splice(quoteIndex, 1);
    }
    return po.filter((x) => {
      if (!enableOrgStruct && x.value === 'department') return false;

      return x.value !== 'datetime' && x.value !== 'currency';
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spaceKey, curUserId, editingProp]);

  const changePropType = (type: PropType, isGoDebug: boolean = false) => {
    const newPropParams = isDebugMode
      ? {
          ...pick(
            [
              'name',
              'remark',
              'visGroups',
              'editGroups',
              'hide',
              'index',
              'sort',
            ],
            propParams,
          ),
          type: null,
          multiple: false,
          required: false,
          editable: true,
          recordModification: false,
          requireModificationRemark: false,
        }
      : propParams;

    let params = {
      ...newPropParams,
      type,
      formulaUpdateField: null,
      allowManualUpdate: null,
      defaultFormula: null,
      defaultValue: null,
    } as BasicProp;

    if (isDebugMode && editingProp) {
      if (temporaryParams.name) params.name = temporaryParams?.name;
      if (
        temporaryParams &&
        temporaryParams.index >= 0 &&
        !isNaN(temporaryParams.index)
      )
        params.index = temporaryParams.index;
    }

    const resetConditionMatching = () => {
      params = {
        ...params,
        matchingType: undefined,
        conditionMatching: false,
        matchingSource: null,
        matchingTemplate: null,
        conditionProp: [],
        quoteProp: [],
        matchingProp: [],
      };
    };

    if (type !== 'quote') resetConditionMatching();

    switch (type) {
      case 'department':
        break;
      case 'tag':
      case 'enum':
        params.enumNotSave = false;
        params.extend = [];
        params.defaultValue = undefined;
        break;
      case 'user':
        params.defaultValue = undefined;
        params.extend = ['-1'];
        break;
      case 'text':
      case 'currencyV2':
      case 'number':
        params.defaultFormula = {
          type: 'const',
          const: type === 'number' ? 0 : undefined,
        };
        if (type === 'number' || type === 'currencyV2') {
          params.numberLimit = {
            upper: {
              type: 'const',
              prop: -1,
              const: undefined,
            },
            lower: {
              type: 'const',
              prop: -1,
              const: undefined,
            },
          };
          params.number = {
            precision: 0,
            unit: '',
            unitPosition: 0,
            numericalFormat: 0,
          };
        }
        break;
      case 'date':
        params.dateFormat = 'YYYY/MM/DD';
        params.defaultValue = undefined;
        params.showInterval = false;
        params.intervalType = 'hour';
        params.numberLimit = {
          upper: {
            type: 'const',
            prop: -1,
            const: undefined,
          },
          lower: {
            type: 'const',
            prop: -1,
            const: undefined,
          },
        };
        break;
      case 'cascade':
        params.addOnSelect = false;
        params.changeOnSelect = false;
        params.hideRoutes = false;
        params.cascade = {
          type: 0,
          titles: [],
          quoteNode: '',
          quoteTemp: '',
          nodes: [],
          nodesMap: {},
        };
        break;
      case 'auto_inc':
        params.inc = {
          inc_prop: [],
          start: 1,
          step: 1,
          cycle: null,
        };
        break;
      case 'formula':
        params.formulaFormat = 0;
        params.formula = undefined;
        params.number = {
          unit: '',
          unitPosition: 0,
          precision: 0,
          numericalFormat: 0,
        };
        break;
      case 'quote':
        params.conditionMatching = true;
        params.remoteRefresh = true;
        openConditionMatching(params);
        break;
      case 'positioning':
        params.showDistance = false;
        params.auxiliaryDisplay = [];
        break;
      case 'address':
        params.accuracy = 'address';
        break;
      default:
        break;
    }
    // 切换属性类型时，清空quickQuote参数
    delete params.quickQuote;

    if (
      (editingProp !== null && !isDebugMode) ||
      (isDebugMode && !isGoDebug && editingProp !== null)
    ) {
      setTemporaryParams(params);
      return;
    }

    setPropParams(params);
  };

  const confirmChangeType = () => {
    if (temporaryParams?.type) changePropType(temporaryParams.type, true);
  };

  const checkPropType = _checkPropType(propParams.type);

  return (
    <>
      <div className={'prop-item'} key={'name&remark'}>
        <div className={'prop-item-label'}>属性名称&说明</div>
        <div className={'prop-item-input inline-2'}>
          <Input
            placeholder={'请输入属性名'}
            value={propParams.name}
            onChange={(e) => savePropParams(e.target.value, 'name')}
            ref={propNameInputRef}
          />
          <Input
            placeholder={'请输入属性说明，可用于指导填写者输入，可不填'}
            value={propParams.remark}
            onChange={(e) => savePropParams(e.target.value, 'remark')}
          />
        </div>
      </div>
      {spaceKey !== curUserId ? (
        <div className={'prop-item'} key={'visGroups'}>
          <div className={'prop-item-label'}>可见分组</div>
          <div className={'prop-item-input'}>
            <Select
              placeholder="隐藏该属性"
              value={propParams.visGroups}
              options={groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              })}
              filterOption={(input, option) =>
                !!option?.label &&
                option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              mode={'multiple'}
              onChange={(e) => savePropParams(e, 'visGroups')}
              showArrow={true}
            />
          </div>
        </div>
      ) : null}
      {spaceKey !== curUserId &&
      !checkPropType(['quote', 'formula', 'auto_inc']) ? (
        <div className={'prop-item'} key={'editGroups'}>
          <div className={'prop-item-label'}>可编辑分组</div>
          <div className={'prop-item-input'}>
            <Select
              value={propParams.editGroups || ['-1']}
              options={groups.map((group) => {
                return {
                  label: group.group_name,
                  value: group.group_id,
                };
              })}
              filterOption={(input, option) =>
                !!option?.label &&
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              mode={'multiple'}
              onChange={(e) => {
                if (!e?.length && propParams.required) {
                  message.warn('必填属性可编辑分组不得为空');
                } else {
                  savePropParams(e, 'editGroups');
                }
              }}
              showArrow={true}
              placeholder={'所有成员不可编辑'}
            />
          </div>
        </div>
      ) : null}
      <div className={'prop-item'} key={'type'}>
        <div className={'prop-item-label'}>属性类型</div>
        <div className={'prop-item-input'}>
          {propOptions.map((p) => {
            // @ts-ignore
            const icon =
              PROP_TYPE[p.value]?.icon || 'iconattribute_selected_value';
            // @ts-ignore
            const name = PROP_TYPE[p.value].label || '未知类型';
            return (
              <Popconfirm
                key={p.label}
                placement="top"
                title="确定修改属性类型"
                onConfirm={confirmChangeType}
                okText="知道了"
                cancelText="取消"
                disabled={!isDebugMode || (isDebugMode && !editingProp)}
              >
                <div
                  key={p.value}
                  className={cls(
                    'prop-item-input-type',
                    (propParams.type === p.value ||
                      (propParams.type === 'tag' && p.value === 'enum')) &&
                      ' active',
                  )}
                  onClick={() => changePropType(p.value)}
                >
                  <i className={`iconfont ${icon}`} />
                  {name}
                </div>
              </Popconfirm>
            );
          })}
        </div>
      </div>
    </>
  );
};
