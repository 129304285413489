import type { ApiResponse } from '@linkpi/core';
import {
  DEFAULT_TEMPLATE,
  generateAddOpId,
  getDefaultTempProp,
  setNodeAction,
  updateProp,
} from '@linkpi/core';
import type { ViewGroupDataType } from '@linkpi/core/web';
import { getAddNodeDataWithViewOption, GetterPiNode } from '@linkpi/core/web';
import { useDispatch, useSelector } from '@umijs/max';
import { useMemoizedFn, useThrottleEffect } from 'ahooks';
import cls from 'classnames';
import type { Dayjs } from 'dayjs';
import { throttle } from 'lodash';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { addDraftNode, DraftNodeModal } from '@/components/DraftNodeModal';
import { useComponentId, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import useViewDataSort from '@/hook/useViewDataSort';
import useViewRecordSort from '@/hook/useViewRecordSort';
import { StatusMenu } from '@/pages/home/components/StatusMenu/StatusMenu';
import {
  ViewContentNiceModal,
  viewNodeContent,
} from '@/pages/home/components/View/ViewContent';
import request from '@/utils/request';

import type { positionType } from '../components/_baseComponent';
import type NodeRow from '../components/nodeRow';
import PiGantt from '../index';
import type { nodeRowData } from '../modules/nodeRowManager';
import type { OverlayerRefType } from './overlayer';
import Overlayer from './overlayer';

import styles from './styles.less';

interface propsType {
  userMap: any;
  orgId: string;
  node: GetterPiNode;
  templateList: ApiResponse.CurrentUser.TemplateInfo[];
  groupData: ViewGroupDataType;
  curViewData: any;
  isDark?: boolean;
  backgroundColor: string;
  handleShare: () => void;
  onDraftAddOk: () => void;
  uniqueCanvasId?: string;
  afterCollapseGroup: any;
}

const ReactPiGantt = (props: propsType, ref: any) => {
  const orgUserMap = useOrgUserMap();
  const {
    userMap,
    orgId,
    node,
    templateList,
    groupData,
    curViewData,
    onDraftAddOk,
    isDark,
    backgroundColor,
    uniqueCanvasId = 'piGantt',
    afterCollapseGroup = () => {},
  } = props;
  const piGanttRef = useRef<PiGantt>();
  const dispatch = useDispatch();
  const departmentNodes = useOrgDepartmentNodes();

  const currentUser: ApiResponse.CurrentUser = useSelector(
    (rootState: any) => rootState.user.currentUser,
  );
  const readOnlyMode = useSelector(
    (state: any) => state.workspace.readOnlyMode,
  );
  const viewInfo = curViewData.view_info || {};
  const condition = viewInfo.condition || [];
  const tempId = useMemo(() => {
    const tempId = condition.find((x: any) => x.key === 'templateId');
    return tempId ? tempId.value : DEFAULT_TEMPLATE;
  }, [condition]);
  const tempConfig = useMemo(() => {
    if (!tempId) return {};
    return templateList.find((x) => x.template_id === tempId) || {};
  }, [templateList, tempId]) as ApiResponse.CurrentUser.TemplateInfo;

  const [addHandleNodeInfo, setAddHandleNodeInfo] = useState<{
    id: string;
    relation: 'next' | 'prev';
  }>({
    id: '',
    relation: 'prev',
  });

  const [statusMenu, setStatusMenu] = useState<JSX.Element>(<></>);
  const [recordSorts, patchUpdateRecordSorts] = useViewRecordSort(
    orgId,
    node.value.id,
    curViewData.view_id,
    () => {
      return piGanttRef.current?.getCurrentIds() || [];
    },
  );
  const [sortData] = useViewDataSort(groupData, recordSorts);

  useImperativeHandle(ref, () => ({
    ganttCollapse(e: boolean) {
      piGanttRef.current?.ganttCollapse(e);
    },
  }));

  useEffect(() => {
    piGanttRef.current = new PiGantt({
      container: '#' + uniqueCanvasId,
      tempConfig: tempConfig,
      groupData: sortData,
      onPreview: onPreview,
      getGroupName: getGroupName,
      onDisplayAddBtn: onDisplayAddBtn, // 显示行间新增按钮
      onAdd: onGroupAddBtn, // 点击分组底部新增按钮
      onStatusIconClick: onStatusIconClick,
      onMove: onMove,
      onDateChange: onDateChange,
      onProgressChange: onProgressChange,
      readOnlyMode,
      curViewData,
      isDark,
      backgroundColor,
      afterCollapseGroup,
      userMap,
    });

    return () => destroyPiGantt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useThrottleEffect(
    () => {
      piGanttRef.current && piGanttRef.current.setData(sortData);
    },
    [sortData],
    { wait: 1000 },
  );

  useThrottleEffect(
    () => {
      piGanttRef.current && piGanttRef.current.updateCurViewData(curViewData);
    },
    [curViewData],
    { wait: 1000 },
  );

  useThrottleEffect(
    () => {
      piGanttRef.current && piGanttRef.current.setTempConfig(tempConfig);
    },
    [tempConfig],
    { wait: 1000 },
  );

  useEffect(() => {
    piGanttRef.current && piGanttRef.current.setIsDark(isDark || false);
  }, [isDark]);

  useEffect(() => {
    piGanttRef.current && piGanttRef.current.onUserMapChange(userMap);
  }, [userMap]);

  const getGroupName = (groupItem: any) => {
    if (groupItem.nameType === 'user') {
      if (userMap[groupItem.name]) {
        return userMap[groupItem.name].nick_name;
      } else {
        return '未知成员';
      }
    }
    return groupItem.name;
  };

  const destroyPiGantt = () => {
    if (piGanttRef.current) {
      piGanttRef.current.destroy();
    }
  };

  // 移动位置
  const onMove = useMemoizedFn(
    (node: PiNode, toNode: PiNode, relation: 'prev' | 'next') => {
      patchUpdateRecordSorts(node.id, toNode.id, relation, 'move');
    },
  );
  const onDateChange = useMemoizedFn(
    async (node: PiNode, startDate: Dayjs, endDate: Dayjs) => {
      const res = await setNodeAction(request, {
        org_id: orgId,
        temp_id: tempId,
        node: [node.id],
        startTime: startDate.valueOf(),
        endTime: endDate.valueOf(),
      });
    },
  );

  const onProgressChange = useMemoizedFn(
    async (node: PiNode, percent: number) => {
      const showProgressByProp = curViewData?.view_info?.showProgressByProp;
      const propIndex = showProgressByProp.split('-')[1];

      updateProp(request, {
        org_id: orgId,
        temp_id: tempId,
        node_id: node.id,
        index: [Number(propIndex)],
        value: [percent / 100],
      });
    },
  );

  const componentId = useComponentId();
  const viewNodeModalId = 'VIEW_NODE_ID_' + componentId;

  const onPreview = useMemoizedFn((nodeId: string, nodeIds: string[]) => {
    if (tempConfig?.temp_option?.viewThemeMode === 'content') {
      dispatch({
        type: 'workspace/setCurrentSelection',
        payload: {
          selectNode: nodeId,
          viewRedirect: true,
        },
      });
    } else {
      const index = nodeIds.findIndex((x) => x === nodeId);
      viewNodeContent({ nodeIndex: index, dataList: nodeIds }, viewNodeModalId);
    }
  });

  const overlayerRef = useRef<OverlayerRefType>(null);
  const onDisplayAddBtn = useCallback(
    throttle((node: NodeRow | false) => {
      overlayerRef.current?.onDisplayAddBtn(node);
    }, 50),
    [],
  );
  const onAddBtnClick = useMemoizedFn((node: NodeRow) => {
    const groupKey = node.data.groupKey;
    const groupInfo = sortData[groupKey];
    onAdd(groupKey, groupInfo, node.data.node.id, 'prev');
  });
  const onGroupAddBtn = useMemoizedFn((rowData: nodeRowData<'addRow'>) => {
    const handleNode =
      rowData.groupInfo.list[rowData.groupInfo.list.length - 1];
    onAdd(rowData.groupKey, rowData.groupInfo, handleNode?.id, 'next');
  });

  const addDraftNodeModalId = 'DRAFT_NODE_ID' + node.value.node_id;
  const onAdd = useMemoizedFn(
    async (
      groupKey: string,
      groupInfo: ViewGroupDataItem,
      handleNodeId: string,
      addRelation: 'next' | 'prev',
    ) => {
      const handleNode = node.value.nodeManager.findChildren(handleNodeId);
      const groupBy = curViewData.view_info.group;
      const dataWithViewOption = getAddNodeDataWithViewOption(
        condition,
        groupBy,
        groupInfo,
        tempConfig,
        groupKey,
        handleNode,
      );
      const viewInitData: any = {
        initTempId: tempConfig.template_id,
      };
      if (dataWithViewOption.tempProp !== undefined) {
        viewInitData.initProp = dataWithViewOption.tempProp;
      }
      if (dataWithViewOption.status !== undefined) {
        viewInitData.initStatus = dataWithViewOption.status;
      }
      if (dataWithViewOption.statusProp !== undefined) {
        viewInitData.initStatusProp = dataWithViewOption.statusProp;
      }
      if (dataWithViewOption.sysTag !== undefined) {
        viewInitData.initSysTag = dataWithViewOption.sysTag;
      }
      if (dataWithViewOption.sysCascade !== undefined) {
        viewInitData.initSysCascade = dataWithViewOption.sysCascade;
      }

      setAddHandleNodeInfo({ id: handleNodeId, relation: addRelation });
      const matchConfig: any = await dispatch({
        type: 'workspace/fetchMatchProps',
        payload: { org_id: orgId, temp_id: tempConfig.template_id },
      });
      const newProps = getDefaultTempProp(
        {},
        tempConfig,
        currentUser,
        null,
        orgUserMap,
        departmentNodes,
        viewInitData,
      );
      const id = generateAddOpId();

      // 节点需要新增在这个节点的上面
      // 所有兄弟节点
      const siblingNodes = handleNode.parent?.children || [];
      const handleNodeIndex = siblingNodes.findIndex(
        (n) => n.id === handleNode.id,
      );
      const siblingId =
        handleNodeIndex === 0
          ? undefined
          : siblingNodes[handleNodeIndex - 1].id;

      const draftsNodeData = {
        org_id: orgId,
        parentId: node.value.id,
        siblingId,
        draft: true,
        node: {
          node_id: id,
          prop: newProps,
          title: '',
        },
      };

      const {
        node: { node_id },
      } = await addDraftNode(
        {
          mode: 'add',
          orgId,
          draftsNodeData,
          initParentId: handleNode.parent?.id || '',
          initSiblingId: siblingId,
        },
        addDraftNodeModalId,
      );

      onAddSuccess(node_id);
    },
  );

  const onAddSuccess = (id: string) => {
    patchUpdateRecordSorts(
      id,
      addHandleNodeInfo.id,
      addHandleNodeInfo.relation,
    );
    onDraftAddOk && onDraftAddOk();
  };

  const onStatusIconClick = (node: PiNode, position: positionType) => {
    setStatusMenu(<></>);
    const getNode = new GetterPiNode(node);
    setTimeout(() => {
      setStatusMenu(
        <div
          className={styles.statusMenuWrap}
          style={{ left: position.x, top: position.y }}
        >
          <StatusMenu
            btnClassName="status-btn"
            trigger={'click'}
            spaceId={orgId}
            data={getNode.value}
            type={'list'}
            autoOpen
          />
        </div>,
      );
    });
  };

  return (
    <div className={cls(styles.reactPiGantt, isDark ? styles.dark : '')}>
      <div id={uniqueCanvasId} className={styles.piGantt} />
      <Overlayer ref={overlayerRef} onAddBtnClick={onAddBtnClick} />

      {/* 预览 */}
      {/* @ts-ignore */}
      <ViewContentNiceModal id={viewNodeModalId} />

      {/* 草稿 */}
      {/* @ts-ignore */}
      <DraftNodeModal id={addDraftNodeModalId} />

      {statusMenu}
    </div>
  );
};

export default memo(forwardRef(ReactPiGantt));
