import type { FilterItemType } from './types';

export const ArrangementList = [
  {
    label: '1列',
    value: 'one',
  },
  {
    label: '2列',
    value: 'two',
  },
  {
    label: '3列',
    value: 'three',
  },
];

export const SubmitButtonId = '$$SubmitButton';
export const FoldButtonId = '$$FoldButton';

export const GLOBAL_CONDITION_LATEST_VERSION = 3 as const;

export type FilterType =
  | 'title'
  | 'style'
  | 'defaultValueInput'
  | 'selectType'
  | 'dataSource'
  | 'fold'
  | 'tempDataSource';
export type StyleType = 'font' | 'size';

export const FilterItemMap: Record<
  FilterItemType | typeof SubmitButtonId | typeof FoldButtonId,
  | {
      props: FilterType[];
      styles: StyleType[];
    }
  | string[]
> = {
  text: {
    props: ['title', 'style', 'defaultValueInput'],
    styles: ['font', 'size'],
  },
  dateRangePicker: {
    props: ['title', 'style', 'tempDataSource'],
    styles: ['font', 'size'],
  },
  cascader: {
    props: ['title', 'style', 'selectType', 'dataSource'],
    styles: ['font', 'size'],
  },
  yearMonthDay: {
    props: ['title', 'style', 'defaultValueInput'],
    styles: ['font', 'size'],
  },
  select: {
    props: ['title', 'style', 'selectType', 'dataSource'],
    styles: ['font', 'size'],
  },
  [SubmitButtonId]: ['submit', 'reset', 'size'],
  [FoldButtonId]: {
    props: ['fold'],
    styles: ['font'],
  },
};

export const DefaultFontValue = {
  fontSize: 14,
  color: { r: 36, g: 45, b: 63, a: 1 },
  bold: false,
  italic: false,
  underline: false,
};
