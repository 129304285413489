import { Date_Format_Options } from '@linkpi/core';
import type { TimeRangePickerProps } from 'antd';
import React from 'react';

import type { LinkPiDateProp } from '@/components/LinkPiDate';
import LinkPiDate from '@/components/LinkPiDate';
import type { TemplateMapUsage } from '@/hook';
import { cn } from '@/utils/utils';

import { useConditionItemInnerConfig } from '../../../hooks/useConditionItemInnerConfig';
import type { Condition } from '../../../types';

type DatePickerValue = [number | undefined] | [];

type RangeValue = TimeRangePickerProps['value'];

interface DatePickerProps extends Pick<LinkPiDateProp, 'justify'> {
  value?: DatePickerValue;
  onChange?: (value: DatePickerValue) => void;
  disabled?: boolean;
  templateId: string | string[];
  templateUsage?: TemplateMapUsage;
  config: Pick<Condition, 'key' | 'index'>;
  disableRelative?: boolean;
  className?: string;
}

export const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  disabled,
  templateId,
  templateUsage,
  config,
  className,
  justify,
}) => {
  const conditionConfig = useConditionItemInnerConfig({
    templateId,
    templateUsage,
    key: config.key,
    index: config.index,
  });

  const conditionConfigType = conditionConfig?.type;

  const dateFormat =
    conditionConfig?.data?.dateFormat ||
    (conditionConfigType === 'date' ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm');
  const dateConfig =
    Date_Format_Options.find((x) => x.value === dateFormat) ||
    Date_Format_Options[0];
  const conditionFilterFormat = dateConfig.conditionFilterFormat;

  return (
    <LinkPiDate
      className={cn('condition-filter-v2-date-picker', 'w-full', className)}
      dateFormat={conditionFilterFormat}
      allowClear
      onChange={(e: any) => {
        onChange?.(e ? [e.valueOf()] : []);
      }}
      dateValue={value?.[0] || undefined}
      disabled={disabled}
      justify={justify}
    />
  );
};
