import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useControllableValue, useMemoizedFn } from 'ahooks';
import { Popover } from 'antd';
import { type FC, memo, useMemo } from 'react';

import { RegularIcon } from '@/components/IconFont';
import { cn } from '@/utils/utils';

import type { IGlobalConditionFilterCondition } from '../../../GlobalConditionFilterWidget';

import Styles from './index.less';

const SortItem: FC<IGlobalConditionFilterCondition> = memo(({ ...props }) => {
  const { id, name } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    isDragging,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 1 } : {}),
  };

  return (
    <div
      key={id}
      className={cn(
        'flex items-center justify-between leading-none pr-3 pl-1 bg-[#F8F9FB] hover:bg-gray-100 transition-colors rounded-[8px] cursor-default group h-[40px] max-w-64 overflow-hidden',
        isDragging && 'shadow-sm',
      )}
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      <div className="flex items-center gap-2 overflow-hidden">
        <span
          ref={setActivatorNodeRef}
          className={cn(isDragging ? 'cursor-grabbing' : 'cursor-grab')}
          {...listeners}
        >
          <RegularIcon type="iconchangyong-paixu" />
        </span>
        <div className="truncate">{name || '未命名筛选项目'}</div>
      </div>
    </div>
  );
});

export const SortPopover: FC<{
  value?: IGlobalConditionFilterCondition[];
  onChange?: (v: IGlobalConditionFilterCondition[]) => void;
  children?: React.ReactNode;
}> = ({ children = '排序', ...rest }) => {
  const [value = [], setValue] = useControllableValue(rest) as [
    IGlobalConditionFilterCondition[],
    (v: IGlobalConditionFilterCondition[]) => void,
  ];

  const handleDragEnd = useMemoizedFn((event: DragEndEvent) => {
    const { active, over } = event;
    if (!active || !over || !value) {
      return;
    }
    const activeId = active.id.toString();
    const overId = over.id.toString();

    const activeIndex = value.findIndex((item) => item.id === activeId);
    const overIndex = value.findIndex((item) => item.id === overId);

    const newValue = arrayMove(value, activeIndex, overIndex)!;

    setValue(newValue);
  });

  const idList = useMemo(() => value.map((item) => item.id), [value]);

  const content = (
    <div className="flex flex-col gap-2 items-start">
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={idList} strategy={verticalListSortingStrategy}>
          <div className="flex flex-col gap-2 w-full">
            {value.map((item) => (
              <SortItem key={item.id} {...item} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );

  return (
    <Popover
      trigger={['click']}
      overlayClassName={Styles['sort-popover']}
      placement="bottom"
      content={content}
    >
      {children}
    </Popover>
  );
};
