import { Tooltip } from 'antd';
import type { FC, ReactElement } from 'react';
import { memo, useMemo } from 'react';

import { BaseSelect } from '@/components/LinkPiForm';
import type { TemplateMapUsage } from '@/hook';
import { cn } from '@/utils/utils';

import { useConditionFilterInnerConfig } from '../../../hooks/useConditionItemInnerConfig';

type StatusEditorProps = {
  value?: string[];
  onChange?: (value: string[]) => void;
  disableHistoryStatus?: boolean;
  templateId: string | string[];
  templateUsage?: TemplateMapUsage;
  maxTagCount?: number;
  className?: string;
};

export const StatusSelectEditor: FC<StatusEditorProps> = memo(
  ({
    value,
    onChange,
    templateId,
    templateUsage,
    maxTagCount,
    className,
    disableHistoryStatus,
  }) => {
    const { filterConditionConfigMapV2 } = useConditionFilterInnerConfig({
      templateId,
      templateUsage,
    });

    const options = useMemo(() => {
      const statusOptions = filterConditionConfigMapV2?.status?.options || [];
      // 单个主题类型
      if (typeof templateId === 'string') {
        const result = [
          {
            label: '当前状态' as string | ReactElement,
            options: statusOptions.map((x) => ({
              label: x.name,
              value: x.key,
              filterText: x.name,
            })),
          },
        ];
        if (!disableHistoryStatus) {
          result.push({
            label: (
              <div>
                历史状态
                <Tooltip
                  title={'状态多次流转时，仅筛选该状态最近一次被流转的记录'}
                  placement={'right'}
                >
                  <i
                    className={'iconfont iconNav_Help'}
                    style={{ fontSize: 12, color: '#316EF5', marginLeft: 8 }}
                  />
                </Tooltip>
              </div>
            ),
            options: statusOptions.map((x) => ({
              label: '历史-' + x.name,
              value: x.key + '_history',
              filterText: x.name,
            })),
          });
        }
        return result;
      }
      // 多个主题类型
      return statusOptions.map((i) => ({
        label: i.name,
        options: (i as any).options.map((s: any) => {
          const optionValue = [s.templateId, s.key].join('_');

          return {
            fullLabel: [i.name, s.name].join('-'),
            label: s.name,
            value: optionValue,
          };
        }),
      }));
    }, [
      disableHistoryStatus,
      filterConditionConfigMapV2?.status?.options,
      templateId,
    ]);

    return (
      <BaseSelect
        className={cn('w-full', className)}
        value={value}
        onChange={onChange}
        options={options as any}
        allowChooseAll
        maxTagCount={maxTagCount}
      />
    );
  },
);
