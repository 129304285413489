import { DEFAULT_AVATAR } from '@linkpi/core';

import BaseModule from './_baseModule';

interface ImageMap {
  avatar: ImageSrcMap;
  attachment: ImageSrcMap;
}

type ImageSrcMap = {
  default?: any;
  [src: string]: any;
};

const DEFAULT_ATTACHMENT = DEFAULT_AVATAR;
export default class ImageLoader extends BaseModule {
  public imageMap: ImageMap = {
    avatar: {},
    attachment: {},
  };

  public bootstrap() {
    const avatarImg = new Image();
    const attachmentImg = new Image();
    // img.crossOrigin = 'Anonymous';
    const { Render } = this.moduleInstances;

    avatarImg.src = DEFAULT_AVATAR;
    attachmentImg.src = DEFAULT_ATTACHMENT;

    avatarImg.onload = () => {
      this.imageMap.avatar.default = avatarImg;
      Render.render();
    };

    attachmentImg.onload = () => {
      this.imageMap.attachment.default = attachmentImg;
      Render.render();
    };
  }

  public loadImage(src: string, type: string) {
    const { Render } = this.moduleInstances;
    const map = this.imageMap[type as keyof ImageMap];
    if (map[src]) return map[src];

    const img = new Image();
    // img.crossOrigin = 'Anonymous';

    img.src = src;
    img.onload = () => {
      map[src] = img;
      Render.render();
    };
    return map.default;
  }
}
