import { useControllableValue } from 'ahooks';
import { cloneElement } from 'react';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/utils/utils';

import { RegularIcon } from '../IconFont';
import type { IValueEditorValue, ValueEditorWithMenuProps } from './types';

export function ValueEditorWithMenu<T extends IValueEditorValue>({
  disabled,
  children,
  typeConfigs = {},
  className,
  supportInputType,
  menuSubProps = {},
  ...props
}: ValueEditorWithMenuProps<T>) {
  const [value, onChange] = useControllableValue<T>(props);
  const currentType = value?.type || 'customEditor';
  const currentConfig = typeConfigs[currentType];

  const handleTypeChange = (newType: T['type']) => {
    if (onChange) {
      onChange({
        // 保留其他字段
        ...(value || {}),
        type: newType,
      } as T);
    }
  };

  const renderValue = () => {
    if (currentType === 'customEditor') {
      return children;
    }

    if (currentConfig?.renderer) {
      return currentConfig.renderer({
        value: value as any,
        onChange,
        disabled,
      });
    }

    return null;
  };

  if (disabled) {
    return (
      <div className={cn('flex items-center gap-2', className)}>
        {currentConfig?.icon}
        {renderValue()}
      </div>
    );
  }

  const trigger =
    currentType === 'customEditor' ? (
      <RegularIcon type="iconzidingyi" color={'#316EF5'} size={16} />
    ) : (
      currentConfig?.icon &&
      cloneElement(currentConfig.icon, {
        color: '#316EF5',
        size: 16,
      })
    );

  return (
    <div className={cn('flex items-center gap-2', className)}>
      {renderValue()}
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            className={cn(
              'flex items-center h-[32px]',
              className,
              disabled
                ? 'cursor-not-allowed pointer-events-none'
                : 'cursor-pointer',
            )}
          >
            {trigger}
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="top" className="z-[1000] pi-light-theme">
          <DropdownMenuGroup>
            <DropdownMenuItem
              onClick={() => handleTypeChange('customEditor')}
              className="flex items-center gap-2"
            >
              <RegularIcon type="iconzidingyi" color={'#242D3F'} size={16} />
              自定义值
            </DropdownMenuItem>
            {Object.entries(typeConfigs)
              .filter(
                ([type]) =>
                  typeof supportInputType === 'undefined' ||
                  supportInputType?.includes(type),
              )
              .map(([type, config]) =>
                config.menuSub ? (
                  config.menuSub({
                    ...menuSubProps,
                    onSelect: (v) => {
                      onChange({
                        ...v,
                        type,
                      });
                    },
                  })
                ) : (
                  <DropdownMenuItem
                    key={type}
                    onClick={() => handleTypeChange(type)}
                    className="flex items-center gap-2"
                  >
                    {cloneElement(config.icon, {
                      color: '#242D3F',
                      size: 16,
                    })}
                    <span>{config.label}</span>
                  </DropdownMenuItem>
                ),
              )}
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
