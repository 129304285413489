import { ExclamationCircleOutlined } from '@ant-design/icons';
import type { CurrentUser } from '@linkpi/core';
import { AND, PROP_TYPE, propIsNull } from '@linkpi/core';
import { useMemoizedFn, useUpdateEffect } from 'ahooks';
import { Button, Checkbox, Divider, message, Modal } from 'antd';
import { useHydrateAtoms } from 'jotai/utils';
import { DevTools } from 'jotai-devtools';
import { ScopeProvider } from 'jotai-scope';
import { isEmpty, isNil } from 'ramda';
import type { FC, PropsWithChildren } from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import LinkPiModal from '@/components/LinkPiModal';
import { useOrgConnection, useOrgInfo } from '@/hook';
import { checkNameDuplicate } from '@/pages/home/components/TempStatus';
import {
  AUXILIARY_PROP_TYPE,
  CONDITION_PROP_TYPE,
} from '@/pages/space/components/TemplateProp/constants';
import request from '@/utils/request';
import { checkPropType } from '@/utils/utils';

import {
  baseConfigAtom,
  DEFAULT_PROP_PARAMS,
  useTempPropConfig,
} from './hooks';
import {
  AddressType,
  AttachmentsConfig,
  AutoIncType,
  BasicParams,
  CascadeType,
  CheckableParams,
  CommonParams,
  ConditionMatching,
  DateType,
  DefaultFormula,
  DepartmentType,
  EnumType,
  FormulaType,
  NumberLimit,
  NumberType,
  PositioningType,
  QrBarcodeParams,
  UserType,
} from './PropConfigComponents';
import { DatetimeRangeType } from './PropConfigComponents/DatetimeRangeType';
import type {
  BasicProp,
  ExternalDataFormulaQuota,
  FormulaQuota,
  FormulaQuotaInput,
  PropOption,
  TemplateInfo,
  TempPropModalProps,
  TreeNode,
} from './types';
import {
  checkPropDefaultValueIsQuote,
  DEFAULT_ALLOW_MANUAL_UPDATE,
  genQuoteConditionsFormula,
  getDefaultAllowManualUpdate,
  transRangeDate,
} from './utils';

import './index.less';

/**
 * 主题类型属性的配置弹窗
 */
const TempPropModal: FC<TempPropModalProps> = (props) => {
  const {
    visible,
    onCancel,
    onSubmit,
    loading,
    editingProp,
    spaceKey,
    currentTemplate,
    templates,
    matchingSources,
    isLock: _isLock = false,
    groups,
    curUserId,
    setEditingProp: _setEditingProp,
    addOnly = false,
    needAddOneBtn = true,
    afterClose,
    allowSwitchProps = true,
  } = props;

  type TimeMultiplier = Record<number, number>;
  const timeMultipliers: TimeMultiplier = {
    0: 60,
    1: 3600,
    2: 86400,
  };

  const propNameInputRef = useRef<any>(null);

  useEffect(() => {
    if (propNameInputRef.current) propNameInputRef.current.focus();
  }, []);

  const isDebugMode = currentTemplate.temp_option?.debugMode || false;

  const orgConnection = useOrgConnection(spaceKey);
  const [orgInfo] = useOrgInfo(spaceKey);
  const {
    propParams = DEFAULT_PROP_PARAMS,
    setPropParams,
    changePropParams,
    savePropParams,
    setNoPermissionUserCanReadAtom,
    changed,
    setChanged,
    noPermissionUserCanRead,
  } = useTempPropConfig({
    isLock: _isLock,
    isDebugMode,
    groups,
  });

  useUpdateEffect(() => {
    if (propParams.index === -1 && editingProp?.index === -1) {
      setPropParams(editingProp);
    }
  }, [editingProp]);

  const [matchingNodes, setMatchingNodes] = useState(matchingSources);

  const [tempProps, setTempProps] = useState<PropOption[]>([]);

  const [conditionForTreeTable, setConditionForTreeTable] = useState<any[]>(
    () => {
      return (
        propParams?.formula?.input?.find((i: any) => i.type === 'quote_table')
          ?.condition || []
      );
    },
  );

  useUpdateEffect(() => {
    setConditionForTreeTable(
      propParams?.formula?.input?.find((i: any) => i.type === 'quote_table')
        ?.condition || [],
    );
  }, [editingProp?.index]);

  const setEditingProp = useMemoizedFn(
    (_editingProp, _editingPropIndex, initial = false) => {
      if (_editingProp) {
        const newEditingProp = JSON.parse(JSON.stringify(_editingProp));
        switch (newEditingProp.type) {
          case 'datetime':
          case 'date':
            newEditingProp.dateFormat =
              newEditingProp.dateFormat ||
              (newEditingProp.type === 'date'
                ? 'YYYY/MM/DD'
                : 'YYYY/MM/DD HH:mm');
            newEditingProp.showInterval = newEditingProp.showInterval || false;
            newEditingProp.intervalType = newEditingProp.intervalType || 'hour';
            newEditingProp.numberLimit = newEditingProp.numberLimit || {
              upper: {
                type: 'const',
                prop: -1,
                const: undefined,
              },
              lower: {
                type: 'const',
                prop: -1,
                const: undefined,
              },
            };
            break;
          case 'currencyV2':
          case 'number':
            newEditingProp.numberLimit = newEditingProp.numberLimit
              ? newEditingProp.number?.numericalFormat
                ? {
                    upper: {
                      ...newEditingProp.numberLimit.upper,
                      const:
                        newEditingProp.numberLimit.upper.const !== undefined &&
                        Number.isFinite(newEditingProp.numberLimit.upper.const)
                          ? newEditingProp.numberLimit.upper.const * 100
                          : undefined,
                    },
                    lower: {
                      ...newEditingProp.numberLimit.lower,
                      const:
                        newEditingProp.numberLimit.lower.const !== undefined &&
                        Number.isFinite(newEditingProp.numberLimit.lower.const)
                          ? newEditingProp.numberLimit.lower.const * 100
                          : undefined,
                    },
                  }
                : newEditingProp.numberLimit
              : {
                  upper: {
                    type: 'const',
                    prop: -1,
                    const:
                      newEditingProp.number?.numericalFormat &&
                      newEditingProp.number.upperLimit !== undefined &&
                      Number.isFinite(newEditingProp.number.upperLimit)
                        ? newEditingProp.number.upperLimit * 100
                        : newEditingProp.number?.upperLimit,
                  },
                  lower: {
                    type: 'const',
                    prop: -1,
                    const:
                      newEditingProp.number?.numericalFormat &&
                      newEditingProp.number.lowerLimit !== undefined &&
                      Number.isFinite(newEditingProp.number.lowerLimit)
                        ? newEditingProp.number.lowerLimit * 100
                        : newEditingProp.number?.lowerLimit,
                  },
                };
            newEditingProp.defaultFormula = newEditingProp.defaultFormula
              ? newEditingProp.number?.numericalFormat
                ? {
                    ...newEditingProp.defaultFormula,
                    const:
                      // @ts-ignore
                      newEditingProp.defaultFormula.const !== undefined &&
                      // @ts-ignore
                      typeof newEditingProp.defaultFormula.const === 'number' &&
                      // @ts-ignore
                      Number.isFinite(newEditingProp.defaultFormula.const)
                        ? // @ts-ignore
                          newEditingProp.defaultFormula.const * 100
                        : // @ts-ignore
                          newEditingProp.defaultFormula.const,
                  }
                : newEditingProp.defaultFormula
              : {
                  type: 'const',
                  prop: -1,
                  const:
                    !propIsNull(newEditingProp.defaultValue) &&
                    Number.isFinite(Number(newEditingProp.defaultValue))
                      ? newEditingProp.number?.numericalFormat
                        ? Number(newEditingProp.defaultValue) * 100
                        : Number(newEditingProp.defaultValue)
                      : undefined,
                };
            break;
          case 'text':
            if (!newEditingProp.defaultFormula)
              newEditingProp.defaultFormula = {
                type: 'const',
                prop: -1,
                const: undefined,
              };

            break;
          case 'tag':
          case 'enum': {
            const getNoPermissionUserCanRead = () => {
              if (!newEditingProp.matchingTemplate) return false;

              const matchingTemplate = tempMap[newEditingProp.matchingTemplate];
              if (!matchingTemplate) return false;
              const { noPermissionUserCanRead: _noPermissionUserCanRead } =
                matchingTemplate.temp_option || {};
              return !!_noPermissionUserCanRead;
            };

            const result = getNoPermissionUserCanRead();
            if (isNil(result)) return;

            setNoPermissionUserCanReadAtom(result);
          }
        }
        if (Array.isArray(newEditingProp.quoteConditions)) {
          newEditingProp.quoteConditions.forEach((q: any) => {
            if (
              q.type === 'date' &&
              Array.isArray(q.date) &&
              q.date.length > 2
            ) {
              q.date = transRangeDate(q.date[2]);
            }
          });
        }
        if (!newEditingProp.editGroups) {
          newEditingProp.editGroups = ['-1'];
        }
        setPropParams(newEditingProp);
      } else {
        setPropParams({
          name: '',
          remark: '',
          index: -1,
          type: 'text',
          hide: false,
          multiple: false,
          required: false,
          visGroups: ['-1'],
          editGroups: ['-1'],
          sort: -1,
          editable: true,
          recordModification: false,
          requireModificationRemark: false,
        });
      }
      setChanged(false);

      if (initial) return;

      _setEditingProp?.(_editingProp, _editingPropIndex);
    },
  );

  useEffect(() => {
    if (!visible) return;
    setEditingProp(editingProp, editingProp?.index, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const tempMap = useMemo(() => {
    return templates.reduce(
      (a, b) => {
        a[b.template_id] = b;
        return a;
      },
      {} as Record<string, TemplateInfo>,
    );
  }, [templates]);

  const resetAutoIncId = async () => {
    await request('/api/resetAutoInc', {
      method: 'POST',
      data: {
        org_id: spaceKey,
        temp_id: currentTemplate?.template_id,
        index: editingProp?.index,
      },
    });
  };

  const showAutoIncModal = () => {
    Modal.confirm({
      title: '重置后，新增的主题将从初识值开始重新生成自增ID，确定重置么 ？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: resetAutoIncId,
    });
  };

  const extendProps: PropOption[] = useMemo(
    () => [
      {
        index: -3,
        type: 'text',
        name: currentTemplate?.title_setting?.titleAlias || '标题',
      },
      {
        index: -1,
        type: 'user',
        name: '创建者',
      },
    ],
    [currentTemplate?.title_setting?.titleAlias],
  );

  useEffect(() => {
    const getChildren = (root: any) => {
      const children: TreeNode[] = [];
      if (Array.isArray(root.children) && root.children.length) {
        root.children.map((f: any) => {
          // if (f.nodeType !== 2) {
          children.push({
            value: f.key,
            title: f.title || '无标题',
            children: getChildren(f),
          });
          // }
        });
      }
      return children;
    };
    if (visible && orgConnection) {
      if (!matchingSources) {
        setMatchingNodes([
          {
            value: orgConnection.nodeManager._rootId,
            title: '根目录',
            children: getChildren(orgConnection.nodeManager.getRoot()),
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, orgConnection]);

  const conditionProps = useMemo(() => {
    const dataSource: PropOption[] = [];
    currentTemplate?.prop?.map((prop, index) => {
      if (prop?.type) {
        dataSource.push({ ...prop, index: index, sort: prop.sort ?? index });
      }
    });
    // @ts-ignore
    dataSource.sort((b, a) => b.sort - a.sort);
    setTempProps(dataSource);
    return [
      ...extendProps,
      ...dataSource.filter((x) => x.index !== editingProp?.index),
    ];
  }, [currentTemplate?.prop, editingProp?.index, extendProps]);

  const openConditionMatching = useMemoizedFn((params: BasicProp) => {
    params.matchingSource = orgConnection?.nodeManager?._rootId || '';
    params.quoteNodesVisible = false;
    params.quoteOptionVisible = false;
    params.quoteConditions = [];
    params.matchingTemplate = undefined;
    params.conditionProp = [];
    params.quoteProp = [];
    params.matchingProp = [];
    params.conditionCascade = {};
    params.matchingType = undefined;
  });

  const changeNoPermissionUserCanRead = useMemoizedFn(async () => {
    const v = noPermissionUserCanRead;
    const showSetNoPermissionUserCanRead = () => {
      return (
        propParams.type === 'enum' &&
        propParams.conditionMatching &&
        propParams.selectType === 1
      );
    };

    if (!showSetNoPermissionUserCanRead()) return;

    if (!propParams.matchingTemplate) return;
    const matchingTemplate = tempMap[propParams.matchingTemplate];
    if (!matchingTemplate) return;

    const temp_option = matchingTemplate.temp_option
      ? { ...matchingTemplate.temp_option, noPermissionUserCanRead: v }
      : { noPermissionUserCanRead: v };

    const data = {
      template_id: matchingTemplate.template_id,
      org_id: matchingTemplate.org_id,
      temp_option,
    };

    const res = await request('/api/template/update', {
      method: 'POST',
      data,
    });

    await request('/api/update_sec_key', {
      method: 'POST',
      data: { org_id: matchingTemplate.org_id },
    });

    if (res.status !== 'ok') message.error('保存失败');
  });

  const saveProp = useMemoizedFn(async (andOne: boolean = false) => {
    if (!changed)
      return andOne ? addProp() : message.warn('当前未编辑任何参数');
    console.log('else...');
    const newProp = JSON.parse(JSON.stringify(propParams));

    if (propParams.dataType === 0 || isNil(propParams.dataType)) {
      if (!propParams.type) return message.error('请选择属性类型');
      if (!propParams.name) return message.error('请填写属性名称');
      if (
        checkNameDuplicate(propParams.name, propParams.index, currentTemplate)
      )
        return message.error('已有相同名称的属性');
      if (propParams.type === 'formula' && !propParams.formula)
        return message.error('请配置运算公式');
      if (propParams.conditionMatching) {
        const matchingType = '引用';
        if (!propParams.matchingSource)
          return message.error(`请选择${matchingType}来源`);
        if (!propParams.matchingTemplate)
          return message.error(`请选择${matchingType}的主题类型`);
        if (!propParams.matchingProp?.length)
          return message.error(`请选择${matchingType}的属性`);
        if (propParams.matchingType === undefined)
          return message.error(`请选择${matchingType}方式`);
        if (propParams.type === 'quote' && !propParams.conditionProp?.length)
          return message.error('请添加引用条件');
        if (propParams.conditionProp && propParams.conditionProp.length) {
          if (~propParams.conditionProp.findIndex((p) => p === undefined))
            return message.error(`${matchingType}条件已有属性未配置`);
        }
        if (propParams.quoteProp && propParams.quoteProp.length) {
          if (~propParams.quoteProp.findIndex((p) => p === undefined))
            return message.error(`${matchingType}条件引用表属性未配置`);
        }
      }
    }

    if (checkType(['number', 'currencyV2'])) {
      newProp.defaultFormula =
        propParams.defaultFormula?.type === 'const' &&
        typeof propParams.defaultFormula?.const === 'number' &&
        Number.isFinite(propParams.defaultFormula?.const) &&
        newProp.number.numericalFormat
          ? {
              ...propParams.defaultFormula,
              const: propParams.defaultFormula.const / 100,
            }
          : propParams.defaultFormula;
      newProp.numberLimit = newProp.number.numericalFormat
        ? {
            upper: {
              ...propParams.numberLimit?.upper,
              const:
                propParams.numberLimit?.upper.const !== undefined &&
                Number.isFinite(propParams.numberLimit.upper.const)
                  ? propParams.numberLimit.upper.const / 100
                  : propParams.numberLimit?.upper.const,
            },
            lower: {
              ...propParams.numberLimit?.lower,
              const:
                propParams.numberLimit?.lower.const !== undefined &&
                Number.isFinite(propParams.numberLimit.lower.const)
                  ? propParams.numberLimit.lower.const / 100
                  : propParams.numberLimit?.lower.const,
            },
          }
        : propParams.numberLimit;
      if ('defaultValue' in newProp) newProp.defaultValue = null;
    }

    newProp.quoteNodesVisible = propParams.conditionMatching
      ? propParams.quoteNodesVisible || false
      : false;
    newProp.quoteOptionVisible =
      propParams.conditionMatching &&
      ['enum', 'tag', 'quote', 'address'].includes(propParams.type) &&
      (propParams.matchingType === 0 ||
        (propParams.matchingType === -1 && propParams.type !== 'quote'))
        ? propParams.quoteOptionVisible || false
        : false;

    newProp.disabled =
      newProp.type === 'quote' ||
      newProp.type === 'formula' ||
      newProp.type === 'auto_inc';

    if (
      'defaultFormula' in newProp &&
      'defaultValue' in newProp &&
      !isNil(newProp.defaultFormula?.type)
    ) {
      newProp.defaultValue = null;
    }

    if (newProp.conditionMatching) {
      let op = '';
      switch (newProp.matchingType) {
        case -1:
          op = `pass_array`;
          break;
        case 0:
          op =
            newProp.multiple || newProp.type === 'quote' ? 'pass_any' : 'pass';
          break;
        case 1:
          op = 'sum';
          break;
        case 2:
          op = 'avg';
          break;
        case 3:
          op = 'max';
          break;
        case 4:
          op = 'min';
          break;
        case 5:
          op = 'count';
          break;
      }
      if (op) {
        const quota: FormulaQuota = {
          type: 'quota',
          temp_id: newProp.matchingTemplate,
          prop: newProp.conditionProp,
          remoteProp: newProp.quoteProp.map((p: any) =>
            typeof p === 'string' ? Number(p.split('-')[1]) : null,
          ),
          op: newProp.matchingOp
            ? newProp.matchingOp.map((x: string | null) =>
                x === 'date_include' || x === 'date_inside'
                  ? 'month_equal'
                  : x
                    ? x.toLowerCase()
                    : null,
              )
            : newProp.conditionProp.map(() => null),
          quotaProp:
            newProp.matchingType === 5
              ? -4
              : Number.isFinite(Number(newProp.matchingProp[0]))
                ? Number(newProp.matchingProp[0])
                : newProp.matchingProp.map((p: string) =>
                    Number(p.split('-')[1]),
                  )[0],
          auxiliaryProp: newProp.auxiliaryProp?.index ?? undefined,
          labelProp: newProp.matchingLabelProps?.map((i: string) =>
            i.startsWith('-') ? Number(i) : Number(i.split('-')[1]),
          ),
        };
        // TODO 暂时不存V2 condition
        /*const matchingConditions: ApiResponse.ViewList.ViewconditionV2 =
          genMatchingCondition(newProp);
        if (matchingConditions.length) {
          quota.condV2 = matchingConditions;
        }*/
        if (newProp.matchingDist) {
          // && matchingOp === 'distance'
          quota.dist = newProp.matchingDist;
        }
        if (
          newProp.matchingSource &&
          newProp.matchingSource !== orgConnection?.nodeManager._rootId
        ) {
          quota.parentId = newProp.matchingSource;
        }
        const quoteConditionsFormula = genQuoteConditionsFormula(propParams);
        if (quoteConditionsFormula.length) {
          quota.formula =
            quoteConditionsFormula.length > 1
              ? AND(...quoteConditionsFormula).formula
              : quoteConditionsFormula[0].formula;
        }
        if (newProp.type === 'quote') {
          newProp.formula = {
            op,
            input: [quota],
          };
        } else {
          newProp.matchFormula = {
            op,
            input: [quota],
          };
          newProp.filterFormula = quota.formula || null;
        }
      }
    }

    changeNoPermissionUserCanRead();
    //引用属性外部数据源
    if (propParams.dataType === 1) {
      if (propParams.scheduleUpdate === true) {
        if (propParams.updateCycleType) {
          newProp.timed_trigger.updateCycleType = propParams.updateCycleType;
          //定时更新自定义
          if (propParams.updateCycleType === 2) {
            const resultInSeconds = calculateSeconds(
              propParams.customUpdateCycleValue ?? 1,
              propParams.customUpdateCycle ?? 1,
            );
            newProp.timed_trigger.time_interval = resultInSeconds;
          }
        }
        if (propParams.firstExecutionTime) {
          newProp.timed_trigger.anchor_time = propParams.firstExecutionTime;
        }
      }
      const input: FormulaQuotaInput[] = [];
      const inputValue: FormulaQuotaInput = {
        type: 'quote_table',
        table_id: propParams.table_id,
        column_id: propParams.column_id,
        label_column: propParams.matchingLabelColumns,
      };
      const quota: ExternalDataFormulaQuota = {
        op: 'pass_any',
      };
      if (propParams.externalDataMatchingType) {
        inputValue.func = propParams.externalDataMatchingType;
        if (propParams.externalDataMatchingType === '') {
          newProp.matchingType = 0;
        } else if (propParams.externalDataMatchingType === 'sum') {
          newProp.matchingType = 1;
        } else if (propParams.externalDataMatchingType === 'avg') {
          newProp.matchingType = 2;
        } else if (propParams.externalDataMatchingType === 'max') {
          newProp.matchingType = 3;
        } else if (propParams.externalDataMatchingType === 'min') {
          newProp.matchingType = 4;
        } else if (propParams.externalDataMatchingType === 'count') {
          newProp.matchingType = 5;
        } else if (propParams.externalDataMatchingType === 'options') {
          newProp.matchingType = -1;
        }
      }

      inputValue.type = 'quote_table';
      inputValue.condition = conditionForTreeTable;
      input.push(inputValue);
      quota.input = input;
      newProp.formula = quota;
    }
    setChanged(false);
    await onSubmit(newProp, andOne);
    if (propParams.type === 'auto_inc') {
      const pStart = propParams?.inc?.start;
      const eStart = editingProp?.inc?.start;

      if (pStart !== eStart) {
        await resetAutoIncId();
      }
    }
  });

  const calculateSeconds = (inputNum: number, unit: number) => {
    const multiplier = timeMultipliers[unit];
    return inputNum * multiplier;
  };

  const addProp = useMemoizedFn(async () => {
    if (!setEditingProp) return;
    if (changed) {
      return Modal.confirm({
        title: '提示',
        content: editingProp
          ? '当前属性有修改，是否保存？'
          : '保存当前新增的属性，并新增下一个吗？',
        onOk: async () => {
          await saveProp(true);
        },
        onCancel: () => setEditingProp(null, -1),
        okText: '保存',
        cancelText: '不保存',
      });
    }
    setEditingProp(null, -1);
    propNameInputRef.current.focus();
  });

  const checkType = checkPropType(propParams.type);

  const propForm = useMemo(
    () => (
      <div id={'TempPropModal'}>
        {allowSwitchProps && (
          <div className={'prop-list'}>
            {tempProps.length && _setEditingProp && !addOnly ? (
              <PerfectScrollbar style={{ width: 128 }}>
                {tempProps.map((p) => {
                  // @ts-ignore
                  const icon =
                    PROP_TYPE[p.type]?.icon || 'iconattribute_selected_value';
                  return (
                    <div
                      className={`prop-list-item text-omit${
                        p.index === editingProp?.index ? ' active' : ''
                      }`}
                      key={p.index}
                      onClick={() => {
                        if (editingProp && changed)
                          return Modal.confirm({
                            title: '提示',
                            content: `当前属性有修改，是否保存？`,
                            onOk: async () => {
                              await saveProp();
                              setEditingProp(p, p.index);
                            },
                            onCancel: () => setEditingProp(p, p.index),
                            okText: '保存',
                            cancelText: '不保存',
                          });
                        setEditingProp(p, p.index);
                      }}
                      title={p.name}
                    >
                      <i className={`iconfont ${icon}`} />
                      {p.name}
                    </div>
                  );
                })}
                <div className={'prop-list-add'} key={-1} onClick={addProp}>
                  新增属性
                </div>
              </PerfectScrollbar>
            ) : null}
          </div>
        )}
        <BasicParams
          spaceKey={spaceKey}
          curUserId={curUserId}
          editingProp={editingProp}
          openConditionMatching={openConditionMatching}
          propNameInputRef={propNameInputRef}
        />
        {propParams.type === 'formula' && (
          <div className={'prop-item'}>
            <div className={'prop-item-label'}>公式格式</div>
            <div className={'prop-item-input'}>
              <div
                className={`prop-item-input-btn${!propParams.formulaFormat ? ' active' : ''}`}
                onClick={() =>
                  setPropParams({ ...propParams, formulaFormat: 0 })
                }
              >
                数字公式
              </div>
              <div
                className={`prop-item-input-btn${propParams.formulaFormat === 1 ? ' active' : ''}`}
                onClick={() =>
                  setPropParams({ ...propParams, formulaFormat: 1 })
                }
              >
                文本公式
              </div>
            </div>
          </div>
        )}
        {checkType('department') && <DepartmentType key={editingProp?.index} />}
        {checkType('attachment') && <AttachmentsConfig />}
        {checkType(['enum', 'tag']) ? <EnumType /> : null}
        {propParams.type === 'user' ? <UserType /> : null}
        {checkType(['number', 'currencyV2']) ||
        (propParams.type === 'formula' && propParams.formulaFormat === 0) ? (
          <NumberType />
        ) : null}
        {checkType(['number', 'currencyV2', 'date', 'datetime']) &&
        propParams.numberLimit ? (
          <NumberLimit
            propType={propParams.type!}
            numberLimit={propParams.numberLimit}
            tempProps={tempProps.filter((x) => x.index !== editingProp?.index)}
            tempMap={tempMap}
            setNumberLimit={(l) => {
              changePropParams({ ...propParams, numberLimit: l });
            }}
            precision={propParams.number?.precision || 0}
            numericalFormat={propParams.number?.numericalFormat || 0}
            dateFormat={propParams.dateFormat}
          />
        ) : null}
        {checkType(['date', 'datetime']) ? (
          <DateType
            tempProps={tempProps.filter((x) => x.index !== editingProp?.index)}
          />
        ) : null}
        {checkType(['datetimeRange']) && <DatetimeRangeType />}
        {checkType(['number', 'currencyV2', 'text']) &&
          propParams.defaultFormula && (
            <DefaultFormula
              propType={propParams.type!}
              defaultFormula={propParams.defaultFormula}
              setDefaultFormula={(f: CurrentUser.propDefaultFormula) =>
                savePropParams(f, 'defaultFormula')
              }
              numberLimit={propParams.numberLimit}
              tempProps={tempProps.filter(
                (x) => x.index !== editingProp?.index,
              )}
              tempMap={tempMap}
              precision={propParams.number?.precision || 0}
              numericalFormat={propParams.number?.numericalFormat || 0}
            />
          )}
        {/**
         * FIXME: 太脏了
         */}
        {/* NOTE: 默认值更新逻辑 */}
        {checkPropDefaultValueIsQuote(propParams) && (
          <div className="prop-item no-padding">
            <Checkbox
              checked={
                !Array.isArray(propParams.formulaUpdateField) ||
                propParams.formulaUpdateField.length > 0
              }
              onChange={(e) => {
                savePropParams(
                  e.target.checked ? [-2] : [],
                  'formulaUpdateField',
                );

                if (isEmpty(propParams.allowManualUpdate)) return;

                // 草稿节点不允许编辑
                if (e.target.checked)
                  savePropParams(
                    DEFAULT_ALLOW_MANUAL_UPDATE.filter((i) => i !== -2),
                    'allowManualUpdate',
                  );
                else
                  savePropParams(
                    DEFAULT_ALLOW_MANUAL_UPDATE,
                    'allowManualUpdate',
                  );
              }}
            >
              新建时实时更新 (勾选后，新建时该属性不允许修改)
            </Checkbox>
          </div>
        )}
        {propParams.type === 'positioning' ? (
          <PositioningType
            tempProps={tempProps.filter((x) => x.index !== editingProp?.index)}
            tempMap={tempMap}
          />
        ) : null}
        {propParams.type === 'cascade' && orgConnection ? (
          <CascadeType
            matchingNodes={matchingNodes}
            orgConnection={orgConnection}
            templates={templates}
            currentTemplate={currentTemplate}
          />
        ) : null}
        {propParams.type === 'formula' ? (
          <FormulaType tempMap={tempMap} currentTemplate={currentTemplate} />
        ) : null}
        {propParams.type === 'auto_inc' ? (
          <AutoIncType
            tempProps={tempProps.filter((x) => x.index !== editingProp?.index)}
          />
        ) : null}
        {propParams.type && AUXILIARY_PROP_TYPE.includes(propParams.type) ? (
          <CommonParams
            tempProps={tempProps.filter((x) => x.index !== editingProp?.index)}
            tempMap={tempMap}
          />
        ) : null}
        {propParams.type &&
        propParams.type !== 'quote' &&
        propParams.type !== 'auto_inc' ? (
          <>
            {propParams.type !== 'formula' ? (
              <div className="prop-item no-padding">
                <Checkbox
                  checked={!isEmpty(propParams.allowManualUpdate)}
                  onChange={(e) => {
                    let newV;
                    // 判断是否有引用的默认值
                    if (checkPropDefaultValueIsQuote(propParams)) {
                      if (
                        !Array.isArray(propParams.formulaUpdateField) ||
                        propParams.formulaUpdateField.length > 0
                      )
                        newV = DEFAULT_ALLOW_MANUAL_UPDATE.filter(
                          (i) => i !== -2,
                        );
                    }

                    const newState = e.target.checked
                      ? getDefaultAllowManualUpdate(newV)
                      : [];
                    savePropParams(newState, 'allowManualUpdate');
                  }}
                >
                  可修改属性值
                </Checkbox>
              </div>
            ) : null}
            <CheckableParams />
          </>
        ) : null}
        {propParams.type === 'address' ? <AddressType /> : null}
        {checkType(['enum', 'tag', 'text', 'formula']) ? (
          <QrBarcodeParams />
        ) : null}

        {propParams.type && checkType(CONDITION_PROP_TYPE) ? (
          <ConditionMatching
            key={editingProp?.index}
            openConditionMatching={openConditionMatching}
            matchingNodes={matchingNodes}
            rootId={orgConnection?.nodeManager?._rootId || ''}
            templates={templates}
            orgId={spaceKey}
            conditionProps={conditionProps}
            tempMap={tempMap}
            vipLevel={orgInfo?.level ?? 0}
            currentTemplate={currentTemplate}
            setConditionForTreeTable={setConditionForTreeTable}
          />
        ) : null}
      </div>
    ),
    [
      _setEditingProp,
      addOnly,
      addProp,
      allowSwitchProps,
      changePropParams,
      changed,
      checkType,
      conditionProps,
      curUserId,
      currentTemplate,
      editingProp,
      matchingNodes,
      openConditionMatching,
      orgConnection,
      orgInfo?.level,
      propParams,
      saveProp,
      savePropParams,
      setEditingProp,
      setPropParams,
      spaceKey,
      tempMap,
      tempProps,
      templates,
    ],
  );

  return (
    <LinkPiModal
      title={
        <div className={'modal-title'}>
          <div className={'modal-title-main'}>
            {editingProp ? '编辑属性' : '新增属性'}
          </div>
          <Divider type={'vertical'} />
          <div className={'modal-title-sub text-omit'}>
            {currentTemplate.name}
          </div>
        </div>
      }
      open={visible}
      onCancel={onCancel}
      // @ts-ignore
      onOk={saveProp}
      loading={loading}
      width={760}
      centered
      modalContent={propForm}
      className={'temp-prop-modal'}
      okText={editingProp ? '保存' : '确定'}
      afterClose={afterClose}
      footer={
        <>
          {isDebugMode && editingProp?.type === 'auto_inc' && (
            <div
              className="reset-auto-inc-box"
              style={{
                position: 'absolute',
                left: 24,
                width: '122px',
                height: '32px',
                background: '#F7F9FB',
                borderRadius: '6px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={() => showAutoIncModal()}
            >
              <i
                className={'iconfont iconrepeat'}
                style={{ fontSize: 14, color: '#316EF5' }}
              />
              <span
                style={{ marginLeft: 8, color: '#316EF5', fontSize: '14px' }}
              >
                重置属性值
              </span>
            </div>
          )}
          <Button type={'default'} onClick={onCancel}>
            取消
          </Button>
          {needAddOneBtn && (
            <Button
              disabled={!propParams?.type || !propParams?.name?.trim()}
              type={'default'}
              loading={loading}
              onClick={() => saveProp(true)}
            >
              保存并新增
            </Button>
          )}
          <Button
            disabled={!propParams?.type || !propParams?.name?.trim()}
            type={'primary'}
            loading={loading}
            onClick={() => saveProp()}
          >
            {editingProp ? '保存' : '确定'}
          </Button>
        </>
      }
    />
  );
};

const HydrateAtom: FC<
  PropsWithChildren<Pick<TempPropModalProps, 'editingProp'>>
> = ({ children, ...props }) => {
  useHydrateAtoms([
    [baseConfigAtom, (props.editingProp || DEFAULT_PROP_PARAMS) as BasicProp],
  ]);

  return children;
};

export default memo(function TempPropModalWrap(props: TempPropModalProps) {
  // const store = useMemo(() => createStore(), []);

  // useHydrateAtoms([[baseConfigAtom, props.editingProp as BasicProp]], {
  //   store,
  // });

  return (
    <ScopeProvider atoms={[baseConfigAtom]}>
      <HydrateAtom editingProp={props.editingProp}>
        <TempPropModal {...props} />
        <DevTools />
      </HydrateAtom>
    </ScopeProvider>
  );
});
