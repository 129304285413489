import type { FC } from 'react';
import { lazy } from 'react';
// @ts-ignore
import JSONPrettyMon from 'react-json-pretty/dist/monikai';
import type { JsonObject } from 'type-fest';

const JSONPretty = lazy(() => import('react-json-pretty'));

/**
 * 只展示 JSON，没有折叠、展开功能
 */
export const PureViewer: FC<{ data: JsonObject }> = ({ data }) => {
  return <JSONPretty data={data} theme={JSONPrettyMon} />;
};
