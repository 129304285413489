import type { GetterPiNode } from '@linkpi/core/web';
import { Empty } from 'antd';
import cls from 'classnames';
import { type FC } from 'react';

import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import { useCurrentUser } from '@/hook';
import NewNodeProp from '@/pages/home/components/NewNodeProp';

import { sharedStateNodeConnect } from '../SharedStateNodeConnect/Connect';

import styles from './styles.less';

const InnerModelCustomProps: FC<{
  data: IWidgetInstanceData & { widgetId: 'CustomProps' };
  getterPiNode: GetterPiNode;
  isDrafts: boolean;
  draftParentId?: string;
  readOnly?: boolean;
}> = (props) => {
  const { data, getterPiNode: node, isDrafts, draftParentId, readOnly } = props;
  const { config } = data;

  const currentUser = useCurrentUser();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.componentName}>{config.name}</div>
      </div>
      <div
        className={cls(styles.content, 'hover-scroll-bar')}
        style={{ flex: 1, height: 0 }}
      >
        {node ? (
          <NewNodeProp
            node={node}
            isSimpleThemeTree={false}
            currentUser={currentUser}
            simpleThemeTreeWidth={0}
            setEditedProps={() => {}}
            mode="edit"
            isDrafts={isDrafts}
            draftParentId={draftParentId}
            nodePropConfig={config}
            readOnly={readOnly}
            showFoldButton={config.showFoldButton}
          />
        ) : (
          <Empty description="暂无数据" />
        )}
      </div>
    </div>
  );
};

export const ModelCustomProps = sharedStateNodeConnect(InnerModelCustomProps);
