import { intersection } from 'ramda';

import { LINKPI_ORG_ID, ORG_ID_WHITELIST } from '@/consts';

import { useOrgList } from './useOrg';

/**
 * isOuterDev 外部开发
 * isDev 内部开发
 * isPlatformDev 开发（无论内部外部）
 */
export const useDev = () => {
  const orgList = useOrgList();
  const orgIds = orgList.map((o) => o.orgId);
  const isOuterDev = !!intersection(orgIds, ORG_ID_WHITELIST).length;
  const isDev = !!intersection(orgIds, LINKPI_ORG_ID).length;

  return { isOuterDev, isDev, isPlatformDev: isDev || isOuterDev };
};
