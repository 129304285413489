import type { CustomAttribute } from '@linkpi/core';
import { PROP_TYPE } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { useMeasure } from '@react-hookz/web';
import { Button, Checkbox, Form, InputNumber, Radio } from 'antd';
import { cloneDeep } from 'lodash';
import { useMemo, useState } from 'react';
import GridLayout from 'react-grid-layout';
import { match, P } from 'ts-pattern';

import { RegularIcon } from '@/components/IconFont';
import { useOrgTempMap } from '@/hook';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

import type { ISharedStateNodeSetting } from '../../components';
import { SharedStateNodeSetting, WidgetIcon } from '../../components';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget, shallowCloneObj } from '../utils';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import styles from './styles.less';

export type ICustomPropsConfig = Omit<CustomAttribute, 'type' | 'id' | 'sort'> &
  ISharedStateNodeSetting & {
    /**
     * default true
     */
    showFoldButton?: boolean;
    /**
     * default false
     */
    showSaveButton?: boolean;
  };

const visibleOptions = [
  { label: '显示', value: true },
  { label: '不显示', value: false },
];

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance<ICustomPropsConfig>(id);

  assertExists(widgetInstance);

  const [domRect = { width: 0 }, containerRef] = useMeasure<HTMLDivElement>();
  const editor = useEditor();
  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<ICustomPropsConfig>;

  const tempMap = useOrgTempMap();
  const _currentTemplate = useCurrentTemplate();
  const currentTemplate = useMemo(
    () =>
      match(widgetInstance?.config)
        .with(
          { nodeSource: 'sharedState', templateId: P.select() },
          (_id) => tempMap[_id!] || _currentTemplate,
        )
        .otherwise(() => _currentTemplate),
    [_currentTemplate, tempMap, widgetInstance?.config],
  );

  const getPropGrid = (props: any[]) => {
    return props.map((p, index) => ({
      i: String(p.index),
      h: 1,
      maxH: 1,
      w: 4,
      minW: 4,
      x: index % 3 === 0 ? 0 : (index % 3) * 4,
      y: index < 3 ? 0 : Math.floor(index / 3),
    }));
  };
  const getGroupPropGrid = (groups: any, props: any, propGrid: any) => {
    const res: any = {};

    // 如果没分组信息, 将全部的属性放进一个默认分组
    if (!groups) {
      res.default = propGrid?.default || getPropGrid(props);
      return res;
    }

    // 存在分组， 复用已有的分组布局
    if (groups) {
      groups.forEach((g: any) => {
        const { id } = g;
        const propsInG = props.filter((p: any) =>
          g.props.includes(Number(p.index)),
        ); // 分组内的属性
        const oldGrid = (propGrid || {})[id]; // 老的布局信息
        res[id] = oldGrid || getPropGrid(propsInG);
      });
    }

    return res;
  };

  const { showGroup, style, attrNameWidth } = widgetInstance.config;
  let props = currentTemplate?.prop || [];
  props = props
    .map((p: any, index: number) => ({ ...p, index }))
    .filter((p: any) => p.type)
    .sort((a: any, b: any) => a.sort - b.sort);

  let propGrid = widgetInstance.config.propGrid;
  if (showGroup) {
    // 分组情况下的属性布局  （对象）
    propGrid = getGroupPropGrid(currentTemplate.prop_groups, props, propGrid);
  }

  // 未分组的属性布局
  if (!showGroup && !Array.isArray(propGrid)) {
    propGrid = getPropGrid(props);
  }

  const handleLayoutChange = (e: any, groupId: string) => {
    e.forEach((i: any) => {
      if (i.w === 1) i.w = 4;
    });

    // 未分组情况
    if (!groupId) {
      updateConfig((config) => {
        config.propGrid = e;
      });
      return;
    }

    const _propGrid = shallowCloneObj(
      Object.prototype.toString.call(propGrid) === '[object Object]'
        ? propGrid
        : {},
    );
    _propGrid[groupId] = e;
    updateConfig((config: any) => {
      config.propGrid = _propGrid;
    });
  };

  // 属性默认样式
  const renderDefaultStyle = (props: any[]) => {
    return props.map((p: any) => (
      <div key={p.index} className={styles.prop}>
        <div className={styles.propName}>
          <i
            style={{ color: '#767C88', fontSize: 12 }}
            className={`${PROP_TYPE[p.type].icon} iconfont`}
          />
          <span>{p.name}</span>
        </div>
        <div style={{ width: 2 }} />
        <div className={styles.propInput}>请输入</div>
      </div>
    ));
  };

  // 属性划线样式
  const renderStyle1 = (props: any[], propGrid: any, groupId: string) => {
    return (
      <GridLayout
        onDragStart={(a, b, c, d, e) => e.stopPropagation()}
        onResizeStart={(a, b, c, d, e) => e.stopPropagation()}
        layout={propGrid}
        cols={12}
        rowHeight={40}
        margin={[6, 6]}
        width={domRect.width}
        isBounded
        draggableHandle=".draggableIcon"
        onLayoutChange={(e: any) => handleLayoutChange(e, groupId)}
        // compactType={null}
      >
        {props.map((p) => (
          <div key={p.index} className={styles.style1Prop}>
            <div className="draggableIcon">
              <i className="iconfont icondrag" />
            </div>
            <div style={{ width: attrNameWidth }} className={styles.label}>
              {p.name}
            </div>
            <div className={styles.propContent}>请输入</div>
          </div>
        ))}
      </GridLayout>
    );
  };

  // 属性划线样式
  const renderStyle2 = (props: any[], propGrid: any, groupId: string) => {
    return (
      <GridLayout
        onDragStart={(a, b, c, d, e) => e.stopPropagation()}
        onResizeStart={(a, b, c, d, e) => e.stopPropagation()}
        layout={propGrid}
        cols={12}
        rowHeight={52}
        margin={[6, 6]}
        width={domRect.width}
        isBounded
        draggableHandle=".draggableIcon"
        onLayoutChange={(e: any) => handleLayoutChange(e, groupId)}
        // compactType={null}
      >
        {props.map((p) => (
          <div key={p.index} className={styles.style2Prop}>
            <div className="draggableIcon">
              <i className="iconfont icondrag" />
            </div>
            <div className={styles.attrName}>
              <i
                style={{ color: '#767C88', fontSize: 12 }}
                className={`${PROP_TYPE[p.type].icon} iconfont`}
              />
              <span>{p.name}</span>
            </div>
            <div className={styles.propValue}>请输入</div>
          </div>
        ))}
      </GridLayout>
    );
  };

  // 属性划线样式
  const renderStyle3 = (props: any[], propGrid: any, groupId: string) => {
    return (
      <GridLayout
        onDragStart={(a, b, c, d, e) => e.stopPropagation()}
        onResizeStart={(a, b, c, d, e) => e.stopPropagation()}
        layout={propGrid}
        cols={12}
        rowHeight={40}
        margin={[6, 6]}
        width={domRect.width}
        isBounded
        draggableHandle=".draggableIcon"
        onLayoutChange={(e: any) => handleLayoutChange(e, groupId)}
        // compactType={null}
      >
        {props.map((p) => (
          <div key={p.index} className={styles.style3Prop}>
            <div className="draggableIcon">
              <i className="iconfont icondrag" />
            </div>
            <div style={{ width: attrNameWidth }} className={styles.label}>
              {p.name}
            </div>
            <div className={styles.propContent}>请输入</div>
          </div>
        ))}
      </GridLayout>
    );
  };

  // 属性分组
  const renderPropGroup = (style: string, props: any[], propGrid: any) => {
    const prop_groups = currentTemplate.prop_groups;
    let className = styles.defaultGroupName;
    if (style === 'style_1') className = styles.style1GroupName;
    if (style === 'style_2') className = styles.style2GroupName;
    if (style === 'style_3') className = styles.style3GroupName;

    const render = (propsInG: any[], groupId: string) => {
      if (style === 'default') return renderDefaultStyle(propsInG);
      // 分组且输入框样式
      if (style === 'style_2')
        return renderStyle2(propsInG, propGrid[groupId], groupId);
      // 左右框样式
      if (style === 'style_3')
        return renderStyle3(propsInG, propGrid[groupId], groupId);
      // 分组且划线样式
      return renderStyle1(propsInG, propGrid[groupId], groupId);
    };

    return prop_groups && prop_groups.length ? (
      prop_groups.map((group: any) => {
        const { id, name } = group;
        if (group.props.length === 0) return null;
        return (
          <div key={id}>
            <div className={className}>
              {style === 'default' && <div className={styles.rect} />}
              <div>{name}</div>
            </div>
            {render(
              props.filter((p: any) => group.props.includes(Number(p.index))),
              id,
            )}
          </div>
        );
      })
    ) : (
      <div>
        <div className={className}>
          {style === 'default' && <div className={styles.rect} />}
          <div>默认分组</div>
        </div>
        {render(props, 'default')}
      </div>
    );
  };

  return [
    <div
      ref={containerRef}
      className={styles.customAttribute}
      key="customAttribute"
    >
      {showGroup
        ? renderPropGroup(style, props, propGrid)
        : style === 'default'
          ? renderDefaultStyle(props)
          : style === 'style_1'
            ? renderStyle1(props, propGrid, '')
            : style === 'style_2'
              ? renderStyle2(props, propGrid, '')
              : renderStyle3(props, propGrid, '')}
    </div>,
    widgetInstance.config.showSaveButton && (
      <div key="saveButton" className="flex gap-2 pl-4">
        <Button type="primary" className="rounded-[8px]">
          保存
        </Button>
        <Button className="rounded-[8px]">重置</Button>
      </div>
    ),
  ];
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ICustomPropsConfig>()!;
  const currentTemplate = useCurrentTemplate();
  const [columnNum, setColumnNum] = useState(undefined);
  const {
    showGroup,
    style,
    attributesDefaultShow,
    valueWrap,
    attrNameWidth,
    propGroupFolds = [],
    propVisibleMap = {},
  } = widgetInstance.config;

  const showSetColumnNum = style !== 'default';

  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<ICustomPropsConfig>;

  const getCurrentTemplateProps = () => {
    if (!currentTemplate) return [];

    return currentTemplate.prop
      .map((p, index) => ({ ...p, index }))
      .filter((i) => i.type)
      .sort((a: any, b: any) => a.sort - b.sort);
  };

  const changeConfig = <K extends keyof ICustomPropsConfig>(v: any, key: K) => {
    updateConfig((config) => {
      if (key === 'style' && v === 'default') {
        config.propGrid = null;
      }
      config[key] = v;
    });
  };

  const changeColumnNum = () => {
    updateConfig((config: any) => {
      const _propGrid = cloneDeep(config.propGrid);

      if (!_propGrid || !columnNum) return;

      if (columnNum === 1) {
        if (Array.isArray(_propGrid)) {
          _propGrid.forEach((p: any, i: number) => {
            p.x = 0;
            p.y = i;
            p.w = 12;
            p.h = 1;
          });
        } else {
          // group
          Object.values(_propGrid).forEach((g: any) => {
            g.forEach((p: any, i: number) => {
              p.x = 0;
              p.y = i;
              p.w = 12;
              p.h = 1;
            });
          });
        }
      }

      if (columnNum === 2) {
        if (Array.isArray(_propGrid)) {
          _propGrid.forEach((p: any, i: number) => {
            p.x = i % 2 === 0 ? 0 : 6;
            p.y = Math.floor(i / 2);
            p.w = 6;
            p.h = 1;
          });
        } else {
          Object.values(_propGrid).forEach((g: any) => {
            g.forEach((p: any, i: number) => {
              p.x = i % 2 === 0 ? 0 : 6;
              p.y = Math.floor(i / 2);
              p.w = 6;
              p.h = 1;
            });
          });
        }
      }

      if (columnNum === 3) {
        if (Array.isArray(_propGrid)) {
          _propGrid.forEach((p: any, i: number) => {
            p.x = (i % 3) * 4;
            p.y = Math.floor(i / 3);
            p.w = 4;
            p.h = 1;
          });
        } else {
          Object.values(_propGrid).forEach((g: any) => {
            g.forEach((p: any, i: number) => {
              p.x = (i % 3) * 4;
              p.y = Math.floor(i / 3);
              p.w = 4;
              p.h = 1;
            });
          });
        }
      }

      config.propGrid = _propGrid;
    });
  };

  const getPropGroups = () => {
    if (!currentTemplate) return [];
    let prop_groups: any = currentTemplate?.prop_groups;
    if (!prop_groups || prop_groups.length === 0)
      prop_groups = [{ id: '-1', name: '默认分组' }];

    return prop_groups;
  };

  const setAllCollapse = (v: string) => {
    const propGroupIds = getPropGroups().map((g: any) => g.id);
    updateConfig((config) => {
      config.propGroupFolds = v ? propGroupIds : [];
    });
  };

  const setCollapse = (v: boolean, groupId: string) => {
    updateConfig((config) => {
      config.propGroupFolds = v
        ? (config.propGroupFolds || []).concat(groupId)
        : (config.propGroupFolds || []).filter((i: string) => i !== groupId);
    });
  };
  return (
    <Form layout="vertical">
      <SharedStateNodeSetting />
      {/* <BasicSetting type="component" /> */}
      <Form.Item label="显示属性编辑入口">
        <Radio.Group
          options={visibleOptions}
          value={widgetInstance.config.editMode === 'toggleEditor'}
          onChange={(e) => {
            changeConfig(
              e.target.value ? 'toggleEditor' : 'default',
              'editMode',
            );
          }}
        />
      </Form.Item>

      <Form.Item label="显示属性分组">
        <Radio.Group
          onChange={(e) => changeConfig(e.target.value, 'showGroup')}
          value={showGroup}
          options={visibleOptions}
        />
      </Form.Item>
      {showGroup && (
        <div style={{ marginBottom: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 8,
            }}
          >
            <div style={{ color: '#242d3f', fontWeight: 'bold' }}>
              默认分组显示
            </div>
            <div style={{ color: '#C9D0D9', display: 'flex' }}>
              <span style={{ width: 40, textAlign: 'center' }}>折叠</span>
              <span style={{ width: 40, textAlign: 'center' }}>展开</span>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 12,
              backgroundColor: '#F8F9FB',
              borderRadius: 8,
              paddingLeft: 20,
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <div style={{ color: '#242D3F', fontWeight: 'bold' }}>统一设置</div>
            <Radio.Group
              style={{ display: 'flex' }}
              onChange={(e) => setAllCollapse(e.target.value)}
            >
              <Radio
                style={{
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: 0,
                }}
                key="fold"
                value={true}
              />
              <Radio
                style={{
                  width: 40,
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: 0,
                }}
                key="expand"
                value={false}
              />
            </Radio.Group>
          </div>
          {getPropGroups().map((g: any) => (
            <div
              key={g.id}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 12,
                backgroundColor: '#F8F9FB',
                borderRadius: 8,
                paddingLeft: 20,
                paddingTop: 8,
                paddingBottom: 8,
              }}
            >
              <div>{g.name}</div>
              <Radio.Group
                value={propGroupFolds.includes(g.id)}
                style={{ display: 'flex' }}
                onChange={(e) => setCollapse(e.target.value, g.id)}
              >
                <Radio
                  style={{
                    width: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: 0,
                  }}
                  key="fold"
                  value={true}
                />
                <Radio
                  style={{
                    width: 40,
                    display: 'flex',
                    justifyContent: 'center',
                    marginRight: 0,
                  }}
                  key="expand"
                  value={false}
                />
              </Radio.Group>
            </div>
          ))}
        </div>
      )}

      <Form.Item label="显示收起展开">
        <Radio.Group
          onChange={(e) => changeConfig(e.target.value, 'showFoldButton')}
          value={widgetInstance.config.showFoldButton ?? true}
          options={visibleOptions}
        />
      </Form.Item>

      <Form.Item label="显示保存按钮">
        <Radio.Group
          onChange={(e) => changeConfig(e.target.value, 'showSaveButton')}
          value={widgetInstance.config.showSaveButton ?? false}
          options={visibleOptions}
        />
      </Form.Item>

      {/* 选择属性 */}
      <Form.Item label="选择属性">
        {getCurrentTemplateProps().map((p) => (
          <div
            style={{
              backgroundColor: 'rgb(248, 249, 251)',
              display: 'flex',
              justifyContent: 'space-between',
              borderRadius: 8,
              padding: '8px 12px 8px 20px',
              marginBottom: 12,
            }}
            key={p.index}
          >
            <span>{p.name}</span>
            <Checkbox
              checked={!propVisibleMap[p.index]}
              onChange={(e) => {
                const v = e.target.checked;
                updateConfig((config) => {
                  config.propVisibleMap = config.propVisibleMap || {};
                  config.propVisibleMap[p.index] = v ? false : 'hidden';
                });
              }}
            />
          </div>
        ))}
      </Form.Item>
      <Form.Item label="属性样式">
        <Radio.Group
          onChange={(e) => changeConfig(e.target.value, 'style')}
          value={style}
        >
          <Radio key={'default'} value={'default'}>
            单列样式
          </Radio>
          <Radio key={'style_1'} value={'style_1'}>
            左右划线样式
          </Radio>
          <Radio key={'style_3'} value={'style_3'}>
            左右框样式
          </Radio>
          <Radio key={'style_2'} value={'style_2'}>
            上下框样式
          </Radio>
        </Radio.Group>
      </Form.Item>
      {showSetColumnNum && (
        <div style={{ marginBottom: 24 }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 8,
            }}
          >
            <div style={{ color: '#242d3f', fontWeight: 'bold' }}>分列数</div>
            <div
              onClick={changeColumnNum}
              style={{ cursor: 'pointer', color: '#316EF5' }}
            >
              应用
            </div>
          </div>

          <Radio.Group
            onChange={(e) => setColumnNum(e.target.value)}
            value={columnNum}
          >
            <Radio key={1} value={1}>
              1列
            </Radio>
            <Radio key={2} value={2}>
              2列
            </Radio>
            <Radio key={3} value={3}>
              3列
            </Radio>
          </Radio.Group>
        </div>
      )}
      <Form.Item label="属性显示">
        <Checkbox
          onChange={() =>
            changeConfig(
              attributesDefaultShow === 'defaultExpand'
                ? 'defaultFold'
                : 'defaultExpand',
              'attributesDefaultShow',
            )
          }
          checked={attributesDefaultShow === 'defaultFold'}
          children="全部收起"
        />
      </Form.Item>
      <Form.Item label="属性值是否换行显示">
        <Radio.Group
          onChange={(e) => changeConfig(e.target.value, 'valueWrap')}
          value={valueWrap}
        >
          <Radio key={'true'} value={true}>
            换行
          </Radio>
          <Radio key={'false'} value={false}>
            不换行
          </Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        hidden={!['style_1', 'style_3'].includes(style)}
        label="属性名宽度"
      >
        <InputNumber
          min={40}
          value={attrNameWidth}
          onChange={(e) => changeConfig(e, 'attrNameWidth')}
          addonAfter="像素"
          style={{ width: '100%' }}
        />
      </Form.Item>
    </Form>
  );
};

export const CustomPropsWidget = defineSystemWidget<ICustomPropsConfig>()({
  id: 'CustomProps',
  title: '自定义属性',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#21BF46"
        icon={
          <RegularIcon
            type="iconneirongyepeizhi-zidingyishuxing"
            color={'#ffffffcc'}
          />
        }
      />
    );
  },
  basic: {
    defaultHeight: 4,
    defaultWidth: 12,
    minHeight: 2,
    maxCount: 1,
    dynamicHeight: true,
  },
  metadata: {
    showGroup: false,
    style: 'default',
    attributesDefaultShow: 'defaultExpand',
    valueWrap: false,
    attrNameWidth: 80,
    propGrid: null,
    propGroupFolds: [],
    propVisibleMap: {},
    editMode: 'default',
    showFoldButton: true,
  },
  preview: Preview,
  setting: Setting,
});
