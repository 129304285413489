import { QuestionCircleOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Form, Switch, Tooltip } from 'antd';
import { type FC, useMemo } from 'react';

import { TemplateCustomPropSelect } from '@/components/LinkPiForm';
import { DataSourceSettingCore } from '@/components/PageModelEditor/src/components';

import type { ICascaderOptionSource } from '../../types';

type ICascaderOptionSourceConfig = ICascaderOptionSource[number];

type ILevelConfig = {
  isFirstLevel?: boolean;
  isLastLevel?: boolean;
  value?: ICascaderOptionSourceConfig;
  onChange?: (value: ICascaderOptionSourceConfig) => void;
};

const genPropValue = (_: any, id: number) => id;

export const LevelConfig: FC<ILevelConfig> = ({
  isFirstLevel,
  isLastLevel,
  ...props
}) => {
  const [value, onChange] =
    useControllableValue<ICascaderOptionSourceConfig>(props);

  const templateId = useMemo(() => {
    return value?.dataSource?.conditions?.find((c) => c.key === 'templateId')
      ?.input?.[0];
  }, [value?.dataSource?.conditions]);

  return (
    <Form labelCol={{ span: 7 }}>
      <Form.Item label="数据源">
        <DataSourceSettingCore
          value={value.dataSource}
          onChange={(dataSource) =>
            onChange?.({
              ...value,
              dataSource,
            })
          }
        />
      </Form.Item>
      <Form.Item label="选项显示属性">
        <TemplateCustomPropSelect
          templateId={templateId}
          genValue={genPropValue}
          fieldProps={{
            value: value.labelProp,
            onChange: (v: unknown) => {
              onChange?.({
                ...value,
                labelProp: v as number,
              });
            },
          }}
          noStyle
        />
      </Form.Item>
      <Form.Item label="选项值属性">
        <TemplateCustomPropSelect
          templateId={templateId}
          genValue={genPropValue}
          fieldProps={{
            value: value.valueProp,
            onChange: (v: unknown) => {
              onChange?.({
                ...value,
                valueProp: v as number,
              });
            },
          }}
          noStyle
        />
      </Form.Item>
      <Form.Item hidden={isFirstLevel} label="与父级匹配属性">
        <TemplateCustomPropSelect
          templateId={templateId}
          genValue={genPropValue}
          fieldProps={{
            value: value.parentIdProp,
            onChange: (v: unknown) => {
              onChange?.({
                ...value,
                parentIdProp: v as number,
              });
            },
          }}
          noStyle
        />
      </Form.Item>
      <Form.Item
        hidden={isLastLevel}
        label={
          <span className="inline-flex gap-1">
            是否可勾选层级
            <Tooltip title="开启时，选中当前层级选项，将会触发筛选相关联数据机制；关闭时，选中当前层级选项，将不会触发筛选中">
              <QuestionCircleOutlined style={{ color: '#316EF5' }} />
            </Tooltip>
          </span>
        }
        labelCol={{ span: '220px' }}
      >
        <Switch
          size="small"
          checked={value.checkable}
          onChange={(v) =>
            onChange?.({
              ...value,
              checkable: v,
            })
          }
        />
      </Form.Item>
    </Form>
  );
};
