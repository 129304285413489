import { PlusOutlined } from '@ant-design/icons';
import type { ApiResponse } from '@linkpi/core';
import { useControllableValue } from 'ahooks';
import { Button, Select } from 'antd';
import { nanoid } from 'nanoid';
import type { FC } from 'react';

import styles from './styles.less';

interface IProps {
  headers: Array<{ id: string; name: string; type: string }>;
  template: ApiResponse.CurrentUser.TemplateInfo;
  defaultValue?: any[];
  onChange?: (v: any[]) => void;
}

const { Option } = Select;

const TreeTableMatch: FC<IProps> = ({
  headers,
  template,
  onChange,
  defaultValue,
}) => {
  2;
  const [matchings, setMatchings] = useControllableValue({
    onChange: onChange,
    defaultValue: defaultValue,
  });

  const getLeftOption = () => {
    const base = [
      { label: '标题', value: 'title' },
      { label: '创建者', value: 'creator' },
    ];

    const prop = template.prop
      .map((p: any, index: number) => ({ ...p, index }))
      .filter((p: any) => p.name)
      .map((p) => ({
        label: p.name,
        value: p.index,
      }));

    return [...base, ...prop];
  };

  // 根据字段类型 获取 op
  const getOpOption = (item: any) => {
    const { left } = item;

    if (left === 'title' || left === 'creator')
      return [
        { label: '包含', value: 'include' },
        { label: '等于', value: 'equal' },
      ];

    // prop
    const t = template.prop[left].type;

    if (
      t === 'user' ||
      t === 'text' ||
      t === 'formula' ||
      t === 'auto_inc' ||
      t === 'positioning' ||
      t === 'address'
    )
      return [
        { label: '包含', value: 'include' },
        { label: '等于', value: 'equal' },
      ];

    if (t === 'date' || t === 'datetime')
      return [
        {
          label: '等于',
          value: 'equal',
        },
        {
          label: '包含',
          value: 'date_include',
        },
        {
          label: '被包含',
          value: 'date_inside',
        },
        {
          label: '晚于',
          value: 'great',
        },
        {
          label: '早于',
          value: 'less',
        },
        {
          label: '晚于等于',
          value: 'great_equal',
        },
        {
          label: '早于等于',
          value: 'less_equal',
        },
      ];

    if (t === 'number')
      return [
        { label: '=', value: 'equal' },
        { label: '>', value: 'greater' },
        { label: '<', value: 'less' },
        { label: '≥', value: 'greaterEqual' },

        { label: '≤', value: 'lessEqual' },
      ];

    if (t === 'enum' || t === 'tag')
      return [
        {
          label: '包含',
          value: 'include',
        },
        {
          label: '等于',
          value: 'equal',
        },
        {
          label: '相交',
          value: 'intersect',
        },
      ];

    return [];
  };

  const add = () => {
    const item = {
      op: '',
      left: undefined,
      field: '',
      id: nanoid(),
    };

    setMatchings([...matchings, item]);
  };

  const changeOp = (v: string, item: any) => {
    const newRes = [...matchings];
    newRes.find((i) => i.id === item.id).op = v;

    setMatchings(newRes);
  };

  const changeField = (v: any, item: any) => {
    const newRes = [...matchings];
    newRes.find((i) => i.id === item.id).field = v;

    setMatchings(newRes);
  };

  const getFieldOption = (item: any) => {
    const { left } = item;
    if (left === undefined) return [];

    if (left === 'title')
      return headers.filter(
        (h) => h.type === 'text' || h.type === 'formula_text',
      );
    if (left === 'creator') return headers.filter((h) => h.type === 'user');

    // prop
    const { type: t, formulaFormat } = template.prop[left];
    if (t === 'user') return headers.filter((h) => h.type === 'user');
    if (t === 'auto_inc')
      return headers.filter(
        (h) =>
          h.type === 'number' ||
          h.type === 'formula_number' ||
          h.type === 'auto_inc',
      );
    if (t === 'address')
      return headers.filter(
        (h) => h.type === 'positioning' || h.type === 'address',
      );
    if (t === 'positioning')
      return headers.filter((h) => h.type === 'positioning');
    if (t === 'text')
      return headers.filter(
        (h) => h.type === 'text' || h.type === 'formula_text',
      );
    if (t === 'number')
      return headers.filter(
        (h) =>
          h.type === 'number' ||
          h.type === 'formula_number' ||
          h.type === 'currencyV2',
      );
    if (t === 'enum' || t === 'tag')
      return headers.filter(
        (h) =>
          h.type === 'text' || h.type === 'formula_text' || h.type === 'enum',
      );
    if (t === 'date' || t === 'datetime')
      return headers.filter((h) => h.type === 'date' || h.type === 'datetime');

    if (t === 'formula') {
      return !formulaFormat
        ? headers.filter(
            (h) =>
              h.type === 'number' ||
              h.type === 'formula_number' ||
              h.type === 'currencyV2',
          )
        : headers.filter(
            (h) =>
              h.type === 'text' ||
              h.type === 'formula_text' ||
              h.type === 'currencyV2',
          );
    }

    return [];
  };

  const renderField = (item: any) => {
    return (
      <Select
        value={item.field}
        style={{ flex: 1, marginRight: 4 }}
        placeholder="请选择字段"
        filterOption={(input, option) =>
          option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >=
          0
        }
        showSearch
        onChange={(v) => changeField(v, item)}
      >
        {getFieldOption(item).map((f) => (
          <Option value={f.id} key={f.id}>
            {f.name}
          </Option>
        ))}
      </Select>
    );
  };

  const renderOp = (item: any) => {
    if (item.left === undefined) return null;

    return (
      <Select
        value={item.op}
        style={{ width: 100, marginRight: 4 }}
        onChange={(v) => changeOp(v, item)}
      >
        {getOpOption(item).map((i) => (
          <Option value={i.value} key={i.value}>
            {i.label}
          </Option>
        ))}
      </Select>
    );
  };

  const del = (id: string) => {
    setMatchings(matchings.filter((i) => i.id !== id));
  };

  const changeLeft = (v: any, item: any) => {
    const newRes = [...matchings];
    const _item = newRes.find((i) => i.id === item.id);

    _item.left = v;
    _item.field = '';
    _item.op = '';

    setMatchings(newRes);
  };

  const renderLeft = (item: any) => {
    return (
      <Select
        value={item.left}
        style={{ width: 140, marginRight: 8 }}
        onChange={(v) => changeLeft(v, item)}
      >
        {getLeftOption().map((i) => (
          <Option value={i.value} key={i.value}>
            {i.label}
          </Option>
        ))}
      </Select>
    );
  };

  return (
    <div className={styles.container}>
      {matchings.map((i) => (
        <div key={i.id} className={styles.item}>
          {renderLeft(i)}
          {renderOp(i)}
          {renderField(i)}
          <i onClick={() => del(i.id)} className="iconfont icona-zujian67" />
        </div>
      ))}
      <Button
        onClick={add}
        style={{ color: '#6B7A96', width: '100%' }}
        type="dashed"
        icon={<PlusOutlined />}
      >
        新增匹配条件
      </Button>
    </div>
  );
};

export default TreeTableMatch;
