import { useQuery } from '@tanstack/react-query';

import { useCurrentOrgId } from '@/hook/useOrg';
import type { GetOptionListParams } from '@/services/options';
import { getOptionList } from '@/services/options';

export const useOptionsList = (
  configType: GetOptionListParams['configType'],
) => {
  const orgId = useCurrentOrgId();

  return useQuery({
    staleTime: 200,
    queryKey: ['options-list', orgId, configType],
    queryFn: async () => {
      const res = await getOptionList({
        org_id: orgId,
        configType,
      })

      if (res.status === 'ok') {
        return res.data
      } else {
        return []
      }
    }
  });
};
