import type { IDataSourceSetting, Legacy_IDataSourceSetting_v1 } from './types';

export const createInitialValue = (): IDataSourceSetting => ({
  type: 'conditionFilterV2',
  parentType: 'others',
  conditions: [
    {
      key: '',
      op: 'and',
      input: [
        {
          key: '',
          op: 'and',
          input: [{ key: '', op: 'intersect', input: [] }],
        },
      ],
    },
  ],
});

export const isLegacyDataSourceSetting = (
  setting: IDataSourceSetting | Legacy_IDataSourceSetting_v1,
): setting is Legacy_IDataSourceSetting_v1 => {
  return setting.type === 'temp' || setting.type === 'dataSource';
};

export const migrateDataSourceSetting = (
  setting: Legacy_IDataSourceSetting_v1 | IDataSourceSetting,
): IDataSourceSetting => {
  if (!isLegacyDataSourceSetting(setting)) return setting;

  const templateId = setting.conditions.find(
    (item) => item.key === 'templateId',
  )?.input[0];

  const conditions = setting.conditions.filter(
    (item) => item.key !== 'templateId',
  );

  // TODO matching 转换
  setting.matchings?.forEach((item) => {
    const { origin, target } = item;
    conditions.push({
      key: 'prop',
      op: 'intersect',
      index: origin,
      input: [],
      extends: {
        inputType: 'currentNode',
        target,
      },
    });
  });

  return {
    type: 'conditionFilterV2',
    parentType: 'others',
    conditions: [
      { key: 'templateId', op: 'intersect', input: [templateId] },
      {
        input: [{ input: conditions, op: 'and' }],
        op: 'and',
        key: '',
      },
    ],
  };
};
