import { SIDE_MODE } from '@linkpi/core';
import { usePiNode } from '@linkpi/core/web';
import { useSelector } from '@umijs/max';
import { type FC, useMemo, useRef } from 'react';

import HomeContextProvider, {
  HomeContext,
} from '@/context/HomeContextProvider';
import {
  useCurrentSelection,
  useCurrentUser,
  useOrgConnection,
  useOrgInfo,
} from '@/hook';
import {
  useWorkbenchViewList,
  WorkbenchViewListContext,
} from '@/hook/useViewList';
import WorkbenchContent from '@/pages/home/Workbench/WorkbenchContent';

type ViewComponentProp = {
  nodeId: string;
  viewId: string;
};

/**
 * 依赖 Home 目录,请勿从 components/index 中导出
 *
 * 渲染视图的组件
 */
export const NodeViewComponent: FC<ViewComponentProp> = ({
  nodeId,
  viewId,
}) => {
  const tabContentRef = useRef<any>({});
  const [orgInfo] = useOrgInfo();
  const orgConnection = useOrgConnection();
  const [node] = usePiNode(orgConnection, nodeId);
  const currentSelection = useCurrentSelection();
  const currentUser = useCurrentUser();
  const { readOnlyMode } = useSelector((state: any) => state.workspace);

  const [viewList, getViewList, authMap, _handleSortViewList, mutateViewInfo] =
    useWorkbenchViewList(
      node!,
      orgInfo!.orgId,
      orgInfo!.role,
      currentUser.userid,
    );

  const WorkbenchViewListContextValue = useMemo(
    () => ({
      mutateViewInfo,
      refreshViewList: getViewList,
    }),
    [getViewList, mutateViewInfo],
  );

  const content = (
    <WorkbenchViewListContext.Provider
      value={WorkbenchViewListContextValue as any}
    >
      <div
        style={{
          flex: 1,
          height: '100%',
          display: 'flex',
          // 放置全屏时，背景颜色消失
          backgroundColor: '#fff',
        }}
        id="WorkbenchContent"
      >
        <WorkbenchContent
          node={node!}
          orgInfo={orgInfo}
          selectViewId={viewId}
          viewList={viewList}
          leftViewCollapse={true}
          leftViewWidth={400}
          showMessage={false}
          getViewList={getViewList}
          authMap={authMap}
          readOnly={readOnlyMode}
          isDrafts={currentSelection.selectSideMode === SIDE_MODE.DRAFTSBOX}
          linkRef={tabContentRef}
        />
      </div>
    </WorkbenchViewListContext.Provider>
  );

  /**
   * PATCH: react
   *
   * _currentValue 非公开属性，后续 react 更新后需要检查兼容性
   */
  // @ts-ignore
  const hasHomeContext = HomeContext._currentValue;

  /**
   * 如果存在 HomeContext，则直接渲染内容
   * 否则，则将内容包裹在 HomeContextProvider 中
   */
  return hasHomeContext ? (
    content
  ) : (
    <HomeContextProvider>{content}</HomeContextProvider>
  );
};
