import { toArray } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import type { SelectProps } from 'antd';
import { Checkbox, Select } from 'antd';
import { defaultTo, intersection } from 'ramda';
import React from 'react';

export interface SelectEditorProps extends SelectProps {
  options?: { label: string; value: string; filterText?: string }[];
  allowChooseAll?: boolean;
}

// 将字符串null处理成null
const handleValueNullStringToNull = (data: any) => {
  if (!Array.isArray(data)) return data;
  return data.map((d) => {
    if (d === 'null') return null;
    return d;
  });
};

export const BaseSelect: React.FC<SelectEditorProps> = ({
  value: _value,
  onChange,
  disabled,
  options,
  allowChooseAll,
  ...rest
}) => {
  console.log('options', options);
  const value = defaultTo([], _value);

  const realOptions: any[] | undefined = options
    ?.map((item) => {
      if ('options' in item) return item.options;
      return item;
    })
    .flat();

  const allChecked =
    value?.length && realOptions?.length
      ? intersection(
          value,
          realOptions.map((x) => x.value),
        ).length === realOptions.length
      : false;

  const toggleAll = useMemoizedFn(() => {
    if (allChecked) {
      onChange?.([], []);
    } else {
      onChange?.(
        realOptions?.map((x) => x.value),
        realOptions as any,
      );
    }
  });

  return (
    <Select
      value={value || ''}
      onChange={(val, option) => {
        const newValue = handleValueNullStringToNull(toArray(val));
        onChange?.(newValue, option);
      }}
      disabled={disabled}
      options={options}
      placeholder="请选择"
      mode="multiple"
      maxTagCount={3}
      maxTagTextLength={7}
      allowClear
      showSearch
      filterOption={(input, option: any) => {
        const filterText = option?.filterText || option?.label;
        if (!filterText) return false;

        if (option.options) return false;

        return (
          (filterText as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      }}
      dropdownRender={(_) => {
        if (!allowChooseAll) return _;

        // 渲染全选
        return (
          <>
            <div style={{ padding: 6, display: 'flex' }}>
              <Checkbox
                indeterminate={false}
                onChange={toggleAll}
                checked={allChecked}
              >
                全选
              </Checkbox>
            </div>
            {_}
          </>
        );
      }}
      {...rest}
    />
  );
};
