import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_TEMPLATE } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { useSelector } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';
import cls from 'classnames';
import dayjs from 'dayjs';
import { isFunction } from 'lodash';
import { defaultTo, isEmpty, pick } from 'ramda';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import { match } from 'ts-pattern';

import {
  useOrgInfo,
  useOrgUserGroup,
  useOrgUserMap,
  useUnsafeTemplateMap,
} from '@/hook';

import { PiButton } from '../Button';
import ConditionItem from './ConditionItem';
import { useViewConditionData } from './hook';
import { ConditionFilterConfigContext } from './hook/useConditionFilterOption';
import { useConditionOptions } from './hook/useConditionOptions';
import type { ViewConditionFormPropsType } from './types';

import styles from './styles.less';

export type FilterConditionConfigType = {
  options: {
    key: string;
    name: string;
    options?: { key: string; name: string; templateId: string }[];
  }[];
  name: string;
  type?: string | undefined;
  data?: any;
};
export type FilterConditionConfigMapType = Record<
  string,
  FilterConditionConfigType
>;

export type ConditionFormRef = {
  clearCondition: () => void;
};

const _ConditionFilter: ForwardRefRenderFunction<
  ConditionFormRef,
  ViewConditionFormPropsType
> = (props, ref) => {
  useImperativeHandle(ref, () => {
    return { clearCondition };
  });

  const {
    addText,
    className,
    filterPathSettings = [],
    conditions: outerConditions,
    onConditionsChange,
    defaultConditions,
    // 入口，分视图或属性配置
    // 1. 处理defaultConditions的逻辑不一样
    // 2. 1.8sp支持的类型不一样
    entry = 'view',
    onChange,
    getTreeData,
    allowTempIds,
    nodeSelectExtraCurrent,
    editable,
    displayHeader = false,
    displayTemplate = true,
    disableTemplate = false,
    displayParentId = true,
    disableParentId = false,
    displayAddBtn = true,
    hideDelete = false,
    allowKey,
    allowPropTypes,
    extraActionsRender,
    onChangeMode,
    experimental_hiddenKeys,
    addButtonProps = {},
    templateUsage,
  } = props;

  const userMap = useOrgUserMap();
  const tempMap = useUnsafeTemplateMap(templateUsage || 'filteredProps');
  const [orgInfo] = useOrgInfo();
  const userGroups = useOrgUserGroup();

  assertExists(orgInfo, 'orgInfo');

  /**
   * state
   */
  const { spaceTags } = useSelector((state: any) => state.space);

  const {
    conditions,
    setConditionByIndex,
    removeCondition,
    addCondition,
    clearCondition,
  } = useViewConditionData({
    conditions: outerConditions,
    onConditionsChange,
    defaultConditions,
    entry,
    orgInfo,
    onChange,
    onChangeMode,
  });

  const hiddenKeysMap: Record<string, true> = useMemo(() => {
    if (!experimental_hiddenKeys || isEmpty(experimental_hiddenKeys)) {
      return {};
    }
    return experimental_hiddenKeys.reduce((r, k) => ({ ...r, [k]: true }), {});
  }, [experimental_hiddenKeys]);

  const checkItemVisible = useMemoizedFn(
    (condition: (typeof conditions)[number]) => {
      if (condition.key === 'templateId' && !displayTemplate) return false;
      if (condition.key === 'ancestor' && !displayParentId) return false;
      if (hiddenKeysMap[condition.key]) return false;

      return true;
    },
  );

  /**
   * 单主题类型
   */
  const curTemplate = useMemo(() => {
    const conditionTemplateId =
      conditions.find((x) => x.key === 'templateId')?.input[0] ||
      DEFAULT_TEMPLATE;
    return tempMap[conditionTemplateId as string];
  }, [tempMap, conditions]);

  /**
   * 多主题类型
   */
  const curTemplateList = useMemo(() => {
    const conditionTemplateIds = conditions.find(
      (x) => x.key === 'templateIds',
    )?.input;
    if (!Array.isArray(conditionTemplateIds)) return undefined;

    return conditionTemplateIds
      .filter((i) => typeof i === 'string')
      .map((id: string) => tempMap[id]);
  }, [tempMap, conditions]);

  const conditionFilterOption = useMemo(() => {
    return {
      ...pick(
        [
          'maxTagCount',
          'experimental_disableHistoryStatus',
          'experimental_disableOpList',
          'experimental_userLiteMode',
          'experimental_enableUserProperty',
          'experimental_multiple',
          'experimental_onlyShowAuxInEnumProp',
        ],
        props,
      ),
      curTemplate,
      curTemplateList,
    };
  }, [curTemplate, curTemplateList, props]);

  const sysTags = useMemo(() => {
    if (orgInfo && spaceTags?.[orgInfo.orgId]) {
      return spaceTags[orgInfo.orgId];
    } else {
      return [];
    }
  }, [orgInfo, spaceTags]);

  const {
    filterConditionOptions,
    filterConditionConfigMap,
    filterConditionConfigMapV2,
  } = useConditionOptions({
    curTemplate,
    curTemplateList,
    tempMap,
    allowKey,
    allowPropTypes,
    experimental_onlyShowAuxInEnumProp:
      props.experimental_onlyShowAuxInEnumProp,
  });

  /**
   * effect
   */
  // 初始化form 和 配置

  /**
   * handle
   */
  // 显示过滤器项的值
  //
  const displayFilterPathSettingsValue = (data: {
    type: string;
    value: any;
    key: string;
  }) => {
    // if (data.value === FILTER_UNSET) {
    //   return '【未定义】';
    // }
    if (data.type === 'date' || data.type === 'datetime') {
      return dayjs(data.value[0]).format('YYYY-MM-DD');
    } else if (data.type === 'user') {
      const userId = Array.isArray(data.value) ? data.value[0] : data.value;
      const user = userMap[userId];
      return user ? user.nick_name : '未知用户';
    } else if (data.key === 'templateStatus') {
      if (!curTemplate) return null;
      const index = data.value[0] ? data.value[0].split('_')[1] : null;
      if (index === null) return;
      const status = curTemplate.task_status[Number(index)];
      return status ? status.name : '';
    } else {
      return Array.isArray(data.value) ? data.value[0] : data.value;
    }
  };

  const getDisabled = (item: ApiResponse.ViewList.ViewconditionV2Item) => {
    if (item.key === 'templateId') {
      return !editable || disableTemplate;
    }
    if (item.key === 'ancestor') {
      return !editable || disableParentId;
    }

    return !editable;
  };

  /**
   * render
   */
  // 显示筛选器条件
  let filterPathSettingsDisplay;
  if (filterPathSettings.length) {
    filterPathSettingsDisplay = (
      <>
        <div className={styles.panelHeader}>筛选器内置条件</div>
        {filterPathSettings.map((x, i) => (
          <ConditionItem
            key={i}
            entry={entry}
            condition={{
              key: (filterConditionConfigMap[x.key]
                ? filterConditionConfigMap[x.key].name
                : x.key) as any,
              input: displayFilterPathSettingsValue(x),
              op: 'equal', // TODO
            }}
            filterConditionOptions={[]}
            disabled={true}
            orgInfo={orgInfo}
            curTemplate={curTemplate}
            filterConditionConfigMap={filterConditionConfigMapV2}
            tempMap={tempMap}
            userMap={userMap}
            sysTags={sysTags}
            allowTempIds={allowTempIds}
            nodeSelectExtraCurrent={nodeSelectExtraCurrent}
            hideDelete={hideDelete}
          />
        ))}
      </>
    );
  }

  let panelHeader;
  if (displayHeader) {
    panelHeader = (
      <div className={styles.panelHeader}>
        筛选条件
        {editable ? (
          <div className={styles.clearBtn} onClick={clearCondition}>
            清空筛选
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <div className={cls(styles.panel, className)}>
      <ConditionFilterConfigContext.Provider value={conditionFilterOption}>
        {filterPathSettingsDisplay}
      </ConditionFilterConfigContext.Provider>
      {panelHeader}
      <ConditionFilterConfigContext.Provider value={conditionFilterOption}>
        <div
          className={cls(styles.panelBody, 'condition-filter-item-container')}
        >
          {conditions.map(
            (x, i) =>
              checkItemVisible(x) && (
                <ConditionItem
                  key={i}
                  entry={entry}
                  filterConditionOptions={filterConditionOptions}
                  condition={x}
                  remove={
                    x.key === 'ancestor' || x.key === 'templateId'
                      ? undefined
                      : () => removeCondition(i)
                  }
                  onChange={(e) => setConditionByIndex(i, e)}
                  disabled={getDisabled(x)}
                  getTreeData={getTreeData}
                  orgInfo={orgInfo}
                  curTemplate={curTemplate}
                  filterConditionConfigMap={filterConditionConfigMapV2}
                  tempMap={tempMap}
                  userGroups={userGroups}
                  userMap={userMap}
                  sysTags={sysTags}
                  allowTempIds={allowTempIds}
                  nodeSelectExtraCurrent={nodeSelectExtraCurrent}
                  hideDelete={hideDelete}
                />
              ),
          )}
        </div>
      </ConditionFilterConfigContext.Provider>
      <div className={styles.panelFooter}>
        {editable && displayAddBtn ? (
          <PiButton
            icon={<i className={'iconfont iconadd'} />}
            onClick={addCondition}
            type="link"
            {...defaultTo({})(addButtonProps)}
          >
            {addText || '新增筛选条件'}
          </PiButton>
        ) : null}
        {match(extraActionsRender)
          .when(isFunction, (v) => v())
          .when(
            (v) => !!v,
            (v) => v,
          )
          .otherwise(() => null)}
      </div>
    </div>
  );
};

const ConditionFilter = forwardRef(_ConditionFilter);
export default ConditionFilter;
