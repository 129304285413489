import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import type { CurrentUser } from '@linkpi/core';
import {
  getQuoteOriginPropInfo,
  getViewSettingOptionWithGroup,
} from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import {
  Button,
  Checkbox,
  Empty,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Popover,
  Radio,
  Segmented,
  Select,
  Switch,
  Table,
  Tree,
} from 'antd';
import { cloneDeep } from 'lodash';
import { nanoid } from 'nanoid';
import { defaultTo, pick, toPairs } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';
import { match } from 'ts-pattern';

import attrWidgetNoData from '@/assets/img/attrWidgetNoData.png';
import { ColorPicker } from '@/components/ColorPicker';
import { RegularIcon } from '@/components/IconFont';
import {
  useCurrentOrgId,
  useOrgInfo,
  useOrgTemplatesSettingInfoMap,
  useOrgTempMap,
} from '@/hook';

import { WidgetIcon } from '../../components';
import type { IDataSourceSetting } from '../../components/DataSourceSetting/Modal';
import { getDataSourceSettingValue } from '../../components/DataSourceSetting/Modal';
import { DataSourceSettingCore } from '../../components/DataSourceSetting/Setting';
import {
  useActiveWidgetInstance,
  useEditor,
  useWidgetInstance,
} from '../../hooks';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';

import styles from './styles.less';
const defaultWidth = 120;

const Preview: WidgetPreivew = ({ id }) => {
  const widgetInstance = useWidgetInstance(id);
  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);

  const {
    titleStyle,
    fixedCol,
    conditions,
    propsConfig: _propsConfig,
    tools,
    bgConfig: { titleBg, contentBg, gapBg },
    horizontalBorder,
    verticalBorder,
    actions,
    pagination,
  } = widgetInstance?.config as any;

  const templateId = conditions.find((i: any) => i.key === 'templateId')
    ?.input[0];

  // mock
  const template: any = tempMap[templateId];
  const prop = template.prop
    .map((p: any, index: number) => ({ propIndex: index, ...p }))
    .filter((p: any) => p.type)
    .sort((p1, p2) => {
      if ('sort' in p1) return p1.sort - p2.sort;
      return 1;
    });

  const getPropsConfig = () => {
    if (_propsConfig) return cloneDeep(_propsConfig);

    return [
      {
        title: '序号',
        key: 'index',
        show: true,
        align: 'left',
        width: 50,
      },
      ...prop.map((p: CurrentUser.TemplateProp & { propIndex: number }) => {
        let propConfig = p;
        if (p.type === 'quote') {
          propConfig = getQuoteOriginPropInfo(
            p,
            tempMap,
          ) as unknown as typeof p;
        }

        const align = match(propConfig)
          .with(
            { type: 'number' },
            { type: 'formula', formulaFormat: 0 },
            () => 'right',
          )
          .otherwise(() => 'left');

        return {
          title: p.name,
          key: p.propIndex,
          show: true,
          align,
          width: undefined,
        };
      }),
    ];
  };
  const renderTitle = (v: string) => {
    return (
      <div
        style={{
          fontSize: titleStyle.size,
          color: titleStyle.color,
          fontWeight: titleStyle.decoration === 'bold' ? 700 : 400,
          fontStyle: titleStyle.decoration === 'italic' ? 'italic' : 'normal',
          textDecoration:
            titleStyle.decoration === 'underline' ? 'underline' : 'none',
        }}
      >
        {v}
      </div>
    );
  };

  const propsConfig = getPropsConfig().filter((p: any) => p.children || p.show);

  let scrollX = 0;
  propsConfig.forEach((i: any) => {
    if (!i.children) {
      const w = i.width === undefined ? defaultWidth : i.width;
      scrollX += w;
    } else {
      i.children.forEach((c: any) => {
        const w = c.width === undefined ? defaultWidth : c.width;
        scrollX += w;
      });
    }
  });

  const fixedColIndex = propsConfig.findIndex((p: any) => p.key === fixedCol);
  propsConfig.forEach((p: any, index: number) => {
    p.title = renderTitle(p.title);
    if (index <= fixedColIndex) p.fixed = 'left';
    if (!p.children) {
      // prop
      p.width = p.width || defaultWidth;
    } else {
      //group
      p.children.forEach((c: any) => {
        c.title = renderTitle(c.title);
        c.width = c.width || defaultWidth;

        if (index <= fixedColIndex) c.fixed = 'left';
      });
    }
  });

  const customizeEmpty = () => (
    <div className={styles.emptyContainer}>
      <Empty
        image={<img src={attrWidgetNoData} />}
        imageStyle={{ width: 40, height: 40 }}
        description={
          <span style={{ color: '#C9D0D9', fontSize: 12 }}>暂无数据</span>
        }
      />
    </div>
  );

  const columns: any[] = propsConfig;

  return (
    <div>
      <Table
        pagination={false}
        columns={columns}
        dataSource={[]}
        bordered
        rowKey={(v: any) => v.id}
        scroll={{ x: scrollX }}
        locale={{
          emptyText: customizeEmpty(),
        }}
      />
      <div
        style={{
          display: pagination ? 'flex' : 'none',
          justifyContent: 'end',
          paddingTop: 8,
        }}
      >
        <Pagination showSizeChanger={false} current={1} total={1} />
      </div>
    </div>
  );
};

type IElewattTableConfig = {
  titleLineHeight?: number;
  contentLineHeight?: number;
  name?: string;
  // 冻结首列至本列
  fixedCol?: number;
  // 属性分组
  propsConfig?: {
    title: string;
    key: number;
    show: boolean;
    align: 'left' | 'center' | 'right';
    width?: number;
  }[];
  groupNum: number;
  // 特殊属性
  specialProp?: number;
  // 操作 自定义按钮
  actions: string[];
  // 批量操作
  allowBatchAction: boolean;
  // 工具栏
  tools: string[];
  // 分页
  pagination?: { size: number };
  // 样式
  titleStyle: {
    size: number;
    color: string;
    decoration: string;
  };
  contentStyle: {
    size: number;
    color: string;
    decoration: string;
  };
  bgConfig: {
    titleBg: string;
    contentBg: string;
    gapBg: string;
  };
  horizontalBorder?: string;
  verticalBorder?: string;
  // 排序
  order?: string;
  // 是否降序
  orderType?: 'desc' | 'asc';
} & IDataSourceSetting;

const Setting: WidgetSetting = () => {
  const orgId = useCurrentOrgId();
  const [orgInfo] = useOrgInfo();

  assertExists(orgInfo);

  const tempMap = useOrgTemplatesSettingInfoMap();
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<IElewattTableConfig>();
  const updateConfig =
    editor.updateActiveWidgetInstanceConfig<IElewattTableConfig>;
  const [type, setType] = useState('属性设置');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [edittingGroup, setEdittingGroup] = useState('');
  const inputRef = useRef<any>(null);

  const widgetInstanceConfig: any = widgetInstance?.config;
  const {
    titleStyle,
    contentStyle,
    bgConfig,
    name,
    specialProp,
    order,
    orderType,
    allowBatchAction,
    tools,
    pagination,
    actions,
    propsConfig,
    fixedCol,
    conditions,
    titleLineHeight,
    contentLineHeight,
  } = widgetInstance?.config as any;
  const templateId = conditions.find((i: any) => i.key === 'templateId')
    ?.input[0];
  useEffect(() => {
    if (edittingGroup && inputRef?.current) {
      inputRef.current.focus();
    }
  }, [edittingGroup]);

  const template = tempMap[templateId];
  const prop = template.prop
    .map((p, index: number) => ({ propIndex: index, ...p }))
    .filter((p) => p.type)
    .sort((p1, p2) => {
      if ('sort' in p1) return p1.sort - p2.sort;
      return 1;
    });

  const buttons = useMemo(() => {
    return [
      { name: '查看', id: 'system_button_view' },
      { name: '编辑', id: 'system_button_edit' },
      { name: '删除', id: 'system_button_delete' },
      ...defaultTo([], template.custom_button),
    ];
  }, [template.custom_button]);

  const { orderByOption } = useMemo(() => {
    return getViewSettingOptionWithGroup({
      templateIdList: template?.template_id,
      orgInfo: pick(['groupList', 'role'], orgInfo),
      tempMap,
    });
  }, [template?.template_id, orgInfo, tempMap]);

  const options = useMemo(() => {
    return toPairs(orderByOption).map(([key, o]) => {
      return {
        value: key,
        label: o.name,
      };
    });
  }, [orderByOption]);

  const getPropsConfig = () => {
    if (propsConfig) return propsConfig;

    return [
      {
        title: '序号',
        key: 'index',
        show: true,
        align: 'left',
        width: 50,
      },
      ...prop.map((p: CurrentUser.TemplateProp & { propIndex: number }) => {
        let propConfig = p;
        if (p.type === 'quote') {
          propConfig = getQuoteOriginPropInfo(
            p,
            tempMap,
          ) as unknown as typeof p;
        }

        const align = match(propConfig)
          .with(
            { type: 'number' },
            { type: 'formula', formulaFormat: 0 },
            () => 'right',
          )
          .otherwise(() => 'left');

        return {
          title: p.name,
          key: p.propIndex,
          show: true,
          align,
          width: undefined,
        };
      }),
    ];
  };

  useEffect(() => {
    updateConfig((config: any) => {
      if (propsConfig) {
        // 新老对比
        const propMap = prop.reduce((res: any, cur: any) => {
          res[cur.propIndex] = cur;
          return res;
        }, {});

        const news: any[] = [];
        prop.forEach((p: CurrentUser.TemplateProp & { propIndex: number }) => {
          let exist = false;

          propsConfig.forEach((i: any) => {
            if (i.children)
              i.children.forEach((j: any) => {
                if (j.key === p.propIndex) exist = true;
              });
            else if (i.key === p.propIndex) exist = true;
          });

          if (!exist) {
            let propConfig = p;
            if (p.type === 'quote') {
              propConfig = getQuoteOriginPropInfo(
                p,
                tempMap,
              ) as unknown as typeof p;
            }

            const align = match(propConfig)
              .with(
                { type: 'number' },
                { type: 'formula', formulaFormat: 0 },
                () => 'right',
              )
              .otherwise(() => 'left');

            news.push({
              title: p.name,
              key: p.propIndex,
              show: true,
              align,
              width: undefined,
            });
          }
        });

        // 清除删除的属性
        const newPropsConfig = cloneDeep(propsConfig.concat(news)).filter(
          (i: any) => String(i.key).length > 4 || propMap[i.key],
        );

        newPropsConfig.forEach((p: any) => {
          if (p.key === 'index') return;
          if (p.children) {
            p.children = p.children.filter((i: any) => propMap[i.key]);
            p.children.forEach((i: any) => {
              i.title = propMap[i.key].name;
            });
          } else {
            p.title = propMap[p.key].name;
          }
        });
        config.propsConfig = newPropsConfig;
      }
    });
  }, []);

  const edit = (e: any, p: any) => {
    e.stopPropagation();
    const isGroup = p.children;

    if (isGroup) return setEdittingGroup(p.key);
  };

  const renameGroup = (v: any) => {
    const newPropsConfig = cloneDeep(getPropsConfig());
    newPropsConfig.forEach((f: any) => {
      if (f.key === edittingGroup) f.title = v.trim() || '无分组名';
    });
    updateConfig((config: any) => {
      config.propsConfig = newPropsConfig;
    });
    setEdittingGroup('');
  };
  const confirmDelGroup = (g: any) => {
    Modal.confirm({
      title: '确定删除分组?',
      icon: <ExclamationCircleFilled />,
      content: '',
      onOk: () => {
        let newPropsConfig = cloneDeep(getPropsConfig());
        let childs: any = [];
        newPropsConfig.forEach((f: any) => {
          if (f.key === g.key) {
            childs = cloneDeep(f.children);
          }
        });

        newPropsConfig = newPropsConfig
          .filter((p: any) => p.key !== g.key)
          .concat(childs);
        updateConfig((config: any) => {
          config.propsConfig = newPropsConfig;
        });
      },
      onCancel() {},
    });
  };

  const titleRender = (n: any) => {
    return (
      <div
        className={styles.treeNode}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onClick={() => {
          if (!n.children) changePropShow(n);
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {edittingGroup === n.key ? (
            <Input
              ref={inputRef}
              defaultValue={n.title}
              style={{
                width: 160,
                boxShadow: 'none',
                outline: 'none',
                border: 'none',
                borderRadius: 0,
                backgroundColor: 'transparent',
              }}
              onPressEnter={(e: any) => renameGroup(e.target.value)}
              onBlur={(e: any) => renameGroup(e.target.value)}
              allowClear
            />
          ) : (
            <span
              style={{
                color: '#242D3F',
                fontWeight: n.children ? 700 : 400,
                maxWidth: 160,
              }}
              className={'text-omit'}
            >
              {n.title}
            </span>
          )}
          <i
            onClick={() => {
              setExpandedKeys(
                expandedKeys.includes(n.key)
                  ? expandedKeys.filter((i) => i !== n.key)
                  : expandedKeys.concat([n.key]),
              );
            }}
            style={{
              transform: `rotate(${expandedKeys.includes(n.key) ? 90 : 0}deg)`,
              height: 40,
              justifyContent: 'center',
              alignItems: 'center',
              display: n.children ? 'flex' : 'none',
              fontSize: 16,
              marginLeft: 2,
            }}
            className={`iconzhankaicopy4 iconfont`}
          />
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Popover
            getPopupContainer={(triggerNode) =>
              triggerNode.parentNode as HTMLElement
            }
            trigger={n.children ? '' : 'hover'}
            placement="bottom"
            content={renderContent(n)}
            overlayClassName={
              checkPropInGroup(n.key, getPropsConfig()).f
                ? styles.childPopover
                : styles.popover
            }
          >
            <div onClick={(e) => edit(e, n)} className={styles.edit}>
              <i
                style={{ color: '#316EF5' }}
                className="iconshaixuanqu-bianji iconfont"
              />
            </div>
          </Popover>
          {!n.children && (
            <Checkbox checked={n.show} onChange={() => changePropShow(n)} />
          )}
          {n.children && (
            <div onClick={() => confirmDelGroup(n)} className={styles.del}>
              <i
                style={{ color: '#F0665E' }}
                className="iconshaixuanqu-shanchu iconfont"
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  // 判断属性是不是在组里
  const checkPropInGroup = (key: number, propsConfig: any[]) => {
    let f = false;
    let index = -1;
    let g = null;
    propsConfig.forEach((p) => {
      if (p.children && p.children.find((i: any) => i.key === key)) {
        f = true;
        index = p.children.findIndex((i: any) => i.key === key);
        g = p;
      }
    });
    return { f, g, index };
  };
  const onDrop = (v: any) => {
    const {
      dropToGap,
      node: { key: dropKey, children: dropChildren },
      dragNode: { key: dragKey, children: dragChildren },
    } = v;

    const newPropsConfig = cloneDeep(getPropsConfig());

    if (!dropToGap && dragChildren && dropChildren)
      return console.log('组不能进组');

    // 处理拖动分组的情况
    if (dragChildren) {
      if (checkPropInGroup(dropKey, newPropsConfig).f)
        return console.log('组不能进组');

      const removedIndex = newPropsConfig.findIndex(
        (i: any) => i.key === dragKey,
      );
      const destinationIndex = newPropsConfig.findIndex(
        (i: any) => i.key === dropKey,
      );

      const removed = newPropsConfig.splice(removedIndex, 1)[0];
      // 修改这里:插入到目标节点后面
      newPropsConfig.splice(destinationIndex + 1, 0, removed);

      updateConfig((config: any) => {
        config.propsConfig = newPropsConfig;
      });
      return;
    } else {
      const { f, g, index } = checkPropInGroup(dragKey, newPropsConfig);
      let removed = null;

      if (f) {
        removed = g.children.splice(index, 1)[0];
      } else {
        const removedIndex = newPropsConfig.findIndex(
          (i: any) => i.key === dragKey,
        );
        removed = newPropsConfig.splice(removedIndex, 1)[0];
      }

      if (dropChildren) {
        if (dropToGap) {
          const destinationIndex = newPropsConfig.findIndex(
            (i: any) => i.key === dropKey,
          );
          // 修改这里:插入到目标节点后面
          newPropsConfig.splice(destinationIndex + 1, 0, removed);
        } else {
          // 拖到分组上时仍然插入到第一个位置
          newPropsConfig
            .find((i: any) => i.key === dropKey)
            .children.splice(0, 0, removed);
        }
        updateConfig((config: any) => {
          config.propsConfig = newPropsConfig;
        });
        return;
      } else {
        const {
          f: dropF,
          g: dropG,
          index: dropIndex,
        } = checkPropInGroup(dropKey, newPropsConfig);

        if (!dropF) {
          const destinationIndex = newPropsConfig.findIndex(
            (i: any) => i.key === dropKey,
          );
          // 修改这里:插入到目标节点后面
          newPropsConfig.splice(destinationIndex + 1, 0, removed);
        } else {
          // 修改这里:插入到目标节点后面
          dropG.children.splice(dropIndex + 1, 0, removed);
        }
        updateConfig((config: any) => {
          config.propsConfig = newPropsConfig;
        });
        return;
      }
    }
  };
  const changePropShow = (p: any) => {
    const newPropsConfig = cloneDeep(getPropsConfig());

    newPropsConfig.forEach((i: any) => {
      if (i.key === p.key) i.show = !i.show;
      if (i.children) {
        i.children.forEach((c: any) => {
          if (c.key === p.key) c.show = !c.show;
        });
      }
    });

    updateConfig((config: any) => {
      config.propsConfig = newPropsConfig;
    });
  };

  const changeProp = (p: any, key: string, value: any) => {
    const newPropsConfig = cloneDeep(getPropsConfig());

    newPropsConfig.forEach((i: any) => {
      if (i.key === p.key) i[key] = value;
      if (i.children) {
        i.children.forEach((c: any) => {
          if (c.key === p.key) c[key] = value;
        });
      }
    });

    updateConfig((config: any) => {
      config.propsConfig = newPropsConfig;
    });
  };

  const renderContent = (p: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()} style={{ padding: 16 }}>
        <div style={{ color: '#242D3F', marginBottom: 8 }}>列宽</div>
        <Select
          style={{ width: '100%' }}
          value={p.width === undefined ? '自适应' : '自定义'}
          onChange={(v) =>
            changeProp(p, 'width', v === '自定义' ? 100 : undefined)
          }
          placeholder="请选择"
        >
          {['自适应', '自定义'].map((k: any) => (
            <Select.Option value={k} key={k}>
              {k}
            </Select.Option>
          ))}
        </Select>
        {p.width && (
          <>
            <div style={{ color: '#242D3F', marginBottom: 8, marginTop: 16 }}>
              自定义列宽（px）
            </div>
            <InputNumber
              style={{ width: '100%' }}
              value={p.width}
              min={20}
              max={2000}
              onChange={(v) => changeProp(p, 'width', v)}
            />
          </>
        )}
        <div style={{ color: '#242D3F', marginBottom: 8, marginTop: 16 }}>
          对齐方式
        </div>
        <Select
          style={{ width: '100%' }}
          value={p.align}
          onChange={(v) => changeProp(p, 'align', v)}
          placeholder="请选择"
        >
          {[
            { label: '左对齐', value: 'left' },
            { label: '居中', value: 'center' },
            { label: '右对齐', value: 'right' },
          ].map((k: any) => (
            <Select.Option value={k.value} key={k.value}>
              {k.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };
  const addGroup = () => {
    const obj: any = {
      title: '新分组',
      key: nanoid(),
      children: [],
    };

    const newPropsConfig = cloneDeep(getPropsConfig());
    newPropsConfig.push(obj);

    updateConfig((config: any) => {
      config.propsConfig = newPropsConfig;
    });
  };
  const getInitFontsize = (type: string) => {
    const style = type === 'title' ? titleStyle : contentStyle;

    return style.size || 14;
  };

  const getInitLineHeight = (type: string) => {
    const v = type === 'title' ? titleLineHeight : contentLineHeight;

    return v || 16;
  };
  const changeFontsize = (v: number, type: string) => {
    const key = type === 'title' ? 'titleStyle' : 'contentStyle';
    updateConfig((config: any) => {
      config[key].size = v;
    });
  };

  const changeLineHeight = (v: number, type: string) => {
    const key = type === 'title' ? 'titleLineHeight' : 'contentLineHeight';
    updateConfig((config: any) => {
      config[key] = v;
    });
  };

  const getInitFontcolor = (type: string) => {
    const style = type === 'title' ? titleStyle : contentStyle;

    return style.color;
  };
  const changeFontcolor = (v: number, type: string) => {
    const key = type === 'title' ? 'titleStyle' : 'contentStyle';
    updateConfig((config: any) => {
      config[key].color = v;
    });
  };

  const renderStyleSetting = () => {
    return (
      <div>
        <div
          style={{ color: '#242D3F', fontWeight: 700, padding: '24px 0 0px 0' }}
        >
          文字
        </div>
        {[
          { label: '标题', value: 'title' },
          { label: '内容', value: 'content' },
        ].map((i) => (
          <div key={i.value}>
            <div style={{ color: '#242D3F', padding: '12px 0' }}>{i.label}</div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Select
                style={{ marginRight: 8, width: 152 }}
                value={getInitFontsize(i.value)}
                onChange={(v) => changeFontsize(v, i.value)}
                placeholder="请选择"
              >
                {[14, 15, 16].map((size: any) => (
                  <Select.Option value={size} key={size}>
                    {size}
                  </Select.Option>
                ))}
              </Select>
              <ColorPicker
                itemRender={(color) => (
                  <div className={styles.fontColor}>
                    <i
                      style={{ fontSize: 16 }}
                      className="iconwenzi iconfont"
                    />
                    <div
                      style={{ backgroundColor: color }}
                      className={styles.fontColorSlide}
                    />
                  </div>
                )}
                value={getInitFontcolor(i.value)}
                onChange={(v) => changeFontcolor(v, i.value)}
              />
              <div className={styles.textDecoration}>
                {[
                  { icon: 'icona-rongqi5', value: 'bold' },
                  { icon: 'iconqingxie', value: 'italic' },
                  { icon: 'icona-xiahuaxian1', value: 'underline' },
                ].map((decoration) => {
                  const style = i.value === 'title' ? titleStyle : contentStyle;
                  const key =
                    i.value === 'title' ? 'titleStyle' : 'contentStyle';
                  return (
                    <div
                      className={styles.decoration}
                      onClick={() => {
                        updateConfig((config: any) => {
                          config[key].decoration =
                            config[key].decoration === decoration.value
                              ? undefined
                              : decoration.value;
                        });
                      }}
                      style={{
                        backgroundColor:
                          style.decoration === decoration.value
                            ? '#316EF5'
                            : 'white',
                      }}
                    >
                      <i
                        style={{
                          fontSize: 16,
                          color:
                            style.decoration === decoration.value
                              ? 'white'
                              : 'black',
                        }}
                        className={`${decoration.icon} iconfont`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <Select
              style={{ marginTop: 12, width: 152 }}
              value={getInitLineHeight(i.value)}
              onChange={(v) => changeLineHeight(v, i.value)}
              placeholder="请选择"
            >
              {[
                { label: '小行高', value: 10 },
                { label: '标准行高', value: 16 },
                { label: '大行高', value: 24 },
              ].map((lh: any) => (
                <Select.Option value={lh.value} key={lh.value}>
                  {lh.label}
                </Select.Option>
              ))}
            </Select>
          </div>
        ))}
        <div
          style={{ color: '#242D3F', fontWeight: 700, padding: '24px 0 0px 0' }}
        >
          背景
        </div>
        {[
          { label: '标题背景', value: 'titleBg' },
          { label: '内容背景', value: 'contentBg' },
          { label: '间隔背景', value: 'gapBg' },
        ].map((bg) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: 12,
            }}
            key={bg.value}
          >
            <div style={{ color: '#242D3F' }}>{bg.label}</div>
            <ColorPicker
              style={{ width: 180 }}
              value={bgConfig[bg.value]}
              onChange={(v) => {
                updateConfig((config: any) => {
                  config.bgConfig[bg.value] = v.toString();
                });
              }}
            />
          </div>
        ))}
        <div
          style={{ color: '#242D3F', fontWeight: 700, padding: '24px 0 0px 0' }}
        >
          边框
        </div>
        {[
          { label: '横向边框', value: 'horizontalBorder' },
          { label: '纵向边框', value: 'verticalBorder' },
        ].map((border) => (
          <div key={border.label}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: 12,
                paddingRight: 16,
              }}
            >
              <div style={{ color: '#242D3F' }}>{border.label}</div>
              <i
                onClick={() => {
                  updateConfig((config: any) => {
                    config[border.value] = config[border.value]
                      ? undefined
                      : '#EBEDF0';
                  });
                }}
                style={{ fontSize: 20, cursor: 'pointer', color: '#316EF5' }}
                className={`${widgetInstanceConfig[border.value] ? 'iconshanchu3' : 'icontianjia'} iconfont`}
              />
            </div>
            {widgetInstanceConfig[border.value] && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 12,
                }}
              >
                <div style={{ color: '#242D3F' }}>边框颜色</div>
                <ColorPicker
                  style={{ width: 180 }}
                  value={widgetInstanceConfig[border.value]}
                  onChange={(v) => {
                    updateConfig((config: any) => {
                      config[border.value] = v.toString();
                    });
                  }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const changeActions = (btn: any) => {
    let _actions = cloneDeep(actions);
    if (_actions.includes(btn.id))
      _actions = _actions.filter((i: any) => i !== btn.id);
    else _actions = _actions.concat([btn.id]);

    updateConfig((config: any) => {
      config.actions = _actions;
    });
  };

  const renderFunctionSetting = () => {
    return (
      <div className={styles.container}>
        <div
          style={{
            color: '#242D3F',
            fontWeight: 700,
            padding: '24px 0 12px 0',
          }}
        >
          组件名称
        </div>
        <Input
          onChange={(e) => {
            updateConfig((w: any) => {
              w.name = e.target.value;
            });
          }}
          defaultValue={name}
        />
        <div
          style={{
            color: '#242D3F',
            fontWeight: 700,
            padding: '24px 0 12px 0',
          }}
        >
          数据源
        </div>
        <DataSourceSettingCore
          value={{
            thmId: widgetInstanceConfig.thmId,
            type: widgetInstanceConfig.type,
            matchings: widgetInstanceConfig.matchings,
            parentType: widgetInstanceConfig.parentType,
            conditions: widgetInstanceConfig.conditions,
          }}
          onChange={(v: any) => {
            updateConfig((w: any) => {
              Object.keys(v).forEach((k) => {
                w[k] = v[k];
              });
            });
          }}
        />
        <div
          style={{
            color: '#242D3F',
            fontWeight: 700,
            padding: '24px 0 0px 0',
          }}
        >
          设置属性
        </div>
        <Tree
          treeData={getPropsConfig()}
          titleRender={titleRender}
          onDrop={onDrop}
          draggable
          blockNode
          expandedKeys={expandedKeys}
        />
        <Button
          onClick={addGroup}
          style={{
            marginTop: 12,
            width: 96,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          icon={<PlusOutlined />}
          type="dashed"
          block
        >
          添加分组
        </Button>
        <div
          style={{
            color: '#242D3F',
            fontWeight: 700,
            padding: '24px 0 12px 0',
          }}
        >
          冻结首列至本列
        </div>
        <Select
          style={{ width: '100%' }}
          value={fixedCol}
          onChange={(v) => {
            updateConfig((config: any) => {
              config.fixedCol = v;
            });
          }}
          placeholder="请选择"
        >
          {getPropsConfig()
            .filter((p: any) => p.children || p.show)
            .map((p: any) => (
              <Select.Option value={p.key} key={p.key}>
                {p.title}
              </Select.Option>
            ))}
        </Select>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 24,
            paddingRight: 16,
          }}
        >
          <div style={{ color: '#242D3F', fontWeight: 700 }}>特殊属性</div>
          <i
            onClick={() => {
              updateConfig((config: any) => {
                config.specialProp =
                  config.specialProp !== undefined
                    ? undefined
                    : prop[0].propIndex;
              });
            }}
            style={{ fontSize: 20, cursor: 'pointer', color: '#316EF5' }}
            className={`${specialProp !== undefined ? 'iconshanchu3' : 'icontianjia'} iconfont`}
          />
        </div>
        {specialProp !== undefined && (
          <Select
            onChange={(v) => {
              updateConfig((w: any) => {
                w.specialProp = v;
              });
            }}
            style={{ width: '100%' }}
            value={specialProp}
            placeholder="请选择"
            filterOption={(input, option) =>
              option?.props?.children
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            showSearch
          >
            {(prop || []).map((p: any) => (
              <Select.Option key={p.propIndex} value={p.propIndex}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 24,
            paddingRight: 16,
          }}
        >
          <div style={{ color: '#242D3F', fontWeight: 700 }}>操作</div>
          <i
            onClick={() => {
              updateConfig((config: any) => {
                config.actions = config.actions.length
                  ? []
                  : ['system_button_view'];
              });
            }}
            style={{ fontSize: 20, cursor: 'pointer', color: '#316EF5' }}
            className={`${actions.length ? 'iconshanchu3' : 'icontianjia'} iconfont`}
          />
        </div>
        {actions.length !== 0 &&
          buttons?.map((b) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 12,
                cursor: 'pointer',
                background: '#F8F9FB',
                height: 40,
                borderRadius: 8,
                padding: '0 12px 0 16px',
                alignItems: 'center',
              }}
              key={b.id}
              onClick={() => changeActions(b)}
            >
              <div style={{ flex: 1, color: '#242D3F' }}>{b.name}</div>
              <Checkbox checked={actions.includes(b.id)} />
            </div>
          ))}
        <div
          style={{
            color: '#242D3F',
            fontWeight: 700,
            padding: '24px 0 12px 0',
          }}
        >
          排序
        </div>
        <Select
          style={{ width: '100%' }}
          options={options}
          value={order}
          onChange={(v) =>
            updateConfig((c: any) => {
              c.order = v;
            })
          }
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 12,
          }}
        >
          {[
            { value: 'desc', label: '降序' },
            { value: 'asc', label: '升序' },
          ].map((t) => (
            <div
              style={{
                backgroundColor: '#F8F9FB',
                borderRadius: 8,
                padding: '8px 10px 8px 16px',
                display: 'flex',
                justifyContent: 'space-between',
                width: 'calc(50% - 6px)',
                cursor: 'pointer',
              }}
              onClick={() => {
                updateConfig((c: any) => {
                  c.orderType = t.value;
                });
              }}
              key={t.label}
            >
              <div style={{ color: '#242D3F' }}>{t.label}</div>
              <Radio checked={orderType === t.value} />
            </div>
          ))}
        </div>
        <div
          style={{
            color: '#242D3F',
            fontWeight: 700,
            padding: '24px 0 0 0',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: 16,
          }}
        >
          <div>批量操作</div>
          <Switch
            size="small"
            checked={allowBatchAction}
            onChange={() => {
              updateConfig((c: any) => {
                c.allowBatchAction = !c.allowBatchAction;
              });
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 24,
            paddingRight: 16,
          }}
        >
          <div style={{ color: '#242D3F', fontWeight: 700 }}>工具栏</div>
          <i
            onClick={() => {
              updateConfig((config: any) => {
                config.tools = config.tools.length ? [] : ['add'];
              });
            }}
            style={{ fontSize: 20, cursor: 'pointer', color: '#316EF5' }}
            className={`${tools.length ? 'iconshanchu3' : 'icontianjia'} iconfont`}
          />
        </div>
        {tools.length !== 0 &&
          [
            // { label: '搜索', value: 'search' },
            { label: '新增按钮', value: 'add' },
          ].map((t) => (
            <div
              style={{
                marginTop: 12,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: 40,
                borderRadius: 8,
                backgroundColor: '#F8F9FB',
                padding: '0 12px 0 16px',
                cursor: 'pointer',
              }}
              onClick={() =>
                updateConfig((config: any) => {
                  let _tools = cloneDeep(config.tools);
                  if (_tools.includes(t.value))
                    _tools = tools.filter((x: any) => x !== t.value);
                  else _tools = _tools.concat([t.value]);
                  config.tools = _tools;
                })
              }
              key={t.label}
            >
              <div>{t.label}</div>
              <Checkbox checked={tools.includes(t.value)} />
            </div>
          ))}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: 24,
            paddingRight: 16,
          }}
        >
          <div style={{ color: '#242D3F', fontWeight: 700 }}>分页</div>
          <i
            onClick={() => {
              updateConfig((config: any) => {
                config.pagination = config.pagination
                  ? undefined
                  : { size: 10 };
              });
            }}
            style={{ fontSize: 20, cursor: 'pointer', color: '#316EF5' }}
            className={`${pagination ? 'iconshanchu3' : 'icontianjia'} iconfont`}
          />
        </div>
        {pagination && (
          <div style={{ marginTop: 12, color: '#242D3F' }}>
            <div>每页行数</div>
            <Select
              style={{ marginTop: 12, width: '100%' }}
              value={pagination.size}
              onChange={(v) => {
                updateConfig((config: any) => {
                  config.pagination.size = v;
                });
              }}
              placeholder="请选择"
            >
              {[10, 20, 30].map((size: any) => (
                <Select.Option value={size} key={size}>
                  {size}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <div style={{ height: 120 }}></div>
      </div>
    );
  };

  const renderMain = () => {
    if (type === '样式设置') return renderStyleSetting();
    return renderFunctionSetting();
  };
  return (
    <div style={{ padding: 12 }}>
      <Segmented onChange={setType} block options={['属性设置', '样式设置']} />
      {renderMain()}
    </div>
  );
};
export const ElewattTable = defineSystemWidget<IElewattTableConfig>()({
  id: 'ElewattTable',
  title: '数据表格',
  icon: () => {
    return (
      <WidgetIcon
        bgColor="#598CFD"
        icon={<RegularIcon type="iconshaixuanqu-biaoge" color={'#ffffffcc'} />}
      />
    );
  },
  basic: {
    defaultHeight: 10,
    defaultWidth: 12,
  },
  metadata: {
    // 行高
    titleLineHeight: 16,
    contentLineHeight: 16,
    // 组件名称
    name: '数据表格',
    // 冻结首列至本列
    fixedCol: undefined,
    // 数据源（筛选 匹配 数据表）
    parentType: 'current',
    matchings: [],
    conditions: [
      {
        op: 'intersect',
        key: 'templateId',
        input: [],
      },
    ],
    type: 'temp',
    // 属性分组
    propsConfig: undefined,
    groupNum: 0,
    // 特殊属性
    specialProp: undefined,
    // 操作 自定义按钮
    actions: [],
    // 排序
    order: '_sys_createTime',
    // 是否降序
    orderType: 'desc', // asc 升序
    // 批量操作
    allowBatchAction: false,
    // 工具栏
    tools: [],
    // 分页
    pagination: undefined,
    // 样式
    titleStyle: {
      size: 14,
      color: '#242D3F',
      decoration: 'bold',
    },
    contentStyle: {
      size: 14,
      color: '#242D3F',
      decoration: '',
    },
    bgConfig: {
      titleBg: '#EFF3FE',
      contentBg: '#FFFFFF',
      gapBg: '#FAFAFA',
    },
    horizontalBorder: '#EBEDF0',
    verticalBorder: undefined,
  },
  preview: Preview,
  setting: Setting,
  onCreate: () => getDataSourceSettingValue() as Promise<IElewattTableConfig>,
});
