import { useSelector } from '@umijs/max';
import { useMemo } from 'react';

import { useOrgInfo } from './useOrg';

export const useSysTags = () => {
  const [orgInfo] = useOrgInfo();
  const { spaceTags } = useSelector((state: any) => state.space);

  const sysTags: { tag: string; create_account: string }[] = useMemo(() => {
    if (orgInfo && spaceTags?.[orgInfo.orgId]) {
      return spaceTags[orgInfo.orgId];
    } else {
      return [];
    }
  }, [orgInfo, spaceTags]);

  return sysTags;
};
