import { QuestionCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { assertExists } from '@linkpi/utils';
import { Form, Switch, Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import type { PropsWithChildren } from 'react';
import { match } from 'ts-pattern';
import { container } from 'tsyringe-neo';

import iconImg from '../../assets/tabSection.png';
import {
  type ISectionColorConfig,
  SectionColorSetting,
} from '../../components';
import { useEditor } from '../../hooks';
import { useActiveWidgetInstance } from '../../hooks';
import { EditorModel } from '../../models';
import type { WidgetPreivew, WidgetSetting } from '../../models/WidgetManager';
import { defineSystemWidget } from '../utils';
import type { ITabSectionContentSetting } from './ContentSetting';
import { TabSectionContentSetting } from './ContentSetting';
import type { ITabSectionConditionRuleSetting } from './TabSectionConditionRuleSetting';
import { TabSectionConditionRuleSetting } from './TabSectionConditionRuleSetting';

export type ITabSectionConfig = ISectionColorConfig &
  ITabSectionContentSetting &
  ITabSectionConditionRuleSetting & {
    /**
     * 仅更新选中标签 label 计数
     *
     * label 计数仅在该 tab 下有且仅有一个 table 组件时生效
     */
    loadTotalOnSelect?: boolean;
  };

const Preview: WidgetPreivew<PropsWithChildren> = ({ children }) => {
  return <div>{children}</div>;
};

const Setting: WidgetSetting = () => {
  const editor = useEditor();
  const widgetInstance = useActiveWidgetInstance<ITabSectionConfig>();
  assertExists(widgetInstance);

  const setTabSectionValue = (value: Partial<ITabSectionConfig>) => {
    editor.store.updateWidgetInstanceConfig(widgetInstance.id, {
      ...widgetInstance.config,
      ...value,
    });
  };

  return (
    <Form layout="vertical">
      <TabSectionContentSetting />
      <SectionColorSetting label="选项卡底色" />
      <TabSectionConditionRuleSetting />
      <Form.Item >
      <div className="flex justify-between items-center">
          <span>选中后加载数据
            <Tooltip title="开启时，选中标签后加载该标签下数据表数据"><QuestionCircleOutlined className="ml-1" style={{ color: '#316ef5' }} /></Tooltip>
          </span>
          <Switch
            checked={widgetInstance.config.loadTotalOnSelect}
            onChange={(e) => setTabSectionValue({ loadTotalOnSelect: e })}
          />
        </div>
    </Form.Item>
    </Form>
  );
};

export const TabSectionWidget = defineSystemWidget<ITabSectionConfig>()({
  id: 'TabSection',
  title: '选项卡',
  icon: () => {
    return <img src={iconImg} style={{ width: 188 }} />;
  },
  basic: { defaultHeight: 4, defaultWidth: 12, nested: true, settingWidth: 380 },
  metadata: {} as any,
  preview: Preview,
  setting: Setting,
  onCreate: async () => {
    const editor = container.resolve(EditorModel);
    const c = editor.store.data.pageConfig;
    return {
      bgColor: match(c.styleMode)
        .with('light', () => '#fff')
        .with('dark', () => '#1B235A')
        .exhaustive(),
      tabs: [
        {
          name: '标签名称1',
          children: [],
          id: nanoid(),
        },
        {
          name: '标签名称2',
          children: [],
          id: nanoid(),
        },
      ],
    };
  },
});
