/* eslint-disable react-refresh/only-export-components */
import { create, show, useModal } from '@ebay/nice-modal-react';
import type { CurrentUser } from '@linkpi/core';
import { generateAddOpId, getDefaultTempProp } from '@linkpi/core';
import { GetterPiNode, type PiNode } from '@linkpi/core/web';
import { assertExists, buildRecord } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import { message } from 'antd';
import { intersection } from 'ramda';
import { type FC, useEffect } from 'react';

import { exportNodeToPDF } from '@/components';
import {
  scanAdd,
  showUpdateNodesPropModal,
  triggerCopyNodeButton,
  triggerCustomButtonCustomAction,
  triggerOpenNodeButton,
} from '@/components/CustomButton';
import { triggerOpenView } from '@/components/CustomButton/OpenView';
import { addDraftNode, DraftNodeModal } from '@/components/DraftNodeModal';
import { setNewRepeatTask } from '@/components/NewRepeatTaskModal';
import {
  useComponentId,
  useCurrentOrgId,
  useCurrentUser,
  useOrgInfo,
  useOrgTemplatesSettingInfoMap,
  useOrgUserMap,
} from '@/hook';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import { openCustomButtonEditPropsModal } from '@/pages/home/components/CustomButtonEditPropsModal';
import { showPropEditModal } from '@/pages/home/components/PropEditModal';
import { runAutomation } from '@/services/automation';
import { customButtonConfirm } from '@/utils/customButton';
import {
  getQuoteOrMatchingInfo,
  isCustomButtonForbiddenClick,
} from '@/utils/utils';

import { ViewContentNiceModal } from '../View/ViewContent';

type TriggerCustomButtonOptions = {
  node: PiNode;
  loading?: boolean;
  config: CurrentUser.CustomButton;
  force?: boolean;
};

/**
 * 利用 nice-modal-react 的 useModal 实现一个假的 TriggerBtnsModal
 *
 * 可以触发不同
 */
const TriggerCustomButton: FC<TriggerCustomButtonOptions> = ({
  node,
  ...restProps
}) => {
  const modal = useModal();
  const componentId = useComponentId();

  const tempMap = useOrgTemplatesSettingInfoMap();
  const userMap = useOrgUserMap();
  const currentUser = useCurrentUser();
  const departmentNodes = useOrgDepartmentNodes();
  const orgId = useCurrentOrgId();
  const [orgInfo] = useOrgInfo();

  const draftNodeModalId = 'DraftNodeModal' + componentId;
  const viewNodeContentTriggerId = 'ViewContentNiceModal' + componentId;

  // 新建节点
  const handleDraftNode = useMemoizedFn(
    async (
      currentTemplate: CurrentUser.TemplateInfo,
      viewInitData: Parameters<typeof getDefaultTempProp>['7'],
      parentId: string,
    ) => {
      const newProps = getDefaultTempProp(
        {},
        currentTemplate,
        currentUser,
        null,
        userMap,
        departmentNodes,
        viewInitData,
      );
      const id = generateAddOpId();

      await addDraftNode(
        {
          mode: 'add',
          orgId: orgId,
          draftsNodeData: {
            org_id: orgId,
            parentId,
            siblingId: null,
            draft: true,
            node: {
              node_id: id,
              prop: newProps as any,
              title: '',
            },
          },
          initParentId: parentId,
        },
        draftNodeModalId,
      );
    },
  );

  const run = useMemoizedFn(
    async ({
      config,
      force,
      loading,
    }: Omit<TriggerCustomButtonOptions, 'node'>) => {
      if (loading) return;

      if (!force) {
        const forbiddenClick = isCustomButtonForbiddenClick(config, node);

        if (forbiddenClick) return;

        const groupList = (() => {
          const list = orgInfo?.groupList || [];
          if (orgInfo?.role === 3 || orgInfo?.role === 1) {
            list.push('-2');
          }
          return list;
        })();

        // 按钮分组判断
        if (config.groups && !config.groups?.includes('-1')) {
          if (
            config.groups?.length &&
            intersection(groupList, config.groups).length === 0
          )
            return;
        }
      }

      const {
        opType,
        multipleProp,
        props,
        node: { initialStatus, parent, tempId } = {},
        automationTriggerSuccessMessage,
        automationTriggerFailMessage,
        repeatTaskPayload,
      } = config;

      try {
        await customButtonConfirm(config);
      } catch {
        return;
      }

      switch (opType) {
        case 'setRepeatTask': {
          await setNewRepeatTask({
            repeatTaskPayload,
            node: new GetterPiNode(node),
          });
          break;
        }
        case 'automationTrigger': {
          const res = await runAutomation({
            orgId: orgId,
            autoId: config.automationId!,
            nodeId: node.id,
          });

          if (res.status === 'ok')
            message.success(
              automationTriggerSuccessMessage || '执行自动化规则成功',
            );
          else
            message.error(automationTriggerFailMessage || '执行自动化规则失败');
          break;
        }
        case 'exportNodes': {
          await exportNodeToPDF({ node });
          break;
        }
        case 'editProp': {
          if (!multipleProp) {
            const initTempId = tempId || node.tempInfo.id;
            const currentTemplateProp = tempMap[initTempId]?.prop;
            const propIndex = props[0].split('-')[1];
            const templateIndex = parseInt(propIndex, 10);
            const prop = currentTemplateProp[templateIndex];
            if (
              (prop.type === 'enum' || prop.type === 'tag') &&
              [1, 2].includes(getQuoteOrMatchingInfo(prop) as any)
            ) {
              await showPropEditModal({
                node: new GetterPiNode(node),
                editProps: props,
              });
            } else {
              await openCustomButtonEditPropsModal({
                node: new GetterPiNode(node),
                editProps: props,
                title: config.text,
              });
            }
          } else {
            await openCustomButtonEditPropsModal({
              node: new GetterPiNode(node),
              editProps: props,
              title: config.text,
            });
          }
          break;
        }
        case 'updateProps': {
          await showUpdateNodesPropModal({
            config,
            orgId: orgId,
            node,
          });
          break;
        }
        case 'copyTemplate': {
          await triggerCopyNodeButton({
            config,
            orgId: orgId,
            node,
          });
          break;
        }
        case 'addNode': {
          const initTempId = tempId || node.tempInfo.id;
          const parentId = parent || node.id; // 父节点

          // 扫码新增
          if (config.addMode === 2) {
            await scanAdd({
              currentNodeTempId: node.metadata?.e?._sys_temp?.[0],
              initParentId: parentId,
              initTempId: initTempId,
              buttonId: config.id,
            });
            break;
          }

          const addInitData = {
            initStatus: initialStatus || 0,
          };

          const template = tempMap[initTempId];

          // 新建节点
          template && (await handleDraftNode(template, addInitData, parentId));
          break;
        }
        case 'openTemplate': {
          await triggerOpenNodeButton({
            config,
            orgId: orgId,
            node,
            openId: viewNodeContentTriggerId,
          });
          break;
        }
        case 'openView': {
          await triggerOpenView({
            config: config.openViewConfig!,
            orgId: orgId,
          });
          break;
        }
        case 'openURL': {
          let url = '';
          if (
            config.openURLConfig?.type === 'currentNode' &&
            typeof config.openURLConfig?.propIndex === 'number'
          ) {
            url = node.tempInfo.prop[config.openURLConfig.propIndex];
          } else if (config.openURLConfig?.type === 'customEditor') {
            url = config.openURLConfig?.url || '';
          }
          if (url) {
            // 检查 URL 是否包含协议，如果没有则添加 https://
            if (!url.match(/^https?:\/\//i)) {
              url = 'https://' + url;
            }
            window.open(url, '_blank');
          } else {
            message.error('链接为空');
          }
          break;
        }
        case 'customMadeAction': {
          await triggerCustomButtonCustomAction({
            config,
            nodeId: node.id,
          });
          break;
        }
        case 'triggerBtns': {
          const { triggerBtnsConfig } = config;

          assertExists(triggerBtnsConfig, 'triggerBtnsConfig 不存在');

          const { btnIds, allowCondition } = triggerBtnsConfig;
          const templateId = node.tempInfo.id;
          const templateInfo = tempMap[templateId];

          assertExists(templateInfo, 'templateInfo 不存在');

          const buttonConfigMap = buildRecord(
            templateInfo.custom_button!,
            'id',
          );

          for (let index = 0; index < btnIds.length; index++) {
            const btnId = btnIds[index];
            const btnConfig = buttonConfigMap[btnId];

            assertExists(btnConfig, 'btnConfig 不存在');

            await run({
              config: btnConfig,
              force: !allowCondition,
            });

            console.info(btnConfig.name + ' 执行完毕');
          }
          break;
        }
        default: {
          message.error('未知按钮');
        }
      }
    },
  );

  useEffect(() => {
    if (modal.visible) {
      try {
        run(restProps);
        modal.resolve();
      } catch (error) {
        modal.reject(error);
      } finally {
        modal.hide();
        modal.resolveHide();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, modal.visible, run]);

  return [
    /**
     * NOTE NiceModal 嵌套死循环
     */
    // @ts-ignore
    <DraftNodeModal key="DraftNodeModal" id={draftNodeModalId} />,
    // @ts-ignore
    <ViewContentNiceModal
      key="ViewContentNiceModal"
      id={viewNodeContentTriggerId}
    />,
  ];
};

export const TriggerCustomButtonNiceModal = create(TriggerCustomButton);

export const triggerCustomButton = (
  props: TriggerCustomButtonOptions,
  id?: string,
) => {
  if (id) {
    return show(id, props);
  }

  return show(TriggerCustomButtonNiceModal, props);
};
