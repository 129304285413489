import { type CurrentUser, PROP_TYPE, type ViewList } from '@linkpi/core';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import clsx from 'clsx';
import { produce } from 'immer';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { useConditionOptions } from '@/components/ConditionFilter/hook/useConditionOptions';
import { RegularIcon } from '@/components/IconFont';
import type { TemplateMapUsage } from '@/hook';
import { useUnsafeTemplateMap } from '@/hook';

const filterOption: SelectProps['filterOption'] = (inputValue, option) => {
  let filterText = '';
  // 忽略分组
  if (option?.label && !option?.options) {
    filterText = option?.filterText ?? (option?.label as string);
  }
  if (!filterText) return false;

  if (option?.options) return false;

  return (
    (filterText as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
  );
};

type DataPropSelectProps = {
  className?: string;
  disabled?: boolean;
  templateId: string | string[];
  allowKey?: ViewList.ViewconditionV2Item['key'][];
  allowPropTypes?: CurrentUser.propType[];
  /**
   * 主题类型使用方式
   */
  templateUsage?: TemplateMapUsage;
} & SelectProps;

export const DataPropSelect: FC<DataPropSelectProps> = memo(
  ({
    templateId,
    allowKey,
    allowPropTypes,
    className,
    templateUsage = 'fullAccess',
    ...rest
  }) => {
    const tempMap = useUnsafeTemplateMap(templateUsage);

    const curTemplate =
      typeof templateId === 'string' ? tempMap[templateId] : undefined;

    const curTemplateList = useMemo(() => {
      return Array.isArray(templateId)
        ? templateId.map((id) => tempMap[id])
        : undefined;
    }, [templateId, tempMap]);

    const { filterConditionOptions, filterConditionConfigMapV2 } =
      useConditionOptions({
        curTemplate,
        curTemplateList,
        tempMap,
        allowKey,
        allowPropTypes,
      });

    const options = useMemo(() => {
      const list = produce(filterConditionOptions, (draft) => {
        draft.forEach((item) => {
          if (item.label === '系统属性') {
            item.options.push({ label: '范围', value: 'ancestor' });
            return;
          }

          if (item.label !== '属性') return;

          item.options.forEach((propItem) => {
            // @ts-ignore
            propItem.filterText = propItem.label;
            const propType = filterConditionConfigMapV2[propItem.value].type!;
            // @ts-ignore
            propItem.label = (
              <span className="inline-flex items-baseline gap-1 leading-none">
                {/* @ts-ignore */}
                <RegularIcon
                  type={PROP_TYPE[propType].icon}
                  size={16}
                  className={
                    propType === 'number'
                      ? 'translate-y-[3px]'
                      : 'translate-y-[2px]'
                  }
                />
                {propItem.label}
              </span>
            );
          });
        });
      });
      return list;
    }, [filterConditionConfigMapV2, filterConditionOptions]);

    return (
      <Select
        className={clsx(className)}
        options={options}
        showSearch
        filterOption={filterOption}
        dropdownStyle={{ minWidth: 280 }}
        {...rest}
      />
    );
  },
);
