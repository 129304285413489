import type { ProFormSelectProps } from '@ant-design/pro-form';
import { ProForm, ProFormSelect } from '@ant-design/pro-form';
import type { ApiResponse } from '@linkpi/core';
import { getEnumOptions } from '@linkpi/core';
import { useMemoizedFn, useRequest } from 'ahooks';
import { type SelectProps } from 'antd';
import { isNil } from 'ramda';
import { memo } from 'react';

import { useOrgTemplatesSettingInfo } from '@/hook/useOrgSetting';
import request from '@/utils/request';

import { selectSearchFn } from '../utils';

type TemplateEnumPropSelectProps = {
  templateName?: ProFormSelectProps['name'];
  templateId?: string;
  propIndex: number;
  placeholder?: SelectProps['placeholder'];
  orgId: string;
  showAux?: boolean;
};
function _TemplateEnumPropSelect<T>(
  props: ProFormSelectProps<T> & TemplateEnumPropSelectProps,
) {
  const { propIndex, orgId, showAux = true } = props;
  const formTempId = ProForm.useWatch(props.templateName);
  const tempId: string = props.templateId || formTempId;

  const [templates] = useOrgTemplatesSettingInfo();

  const fetchOptions = useMemoizedFn(async () => {
    const tempMap = templates.reduce<
      Record<string, ApiResponse.CurrentUser.TemplateInfo>
    >((r, t) => ({ ...r, [t.template_id]: t }), {});
    const temp = tempMap[tempId];

    if (isNil(temp)) return [];
    if (!Array.isArray(temp.prop)) return [];

    const tempProp = temp.prop[propIndex];

    let options: {
      label: string | JSX.Element;
      value: string;
      filterLabel: string;
    }[] =
      tempProp.extend?.map((v: string) => ({
        label: v,
        filterLabel: v,
        value: v,
      })) || [];

    if (tempProp.conditionMatching) {
      const result = await getEnumOptions({
        orgId,
        request,
        template: temp,
        propIndexs: [propIndex],
      });

      const getLabel = (o: {
        label: string;
        value: string;
        aux?:
          | {
              label: string;
              position: 0 | 1;
            }
          | null
          | undefined;
      }) => {
        return o.aux && showAux
          ? (o.aux.position
              ? [o.label, o.aux.label]
              : [o.aux.label, o.label]
            ).join(' - ')
          : o.label;
      };

      const quoteOptions = result[propIndex].map((o) => ({
        value: o.value,
        label: getLabel(o),
        filterLabel: getLabel(o),
      }));
      options = [...options, ...quoteOptions];
    }

    if (tempProp.extendColorMode && tempProp.extendColor) {
      options = options.map((o, index) => ({
        ...o,
        label: tempProp.extendColor![index] ? (
          <span
            className="px-2 text-white rounded-full"
            style={{ backgroundColor: tempProp.extendColor![index] }}
          >
            {o.label}
          </span>
        ) : (
          o.label
        ),
      }));
    }

    return options.reduce((r, i) => ({ ...r, [i.value]: i.label }), {});
  });

  const { data, loading } = useRequest(fetchOptions, {
    cacheKey: [orgId, tempId, propIndex].join('@'),
    refreshDeps: [orgId, tempId, propIndex],
    loadingDelay: 100,
  });

  return (
    <ProFormSelect
      {...props}
      valueEnum={data}
      fieldProps={{
        ...props.fieldProps,
        placeholder: props.placeholder || '请选择属性值',
        filterOption: selectSearchFn,
        showSearch: true,
        loading,
      }}
    />
  );
}

export const TemplateEnumPropSelect = memo(_TemplateEnumPropSelect);
