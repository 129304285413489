import { DownOutlined } from '@ant-design/icons';
import { useControllableValue } from 'ahooks';
import { Dropdown, Input } from 'antd';
import React from 'react';

import { cn } from '@/utils/utils';

import Styles from './index.less';

const DefaultOptions = [12, 14, 16, 18, 24, 32, 64];

interface SelectInputProps {
  defaultValue?: number;
  value?: number;
  onChange?: (value: number) => void;
  options?: number[];
  className?: string;
  style?: React.CSSProperties;
}

// 目前该组件仅支持 number 类型
export const SelectInput = (props: SelectInputProps) => {
  const {
    value,
    onChange,
    defaultValue,
    options = DefaultOptions,
    className,
    style,
  } = props;
  const [_value, _onChange] = useControllableValue<number>(
    { value, onChange },
    {
      defaultValue,
    },
  );

  const items: { key: React.Key; label: React.ReactNode }[] = options.map(
    (o) => ({
      key: o,
      label: (
        <span
          className={cn(
            'cursor-pointer w-[100px] h-10 p-2 rounded-[8px] text-[#242D3F] flex items-center hover:bg-[#F7F7F9]',
            {
              'text-[#316EF5] bg-[#F1F7FE] hover:bg-[#F1F7FE]': value === o,
            },
          )}
          onClick={() => _onChange(o)}
        >
          {o}
        </span>
      ),
    }),
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target;
    const reg = /^\d*(\.\d*)?$/;
    if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
      _onChange(+inputValue);
    }
  };

  return (
    <div
      className={cn(
        'w-[100px] h-10 border rounded-[8px] border-solid border-[#EBEDF0] flex items-center',
        className,
      )}
      style={style}
    >
      <Input bordered={false} value={value} onChange={handleChange} />
      <Dropdown
        menu={{ items }}
        placement="bottomLeft"
        dropdownRender={(node) => {
          return (
            <div
              className={cn(
                'bg-white p-2 rounded-[8px]',
                Styles['dropdown-wrapper'],
              )}
            >
              {items.map((item) => (
                <div key={item.key}>{item.label}</div>
              ))}
            </div>
          );
        }}
      >
        <span className="w-6 h-6 flex items-center justify-center shrink-0 cursor-pointer">
          <DownOutlined style={{ fontSize: 16, color: '#B7BAC0' }} />
        </span>
      </Dropdown>
    </div>
  );
};
