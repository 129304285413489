import { type FC, type PropsWithChildren } from 'react';

import noModelData from '@/assets/img/noModelData.png';
import { cn } from '@/utils/utils';

import { useEditorWidgetTree } from '../hooks';
import type { LayoutPositionType } from '../models/Store';

type EmptyCheckerProp = {
  type: LayoutPositionType;
  className?: string;
};

export const EmptyChecker: FC<PropsWithChildren<EmptyCheckerProp>> = ({
  type,
  children,
  className,
}) => {
  const list = useEditorWidgetTree(type);

  return (
    <div
      className={cn('relative', className)}
      style={{
        height: list.length === 0 ? '200px' : undefined,
      }}
    >
      {list.length === 0 && (
        <div className="flex flex-col items-center w-full absolute top-[50%] translate-y-[-50%]">
          <img style={{ height: 160, width: 160 }} src={noModelData} alt="" />
          <span style={{ color: '#C9D0D9' }}>请从左侧选择板块或组件拖入</span>
        </div>
      )}
      {children}
    </div>
  );
};
