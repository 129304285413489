import ProForm from '@ant-design/pro-form';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { useMemoizedFn, useMount } from 'ahooks';
import { Modal } from 'antd';
import { useSetAtom } from 'jotai';
import { nanoid } from 'nanoid';
import { useEffect, useMemo } from 'react';
import { match } from 'ts-pattern';

import { useSetActiveWidgetInstanceConfig } from '@/components/PageModelEditor/src/hooks';
import { cn } from '@/utils/utils';

import { GLOBAL_CONDITION_LATEST_VERSION } from '../../constant';
import { ConditionMapAtom, SelectedFilterIdAtom } from '../../store';
import type {
  IGlobalConditionFilterCondition,
  IGlobalConditionFilterConfig,
} from '../../types';
import FilterItemSetting from '../FilterItemSetting';
import FilterList from '../FilterList';
import FilterToolbar from '../FilterToolbar';

import Styles from './index.less';

const getDefaultValue = (): IGlobalConditionFilterConfig => ({
  name: '未命名筛选',
  // templateId: '',
  version: GLOBAL_CONDITION_LATEST_VERSION,
  enableSubmitButton: true,
  enableFold: false,
  conditionList: [
    {
      name: '未命名',
      showName: true,
      id: nanoid(),
      filedType: 'text' as const,
    },
  ],
  arrangement: 'three',
});

type IFilterSettingModalProps =
  | {
      type: 'create';
    }
  | {
      type: 'edit';
      value: IGlobalConditionFilterConfig;
    };

const FilterSettingModal = create((props: IFilterSettingModalProps) => {
  const modal = useModal();
  const modalV5 = antdModalV5(modal);

  const setActiveWidgetInstanceConfig =
    useSetActiveWidgetInstanceConfig<IGlobalConditionFilterConfig>();

  const [form] = ProForm.useForm<IGlobalConditionFilterConfig>();
  const list = ProForm.useWatch<IGlobalConditionFilterConfig['conditionList']>(
    'conditionList',
    form,
  );

  const setConditionMap = useSetAtom(ConditionMapAtom);

  useEffect(() => {
    if (list) {
      setConditionMap(
        list.reduce<
          Record<string, IGlobalConditionFilterCondition & { index: number }>
        >((result, item, index) => {
          result[item.id] = {
            ...item,
            index,
          };
          return result;
        }, {}),
      );
    }
  }, [list, setConditionMap]);

  // console.log('🍼', config);
  // const finalConfig = useMemo(() => {
  //   /*
  //    * 版本迁移
  //    */
  //   if ((config as any).version === 1) return migrateConfig(config as any);

  //   return config;
  // }, [config]);

  const defaultValue = useMemo(
    () =>
      match(props)
        .with({ type: 'create' }, () => getDefaultValue())
        .with({ type: 'edit' }, (_props) => {
          return _props.value;
        })
        .otherwise(() => getDefaultValue()),
    [props.type],
  );

  useMount(() => {
    setSelectedFilterId(defaultValue.conditionList[0].id);
  });

  const setSelectedFilterId = useSetAtom(SelectedFilterIdAtom);

  const onOk = useMemoizedFn(async () => {
    const values = await form.validateFields();
    match(props)
      .with({ type: 'create' }, () => {
        modal.resolve({
          ...defaultValue,
          ...values,
        });
        modal.hide();
      })
      .with({ type: 'edit' }, (_props) => {
        const _values = {
          ..._props.value,
          ...values,
        };
        setActiveWidgetInstanceConfig(_values as any);
        modal.resolve(_values);
        modal.hide();
      })
      .exhaustive();
  });

  const onCancel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <Modal
      className={cn(Styles['fs-modal'])}
      {...modalV5}
      onOk={onOk}
      onCancel={onCancel}
      centered
      maskClosable={false}
      destroyOnClose
      title="筛选组件"
      width={1200}
      bodyStyle={{
        padding: 0,
      }}
    >
      <ProForm
        form={form}
        className="flex h-[746px]"
        initialValues={defaultValue}
        layout="vertical"
        submitter={false}
      >
        <div className="flex-1 flex flex-col">
          <FilterToolbar />
          <div className="flex-1 bg-[#F5F7FA] p-6">
            <FilterList className="w-full h-fit px-3 py-2 bg-white rounded-[8px]" defaultValue={defaultValue} />
          </div>
        </div>
        <FilterItemSetting />
      </ProForm>
    </Modal>
  );
});

export const showCreateFilterSettingModal = (): Promise<any> =>
  show(FilterSettingModal, { type: 'create' });

export const showEditFilterSettingModal = (
  value: Extract<IFilterSettingModalProps, { type: 'edit' }>['value'],
): Promise<any> => show(FilterSettingModal, { type: 'edit', value });
