import type { NodeManager } from '@linkpi/core/web';
import { getVirtualNodeOriginId } from '@linkpi/core/web';
import { useEffect, useState } from 'react';

export const useCurrentNodeId = (nodeManager: NodeManager, nodeId: string) => {
  const [originId, setOriginId] = useState('');

  useEffect(() => {
    function getId() {
      const [_originId] = getVirtualNodeOriginId(nodeManager, nodeId);
      setOriginId(_originId);
    }
    getId();
  }, [nodeManager, nodeId]);

  return [originId];
};

export const useViewContentWidth = (depend: any[], childId?: string) => {
  const [contentWidth, setContentWidth] = useState(0);

  const contentWidthHandler = () => {
    const width = window.innerWidth;
    // TODO 大模型 @1-week @yuan7
    // FIXME: TabContent

    const viewEle = childId
      ? Array.from(document.querySelectorAll('#view')).find((v) =>
          v.contains(document.querySelector(`#${childId}`)),
        )
      : document.querySelector('#view');

    const view =
      viewEle ||
      //
      document.querySelector('#WorkbenchContent') ||
      /**
       * FIXME: 废弃
       */
      document.querySelector('#TabContent');
    if (!view) {
      setContentWidth(width - (depend[0] ? 0 : depend[1]) - 66);
      return;
    }
    let actualLeft = (view as HTMLElement).offsetLeft;
    let current = (view as HTMLElement).offsetParent;
    while (current !== null) {
      actualLeft += (current as HTMLElement).offsetLeft;
      current = (current as HTMLElement).offsetParent;
    }
    setContentWidth(width - actualLeft - 66);
  };
  useEffect(() => {
    contentWidthHandler();
    window.addEventListener('resize', contentWidthHandler);
    return () => window.removeEventListener('resize', contentWidthHandler);
  }, []);
  useEffect(() => {
    contentWidthHandler();
  }, [depend]);

  return [contentWidth];
};

/**
 * 修改权限
 * @param {*} modify_permission 权限值 1. 仅自己 2. 自己+管理员 3. 所有人
 * @param {*} role
 * @param {*} isCreater 是否是视图创建者
 * @returns boolen
 */
export const getModifyPermission = (
  modify_permission: number,
  role: number,
  isCreater: boolean,
) => {
  // 空间持有者默认具有管理员权限
  let _role = role;
  if (_role == 1) _role = 3;

  if (_role === 3) return true;
  if (isCreater) return true;

  return false;

  // let f = false;

  // // 仅自己
  // if (modify_permission === 1 && isCreater) f = true;
  // // 兼容原来视图
  // if (!modify_permission) f = true;
  // // 所有人
  // if (modify_permission === 3) f = true;
  // // 自己+管理员
  // if (modify_permission === 2 && _role === 3) f = true;
  // if (modify_permission === 2 && isCreater) f = true;

  // return f;
};

export { useQuoteOptions } from '@/hook/useQuoteOptions';
