import { useDispatch } from '@umijs/max';
import { useEventEmitter } from 'ahooks';
import type { EventEmitter } from 'ahooks/lib/useEventEmitter';
import type { Dispatch } from 'react';
import { createContext, useContext, useState } from 'react';

type HomeContextType = {
  visibleChat: boolean;
  setVisibleChat: Dispatch<boolean>;
  visibleKeyMap: boolean;
  setVisibleKeyMap: Dispatch<boolean>;
  exportCurViewData: EventEmitter<'excel' | 'pdf'>;
};
// @ts-ignore
// eslint-disable-next-line react-refresh/only-export-components
export const HomeContext = createContext<HomeContextType>(null);

const HomeContextProvider = ({ children }: any) => {
  const dispatch = useDispatch();
  const [visibleChat, setVisibleChat] = useState(false);
  const [visibleKeyMap, setVisibleKeyMap] = useState(false);
  const exportCurViewData = useEventEmitter<'excel' | 'pdf'>();

  const handleVisibleChat = (status: boolean) => {
    if (status) {
      setVisibleKeyMap(false);
      setVisibleChat(true);
    } else {
      setVisibleChat(false);
    }
  };
  const handleVisibleKeyMap = (status: boolean) => {
    if (status) {
      setVisibleChat(false);
      setVisibleKeyMap(true);
    } else {
      setVisibleKeyMap(false);
      dispatch({ type: 'intro/finishStep', name: 'more' });
    }
  };

  return (
    <HomeContext.Provider
      value={{
        visibleChat,
        visibleKeyMap,
        setVisibleChat: handleVisibleChat,
        setVisibleKeyMap: handleVisibleKeyMap,
        exportCurViewData,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};
export default HomeContextProvider;
export const useHomeContext = () => useContext(HomeContext);
