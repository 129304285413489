import { useMemoizedFn } from 'ahooks';
import { useAtom, useAtomValue } from 'jotai';

import type { OperatorConfig, TypeConfigKey } from '../types';
import { registerTypeAtom, typeConfigsAtom } from './atoms';

export function useTypeManager() {
  const typeConfigs = useAtomValue(typeConfigsAtom);
  const [, registerType] = useAtom(registerTypeAtom);

  const getOperatorsForType = useMemoizedFn((type: TypeConfigKey) => {
    const operators = typeConfigs[type];
    if (!operators)
      return [{ label: '包含', value: 'intersect' }] as OperatorConfig[];
    return operators;
  });

  const validateValueForType = useMemoizedFn(
    (type: TypeConfigKey, value: any): boolean => {
      const config = getOperatorsForType(type);
      if (!config) return false;

      // 基础类型验证
      switch (type) {
        case 'text':
          return typeof value === 'string';
        case 'number':
          return typeof value === 'number' && !isNaN(value);
        case 'date':
        case 'datetime':
          return value instanceof Date && !isNaN(value.getTime());
        default:
          return true;
      }
    },
  );

  return {
    registerType,
    getOperatorsForType,
    validateValueForType,
    typeConfigs,
  };
}
