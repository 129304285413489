import { useControllableValue, useMemoizedFn } from 'ahooks';
import { type FC, memo } from 'react';

import { cn } from '@/utils/utils';

import { RegularIcon } from '../IconFont';

export const PiSwitch: FC<{
  value?: boolean;
  onChange?: (checked: boolean) => void;
  size?: number;
  className?: string;
}> = memo(({ size, className, ...props }) => {
  const [value, onChange] = useControllableValue(props);

  const toggle = useMemoizedFn(() => {
    onChange?.(!value);
  });

  return (
    <span onClick={toggle} className={cn('cursor-pointer', className)}>
      <RegularIcon
        type={value ? 'iconshanchu3' : 'icontianjia'}
        size={size}
        color="#316EF5"
      />
    </span>
  );
});
