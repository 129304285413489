import { atom } from 'jotai';
import { match } from 'ts-pattern';

import { FoldButtonId, SubmitButtonId } from './constant';
import type { IGlobalConditionFilterCondition } from './types';

export const SelectedFilterIdAtom = atom<string | null>(null);

export const ConditionMapAtom = atom<
  Record<string, IGlobalConditionFilterCondition & { index: number }>
>({});

export const SelectedFilterPrefixNamesAtom = atom((get) => {
  const selectedFilterId = get(SelectedFilterIdAtom);
  const conditionMap = get(ConditionMapAtom);
  if (!selectedFilterId) return [];

  return match(selectedFilterId)
    .with(SubmitButtonId, () => ['submitButtonConfig'])
    .with(FoldButtonId, () => ['foldConfig'])
    .otherwise(() =>
      conditionMap[selectedFilterId]
        ? ['conditionList', conditionMap[selectedFilterId].index]
        : [],
    );
});

export const SelectedFilterSettingTypeAtom = atom((get) => {
  const selectedFilterId = get(SelectedFilterIdAtom);
  const conditionMap = get(ConditionMapAtom);
  if (!selectedFilterId) return null;

  return match(selectedFilterId)
    .with(SubmitButtonId, FoldButtonId, (_id) => _id)
    .otherwise(() =>
      conditionMap[selectedFilterId]
        ? conditionMap[selectedFilterId].filedType
        : null,
    );
});

// TODO async atom
export const OptionListAtom = atom<any[]>([]);
