import { useWidgetSharedState } from '@mylinkpi/widget-react';
import type { Draft } from 'immer';
import { produce } from 'immer';
import { useMemo } from 'react';

import type { GlobalConditionFilterValue } from '@/components/PageModelEditor';

const produceGlobalConditionFilterValue = (
  value: Draft<GlobalConditionFilterValue>,
) => {
  Object.keys(value).forEach((key) => {
    (value[key] as any).value = (value[key] as any).value;
  });
};

const EMPTY_GLOBAL_CONDITION_FILTER_VALUE = {};

export const useAllGlobalConditionFilterValue = () => {
  const [sharedState] = useWidgetSharedState<{
    globalConditions: Record<string, GlobalConditionFilterValue>;
  }>();

  const allGlobalConditionFilterValue = useMemo(() => {
    if (!sharedState.globalConditions)
      return EMPTY_GLOBAL_CONDITION_FILTER_VALUE as Record<
        string,
        Record<string, any>
      >;

    return produce(sharedState.globalConditions, (draft) => {
      Object.keys(draft).forEach((key) => {
        produceGlobalConditionFilterValue(draft[key]);
      });
    }) as Record<string, Record<string, any>>;
  }, [sharedState.globalConditions]);

  return allGlobalConditionFilterValue;
};

export const useGlobalConditionFilterValue = (widgetId: string) => {
  const [sharedState] = useWidgetSharedState<{
    globalConditions: Record<string, GlobalConditionFilterValue>;
  }>();

  const allGlobalConditionFilterValue = useMemo(() => {
    if (!sharedState.globalConditions)
      return EMPTY_GLOBAL_CONDITION_FILTER_VALUE as Record<string, any>;
    if (!sharedState.globalConditions[widgetId])
      return EMPTY_GLOBAL_CONDITION_FILTER_VALUE as Record<string, any>;

    return produce(sharedState.globalConditions[widgetId], (draft) => {
      produceGlobalConditionFilterValue(draft);
    }) as Record<string, any>;
  }, [sharedState.globalConditions, widgetId]);

  return allGlobalConditionFilterValue;
};
