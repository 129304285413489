import { useControllableValue } from 'ahooks';
import { useMemo } from 'react';

import { cn } from '@/utils/utils';

import { DefaultFontValue } from '../../GlobalFilterWidget/constant';
import type { IYearMonthDayConfig } from '../../GlobalFilterWidget/types';

import Styles from './RadioButtonGroup.module.less';

export interface RadioOption {
  label: React.ReactNode;
  value: string | number;
  disabled?: boolean;
}

interface RadioGroupProps {
  value?: string | number;
  options: RadioOption[];
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  className?: string;
  size?: 'small' | 'middle' | 'large';
  selectedStyle?: IYearMonthDayConfig['selected'];
  unselectedStyle?: IYearMonthDayConfig['unselected'];
}

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const {
    options,
    disabled: groupDisabled,
    className,
    size = 'middle',
    selectedStyle = {
      ...DefaultFontValue,
      color: {
        r: 255,
        g: 255,
        b: 255,
        a: 1,
      },
      bgColor: '#316EF5',
      borderColor: '#316EF5',
    },
    unselectedStyle = {
      ...DefaultFontValue,
      bgColor: '#fff',
      borderColor: '#EBEDF0',
    },
    ...rest
  } = props;
  const [selectedValue, setSelectedValue] = useControllableValue(rest);

  const handleClick = (
    optionValue: string | number,
    optionDisabled?: boolean,
  ) => {
    if (groupDisabled || optionDisabled) return;

    setSelectedValue(optionValue);
  };

  const optionStyle = useMemo<{
    selected: React.CSSProperties;
    unselected: React.CSSProperties;
  }>(() => {
    const selectedColor = selectedStyle.color;
    const unselectedColor = unselectedStyle.color;
    return {
      selected: {
        color: selectedColor
          ? `rgba(${selectedColor.r}, ${selectedColor.g}, ${selectedColor.b}, ${selectedColor.a})`
          : undefined,
        backgroundColor: selectedStyle.bgColor,
        borderColor: selectedStyle.borderColor,
        fontSize: selectedStyle.fontSize,
        fontWeight: selectedStyle.bold ? 'bold' : 'normal',
        fontStyle: selectedStyle.italic ? 'italic' : 'normal',
        textDecoration: selectedStyle.underline ? 'underline' : 'none',
      },
      unselected: {
        color: unselectedColor
          ? `rgba(${unselectedColor.r}, ${unselectedColor.g}, ${unselectedColor.b}, ${unselectedColor.a})`
          : undefined,
        backgroundColor: unselectedStyle.bgColor,
        borderColor: unselectedStyle.borderColor,
        fontSize: unselectedStyle.fontSize,
        fontWeight: unselectedStyle.bold ? 'bold' : 'normal',
        fontStyle: unselectedStyle.italic ? 'italic' : 'normal',
        textDecoration: unselectedStyle.underline ? 'underline' : 'none',
      },
    };
  }, [selectedStyle, unselectedStyle]);

  return (
    <div
      className={cn(
        'flex items-center h-8',
        {
          'h-6': size === 'small',
          'h-8': size === 'middle',
          'h-10': size === 'large',
        },
        className,
      )}
    >
      {options.map((option, index) => (
        <span
          key={option.value}
          className={cn(
            Styles['radio-option'],
            'h-full flex items-center px-2 border cursor-pointer',
            {
              'rounded-l-[8px]': index === 0,
              'rounded-r-[8px]': index === options.length - 1,
              [Styles['radio-option-selected']]: selectedValue === option.value,
            },
          )}
          onClick={() => handleClick(option.value, option.disabled)}
          style={
            selectedValue === option.value
              ? optionStyle.selected
              : optionStyle.unselected
          }
        >
          <span
            className={Styles['radio-label']}
            onClick={() => handleClick(option.value, option.disabled)}
          >
            {option.label}
          </span>
        </span>
      ))}
    </div>
  );
};

export default RadioGroup;
