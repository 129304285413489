import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';

import type { FallbackPanelType } from '@/components/FallbackPanel';
import FallbackPanel from '@/components/FallbackPanel';
import sendWebError from '@/utils/sendWebError';

interface Props {
  children?: ReactNode;
  type?: FallbackPanelType;
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Props, State> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(
      '%ccomponentDidCatch',
      'color:red',
      `{${error}}`,
      errorInfo,
      process.env.NODE_ENV,
    );

    sendWebError({
      title: error,
      desc: errorInfo.componentStack,
    });
  }

  static getDerivedStateFromError() {
    // 更新 state 使下一次渲染可以显示降级 UI
    return { hasError: true };
  }

  render() {
    const { type } = this.props;

    if (this.state?.hasError && type) {
      return <FallbackPanel type={type} />;
    }

    return this.props.children;
  }
}
